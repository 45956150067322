import { Button, Card, Col, Form, FormGroup, Label, Row } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import {
  getDateRangeOptions,
  getResultsSourceOptions,
  getResultsTypeOptions,
  getResultsTypeOptionsAllDefault,
  populateForm,
  processForm,
  validateDateRange,
} from "../lib/forms";
import { gql, useMutation } from "@apollo/client";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import JobStatus from "../JobStatus";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import { Storage } from "aws-amplify";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import { fnTriggerJob } from "../../graphql/mutations";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Export = ({ projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));
  const alertModal = createConfirmation(AlertModal);

  const [jobId, setJobId] = useState(null);

  const resultsSourceOptions = getResultsSourceOptions(projectData);
  const initialResultsSource = resultsSourceOptions[0].value;

  let resultsTypeOptions = getResultsTypeOptions(initialResultsSource, projectData);
  const initialResultsType = getResultsTypeOptionsAllDefault.value;

  const dateRangeOptions = getDateRangeOptions(false);
  const formatOptions = [
    { value: "excel", label: "Excel" },
    { value: "rss", label: "RSS" },
  ];

  const initial = {
    source: initialResultsSource,
    resultsType: initialResultsType,
    dateRange: "1",
    startDate: "",
    startTime: "00:00",
    endDate: "",
    endTime: "00:00",
    format: "excel",
  };
  const { register, handleSubmit, errors, formState, control, setValue, watch, getValues, reset } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["startDate", "startTime", "endDate", "endTime"],
      selects: [
        ["source", resultsSourceOptions, false],
        ["resultsType", resultsTypeOptions, true],
        ["dateRange", dateRangeOptions, false],
        ["format", formatOptions, false],
      ],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  // change result types when result source is changed
  // if there is no sources to select from, metabase will be the source
  const watchResultsSource = watch("source");
  const selectedResultsSource = watchResultsSource ? watchResultsSource.value : null;
  resultsTypeOptions = getResultsTypeOptions(selectedResultsSource ?? initialResultsSource, projectData);

  // reset result types when result source is changed
  useEffect(() => {
    const values = getValues();
    reset({ ...values, resultsType: getResultsTypeOptionsAllDefault });
  }, [selectedResultsSource, getValues, reset]);

  const watchDateRange = watch("dateRange");
  const selectedDateRange = watchDateRange ? watchDateRange.value : null;

  const onSubmit = async (values) => {
    // validate custom date range
    let startDate, startTime, endDate, endTime;
    if (values.dateRange.value === "0") {
      try {
        const validatedDates = validateDateRange(values.startDate, values.startTime, values.endDate, values.endTime);
        startDate = validatedDates.startDate;
        startTime = validatedDates.startTime;
        endDate = validatedDates.endDate;
        endTime = validatedDates.endTime;
      } catch (e) {
        return await alertModal({
          message: e.message,
        });
      }
    }

    try {
      // use validated datetimes
      const formData = await processForm(
        { ...values, startDate, startTime, endDate, endTime },
        { selects: ["source", "resultsType", "dateRange", "format"] }
      );
      if (!formData.source) formData.source = initialResultsSource; // field may not be rendered
      // cognito identity passed for storing in user's private folder
      const jobData = { ...formData, cognitoIdentityId: appContext.cognitoIdentityId };

      // trigger job
      const res = await mutationFnTriggerJob({
        variables: { input: { clientId, projectId, job: "EXPORT", data: JSON.stringify(jobData) } },
      });

      // store jobId to trigger JobStatus
      const jobId = res.data.fnTriggerJob.id;
      setJobId(jobId);
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const onSuccess = async (result) => {
    const url = await Storage.get(result, { level: "private" });
    window.open(url);
    setJobId(null);
  };

  return (
    <>
      <PageTitle title="Export" />
      <Card body className="mb-0 card-fullscreen scroll vh-with-title">
        <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
          {jobId && (
            <JobStatus
              id={jobId}
              type="export"
              successAction="download"
              successButtonIcon="uil-download-alt"
              successButtonText="Download"
              onSuccess={onSuccess}
              onRestart={() => setJobId(null)}
              displayType="FULL"
            />
          )}
          {/* keep form rendered but hidden incase of job retry */}
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={classNames({
              "w-75": true,
              "d-none": jobId,
            })}
          >
            {projectData.resultsSources.length > 1 && (
              <FormSelect
                name="source"
                label="Results source"
                options={resultsSourceOptions}
                required
                formProps={formProps}
              />
            )}
            <FormSelect
              name="resultsType"
              label="Results type"
              options={resultsTypeOptions}
              required
              formProps={formProps}
            />
            <FormSelect name="dateRange" label="Date range" options={dateRangeOptions} required formProps={formProps} />
            {selectedDateRange === "0" && (
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label for="startDate">Start date:</Label>
                    <Row noGutters>
                      <Col xs={7}>
                        <FormInput name="startDate" type="date" required formProps={formProps} />
                      </Col>
                      <Col xs={5}>
                        <FormInput name="startTime" type="time" required formProps={formProps} />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-0">
                    <Label for="startDate">End date:</Label>
                    <Row noGutters>
                      <Col xs={7}>
                        <FormInput name="endDate" type="date" required formProps={formProps} />
                      </Col>
                      <Col xs={5}>
                        <FormInput name="endTime" type="time" required formProps={formProps} />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <FormSelect name="format" label="Export format" options={formatOptions} required formProps={formProps} />
            <div className="d-flex">
              <Button color="primary" className="ml-auto" type="submit" disabled={isSubmitting}>
                {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                Export
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default Export;

import React, { useEffect, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";

import { Container } from "reactstrap";
import LeftSidebar from "../components/LeftSidebar";
import Spinner from "../components/Spinner";
import Topbar from "../components/Topbar";
import { devProjectOverrides } from "../lib/devProjectOverrides";
import { getProject } from "../graphql/queries";
import { subscribeToProjectUpdate } from "../outcider/lib/subscriptions";

const Project = ({ children, openLeftMenu, user, ...rest }) => {
  const apollo = useApolloClient();
  const [project, setProject] = useState(null);

  // get projectId from URL, we don't have a route match at the layout level, only the component level
  const clientId = window.location.pathname.split("/")[1];
  const projectId = window.location.pathname.split("/")[2];

  useEffect(() => {
    let subscriptionProject;

    async function doProject() {
      const project = await apollo.query({
        query: gql(getProject),
        variables: { id: projectId },
      });
      const obj = project.data.getProject;

      const handleProject = (obj) =>
        setProject({
          ...obj,
          ...devProjectOverrides(),
        });

      if (obj === null || (obj && obj.isArchived) || (obj && obj.isDeleted)) {
        // archived or hard or soft deleted
        setProject(false);
      } else {
        // set project
        handleProject(obj);
        // subscribe to changes on this project
        subscriptionProject = subscribeToProjectUpdate(projectId, handleProject);
      }
    }

    doProject();

    return () => {
      if (subscriptionProject) subscriptionProject.unsubscribe();
    };
  }, [apollo, projectId]);

  if (project === null) return <Spinner className="loading-full" />;

  return (
    <div className="layout-project">
      <LeftSidebar
        {...rest}
        // for project links e.g. issues
        project={project}
        // for fixing menu links
        clientId={clientId}
        projectId={projectId}
        // user from layout
        user={user}
      />
      <div className="content-page">
        <div className="content">
          <Topbar
            {...rest}
            openLeftMenuCallBack={openLeftMenu}
            // for project title and client logo
            project={project}
            // for linking to within project
            clientId={clientId}
            projectId={projectId}
            // user from layout
            user={user}
          />
          {/* children is actually a Switch due to nested routes, so we can't pass project down (unless we used context) */}
          <Container fluid>{children}</Container>
        </div>
      </div>
    </div>
  );
};

export default Project;

import "./assets/scss/sass.scss";

import * as Sentry from "@sentry/react";

import { Amplify, Auth } from "aws-amplify";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import ErrorBoundary, { reportToSentry } from "./outcider/lib/errors";
import { INTERCOM_APP_ID, getAppUrl, getPublicApiUrlPrefix } from "./lib/constants";
import React, { createContext, useEffect, useState } from "react";
import { cacheQueryFields, cacheTypeFields } from "./outcider/lib/cache";
import { getS3Public, getThumborImage } from "./lib/files";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

import Color from "color";
import ErrorModal from "./outcider/Modals/ErrorModal";
import { IntercomProvider } from "react-use-intercom";
import { RetryLink } from "@apollo/client/link/retry";
import Routes from "./routes/Routes";
import Spinner from "./components/Spinner";
import awsconfig from "./aws-exports";
import axios from "axios";
import { createConfirmation } from "react-confirm";
import defaultImage from "./assets/img/bg.jpg";
import defaultLogo from "./assets/img/logo.png";
import { getEnv } from "./lib/env";
import { onError } from "@apollo/client/link/error";
import queryString from "query-string";
import { setContext } from "@apollo/client/link/context";

Amplify.configure(awsconfig);

export const AppContext = createContext();

// override Authenticator defaults
const authComponents = {
  SignIn: {
    Header() {
      return <p className="outcider-auth-title outcider-auth-title--padded">Sign in to your account</p>;
    },
  },
  ResetPassword: {
    Header() {
      return <p className="outcider-auth-title">Reset your password</p>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return <p className="outcider-auth-title">Reset your password</p>;
    },
  },
  ForceNewPassword: {
    Header() {
      return <p className="outcider-auth-title">Set your password</p>;
    },
  },
};
const authFormFields = {
  signIn: {
    username: {
      label: "Email Address *",
      placeholder: "Enter your email address",
    },
    password: {
      label: "Password *",
      placeholder: "Enter your password",
    },
  },
  resetPassword: {
    username: {
      label: "Email Address *",
      placeholder: "Enter your email address",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: "Verification Code *",
      placeholder: "Enter verification code sent by email",
    },
    password: {
      label: "New Password *",
      placeholder: "Enter your new password",
    },
    confirm_password: {
      label: "Confirm New Password *",
      placeholder: "Enter your new password again",
    },
  },
  forceNewPassword: {
    password: {
      label: "New Password *",
      placeholder: "Enter your new password",
    },
    confirm_password: {
      label: "Confirm New Password *",
      placeholder: "Enter your new password again",
    },
  },
};

// warn if local development connected to production data
const isDevelopmentConnectedToProduction =
  process.env.NODE_ENV === "development" && awsconfig.aws_user_files_s3_bucket.endsWith("production");

// enable apollo error messages for local development
if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

// APOLLO
// adapted from https://github.com/apollographql/apollo-feature-requests/issues/224

// id token (not access token) needs to be used so that custom resolvers can access custom user claims
const authLink = setContext(async (_, previousContext) => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  const jwtToken = idToken.getJwtToken();
  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      authorization: jwtToken,
    },
  };
});

const httpLink = createHttpLink({
  uri: awsconfig.aws_appsync_graphqlEndpoint,
});

class GraphQLError extends Error {
  constructor(message) {
    super(message);
    this.name = "GraphQLError";
  }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // report graphql errors to sentry (queries may error, mutations are caught individually)
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      // just stringify whatever the server returned
      reportToSentry(new GraphQLError(JSON.stringify(error)));
    }
  }
  // after retrying is of network requests is exhausted, goto error page
  if (networkError) {
    const eventId = reportToSentry(networkError);
    window.location.href = `/?error=${eventId}&next=${window.location.pathname}`;
  }
});

// retry on network errors
const retryLink = new RetryLink();

// caching with custom merge behaviour
const client = new ApolloClient({
  link: from([authLink, errorLink, retryLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: cacheQueryFields,
      },
      ...cacheTypeFields,
    },
  }),
  connectToDevTools: getEnv() === "sandbox",
});

const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [maxDate, setMaxDate] = useState(Date.now());

  // for spinner if waiting to get client
  const [ready, setReady] = useState(false);
  const [subdomainClient, setSubdomainClient] = useState({
    name: "Unifi Media",
    logo: defaultLogo,
    logoIsDefault: true,
    image: defaultImage,
  });

  // default to true to avoid FOUC, better to hide logo and add it later than flash it up
  const [signedIn, setSignedIn] = useState(true);

  // https://github.com/aws-amplify/amplify-js/issues/54
  // store Cognito Identity ID for passing to lambda for putting files (e.g. jobExport)
  const [cognitoIdentityId, setCognitoIdentityId] = useState(null);

  useEffect(() => {
    async function doSubdomain() {
      // see if there is a client subdomain
      const clientSubdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : null;
      // no client subdomain (or subdomain is an environment name, or production) use default styling
      if (!clientSubdomain || (clientSubdomain && (clientSubdomain === getEnv() || clientSubdomain === "unifi"))) {
        setReady(true);
        return;
      }

      // get client using public API
      const clientRes = await axios.get(`${getPublicApiUrlPrefix(null)}/subdomain/${clientSubdomain}`, {
        validateStatus: false, // continue on 404/500
      });

      // if client doesn't exist (unlikely since subdomain shouldn't exist) redirect to root
      if (clientRes.status !== 200) {
        window.location.href = getAppUrl(null);
        return;
      }

      // apply client styles, guard against unparsable colour
      const client = clientRes.data;
      setSubdomainClient({
        name: client.name,
        logo: getS3Public(client.logo),
        logoIsDefault: false,
        image: client.image ? getS3Public(client.image) : defaultImage,
      });
      let clientColour = Color("#172544");
      try {
        clientColour = Color(client.colour || clientColour);
      } catch (e) {}
      const clientColourHex = clientColour.hex();
      document.documentElement.style.setProperty("--client", clientColourHex);

      setReady(true);
    }

    doSubdomain();
  }, []);

  useEffect(() => {
    async function doAuth() {
      // if configuring, nothing to do
      if (authStatus === "configuring") return;

      // clear sentry user when authStatus changes
      Sentry.configureScope((scope) => scope.setUser(null));

      if (authStatus === "unauthenticated") {
        // update UI to not signed in state
        if (document.body) {
          document.body.classList.add("authentication-bg");
          document.body.style = `background-image:url("${subdomainClient.image}")`;
        }
        setSignedIn(false);
        // if we're not at root URL, go there with this url in next param
        if (window.location.pathname !== "/") {
          setReady(false); // show spinner while refreshing
          window.location.href = "/?next=" + window.location.pathname;
        }
      } else {
        // get current signed in state
        const currentSignedIn = signedIn;
        // https://github.com/aws-amplify/amplify-js/issues/54#issuecomment-370262093
        const credentials = await Auth.currentCredentials();
        setCognitoIdentityId(credentials.identityId);
        // update UI to signed in state
        if (document.body) {
          document.body.classList.remove("authentication-bg");
          document.body.style = "";
        }
        setSignedIn(true);
        // if moved from not signed in to signed in, reload page to get new session, go to next
        if (!currentSignedIn) {
          const next = queryString.parse(window.location.search).next;
          setReady(false); // show spinner while refreshing
          if (next) {
            window.location.href = next;
          } else {
            window.location.href = "/";
          }
        }
      }
    }

    doAuth();
  }, [authStatus, signedIn, subdomainClient]);

  // handle errors from mutations
  const errorModal = createConfirmation(ErrorModal);
  const handleError = (error) => {
    if (error.message && error.message.startsWith("[UI]")) {
      // handle UI-facing errors
      errorModal({ message: error.message.substr(5) });
    } else {
      // exception could be from appsync or lambda, lambda has already been reported to sentry (with good debug info)
      // we want to show the report dialog, but this needs an eventId, so we have to report the error again and attach the report to that error
      const eventId = reportToSentry(error);
      errorModal({
        message:
          "We're sorry but an error has occurred. Our technical team have been informed of the error, but you can help us by submitting a crash report about what went wrong.",
        eventId,
      });
    }
  };

  // if there is an error from errorLink show error dialog (don't get involved in routing/layouts which make more network calls)
  const qs = queryString.parse(window.location.search);
  if (qs.error) return <ErrorBoundary eventId={qs.error} next={qs.next} />;

  // spinner while waiting to get client from subdomain (or hard refresh due to auth)
  if (!ready) return <Spinner className="loading-full" />;

  return (
    <ErrorBoundary>
      {isDevelopmentConnectedToProduction && (
        <div className="dev-production-warning">
          <i className="uil-exclamation-triangle mr-2" />
          PRODUCTION
          <i className="uil-exclamation-triangle ml-2" />
        </div>
      )}

      {authStatus !== "configuring" && (
        <>
          {!signedIn && (
            <div className="outcider-auth-logo">
              <img
                src={
                  subdomainClient.logoIsDefault
                    ? subdomainClient.logo
                    : getThumborImage(subdomainClient.logo, 400, 122, true)
                }
                alt={subdomainClient.name}
                style={{ maxWidth: 200, maxHeight: 61 }}
              />
            </div>
          )}

          <Authenticator formFields={authFormFields} components={authComponents} hideSignUp>
            <ApolloProvider client={client}>
              <AppContext.Provider
                value={{
                  title: document.title,
                  handleError,
                  maxDate,
                  setMaxDate,
                  cognitoIdentityId,
                }}
              >
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <Routes></Routes>
                </IntercomProvider>
              </AppContext.Provider>
            </ApolloProvider>
          </Authenticator>

          {!signedIn && <div className="outcider-auth-footer">&copy; Unifi Media</div>}
        </>
      )}
    </ErrorBoundary>
  );
};

const AppContainer = () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);

export default AppContainer;

import React, { useContext, useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import {
  getClient,
  getProject,
  listUserProfiles,
  queryAgents,
  queryCharts,
  queryFilters,
  queryMailingListMembers,
  queryMailingLists,
  queryOrganisations,
  queryPeople,
  queryReports,
  queryTags,
} from "../graphql/queries";
import { getProjectHelpers, sort } from "../lib/utils";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { handleDeleted, refreshRoute } from "../outcider/lib/utils";
import {
  listUserProfilesVariables,
  queryAgentsVariables,
  queryChartsVariables,
  queryFiltersVariables,
  queryMailingListMembersVariables,
  queryMailingListsVariables,
  queryOrganisationsVariables,
  queryPeopleVariables,
  queryReportsVariables,
  queryTagsVariables,
} from "../lib/variables";
import { projectDataFilterFunctions, projectDataSortKey } from "../lib/projectData";

import Admin from "../outcider/Admin";
import Agents from "../outcider/Agents";
import AlertModal from "../outcider/Modals/AlertModal";
import Alerts from "../outcider/Alerts";
import { AppContext } from "../App";
import Charts from "../outcider/Charts";
// not lazy loading at the moment due to http://dimaip.github.io/2020/04/25/deploying-apps-with-code-splitting/
import Color from "color";
import Dashboard from "../outcider/Dashboard";
import Export from "../outcider/Export";
import Lists from "../outcider/Lists";
import Projects from "../outcider/Projects";
import Reports from "../outcider/Reports";
import Results from "../outcider/Results";
import Spinner from "../components/Spinner";
import Stakeholders from "../outcider/Stakeholders";
import Tags from "../outcider/Tags";
import { createConfirmation } from "react-confirm";
import { devProjectOverrides } from "../lib/devProjectOverrides";
import { fetchAllEffect } from "../outcider/lib/effects";
import { getUser } from "../outcider/lib/auth";
import { subscribeToProjectUpdate } from "../outcider/lib/subscriptions";

const OutciderRoute = ({ component: Component, section, can, children, ...rest }) => {
  const history = useHistory();
  const apollo = useApolloClient();

  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [project, setProject] = useState(null);

  const alertModal = createConfirmation(AlertModal);

  useEffect(() => {
    // for project subscriptions
    let subscriptionProject;

    // build user from cognito and UserProfile
    async function doUser() {
      const user = await getUser(apollo);
      setUser(user);
    }

    // get client if there's a clientId param
    async function doClient() {
      const id = rest.computedMatch.params.clientId;
      if (id) {
        const client = await apollo.query({
          query: gql(getClient),
          variables: { id },
        });
        const obj = client.data.getClient;
        // hard or soft deleted
        if (obj === null || (obj && obj.isDeleted)) {
          setClient(false);
        } else {
          setClient(obj);
          // set client colours, guard against unparsable colour
          let clientColour = Color("#172544");
          let clientColourHover = clientColour.darken(0.2).hex();
          let clientColourActive = clientColour.darken(0.4).hex();
          let clientColourHome = clientColour.darken(0.6).hex();
          try {
            clientColour = Color(obj.colour || clientColour);
            clientColourHover = clientColour.darken(0.2).hex();
            clientColourActive = clientColour.darken(0.4).hex();
            clientColourHome = clientColour.darken(0.6).hex();
          } catch (e) {}
          document.documentElement.style.setProperty("--client", clientColour.hex());
          document.documentElement.style.setProperty("--client-hover", clientColourHover);
          document.documentElement.style.setProperty("--client-active", clientColourActive);
          document.documentElement.style.setProperty("--client-home", clientColourHome);
        }
      } else {
        setClient(false);
      }
    }

    // get project if there's a projectId param
    async function doProject() {
      const id = rest.computedMatch.params.projectId;
      if (id) {
        const project = await apollo.query({
          query: gql(getProject),
          variables: { id },
        });
        const obj = project.data.getProject;

        const handleProject = (obj) =>
          setProject({
            ...obj,
            ...devProjectOverrides(),
          });

        if (obj === null || (obj && obj.isArchived) || (obj && obj.isDeleted)) {
          // archived or hard or soft deleted
          setProject(false);
        } else {
          // set project
          handleProject(obj);
          // subscribe to changes on this project
          subscriptionProject = subscribeToProjectUpdate(id, handleProject);
        }
      } else {
        setProject(false);
      }
    }

    doUser();
    doClient();
    doProject();

    return () => {
      if (subscriptionProject) subscriptionProject.unsubscribe();
    };
  }, [apollo, rest.computedMatch.params.clientId, rest.computedMatch.params.projectId]);

  // if there is redirectAlert in the route state, show the alert then clear from state
  useEffect(() => {
    if (history.location.state && history.location.state.redirectAlert) {
      alertModal({ message: history.location.state.redirectAlert });
      refreshRoute(history);
    }
  });

  if (!user || client === null || project === null) return <Spinner className="loading-full" />;

  // if we have client/project, but it was hard/soft deleted, go home
  const usedParams = Object.keys(rest.computedMatch.params);
  if (
    (usedParams.includes("clientId") && client === false) ||
    (usedParams.includes("projectId") && project === false)
  ) {
    return handleDeleted(
      null,
      "project",
      "/",
      "The project you are trying to access does not exist or has been archived or deleted."
    );
  }

  // if we have project, check user can access it
  if (project && !user.can.readProject(project.id)) {
    return handleDeleted(null, "project", "/", "You do not have permission to access this project.");
  }

  // check user can permissions
  if (can && !user.can[can]) {
    return handleDeleted(null, null, "/", "You do not have permission to access this page.");
  }

  // update page title for top-level sections
  let pageTitle = "Unifi Media";
  if (project) pageTitle = project.name + " | " + pageTitle;
  pageTitle = rest.name(project) + " | " + pageTitle;
  document.title = pageTitle;

  // update active menu section
  document.querySelectorAll(".side-nav-item[data-route-section]").forEach((menuItem) => {
    menuItem.classList.remove("mm-active");
    if (menuItem.getAttribute("data-route-section") === section) menuItem.classList.add("mm-active");
  });

  // if we have a project, wrap the route in OutciderProjectRoute to get supporting projectData
  const RouteWrapper = project ? OutciderProjectRoute : OutciderNonProjectRoute;

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <RouteWrapper project={project}>
            <Component {...props} user={user} client={client} project={project} />
          </RouteWrapper>
        );
      }}
    />
  );
};

const OutciderNonProjectRoute = ({ children }) => <>{children}</>;

const OutciderProjectRoute = ({ project, children }) => {
  const appContext = useContext(AppContext);
  const { setMaxDate } = appContext;
  const history = useHistory();
  const { clientId, projectId } = useParams();

  // when the section of the route path changes within a project (e.g. results, dashboard, tags), update maxDate so latest results are fetched
  // for pages with menus (results, stakeholders) we also update when the sub-page changes
  // (maxDate is still used to keep in-page data consistent in the event that new results arrive)
  // NOTE that this resets the pagination on the results page
  const pathSplit = history.location.pathname.split("/");
  let section = pathSplit[3];
  if (section === "results" || section === "social") section = `${section}/${pathSplit[4]}`;
  if (section === "stakeholders") section = `${section}/${pathSplit[4]}/${pathSplit[5]}/${pathSplit[6]}`;
  useEffect(() => {
    setMaxDate(Date.now());
  }, [section, setMaxDate]);

  // DATA REQUIRED FOR GETTING RESULTS - SHARED BETWEEN FUNCTIONS AND UI
  // if updated, change in lib/projectData

  // agents
  const [loadingAgents, setLoadingAgents] = useState(true);
  const { data: dataAgents, error: errorAgents, fetchMore: fetchMoreAgents } = useQuery(gql(queryAgents), {
    variables: queryAgentsVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataAgents, errorAgents, fetchMoreAgents, setLoadingAgents), [
    dataAgents,
    errorAgents,
    fetchMoreAgents,
  ]);

  // filters
  const [loadingFilters, setLoadingFilters] = useState(true);
  const { data: dataFilters, error: errorFilters, fetchMore: fetchMoreFilters } = useQuery(gql(queryFilters), {
    variables: queryFiltersVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataFilters, errorFilters, fetchMoreFilters, setLoadingFilters), [
    dataFilters,
    errorFilters,
    fetchMoreFilters,
  ]);

  // people
  const [loadingPeople, setLoadingPeople] = useState(true);
  const { data: dataPeople, error: errorPeople, fetchMore: fetchMorePeople } = useQuery(gql(queryPeople), {
    variables: queryPeopleVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataPeople, errorPeople, fetchMorePeople, setLoadingPeople), [
    dataPeople,
    errorPeople,
    fetchMorePeople,
  ]);

  // organisations
  const [loadingOrganisations, setLoadingOrganisations] = useState(true);
  const { data: dataOrganisations, error: errorOrganisations, fetchMore: fetchMoreOrganisations } = useQuery(
    gql(queryOrganisations),
    {
      variables: queryOrganisationsVariables({ clientId, projectId }),
    }
  );
  useEffect(
    () => fetchAllEffect(dataOrganisations, errorOrganisations, fetchMoreOrganisations, setLoadingOrganisations),
    [dataOrganisations, errorOrganisations, fetchMoreOrganisations]
  );

  // tags
  const [loadingTags, setLoadingTags] = useState(true);
  const { data: dataTags, error: errorTags, fetchMore: fetchMoreTags } = useQuery(gql(queryTags), {
    variables: queryTagsVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataTags, errorTags, fetchMoreTags, setLoadingTags), [
    dataTags,
    errorTags,
    fetchMoreTags,
  ]);

  // DATA REQUIRED FOR UI ONLY

  // charts
  const [loadingCharts, setLoadingCharts] = useState(true);
  const { data: dataCharts, error: errorCharts, fetchMore: fetchMoreCharts } = useQuery(gql(queryCharts), {
    variables: queryChartsVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataCharts, errorCharts, fetchMoreCharts, setLoadingCharts), [
    dataCharts,
    errorCharts,
    fetchMoreCharts,
  ]);

  // mailing lists
  const [loadingMailingLists, setLoadingMailingLists] = useState(true);
  const { data: dataMailingLists, error: errorMailingLists, fetchMore: fetchMoreMailingLists } = useQuery(
    gql(queryMailingLists),
    {
      variables: queryMailingListsVariables({ clientId, projectId }),
    }
  );
  useEffect(() => fetchAllEffect(dataMailingLists, errorMailingLists, fetchMoreMailingLists, setLoadingMailingLists), [
    dataMailingLists,
    errorMailingLists,
    fetchMoreMailingLists,
  ]);

  // mailing list members
  const [loadingMailingListMembers, setLoadingMailingListMembers] = useState(true);
  const {
    data: dataMailingListMembers,
    error: errorMailingListMembers,
    fetchMore: fetchMoreMailingListMembers,
  } = useQuery(gql(queryMailingListMembers), {
    variables: queryMailingListMembersVariables({ clientId, projectId }),
  });
  useEffect(
    () =>
      fetchAllEffect(
        dataMailingListMembers,
        errorMailingListMembers,
        fetchMoreMailingListMembers,
        setLoadingMailingListMembers
      ),
    [dataMailingListMembers, errorMailingListMembers, fetchMoreMailingListMembers]
  );

  // reports
  const [loadingReports, setLoadingReports] = useState(true);
  const { data: dataReports, error: errorReports, fetchMore: fetchMoreReports } = useQuery(gql(queryReports), {
    variables: queryReportsVariables({ clientId, projectId }),
  });
  useEffect(() => fetchAllEffect(dataReports, errorReports, fetchMoreReports, setLoadingReports), [
    dataReports,
    errorReports,
    fetchMoreReports,
  ]);

  // social author groups (if project uses social)
  // const [loadingSocialAuthorGroups, setLoadingSocialAuthorGroups] = useState(project.socialUsed);
  // const [dataSocialAuthorGroups, setDataSocialAuthorGroups] = useState([]);
  // useEffect(() => {
  //   async function doSocialAuthorGroups() {
  //     const response = await axios.get(getS3Public(SOCIAL360_AUTHOR_GROUPS_KEY));
  //     const socialAuthorGroups = response.data;
  //     setLoadingSocialAuthorGroups(false);
  //     setDataSocialAuthorGroups(socialAuthorGroups);
  //   }

  //   doSocialAuthorGroups();
  // }, [setLoadingSocialAuthorGroups, setDataSocialAuthorGroups]);

  // users who can access the current client
  // query will return users on all clients that the user can access, filter it down
  const [loadingUsers, setLoadingUsers] = useState(true);
  const { data: dataUsers, error: errorUsers, fetchMore: fetchMoreUsers } = useQuery(gql(listUserProfiles), {
    variables: listUserProfilesVariables({ clientId }),
  });
  useEffect(() => fetchAllEffect(dataUsers, errorUsers, fetchMoreUsers, setLoadingUsers), [
    dataUsers,
    errorUsers,
    fetchMoreUsers,
  ]);

  // wait until all data is fetched
  if (
    loadingAgents ||
    loadingFilters ||
    loadingPeople ||
    loadingOrganisations ||
    loadingTags ||
    loadingCharts ||
    loadingMailingLists ||
    loadingMailingListMembers ||
    loadingReports ||
    // loadingSocialAuthorGroups ||
    loadingUsers
  ) {
    return <Spinner className="loading-full" />;
  }

  // non-deleted project data
  // (e.g. if a comment has a link to an filter by ID, but the filter has since been deleted, we don't list the filter against that comment)
  const agents = sort(
    dataAgents.queryAgents.items.filter(projectDataFilterFunctions["agents"]),
    projectDataSortKey["agents"]
  );
  const filters = sort(
    dataFilters.queryFilters.items.filter(projectDataFilterFunctions["filters"]),
    projectDataSortKey["filters"]
  );
  const people = sort(
    dataPeople.queryPeople.items.filter(projectDataFilterFunctions["people"]),
    projectDataSortKey["people"]
  );
  const organisations = sort(
    dataOrganisations.queryOrganisations.items.filter(projectDataFilterFunctions["organisations"]),
    projectDataSortKey["organisations"]
  );
  const tags = sort(dataTags.queryTags.items.filter(projectDataFilterFunctions["tags"]), projectDataSortKey["tags"]);
  const charts = sort(
    dataCharts.queryCharts.items.filter(projectDataFilterFunctions["charts"]),
    projectDataSortKey["charts"]
  );
  const mailingLists = sort(
    dataMailingLists.queryMailingLists.items.filter(projectDataFilterFunctions["mailingLists"]),
    projectDataSortKey["mailingLists"]
  );
  const mailingListMembers = sort(
    dataMailingListMembers.queryMailingListMembers.items.filter(projectDataFilterFunctions["mailingListMembers"]),
    projectDataSortKey["mailingListMembers"]
  );
  const reports = sort(
    dataReports.queryReports.items.filter(projectDataFilterFunctions["reports"]),
    projectDataSortKey["reports"]
  );
  // const socialAuthorGroups = sort(dataSocialAuthorGroups, projectDataSortKey["socialAuthorGroups"]);

  // users who can access client
  const users = sort(
    dataUsers.listUserProfiles.items.filter(projectDataFilterFunctions["users"]),
    projectDataSortKey["users"]
  );

  return (
    <>
      {React.cloneElement(children, {
        projectData: {
          agents,
          filters,
          people,
          organisations,
          tags,
          charts,
          mailingLists,
          mailingListMembers,
          reports,
          // socialAuthorGroups,
          users,
          ...getProjectHelpers(project),
        },
      })}
    </>
  );
};

// Vertical, NonProject and LeftSidebar not set up for nesting of root routes
const rootRoutes = [
  {
    path: "/",
    name: () => "Projects",
    exact: true,
    icon: "uil-cog", // not shown in LeftSidebar
    component: Projects,
    section: "projects",
  },
  {
    path: "/admin",
    name: () => "Admin",
    exact: true,
    icon: "uil-cog",
    component: Admin,
    section: "admin",
    can: "admin",
  },
];

const projectRoutes = [
  {
    path: "/:clientId/:projectId/dashboard",
    name: () => "Dashboard",
    icon: "uil-apps",
    component: Dashboard,
    section: "dashboard",
  },
  {
    path: "/:clientId/:projectId/results/:resultsType",
    name: ({ socialUsed }) => (socialUsed ? "News Results" : "Results"),
    icon: "uil-file-search-alt",
    component: (props) => <Results {...props} source="METABASE" />,
    section: "results",
  },
  {
    path: "/:clientId/:projectId/social/:resultsType",
    name: () => "Social Results",
    icon: "uil-file-bookmark-alt",
    component: (props) => <Results {...props} source="SOCIAL360" />,
    section: "social",
    visible: ({ socialUsed }) => socialUsed,
  },
  {
    path: "/:clientId/:projectId/stakeholders/:entityType/:stakeholderType/:resultsType",
    name: () => "Stakeholders",
    icon: "uil-users-alt",
    component: Stakeholders,
    section: "stakeholders",
  },
  {
    path: "/:clientId/:projectId/reports",
    name: () => "Reports",
    icon: "uil-newspaper",
    component: Reports,
    section: "reports",
    can: "reports",
  },
  {
    path: "/:clientId/:projectId/alerts",
    name: () => "Alerts",
    icon: "uil-bell",
    component: Alerts,
    section: "alerts",
  },
  {
    path: "/:clientId/:projectId/charts",
    name: () => "Charts",
    icon: "uil-chart-line",
    component: Charts,
    section: "charts",
  },
  {
    path: "/:clientId/:projectId/lists",
    name: () => "Mailing Lists",
    icon: "uil-envelopes",
    component: Lists,
    section: "lists",
  },
  {
    path: "/:clientId/:projectId/export",
    name: () => "Export",
    icon: "uil-download-alt",
    component: Export,
    section: "export",
    can: "exports",
  },
  {
    path: "/:clientId/:projectId/tags",
    name: () => "Tags",
    icon: "uil-tag-alt",
    component: Tags,
    section: "tags",
  },
  {
    path: "/:clientId/:projectId/agents",
    name: () => "Search Agents",
    icon: "uil-search-alt",
    component: Agents,
    section: "agents",
    can: "agents",
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];
  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);
    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const allRoutes = [...projectRoutes, ...rootRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { OutciderRoute, allFlattenRoutes, allRoutes, projectRoutes, rootRoutes };

import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useContext } from "react";
import { createProject, updateProject } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { inputValidateTimestamp, populateForm, processForm } from "../lib/forms";

import { AI_ANALYST_PROMPT } from "../../lib/aiAnalyst";
import AlertModal from "./AlertModal";
import { AppContext } from "../../App";
import FormFile from "../Forms/FormFile";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import filter from "lodash/filter";
import { listProjects } from "../../graphql/queries";
import { listProjectsVariables } from "../../lib/variables";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const HOURS = [...Array(24).keys()].map((x) => ({
  value: x,
  label: `${`${x < 10 ? "0" : ""}${x}`}:00`,
}));

// tidy up timezone list, just keep country-level ones (contain slash), plus UTC
const TIMEZONES = [
  ...filter(moment.tz.names(), (name) => name.indexOf("/") !== -1 && name.indexOf("Etc/") === -1),
  "UTC",
].map((x) => ({ value: x, label: x }));

const ProjectModal = ({ data: { data, op }, toggle, isAlerts }) => {
  const appContext = useContext(AppContext);
  const [mutationCreateProject] = useMutation(gql(createProject));
  const [mutationUpdateProject] = useMutation(gql(updateProject));
  const alertModal = createConfirmation(AlertModal);

  const defaultValues = {
    timezone: "Europe/London",
    topStoriesViewAll: true,
    comprehendEnabled: false,
    socialEnabled: false,
    aiAnalystEnabled: false,
    dailyAlertAuto: false,
    dailyAlertHour: 9,
    dailyAlertTopAll: true,
  };
  const initial = op === "CREATE" ? { ...defaultValues } : { ...defaultValues, ...data };

  const { register, handleSubmit, errors, formState, control, setValue, watch } = useForm({
    defaultValues: populateForm(initial, {
      fields: [
        "name",
        "description",
        "aiAnalystPromptWho",
        "aiAnalystPromptTopic",
        "aiAnalystPromptFocus",
        "dailyAlertLastSent",
      ],
      switches: [
        ["topStoriesViewAll", true],
        ["comprehendEnabled", false],
        ["socialEnabled", false],
        ["aiAnalystEnabled", false],
        ["dailyAlertAuto", false],
        ["dailyAlertTopAll", true],
      ],
      selects: [
        ["timezone", TIMEZONES],
        ["dailyAlertHour", HOURS],
      ],
      files: ["image"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      if (op === "CREATE") {
        const input = await processForm(
          {
            id: uuid(),
            clientId: data,
            ...values,
            // set comprehendUsed if comprehend is enabled from the start of the project being created
            comprehendUsed: values.comprehendEnabled,
            // set socialUsed if social is enabled from the start of the project being created
            socialUsed: values.socialEnabled,
          },
          { selects: ["timezone", "dailyAlertHour"], files: [["image", null]] }
        );
        await mutationCreateProject({
          variables: { input },
          update: updateCacheCreate(gql(listProjects), listProjectsVariables()),
        });
      } else {
        const newValues = { ...values };
        // selects/files are not on form for Alert Settings modal
        const processFormConfig = isAlerts
          ? { selects: ["dailyAlertHour"] }
          : { selects: ["timezone", "dailyAlertHour"], files: [["image", data.image]] };
        // set comprehendUsed if comprehend has ever been enabled (don't unset it if comprehend is turned off)
        if (values.comprehendEnabled) newValues.comprehendUsed = true;
        // set socialUsed if social has ever been enabled (don't unset it if social is turned off)
        if (values.socialEnabled) newValues.socialUsed = true;
        const input = await processForm({ id: data.id, ...newValues }, processFormConfig);
        await mutationUpdateProject({ variables: { input } });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const watchDailyAlertAuto = watch("dailyAlertAuto");
  const watchAiAnalystEnabled = watch("aiAnalystEnabled");

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  const renderSettings = () => (
    <>
      <FormInput name="name" label="Name" required formProps={formProps} />
      <FormInput name="description" label="Description" type="textarea" rows={5} formProps={formProps} />
      <FormFile
        name="image"
        label="Image"
        accept="image/*"
        current={data.image}
        uploadPath="projects"
        uploadLevel="public"
        formProps={formProps}
      />
      <FormSelect name="timezone" label="Timezone" options={TIMEZONES} required formProps={formProps} />
    </>
  );

  const renderDashboardSettings = () => (
    <>
      <FormGroup className="legend">Dashboard</FormGroup>
      <FormSwitch
        name="topStoriesViewAll"
        label="Default Top Stories view"
        inlineLabelOn="All"
        inlineLabelOff="Manual"
        oneLine
        formProps={formProps}
      />
    </>
  );

  const renderStakeholderSettings = () => (
    <>
      <FormGroup className="legend">Stakeholder Analysis</FormGroup>
      <FormSwitch name="comprehendEnabled" label="Enabled" oneLine formProps={formProps} />
    </>
  );

  const renderSocialSettings = () => (
    <>
      <FormGroup className="legend">Social Results</FormGroup>
      <FormSwitch name="socialEnabled" label="Enabled" oneLine formProps={formProps} />
    </>
  );

  const renderAiAnalystSettings = () => (
    <>
      <FormGroup className="legend">
        AI Analyst
        <i
          className="uil-info-circle click float-right"
          onClick={() =>
            alertModal({
              message: AI_ANALYST_PROMPT.split("\n")
                .filter((p) => p)
                .map((p, i) => <p key={i}>{p}</p>),
            })
          }
        />
      </FormGroup>
      <FormSwitch name="aiAnalystEnabled" label="Enabled" oneLine formProps={formProps} />
      {watchAiAnalystEnabled && (
        <Row>
          <Col>
            <FormInput name="aiAnalystPromptWho" label="Who" required formProps={formProps} />
          </Col>
          <Col>
            <FormInput name="aiAnalystPromptTopic" label="Topic" required formProps={formProps} />
          </Col>
          <Col>
            <FormInput name="aiAnalystPromptFocus" label="Focus" required formProps={formProps} />
          </Col>
        </Row>
      )}
    </>
  );

  const renderAlertsSettings = () => (
    <>
      {!isAlerts && <FormGroup className="legend">Alerts</FormGroup>}
      <FormSwitch
        name="dailyAlertAuto"
        label="Send daily alerts"
        inlineLabelOn="Automatically"
        inlineLabelOff="Manually"
        oneLine
        formProps={formProps}
      />
      {watchDailyAlertAuto && (
        <FormSelect
          name="dailyAlertHour"
          label="Send automatically at this hour in the project timezone"
          options={HOURS}
          required
          formProps={formProps}
        />
      )}
      <FormSwitch
        name="dailyAlertTopAll"
        label="Top Stories to include in daily alert"
        inlineLabelOn="All"
        inlineLabelOff="Manual"
        oneLine
        formProps={formProps}
      />
      <FormInput
        name="dailyAlertLastSent"
        validate={inputValidateTimestamp}
        label="Daily alert last sent"
        helpText="Enter timestamp in UTC format - e.g. 2024-06-07T10:07:59.000Z - or leave blank if never sent or for default behaviour."
        formProps={formProps}
      />
    </>
  );

  return (
    <Modal className="modal-dialog-centered" size={isAlerts ? "md" : "xl"} isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{isAlerts ? "Alert Settings" : <>{title} Project</>}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {/* keep the form simpler if editing alert settings */}
          {isAlerts ? (
            <>{renderAlertsSettings()}</>
          ) : (
            <>
              <Row>
                <Col>{renderSettings()}</Col>
                <Col>
                  {renderDashboardSettings()}
                  {renderStakeholderSettings()}
                  {renderSocialSettings()}
                  {renderAiAnalystSettings()}
                  {renderAlertsSettings()}
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ProjectModal;

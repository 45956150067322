import React, { useEffect } from "react";
import { formatDateTime, getLinkedItemName, getLinkedItemNames } from "../../lib/utils";
import { getResultPendingJobsQuery, resultHasPendingJobsOfType } from "../../lib/resultJobs";
import { gql, useApolloClient } from "@apollo/client";

import { Excerpt } from "../lib/excerpts";
import Highlighter from "react-highlight-words";
import ResultControls from "./ResultControls";
import classNames from "classnames";
import escapeStringRegexp from "escape-string-regexp";
import { languageWordBoundary } from "../../lib/constants";

const ResultsListingItem = ({
  result,
  index,
  resultId,
  source,
  resultsRef,
  resultControlsDuplicateGroupRefetchRef,
  listingDuplicates,
  resultsTypeDerived,
  filter,
  duplicates,
  checked,
  checkResult,
  highlight,
  excerptBaseIsDynamic,
  isMediaBreakpointDownLg,
  closeResult,
  openResult,
  duplicatesToggleModal,
  user,
  projectData,
}) => {
  const apollo = useApolloClient();

  // for spinner of any type of pending job happening
  // const resultHasPendingJobs = !!(result.pendingJobs || []).length;

  // for specific types of pending job happening
  const resultHasPendingJobsAiAnalyst = resultHasPendingJobsOfType(result, "resultaianalyst");

  useEffect(() => {
    let intervalId;
    const pendingJobs = result.pendingJobs || [];

    // poll for changes every 5s
    intervalId = setInterval(async () => {
      if (pendingJobs.length) {
        // there are pending jobs on the result, or a sentinel exists to trigger polling
        // refetch the result, this updates the cache and triggers re-render of this component
        await apollo.query({
          query: gql(getResultPendingJobsQuery),
          variables: { id: result.id },
          fetchPolicy: "network-only",
        });
      } else {
        // no jobs left to poll for
        clearInterval(intervalId);
      }
    }, 5_000);

    // stop polling on unmount
    return () => clearInterval(intervalId);
  }, [apollo, result]);

  return (
    <div
      className={classNames({
        "results-listing-item": true,
        "results-listing-item--active": result.id === resultId,
        "results-listing-item--checked": checked.includes(result.id),
        // in duplicates modal, show deleted status before modal is reloaded
        "results-listing-item--deleted": listingDuplicates && result.isDeleted,
        click: true,
      })}
      ref={(el) => (resultsRef.current[index] = el)}
      onClick={(e) => {
        // hack for not being able to stopPropagation from CustomInput
        if (e.target.className.includes("custom-control")) return;
        // mobile has no right column to view result, do nothing
        if (listingDuplicates && isMediaBreakpointDownLg) return;
        // close/open result
        result.id === resultId ? closeResult() : openResult(result, index);
      }}
    >
      {user.can.updateResult && (
        <ResultControls
          result={result}
          resultControlsDuplicateGroupRefetchRef={resultControlsDuplicateGroupRefetchRef}
          resultsType={resultsTypeDerived}
          filter={filter}
          duplicates={duplicates}
          isListing={true}
          listingDuplicates={listingDuplicates}
          checked={checked.includes(result.id)}
          onCheck={checkResult}
          source={source}
          projectData={projectData}
        />
      )}

      <p
        className={classNames({
          "result-content": true,
          "result-content-title": true,
          "result-content-title-social": result.source === "SOCIAL360",
          "result-content-title-social-with-image": result.source === "SOCIAL360" && result.authorImage,
          "text-primary": true,
          "font-18": true,
          "font-semibold": true,
        })}
      >
        {result.authorImage && (
          <img src={result.authorImage} alt={result.authorName} className="result-content-title-author" />
        )}
        {result.title ? (
          <Highlighter
            searchWords={highlight.terms.map(
              (word) =>
                `${languageWordBoundary(result.language)}${escapeStringRegexp(word)}${languageWordBoundary(
                  result.language
                )}`
            )} // match full words
            autoEscape={false}
            textToHighlight={result.title}
            highlightTag="u"
          />
        ) : (
          "No Title"
        )}
        {(duplicates || []).includes(result.duplicateGroup) && (
          <i
            className="uil-minus-path click ml-1"
            onClick={(e) => {
              e.stopPropagation();
              duplicatesToggleModal(result.duplicateGroup);
            }}
          />
        )}
        {/* {resultHasPendingJobs && (
          <Spinner className="spinner-border-xs spinner-border-xs--resultjob ml-1" tag="span" color="muted" />
        )} */}
      </p>

      <div className="mb-2">
        <Excerpt result={result} highlight={highlight} excerptBaseIsDynamic={excerptBaseIsDynamic} />
      </div>

      <p className="text-muted mb-0">
        {formatDateTime(result.publishedDate, user.timezone)} | {result.sourceName} | {result.sourceType}
        {getLinkedItemName(projectData.filters, result.commentFilterId) && (
          <>
            <br />
            <span className="color-purple">
              <i className="mdi mdi-star result-linked-item result-linked-item--filter" />
              {getLinkedItemName(projectData.filters, result.commentFilterId)}
            </span>
          </>
        )}
        {getLinkedItemNames(projectData.agents, result.agents) && (
          <>
            <br />
            <span className="color-orange">
              <i className="uil-search-alt result-linked-item result-linked-item--agent" />
              {getLinkedItemNames(projectData.agents, result.agents).join(", ")}
            </span>
          </>
        )}
      </p>

      {resultHasPendingJobsAiAnalyst && (
        <div className="badge badge-info-lighten p-1 mt-1 badge--resultjob">
          <i className="uil-robot mr-1" />
          Auto Analyst processing...
        </div>
      )}
    </div>
  );
};

export default ResultsListingItem;

import { Button, ButtonGroup } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import React from "react";
import classNames from "classnames";

const StakeholdersSettings = ({ filterId, isPopup }) => {
  const history = useHistory();
  const { clientId, projectId, entityType, stakeholderType } = useParams();

  return (
    <>
      <ButtonGroup
        className={classNames({
          "mr-2": !isPopup,
          "mb-2": isPopup,
          "d-flex": isPopup,
        })}
      >
        <Button
          outline={stakeholderType !== "all"}
          color="secondary"
          onClick={() =>
            history.push(
              `/${clientId}/${projectId}/stakeholders/${entityType}/all/${filterId ? `issue-${filterId}` : "all"}`
            )
          }
        >
          All
        </Button>
        <Button
          outline={stakeholderType !== "followed"}
          color="secondary"
          onClick={() =>
            history.push(
              `/${clientId}/${projectId}/stakeholders/${entityType}/followed/${filterId ? `issue-${filterId}` : "all"}`
            )
          }
        >
          Followed
        </Button>
        <Button
          outline={stakeholderType !== "tagged"}
          color="secondary"
          onClick={() =>
            history.push(
              `/${clientId}/${projectId}/stakeholders/${entityType}/tagged/${filterId ? `issue-${filterId}` : "all"}`
            )
          }
        >
          Tagged
        </Button>
      </ButtonGroup>
      <ButtonGroup
        className={classNames({
          "d-flex": isPopup,
        })}
      >
        <Button
          outline={entityType !== "people"}
          color="primary"
          onClick={() =>
            history.push(
              `/${clientId}/${projectId}/stakeholders/people/${stakeholderType}/${
                filterId ? `issue-${filterId}` : "all"
              }`
            )
          }
        >
          <i className="uil-users-alt" />
          <span className="ml-1">People</span>
        </Button>
        <Button
          outline={entityType !== "organisations"}
          color="primary"
          onClick={() =>
            history.push(
              `/${clientId}/${projectId}/stakeholders/organisations/${stakeholderType}/${
                filterId ? `issue-${filterId}` : "all"
              }`
            )
          }
        >
          <i className="uil-building" />
          <span className="ml-1">Organisations</span>
        </Button>
      </ButtonGroup>
    </>
  );
};

export default StakeholdersSettings;

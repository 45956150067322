module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  <mj-section mj-class="p-0">
    <mj-column>
      {{#if title}}
        <mj-text mj-class="block-title">{{title}}</mj-text>
      {{/if}}
      {{#if text}}
        <mj-text mj-class="{{#if title}}block-text{{else}}block-text-without-title{{/if}}">{{{text}}}</mj-text>
      {{/if}}
    </mj-column>
  </mj-section>
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->
</mj-wrapper>`
import * as Sentry from "@sentry/react";

import { Button } from "reactstrap";
import React from "react";

export const reportToSentry = (error) => {
  const sentryUser = localStorage.getItem("SentryUser") ? JSON.parse(localStorage.getItem("SentryUser")) : null;
  if (sentryUser) Sentry.setUser(sentryUser);
  const eventId = Sentry.captureException(error);
  return eventId;
};

class ErrorBoundary extends React.Component {
  state = { hasError: false, eventId: null, next: null };

  constructor(props) {
    super(props);
    // boundary hasError if rendered with an eventId from an exception which has already been reported
    this.state = { hasError: Boolean(props.eventId), eventId: props.eventId, next: props.next };
  }

  // catch uncaught errors
  componentDidCatch(error) {
    // const eventId = reportToSentry(error);
    // this.setState({ hasError: true, eventId });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="app" style={{ height: "100vh" }}>
          <div className="h-100 wrapper">
            <div className="h-100 d-flex justify-content-center align-items-center" style={{ padding: "2rem" }}>
              <div className="text-center" style={{ maxWidth: "35%" }}>
                <p className="mb-3">
                  <i className="uil-exclamation-triangle font-24 d-block"></i>
                  <span className="font-bold font-20">We're sorry but an error has occurred.</span>
                </p>
                <p className="mb-4">
                  <span className="font-16 d-block mb-1">Please refresh to try again.</span>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.state.next ? (window.location.href = this.state.next) : window.location.reload()
                    }
                  >
                    <i className="uil-refresh mr-1" />
                    Refresh
                  </Button>
                </p>
                {this.state.eventId && (
                  <p className="mb-0">
                    <span className="font-16 d-block mb-1">
                      Our technical team have been informed of the error, but you can help us by submitting a crash
                      report about what went wrong.
                    </span>
                    <Button
                      color="danger"
                      onClick={() => {
                        const sentryUser = localStorage.getItem("SentryUser")
                          ? JSON.parse(localStorage.getItem("SentryUser"))
                          : null;
                        const user = sentryUser
                          ? {
                              name: sentryUser.username,
                              email: sentryUser.email,
                            }
                          : null;
                        Sentry.showReportDialog({
                          eventId: this.state.eventId,
                          user,
                        });
                      }}
                    >
                      <i className="uil-bug mr-1" />
                      Submit Crash Report
                    </Button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;

import { getResult } from "../graphql/queries";
import { updateResult } from "../graphql/mutations";

export const RESULT_JOB_SENTINEL_PREFIX = "__OUTCIDER_PENDING_JOB_RESULT";

export const getResultJobSentinel = (id) => `${RESULT_JOB_SENTINEL_PREFIX}_${id}__`;

// smaller query with only data required for polling
// also get other fields amended by the AI job (will need refactor when new types of result job are added)
export const getResultPendingJobsQuery = `
  query GetResultPendingJobsQuery($id: ID!) {
    getResult(id: $id) {
      id
      pendingJobs
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
    }
  }
`;

export const resultHasPendingJobsOfType = (result, type) =>
  !!(result.pendingJobs || []).filter((x) => x.includes(type)).length;

export const updateResultPendingJobs = async (log, gateway, resultId, jobId, status) => {
  const sentinelPendingJob = getResultJobSentinel(jobId);
  let res, result;

  // get existing result (may have changed by other jobs if multiple have ran)
  res = await gateway.runQuery({
    operationName: "GetResult",
    query: getResult,
    variables: { id: resultId },
  });
  result = res.getResult;

  // get existing pending jobs
  let pendingJobs = result.pendingJobs || [];
  log.info(`Result has existing jobs: ${JSON.stringify(pendingJobs)}`);

  // add to pending jobs, remove sentinel
  if (["PENDING", "STARTED"].includes(status)) {
    pendingJobs = pendingJobs.filter((x) => x !== sentinelPendingJob);
    pendingJobs.push(jobId);
    log.info(`Adding job: ${jobId}`);
  }

  // remove from pending jobs, remove sentinel (incase we failed before we started)
  if (["SUCCESS", "FAIL"].includes(status)) {
    pendingJobs = pendingJobs.filter((x) => ![jobId, sentinelPendingJob].includes(x));
    log.info(`Removing job: ${jobId}`);
  }

  // update with new pending jobs
  log.info(`Updating pending jobs: ${JSON.stringify(pendingJobs)}`);
  res = await gateway.runQuery({
    operationName: "UpdateResult",
    query: updateResult,
    variables: {
      input: {
        clientId: result.clientId,
        projectId: result.projectId,
        id: resultId,
        pendingJobs,
        // these are part of keys
        duplicateGroupSource: result.duplicateGroupSource,
      },
    },
  });
};

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client p-0" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  <mj-section mj-class="p-0">
    <mj-column mj-class="p-0">
      <mj-image src="{{thumborImage}}" fluid-on-mobile="true" mj-class="p-0" alt="{{imageCaption}}"{{#if imageLink}} href="{{imageLink}}"{{/if}} css-class="report-image-fullwidth" />
    </mj-column>
  </mj-section>
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->
</mj-wrapper>`
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { formatChartType, formatDateRange, formatMetricType, formatResultsType } from "./lib";
import { headerFormatter, textFilter } from "../lib/tables";

import BootstrapTable from "react-bootstrap-table-next";
import ChartBuilder from "./ChartBuilder";
import ChartModal from "./ChartModal";
import PageTitle from "../../components/PageTitle";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { bodyFixedEffect } from "../lib/effects";
import filterFactory from "react-bootstrap-table2-filter";
import find from "lodash/find";
import { getResultSourceNameForCharts } from "../../lib/utils";
import { handleDeleted } from "../lib/utils";
import { titleCase } from "title-case";
import { useParams } from "react-router-dom";

const Charts = ({ user, project, projectData }) => {
  useEffect(bodyFixedEffect);
  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [modal, toggleModal] = useState(null);

  // for nested routes
  const chartId = history.location.pathname.split("/")[4];
  if (chartId) {
    const deleted = handleDeleted(
      find(projectData.charts, (x) => x.id === chartId),
      "chart",
      `/${clientId}/${projectId}/charts`
    );
    if (deleted) return deleted;
  }

  const records = projectData.charts;
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell) => <span className="font-bold">{cell}</span>,
      filter: textFilter,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "metricType",
      text: "Metric",
      sort: true,
      formatter: (cell, row) => formatMetricType(cell, row.source),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "chartType",
      text: "Chart",
      sort: true,
      formatter: formatChartType,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      formatter: (cell) => titleCase(getResultSourceNameForCharts(cell)),
      headerFormatter: headerFormatter,
      hidden: projectData.resultsSources.length === 1,
    },
    {
      dataField: "resultsType",
      text: "Type",
      sort: true,
      formatter: (cell, row) =>
        formatResultsType(cell, row.source, projectData).map((x, index) => (
          <React.Fragment key={index}>
            {x}
            <br />
          </React.Fragment>
        )),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "dateRange",
      text: "Date Range",
      formatter: (cell, row) =>
        formatDateRange(cell, row).map((x) => (
          <React.Fragment key={x}>
            {x}
            <br />
          </React.Fragment>
        )),
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <Switch>
            <Route exact path={path}>
              <PageTitle title="Charts">
                {user.can.updateCharts && (
                  <>
                    {projectData.resultsSourcesForCharts.length === 1 ? (
                      <Button
                        color="success"
                        onClick={() => toggleModal({ data: "METABASE", op: "CREATE" })}
                        className="ml-2"
                      >
                        <i className="uil-plus mr-1" />
                        Create Chart
                      </Button>
                    ) : (
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="success">
                          <i className="uil-plus mr-1" />
                          Create Chart
                        </DropdownToggle>
                        <DropdownMenu>
                          {projectData.resultsSourcesForCharts.map((source) => (
                            <DropdownItem key={source} onClick={() => toggleModal({ data: source, op: "CREATE" })}>
                              {titleCase(getResultSourceNameForCharts(source))}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    )}
                  </>
                )}
              </PageTitle>
              <ToolkitProvider bootstrap4 keyField="id" data={records} columns={columns}>
                {(props) => (
                  <BootstrapTable
                    {...props.baseProps}
                    hover
                    rowClasses="click"
                    defaultSorted={[
                      {
                        dataField: "name",
                        order: "asc",
                      },
                    ]}
                    filter={filterFactory()}
                    classes="bootstrap-table mb-0"
                    wrapperClasses="vh-with-title table-responsive card mb-0"
                    headerWrapperClasses="card-header"
                    selectRow={{
                      selected: chartId ? [chartId] : [],
                      mode: "radio",
                      hideSelectColumn: true,
                      clickToSelect: true,
                      classes: "bootstrap-table-row-active",
                      onSelect: (row, isSelected) => {
                        isSelected ? history.push(`${url}/${row.id}`) : history.push(`${url}`);
                        return false;
                      },
                    }}
                    noDataIndication={
                      <div className="table-empty">
                        <p className="mb-0 text-center">
                          <i className="uil-chart-line font-24 d-block" />
                          There are no charts to display.
                        </p>
                      </div>
                    }
                  />
                )}
              </ToolkitProvider>
            </Route>

            <Route path={`${path}/:chartId`}>
              <ChartBuilder
                key={chartId}
                chart={find(projectData.charts, (x) => x.id === chartId)}
                toggleModal={toggleModal}
                user={user}
                project={project}
                projectData={projectData}
              />
            </Route>
          </Switch>
        </Col>
      </Row>

      {modal && <ChartModal data={modal} toggle={toggleModal} projectData={projectData} />}
    </>
  );
};

export default Charts;

import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";
import { useHistory, useParams } from "react-router";

import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSwitch from "../Forms/FormSwitch";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import pick from "lodash/pick";
import { updateFilter } from "../../graphql/mutations";
import { useForm } from "react-hook-form";

const UpdateModal = ({ data, toggle }) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { filterId } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [mutationUpdateFilter] = useMutation(gql(updateFilter));

  const confirmModal = createConfirmation(ConfirmModal);

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(data, {
      fields: ["name"],
      switches: [
        ["isTop", false],
        ["isVisible", true],
      ],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const handleDelete = async () => {
    if (isDeleting) return;
    const ok = await confirmModal();
    if (!ok) return;
    try {
      setIsDeleting(true);
      const input = pick(data, ["clientId", "projectId", "id"]);
      await mutationUpdateFilter({ variables: { input: { ...input, isDeleted: true } } });
      // go back to all if deleted current issue (can also be called from ResultsMenu)
      if (filterId && data.id === filterId) {
        history.push("all");
      } else {
        toggle();
      }
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  const onSubmit = async (values) => {
    try {
      const input = pick(data, ["clientId", "projectId", "id"]);
      const form = await processForm(values);
      await mutationUpdateFilter({ variables: { input: { ...input, ...form } } });
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Edit Issue</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />
          <FormSwitch
            name="isTop"
            label="Top story rule"
            oneLine
            helpText="All results that match this issue will appear in Top Stories automatically."
            formProps={formProps}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" disabled={isDeleting} onClick={handleDelete} className="mr-auto">
            {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Delete
          </Button>
          <FormSwitch
            name="isVisible"
            inlineLabelOn="Visible"
            inlineLabelOff="Hidden"
            className="mr-2"
            formProps={formProps}
          />
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UpdateModal;

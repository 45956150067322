import { Col, Row } from "reactstrap";
import React, { useContext } from "react";

import { AppContext } from "../../App";
import Chart from "../Charts/Chart";
import get from "lodash/get";
import pick from "lodash/pick";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router";

const ResultsPanelCharts = (props) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  const chartProps = {
    ...pick(props, ["source", "resultsType", "filterParams", "search", "filter", "user", "project", "projectData"]),
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    apollo,
  };

  // if there is an isTop chartFilter, reach chart cannot be rendered (requires refactor)
  const chartFilterHasIsTop = Boolean(get(props.filterParams, "chartQuery.isTop"));

  const renderReachChart = () => {
    let chartType = "line";
    if (props.resultsType === "all") chartType = "quickResultsPanelLineAll";
    if (props.resultsType === "top") chartType = "quickResultsPanelLineTop";
    if (props.resultsType === "manualtop") chartType = "quickResultsPanelLineManualTop";
    if (props.resultsType.startsWith("issue-")) chartType = "quickResultsPanelLineIssue";
    return <Chart title="Reach Frequency" metricType="reachFrequency" chartType={chartType} {...chartProps} />;
  };

  return (
    <>
      {!chartFilterHasIsTop && (
        <Row className="mb-3">
          <Col>{renderReachChart()}</Col>
        </Row>
      )}
      <Row>
        <Col xs={12} xl={6} className="mb-3">
          <Chart title="Sentiment" metricType="sentiment" chartType="pie" {...chartProps} />
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <Chart title="Traffic Light" metricType="trafficLight" chartType="pie" {...chartProps} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={4}>
          <Chart title="Media Type" metricType="mediaType" chartType="column" {...chartProps} />
        </Col>
        <Col xs={12} xl={4} className="mb-3">
          <Chart title="Source Rank" metricType="sourceRank" chartType="bar" {...chartProps} />
        </Col>
        <Col xs={12} xl={4} className="mb-3">
          <Chart title="Source Category" metricType="category" chartType="column" {...chartProps} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={6} className="mb-3">
          <Chart title="Topics" metricType="topic" chartType="pie" {...chartProps} />
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <Chart title="Key Concerns" metricType="concern" chartType="pie" {...chartProps} />
        </Col>
      </Row>
    </>
  );
};

export default ResultsPanelCharts;

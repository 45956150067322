import { Button } from "reactstrap";
import React from "react";

const ResultsError = ({ message, refetch }) => (
  <div className="results-error">
    <p className="mb-0 px-4 text-center">
      <i className="uil-exclamation-triangle font-24 d-block" />
      <span className="d-block mb-2">
        We're sorry but an error has occurred when loading {message ? message : "results"}.
      </span>
      <Button size="sm" onClick={() => refetch()}>
        <i className="uil-refresh mr-1" /> Retry
      </Button>
    </p>
  </div>
);

export default ResultsError;

import { getEnv } from "./env";

const env = getEnv();

export const getProjectSocial360Id = (project) => {
  // for sandbox env use dummy IDs which will return some data
  if (env === "sandbox") {
    return (
      {
        "0ab3ca06-f5a7-4b04-bed7-e7f6140f7fe1": "1686",
      }[project.id] || null
    );
  }
  return project.social360Id;
};

export const getAgentSocial360Id = (agent) => {
  // for sandbox env use dummy IDs which will return some data
  if (env === "sandbox") {
    return (
      {
        "1903c22b-6f37-43b9-b174-3311b93fa715": "20197",
        "1d9a795c-423d-4fbd-898a-1cd42e2eba79": "19105",
        "2d4dfe73-eba2-439c-8cb5-d01ae313b5d4": "19066",
      }[agent.id] || null
    );
  }
  return agent.social360Id;
};

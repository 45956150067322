import { Button, Card, CardBody, Form, InputGroupAddon, Nav, NavItem, Spinner } from "reactstrap";
import React, { useContext } from "react";
import { fnReport, updateReportBlock } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { inputValidateUrl, populateForm, processForm } from "../lib/forms";
import { nonResultBlockOptions, resultBlockOptions, tableBlockOption } from "./lib";

import { AppContext } from "../../App";
import FormEditor from "../Forms/FormEditor";
import FormFile from "../Forms/FormFile";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import { getChartEmbedOptions } from "../Charts/lib";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

const BlockPanel = ({ op, data, setIsUpdating, onClose, projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId, reportId } = useParams();
  const [mutationFnReport] = useMutation(gql(fnReport));
  const [mutationUpdateReportBlock] = useMutation(gql(updateReportBlock));

  const initial =
    op === "CREATE"
      ? {
          type: data.type,
        }
      : data;

  // table option is only available on create, cannot be edited
  const typeOptions =
    initial.type === "TABLE"
      ? [tableBlockOption]
      : [
          {
            label: "Results",
            options: resultBlockOptions,
          },
          {
            label: "Non-Results",
            options:
              op !== "CREATE" ? nonResultBlockOptions.filter((x) => x !== tableBlockOption) : nonResultBlockOptions,
          },
        ];
  const typeOptionsAreGrouped = initial.type !== "TABLE";

  const chartOptions = getChartEmbedOptions(projectData);

  const { register, handleSubmit, errors, formState, control, setValue, watch } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["title", "subtitle", "imageCaption", "imageLink"],
      selects: [
        ["type", typeOptions, typeOptionsAreGrouped],
        ["chartId", chartOptions, false],
      ],
      files: ["image"],
      switches: [
        ["bannerShowTitle", true],
        ["contentsInclude", true],
        ["pdfPageBreakBefore", false],
      ],
      editors: ["text"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const watchType = watch("type");
  const selectedType = watchType ? watchType.value : null;

  const onSubmit = async (values) => {
    try {
      // mutations going through fnReport require a refresh
      let refetch = false;
      setIsUpdating(true);
      const formConfig = {
        selects: ["type", "chartId"],
        files: [["image", data.image]],
        editors: ["text"],
      };
      if (op === "CREATE") {
        const input = await processForm({ reportId, id: uuid(), order: data.index, ...values }, formConfig);
        await mutationFnReport({
          variables: { input: { clientId, projectId, op: "BLOCK_CREATE", data: JSON.stringify(input) } },
        });
        refetch = true;
      } else {
        const input = await processForm({ clientId, projectId, reportId, id: data.id, ...values }, formConfig);
        await mutationUpdateReportBlock({ variables: { input } });
      }
      onClose(refetch);
    } catch (e) {
      setIsUpdating(false);
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="side-panel side-panel--white vh-with-title">
        <Nav tabs className="nav-bordered">
          <NavItem>
            <h4 className="side-panel-title text-title mt-0 mb-0">{title} Block</h4>
          </NavItem>
          <NavItem className="ml-auto side-panel--buttons">
            <Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Save
            </Button>
            <Button color="secondary" size="sm" className="side-panel--close-button-sm" onClick={() => onClose(false)}>
              <i className="uil-times" />
            </Button>
          </NavItem>
        </Nav>

        <CardBody className="pb-2 scroll">
          <FormSelect name="type" label="Type" options={typeOptions} required formProps={formProps} />

          {!["IMAGE", "CHART"].includes(selectedType) && (
            <FormInput
              name="title"
              label={selectedType === "HEADING" ? "Heading" : selectedType === "CONTENTS" ? "Title override" : "Title"}
              formProps={formProps}
            />
          )}

          {selectedType === "HEADING" && <FormInput name="subtitle" label="Subheading" formProps={formProps} />}

          {["TEXT", "CONTENTS"].includes(selectedType) && <FormEditor name="text" label="Text" formProps={formProps} />}

          {["HEADLINE", "LIST", "FOCUS", "IMAGE"].includes(selectedType) && (
            <>
              <hr className="form" />
              <FormFile
                name="image"
                label="Image"
                accept="image/*"
                current={data.image}
                uploadPath="reports/images"
                uploadLevel="public"
                required={selectedType === "IMAGE"}
                formProps={formProps}
              />
              <FormInput name="imageCaption" label="Image caption" formProps={formProps} />
              <FormInput
                name="imageLink"
                validate={inputValidateUrl}
                label="Image link"
                addon={
                  data.imageLink && (
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() => {
                          window.open(data.imageLink);
                        }}
                      >
                        <i className="uil-eye" />
                      </Button>
                    </InputGroupAddon>
                  )
                }
                formProps={formProps}
              />
            </>
          )}

          {["HEADLINE", "LIST", "FOCUS", "CHART"].includes(selectedType) && (
            <>
              <hr className="form" />
              <FormSelect
                name="chartId"
                label="Chart"
                options={chartOptions}
                required={selectedType === "CHART"}
                isClearable={true}
                formProps={formProps}
              />
            </>
          )}

          <hr className="form" />

          {selectedType === "BANNER" && (
            <FormSwitch
              name="bannerShowTitle"
              label="Show title above results"
              inlineLabelOn="Yes"
              inlineLabelOff="No"
              oneLine
              formProps={formProps}
            />
          )}

          {!["CONTENTS", "IMAGE", "CHART"].includes(selectedType) && (
            <FormSwitch
              name="contentsInclude"
              label="Show in contents"
              inlineLabelOn="Yes"
              inlineLabelOff="No"
              oneLine
              formProps={formProps}
            />
          )}

          <FormSwitch
            name="pdfPageBreakBefore"
            label="Insert page break before block (PDF version)"
            inlineLabelOn="Yes"
            inlineLabelOff="No"
            oneLine
            formProps={formProps}
          />
        </CardBody>
      </Card>
    </Form>
  );
};

export default BlockPanel;

module.exports=`{{#if reportShowHeader}}
  <mj-wrapper mj-class="border-top-8px-client">
    <mj-section mj-class="p-0">
      <mj-column mj-class="header">
        {{#if clientLogo}}
          <mj-image mj-class="logo" src="{{clientLogo}}" alt="{{clientName}}" width="150px"></mj-image>
        {{/if}}
        <mj-text mj-class="title">{{reportTitle}}</mj-text>
        <mj-text mj-class="date">{{reportDate}}</mj-text>
        {{#if switches.showWebVersion}}
          <mj-text mj-class="browser"><a href="[__OUTCIDER_WEBVERSION__]" class="browser-link">View in browser</a></mj-text>
        {{/if}}
      </mj-column>
    </mj-section>
  </mj-wrapper>
{{else}}
  <mj-wrapper mj-class="border-top-8px-client p-0"></mj-wrapper>
{{/if}}`
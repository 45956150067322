import { useMediaBreakpointDownLg, useMediaBreakpointDownMd } from "../outcider/lib/effects";

import IntercomToggleTooltips from "./IntercomToggleTooltips";
import ProfileDropdown from "./ProfileDropdown";
import React from "react";
import { getThumborImage } from "../lib/files";

const Topbar = ({ title, project, user, openLeftMenuCallBack }) => {
  const isMediaBreakpointDownMd = useMediaBreakpointDownMd();
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  return (
    <div className="navbar-custom">
      {project && isMediaBreakpointDownMd && (
        <button className="button-menu-mobile open-left" onClick={openLeftMenuCallBack}>
          <i className="mdi mdi-menu"></i>
        </button>
      )}

      {project && !isMediaBreakpointDownLg && (
        <div className="topbar-logo">
          <img
            src={getThumborImage(project.client.logo, 300, 92, true)}
            alt={project.client.name}
            style={{ maxWidth: 150, maxHeight: 46 }}
          />
        </div>
      )}

      {title && <h1 className="topbar-title">{title()}</h1>}

      {project && (
        <h1 className="topbar-title">
          {project.name} {project.isArchived && " (Archived)"}
        </h1>
      )}

      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        <li className="intercom-toggle">
          <IntercomToggleTooltips user={user} />
        </li>
        <li className="notification-list">
          <ProfileDropdown user={user} />
        </li>
      </ul>
    </div>
  );
};

export default Topbar;

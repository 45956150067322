import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getAuthorContactLink, getResultMetadata } from "../../lib/metadata";

import React from "react";

const ResultPanelAnalysis = ({ result, user }) => {
  const metadata = getResultMetadata(result, user, "RESULT_ANALYSIS");

  const renderRowValue = (row) => {
    const key = row[1];
    const value = row[2];

    if (key === "articleOutboundLinks") {
      return value.map((item, index) => (
        <p key={index}>
          <a href={item} rel="noopener noreferrer" target="_blank">
            {item}
          </a>
        </p>
      ));
    }

    if (key === "authorContact") {
      return (
        <a href={getAuthorContactLink(result)} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    }

    if (["articleTopics", "sourceAutoTopics", "authorGroups", "autoAnalystRecommendations"].includes(key)) {
      return value.map((item, index) => <p key={index}>{item}</p>);
    }

    return row[2];
  };

  return (
    <div className="result-analysis">
      {metadata.map((group, index) => (
        <Card key={index}>
          <CardHeader>
            <span className="font-bold">{group[0]}</span>
          </CardHeader>
          <CardBody>
            {group[1].map((row, rowIndex) => (
              <Row key={rowIndex}>
                <Col md={3} className="result-analysis-title">
                  {row[0]}:
                </Col>
                <Col md={9} className="result-analysis-content text-primary">
                  {renderRowValue(row)}
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default ResultPanelAnalysis;

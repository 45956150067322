import {
  METABASE_NEWS_MEDIA_TYPES,
  METABASE_OTHER_MEDIA_TYPES,
  METABASE_SOCIAL_MEDIA_TYPES,
  SOCIAL360_MEDIA_TYPES,
} from "./constants";

import find from "lodash/find";
import get from "lodash/get";
import intersection from "lodash/intersection";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import pull from "lodash/pull";
import reduce from "lodash/reduce";
import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";

// build lucene text query, quote each word
export const buildLuceneTextQuery = ({ any, inConnectionWithAny, inConnectionWithAll, none }) => {
  let query = "(" + any.map((x) => `"${x}"`).join(" OR ") + ")";
  if (inConnectionWithAny) {
    query += " AND (" + inConnectionWithAny.map((x) => `"${x}"`).join(" OR ") + ")";
  }
  if (inConnectionWithAll) {
    query += " AND (" + inConnectionWithAll.map((x) => `"${x}"`).join(" AND ") + ")";
  }
  if (none) {
    query += " NOT (" + none.map((x) => `"${x}"`).join(" OR ") + ")";
  }
  query = "(" + query + ")";
  return query;
};

// build query for social360
export const buildSocialQuery = ({
  any,
  inConnectionWithAny,
  inConnectionWithAll,
  none,
  mediaTypes,
  socialLanguages,
}) => {
  // keyword query
  let query = buildLuceneTextQuery({ any, inConnectionWithAny, inConnectionWithAll, none });

  // media types
  let AF_feed_type = null;
  const socialMediaTypesWithoutPrefix = mediaTypes.filter((x) => x.startsWith("social-")).map((x) => x.substr(7));
  if (socialMediaTypesWithoutPrefix && socialMediaTypesWithoutPrefix.length) {
    AF_feed_type = socialMediaTypesWithoutPrefix.join("|");
  }

  // languages
  let AF_language = null;
  if (socialLanguages && socialLanguages.length) {
    AF_language = socialLanguages.join("|");
  }

  return {
    AQ_text: query,
    AF_feed_type,
    AF_language,
    // only include results from social influencers
    AF_author_data_group: "exists",
    // only include results from public author groups
    // AF_author_data_public: true,
    // only include results that are original parent posts
    // NEW results using indexed date: only want original posts (no retweets etc)
    // UPDATED results using updated date: parent post is updated with engagement stats (in event of retweet etc), child posts (retweets etc) are not stored in the app so we can exclude them
    // NF_parent_id: "exists",
  };
};

// build query for metabase search/filters, quote each word
export const buildMetabaseQuery = ({
  any,
  inConnectionWithAny,
  inConnectionWithAll,
  none,
  mediaTypes,
  newsSourceRanks,
  socialSourceRanks,
  sourceNames,
  sourceNamesInclude,
  sourceDomains,
  sourceDomainsInclude,
  sourceCategories,
  sourceCategoriesInclude,
  countries,
  articleTopics,
  articleTopicsInclude,
  languages,
}) => {
  // keyword query, any is always used
  let query = buildLuceneTextQuery({ any, inConnectionWithAny, inConnectionWithAll, none });

  const feedClass = [];

  const newsMediaTypesWithPrefix = METABASE_NEWS_MEDIA_TYPES.map((x) => `metabase-${x}`);
  const socialMediaTypesWithPrefix = METABASE_SOCIAL_MEDIA_TYPES.map((x) => `metabase-${x}`);
  const otherMediaTypesWithPrefix = METABASE_OTHER_MEDIA_TYPES.map((x) => `metabase-${x}`);

  // add news/print mediaType if used and its source ranks (1-5)
  if (intersection(mediaTypes, newsMediaTypesWithPrefix).length) {
    const newsTypes = intersection(mediaTypes, newsMediaTypesWithPrefix).map((x) => `"${x.substr(9)}"`);
    // each feedClass needs to be added separately with rankings
    for (const newsType of newsTypes) {
      feedClass.push(
        newsSourceRanks
          ? `(feedClass:${newsType} AND sourceRank:(${newsSourceRanks.join(" OR ")}))`
          : `(feedClass:${newsType})`
      );
    }
  }

  // add social mediaType if used and its source ranks (1-10)
  if (intersection(mediaTypes, socialMediaTypesWithPrefix).length) {
    const socialTypes = intersection(mediaTypes, socialMediaTypesWithPrefix).map((x) => `"${x.substr(9)}"`);
    // each feedClass needs to be added separately with rankings
    for (const socialType of socialTypes) {
      feedClass.push(
        socialSourceRanks
          ? `(feedClass:${socialType} AND sourceRank:(${socialSourceRanks.join(" OR ")}))`
          : `(feedClass:${socialType})`
      );
    }
  }

  // add other mediaTypes if used
  if (intersection(mediaTypes, otherMediaTypesWithPrefix).length) {
    const otherTypes = intersection(mediaTypes, otherMediaTypesWithPrefix).map((x) => `"${x.substr(9)}"`);
    // each feedClass needs to be added separately
    for (const otherType of otherTypes) {
      feedClass.push(`(feedClass:${otherType})`);
    }
  }

  query += ` AND (${feedClass.join(" OR ")})`;

  // source names/domains/category/country (`source` work across both Metabase search/filters, whereas `sourceName` does not)
  if (sourceNames)
    query += ` ${sourceNamesInclude ? "AND" : "NOT"} source:(${sourceNames.map((x) => `"${x}"`).join(" OR ")})`;
  if (sourceDomains)
    query += ` ${sourceDomainsInclude ? "AND" : "NOT"} site:(${sourceDomains.map((x) => `"${x}"`).join(" OR ")})`;
  if (sourceCategories)
    query += ` ${sourceCategoriesInclude ? "AND" : "NOT"} sourceCategory:(${sourceCategories
      .map((x) => `"${x}"`)
      .join(" OR ")})`;
  if (countries) query += ` AND sourceCountry:(${countries.map((x) => `"${x}"`).join(" OR ")})`;

  // article topics
  if (articleTopics)
    query += ` ${articleTopicsInclude ? "AND" : "NOT"} category:(${articleTopics.map((x) => `"${x}"`).join(" OR ")})`;

  // languages
  if (languages) query += ` AND language:(${languages.map((x) => `"${x}"`).join(" OR ")})`;

  return query;
};

// throw if source is incorrect
const validateSource = (source) => {
  if (!(source === "ALL" || source === "METABASE" || source === "SOCIAL360"))
    throw new Error("Invalid source was provided.");
};

// throw if projectData does not contain all keys required for getting results
const validateProjectData = (projectData) => {
  const keys = Object.keys(projectData);
  const required = ["agents", "filters", "people", "organisations", "tags"];
  if (!required.every((x) => keys.includes(x)))
    throw new Error(`Project data does not contain all required data, got ${keys}, required ${required}.`);
};

// build ES params for a resultsType, which may combine multiple calls to buildSearchResultsParams and OR the params together
export const buildSearchResultsTypeParams = ({
  source,
  resultsType,
  resultsTypeIssues = [],
  resultsTypeIssuesTop = null,
  filterParams,
  highlight,
  aggs,
  projectData,
}) => {
  validateSource(source);
  validateProjectData(projectData);

  let params;
  let topLevelQuery;

  // straight through to buildSearchResultsParams
  // highlight/aggs will be at the top level
  if (
    resultsType === "all" ||
    resultsType === "manualtop" ||
    resultsType === "deleted" ||
    resultsType.startsWith("search-") ||
    resultsType.startsWith("issue-")
  ) {
    // throw if getting search/filter, but no query has been passed in
    if ((resultsType.startsWith("search-") || resultsType.startsWith("issue-")) && !filterParams.query)
      throw new Error("Attempted to use search/filter but no query filterParam was provided.");

    // start with the filterParams provided
    const fp = { ...filterParams };

    // add isDeleted=false, unless viewing deleted results
    fp.isDeleted = resultsType === "deleted";

    // add isTop=true if viewing manualtop results
    if (resultsType === "manualtop") fp.isTop = true;

    params = buildSearchResultsParams({
      source,
      filterParams: fp,
      highlight,
      aggs,
      resultsType,
      projectData,
    });
  }

  // all top stories is a combination of results from top story issues, plus results with manual isTop, minus results with isNotTop
  if (resultsType === "top") {
    // store filterParams.query to apply at the end
    topLevelQuery = filterParams.query;

    // get all top story issues, of the specified source
    let topStoryIssues = projectData.filters.filter((x) => x.isTop);
    if (source !== "ALL") topStoryIssues = topStoryIssues.filter((x) => x.source === source);

    // get params for manual isTop results
    const manualParamsQuery = buildSearchResultsParams({
      source,
      filterParams: { ...filterParams, isTop: true },
      resultsType,
      projectData,
    }).query;

    // get params for each top story issue
    const topStoryParams = [manualParamsQuery];
    topStoryIssues.forEach((issue) => {
      const issueParamsQuery = buildSearchResultsParams({
        source,
        filterParams: { ...filterParams, query: JSON.parse(issue.query) },
        resultsType,
        projectData,
      }).query;
      topStoryParams.push(issueParamsQuery);
    });

    // match at least one top story issue, but not isNotTop
    params = {
      query: {
        bool: {
          should: topStoryParams,
          must_not: { term: { isNotTop: true } },
          minimum_should_match: 1,
        },
      },
    };
  }

  // results matching one or many issues, looking up issue IDs using projectData (used on charts)
  // resultsTypeIssuesTop (top/manualtop) can be used to get only top stories from the issue
  if (resultsType === "issues") {
    // store filterParams.query to apply at the end
    topLevelQuery = filterParams.query;

    // get all issues, filter down if resultsTypeIssues array is provided, of the specified source
    let issues = projectData.filters;
    if (resultsTypeIssues.length) issues = issues.filter((x) => resultsTypeIssues.includes(x.id));
    if (source !== "ALL") issues = issues.filter((x) => x.source === source);

    // get params for each issue
    const issuesParams = [];
    issues.forEach((issue) => {
      const issueFilterParams = { ...filterParams, query: JSON.parse(issue.query) };

      // if viewing manual only, only keep manually topped
      if (resultsTypeIssuesTop === "manualtop") issueFilterParams.isTop = true;

      // if viewing all...
      if (resultsTypeIssuesTop === "top") {
        if (issue.isTop) {
          // issue is top story issue, just keep results that have NOT been excluded
          // (everything else is a top story by definition of being in the issue)
          issueFilterParams.isNotTop = false;
        } else {
          // issue is normal issue, just keep isTop
          issueFilterParams.isTop = true;
        }
      }

      const issueParamsQuery = buildSearchResultsParams({
        source,
        filterParams: issueFilterParams,
        resultsType,
        projectData,
      }).query;
      issuesParams.push(issueParamsQuery);
    });

    // match at least one issue
    params = {
      query: {
        bool: {
          should: issuesParams,
          minimum_should_match: 1,
        },
      },
    };
  }

  // stop if params not defined, unsupported resultsType
  if (!params) throw new Error(`Unsupported results type: ${resultsType}.`);

  // start with the set of filters generated above
  const filters = [params.query];

  // if there is a topLevelQuery (because the query is top/issues), AND it with the query already generated
  if (topLevelQuery) {
    const queryFromQuery = buildSearchResultsParams({
      source,
      filterParams: { ...filterParams, query: topLevelQuery },
      resultsType,
      projectData,
    }).query;
    filters.push(queryFromQuery);
  }

  // if there is a chartQuery, AND it with the query already generated
  if (filterParams.chartQuery) {
    const queryFromChartQuery = buildSearchResultsParams({
      source,
      filterParams: { ...filterParams, query: filterParams.chartQuery },
      resultsType,
      projectData,
    }).query;
    filters.push(queryFromChartQuery);
  }

  // build final params
  params = {
    query: {
      bool: {
        filter: filters,
      },
    },
  };

  // apply highlight/aggs at top level
  // may have been through the top/issues logic, where we don't want highlight/aggs to be nested deep in the query
  if (highlight) params.highlight = buildSearchResultHighlightParam(highlight);
  if (aggs) params.aggs = buildSearchResultAggsParam(aggs);

  return params;
};

// build ES params for a single set of filters
export const buildSearchResultsParams = ({ source, filterParams, highlight, aggs, resultsType, projectData }) => {
  validateSource(source);
  validateProjectData(projectData);

  const {
    // basic filters
    clientId,
    projectId,
    id,
    dateRange,
    dateRangeUTCField = "createdDate",
    startDate,
    startTime,
    endDate,
    endTime,
    maxDate,
    duplicateGroup,
    hasImage,
    priorityIssueId,
    isDeleted, // set by buildSearchResultsTypeParams
    isTop, // set by buildSearchResultsTypeParams
    isNotTop, // set by buildSearchResultsTypeParams
    query, // query for search/filters
  } = filterParams;

  let filter = [];
  let mustNot = [];

  // ensure maxDate is set
  if (!maxDate) throw new Error("No maxDate passed to buildSearchResultsParams.");

  // source
  if (source !== "ALL") filter.push({ term: { "source.keyword": source } });

  // client/project/result
  filter.push({ term: { "clientId.keyword": clientId } });
  filter.push({ term: { "projectId.keyword": projectId } });
  if (id) filter.push({ term: { "id.keyword": id } });

  // date range (created date)
  if (dateRange) {
    if (dateRange === "0" || dateRange === "comparison") {
      // specify from UI (local time)
      // for the moment we assume that browser supports date/time <input> and therefore formats are YYYY-MM-DD and HH24:MM
      // input is local time, convert to UTC iso string
      const gte = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm").toISOString();
      const lte = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm").toISOString();
      filter.push({ range: { createdDate: { gte, lte } } });
    } else if (dateRange === "UTC") {
      // specify in UTC with provided ISO strings (used for alerts) using GTE and LTE
      const gte = startDate;
      const lte = endDate;
      filter.push({ range: { [dateRangeUTCField]: { gte, lte } } });
    } else if (dateRange === "UTC_GT") {
      // specify in UTC with provided ISO strings (used for public feed) using GT and LTE
      const gt = startDate;
      const lte = endDate;
      filter.push({ range: { [dateRangeUTCField]: { gt, lte } } });
    } else {
      // relative, using epoch
      filter.push({ range: { createdDate: { gte: `now-${dateRange}d`, lte: maxDate } } });
    }
  } else {
    // always set maxDate, using epoch
    filter.push({ range: { createdDate: { lte: maxDate } } });
  }

  // duplicate group (for listing duplicates)
  if (duplicateGroup) filter.push({ term: { "duplicateGroup.keyword": duplicateGroup } });

  // with image
  if (hasImage) filter.push({ exists: { field: "image" } });

  // assigned to priority issue
  if (priorityIssueId) filter.push({ term: { "commentFilterId.keyword": priorityIssueId } });

  // remove deleted (unless listing deleted results)
  filter.push({ term: { isDeleted: isDeleted || false } });

  // manually topped
  if (isTop) filter.push({ term: { isTop: true } });

  // exclude isNotTop (just keep results that have NOT been excluded)
  // (if isNotTop not provided, this is null, hence the false check)
  if (isNotTop === false) filter.push({ term: { isNotTop } });

  // get items from query
  const {
    isTop: chartQueryIsTop,
    keyword,
    sourceName,
    sourceDomain,
    sentiment,
    trafficLight,
    topic,
    concern,
    mediaType,
    language,
    country,
    category,
    reach,
    mozrank,
    agent,
    person,
    organisation,
    entityGroup,
    articleTopic,
    authorGroups,
    authorName,
    authorEmail,
    // range fields e.g. socialEngagement are not destructured but handled in loop below
  } = query || {};

  // keywords (and entities/groups) are handled with ES `query_string`
  if (
    (keyword && keyword.active) ||
    (person && person.active) ||
    (organisation && organisation.active) ||
    (entityGroup && entityGroup.active)
  ) {
    let queryString = [];

    // keywords
    if (keyword && keyword.active) {
      // "within" field for any/+any/+all and proximity fields
      const includeSearchFields = {
        all: ["title", "content"],
        content100: ["content100"],
        title: ["title"],
      }[keyword.within || "all"];
      // "within" field for none field
      const excludeSearchFields = {
        all: ["title", "content"],
        content100: ["content100"],
        title: ["title"],
      }[keyword.withinExclude || "all"];

      if (keyword.any)
        queryString.push(
          "(" + keyword.any.map((x) => includeSearchFields.map((f) => `${f}:"${x}"`).join(" OR ")).join(" OR ") + ")"
        );
      if (keyword.inConnectionWithAny)
        queryString.push(
          "(" +
            keyword.inConnectionWithAny
              .map((x) => includeSearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
              .join(" OR ") +
            ")"
        );
      if (keyword.inConnectionWithAll)
        queryString.push(
          "(" +
            keyword.inConnectionWithAll
              .map((x) => includeSearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
              .join(" AND ") +
            ")"
        );
      if (keyword.none)
        queryString.push(
          "NOT (" +
            keyword.none.map((x) => excludeSearchFields.map((f) => `${f}:"${x}"`).join(" OR ")).join(" OR ") +
            ")"
        );
      if (keyword.proximity1 && keyword.proximity2 && keyword.proximityDistance)
        queryString.push(
          "(" +
            includeSearchFields
              .map((f) => `${f}:"${keyword.proximity1} ${keyword.proximity2}"~${keyword.proximityDistance}`)
              .join(" OR ") +
            ")"
        );
    }

    let personNames = { true: [], false: [] },
      organisationNames = { true: [], false: [] },
      entitySearchFields = ["title", "content", "authorName"];

    // get names/alternativeNames from person/organisation
    if (person && person.active) {
      for (const id of person.query) {
        const entity = find(projectData.people, (x) => x.id === id);
        if (entity)
          personNames[person.include].push(
            entity.uniqueName,
            ...(entity.alternativeNames || []).map((x) => x.toLowerCase())
          );
      }
    }
    if (organisation && organisation.active) {
      for (const id of organisation.query) {
        const entity = find(projectData.organisations, (x) => x.id === id);
        if (entity)
          organisationNames[organisation.include].push(
            entity.uniqueName,
            ...(entity.alternativeNames || []).map((x) => x.toLowerCase())
          );
      }
    }

    // if there are groups, add the names from people/organisations in those groups
    if (entityGroup && entityGroup.active) {
      for (const id of entityGroup.query) {
        const peopleInGroup = projectData.people.filter((x) => (x.tags || []).includes(id));
        for (const entity of peopleInGroup)
          personNames[entityGroup.include].push(
            entity.uniqueName,
            ...(entity.alternativeNames || []).map((x) => x.toLowerCase())
          );
        const organisationsInGroup = projectData.organisations.filter((x) => (x.tags || []).includes(id));
        for (const entity of organisationsInGroup)
          organisationNames[entityGroup.include].push(
            entity.uniqueName,
            ...(entity.alternativeNames || []).map((x) => x.toLowerCase())
          );
      }
    }

    // join onto querystring, remove duplicates (entity may be specifed via group and directly)
    if (uniq(personNames[true]).length)
      queryString.push(
        "(" +
          uniq(personNames[true])
            .map((x) => entitySearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
            .join(" OR ") +
          ")"
      );
    if (uniq(personNames[false]).length)
      queryString.push(
        "NOT (" +
          uniq(personNames[false])
            .map((x) => entitySearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
            .join(" OR ") +
          ")"
      );
    if (uniq(organisationNames[true]).length)
      queryString.push(
        "(" +
          uniq(organisationNames[true])
            .map((x) => entitySearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
            .join(" OR ") +
          ")"
      );
    if (uniq(organisationNames[false]).length)
      queryString.push(
        "NOT (" +
          uniq(organisationNames[false])
            .map((x) => entitySearchFields.map((f) => `${f}:"${x}"`).join(" OR "))
            .join(" OR ") +
          ")"
      );

    if (queryString.length) {
      queryString = queryString.join(" AND ");
      filter.push({ query_string: { query: queryString } });
    }
  }

  // true is includes (ES filter), false is excludes (ES must_not)
  const queryFilters = { true: [], false: [] };

  // isTop filter from clicking on chart, to filter issue results by top story status
  if (chartQueryIsTop && chartQueryIsTop.active) {
    // expect resultsType
    if (!resultsType)
      throw new Error("No resultsType passed to buildSearchResultsParams but required for chartQueryIsTop.");
    // must be of a single issue
    if (!resultsType.startsWith("issue-"))
      throw new Error("Invalid resultsType passed to buildSearchResultsParams but required for chartQueryIsTop.");

    const issueId = resultsType.substr(6);
    const issue = projectData.filters.find((x) => x.id === issueId);
    if (issue.isTop) {
      // issue is top story issue, just keep results that have NOT been excluded
      queryFilters[true].push({ term: { isNotTop: false } });
    } else {
      // issue is normal issue, just keep isTop
      queryFilters[true].push({ term: { isTop: true } });
    }
  }

  // source name (case sensitive match)
  if (sourceName && sourceName.active)
    queryFilters[sourceName.include].push({ terms: { "sourceName.keyword": sourceName.query } });

  // source domain (saved as lowercase when entered, results stored as lowercase)
  if (sourceDomain && sourceDomain.active)
    queryFilters[sourceDomain.include].push({ terms: { "sourceUrl.keyword": sourceDomain.query } });

  // sentiment
  if (sentiment && sentiment.active) queryFilters[true].push({ terms: { "sentiment.keyword": sentiment.query } });

  // traffic light
  if (trafficLight && trafficLight.active)
    queryFilters[true].push({ terms: { "trafficLight.keyword": trafficLight.query } });

  // topic
  if (topic && topic.active) queryFilters[topic.include].push({ terms: { "topics.keyword": topic.query } });

  // concern
  if (concern && concern.active) queryFilters[concern.include].push({ terms: { "concerns.keyword": concern.query } });

  // media type and ranks
  if (mediaType && mediaType.active) {
    const mediaFilters = [];
    const mediaTypes = mediaType.query;
    const newsSourceRanks = mediaType.newsSourceRanks;
    const socialSourceRanks = mediaType.socialSourceRanks;

    const webNewsMediaTypesWithPrefix = METABASE_NEWS_MEDIA_TYPES.map((x) => `metabase-${x}`);
    const webSocialMediaTypesWithPrefix = METABASE_SOCIAL_MEDIA_TYPES.map((x) => `metabase-${x}`);
    const webOtherMediaTypesWithPrefix = METABASE_OTHER_MEDIA_TYPES.map((x) => `metabase-${x}`);

    const socialMediaTypes = Object.entries(SOCIAL360_MEDIA_TYPES).map((x) => `social-${x[0]}`);

    // add news/print mediaType if used and its source ranks (1-5)
    if (intersection(mediaTypes, webNewsMediaTypesWithPrefix).length) {
      const newsTypes = intersection(mediaTypes, webNewsMediaTypesWithPrefix).map((x) => x.substr(9));
      newsSourceRanks
        ? mediaFilters.push({
            bool: {
              filter: { terms: { "sourceType.keyword": newsTypes } },
              should: { terms: { sourceRank: newsSourceRanks } },
              minimum_should_match: 1,
            },
          })
        : mediaFilters.push({ terms: { "sourceType.keyword": newsTypes } });
    }

    // add social mediaType if used and its source ranks (1-10)
    if (intersection(mediaTypes, webSocialMediaTypesWithPrefix).length) {
      const socialTypes = intersection(mediaTypes, webSocialMediaTypesWithPrefix).map((x) => x.substr(9));
      socialSourceRanks
        ? mediaFilters.push({
            bool: {
              filter: { terms: { "sourceType.keyword": socialTypes } },
              should: { terms: { sourceRank: socialSourceRanks } },
              minimum_should_match: 1,
            },
          })
        : mediaFilters.push({ terms: { "sourceType.keyword": socialTypes } });
    }

    // add other mediaTypes if used
    if (intersection(mediaTypes, webOtherMediaTypesWithPrefix).length) {
      const otherTypes = intersection(mediaTypes, webOtherMediaTypesWithPrefix).map((x) => x.substr(9));
      mediaFilters.push({ terms: { "sourceType.keyword": otherTypes } });
    }

    // add SOCIAL360 media types
    if (intersection(mediaTypes, socialMediaTypes).length) {
      const socialTypeIds = intersection(mediaTypes, socialMediaTypes).map((x) => x.substr(7));
      // transform ids into source type names, which match Result.sourceType
      const socialTypes = socialTypeIds.map((x) => SOCIAL360_MEDIA_TYPES[x]);
      mediaFilters.push({ terms: { "sourceType.keyword": socialTypes } });
    }

    // match at least one media type
    queryFilters[mediaType.include].push({
      bool: {
        should: mediaFilters,
        minimum_should_match: 1,
      },
    });
  }

  // language
  if (language && language.active)
    queryFilters[language.include].push({ terms: { "language.keyword": language.query } });

  // country
  if (country && country.active)
    queryFilters[country.include].push({ terms: { "sourceCountry.keyword": country.query } });

  // category
  if (category && category.active)
    queryFilters[category.include].push({ terms: { "sourceCategory.keyword": category.query } });

  // article topic
  if (articleTopic && articleTopic.active)
    queryFilters[articleTopic.include].push({ terms: { "articleTopics.keyword": articleTopic.query } });

  // reach
  if (reach && reach.active) {
    const reachFilters = [];
    for (const band of reach.query) {
      const range = band.split("-");
      const min = parseInt(range[0], 10);
      const max = range[1] ? parseInt(range[1], 10) : null;
      const reachFilter = { range: { reach: { gte: min, lt: max } } };
      reachFilters.push(reachFilter);
    }
    queryFilters[reach.include].push({
      bool: {
        should: reachFilters,
        minimum_should_match: 1,
      },
    });
  }

  // mozrank
  if (mozrank && mozrank.active)
    queryFilters[mozrank.include].push({ terms: { "mozrank.keyword": mozrank.query.map((x) => parseInt(x, 10)) } });

  // agent - always only include results from non-deleted agents
  queryFilters[true].push({ terms: { "agents.keyword": projectData.agents.map((x) => x.id) } });

  // agent - specified agents
  if (agent && agent.active) queryFilters[agent.include].push({ terms: { "agents.keyword": agent.query } });

  // number range fields
  for (const rangeFieldName of [
    "socialEngagement",
    "socialReactions",
    "socialComments",
    "socialShares",
    "socialViews",
    "authorPosts",
    "authorFollowers",
    "authorFollowing",
  ]) {
    const rangeField = get(query, rangeFieldName);
    if (rangeField && rangeField.active) {
      queryFilters[rangeField.include].push({
        range: { [`${rangeFieldName}`]: { gte: rangeField.queryFrom, lte: rangeField.queryTo } },
      });
    }
  }

  // author groups
  if (authorGroups && authorGroups.active)
    queryFilters[authorGroups.include].push({ terms: { "authorGroups.keyword": authorGroups.query } });

  // author name
  if (authorName && authorName.active)
    queryFilters[authorName.include].push({ terms: { "authorName.keyword": authorName.query } });

  // author contact
  if (authorEmail && authorEmail.active)
    queryFilters[authorEmail.include].push({ terms: { "authorEmail.keyword": authorEmail.query } });

  // add include queryFilters into filters
  filter.push(...queryFilters[true]);

  // add exclude queryFilters into mustNot
  mustNot.push(...queryFilters[false]);

  // build query params
  const params = {
    query: {
      bool: {
        filter,
        must_not: mustNot,
      },
    },
  };

  // apply highlight/aggs if provided
  if (highlight) params.highlight = buildSearchResultHighlightParam(highlight);
  if (aggs) params.aggs = buildSearchResultAggsParam(aggs);

  return params;
};

// build sort for searching results
export const buildSearchResultsSort = (sort) => {
  const direction = sort[0] === "-" ? "desc" : "asc";
  const field = sort[0] === "-" ? sort.substr(1) : sort;
  // resolver adds PK tie-breaker
  return [{ direction, field }];
};

// build aggs param
export const buildSearchResultAggsParam = (aggsQuery) => aggsQuery;

// build highlight param
export const buildSearchResultHighlightParam = (highlightQuery) => ({
  fields: {
    content: {
      fragment_size: 200,
      number_of_fragments: 3,
      no_match_size: 600,
      // do not markup, Highlighter does this in UI
      pre_tags: [""],
      post_tags: [""],
      highlight_query: {
        query_string: {
          fields: ["content"],
          query: highlightQuery,
        },
      },
    },
  },
});

// build terms (list of individual words) and ES querystring (words/phrases) from list of keywords from agents
// Highlighter/Charts/ES are not case sensitive, we display using the case as entered in the agent, but remove duplicates by making lowercase
export const buildSearchResultsHighlightFromAgents = (agents) => {
  const terms = [];
  const qs = [];
  agents.forEach((agent) => {
    // do not look at "none of" field as these results will have been excluded
    for (const key of ["any", "inConnectionWithAny", "inConnectionWithAll"]) {
      for (const term of agent[key] || []) {
        // full words/phrases for Highlighter/Charts
        terms.push(term);
        // quoted words/phrases for ES query_string
        qs.push(`\\"${term}\\"`);
      }
    }
  });
  return {
    terms: uniqWith(terms, (x, y) => x.toLowerCase() === y.toLowerCase()),
    query: uniqWith(qs, (x, y) => x.toLowerCase() === y.toLowerCase()).join(" "),
  };
};

// as above but using search/filter query keywords
// Highlighter/Charts/ES are not case sensitive, we display using the case as entered in the agent, but remove duplicates by making lowercase
export const buildSearchResultsHighlightFromQuery = (query) => {
  const terms = [];
  const qs = [];
  // do not look at "none of" field as these results will have been excluded
  for (const key of ["any", "inConnectionWithAny", "inConnectionWithAll"]) {
    for (const term of query[key] || []) {
      // full words/phrases for Highlighter/Charts
      terms.push(term);
      // quoted words/phrases for ES query_string
      qs.push(`\\"${term}\\"`);
    }
  }
  // add proximity terms
  for (const key of ["proximity1", "proximity2"]) {
    const term = query[key];
    if (term) {
      terms.push(term);
      qs.push(term);
    }
  }
  return {
    terms: uniqWith(terms, (x, y) => x.toLowerCase() === y.toLowerCase()),
    query: uniqWith(qs, (x, y) => x.toLowerCase() === y.toLowerCase()).join(" "),
  };
};

// keyword filter is valid if it is present, is active, and has some terms in fields other than "none of"
export const keywordFilterIsValidForHighlighting = (keywordFilter) => {
  if (keywordFilter && keywordFilter.active) {
    let count = 0;
    for (const key of ["any", "inConnectionWithAny", "inConnectionWithAll"]) {
      // there are many terms in each key
      count += (keywordFilter[key] || []).length;
    }
    for (const key of ["proximity1", "proximity2"]) {
      // there is one term in each key
      count += keywordFilter[key] ? 1 : 0;
    }
    return count > 0;
  }
  return false;
};

// remove duplicates, keep first occurance of duplicateGroup in the order the results are in
export const removeDuplicates = (results) => {
  const seen = [];
  const removed = [];
  const newResults = pull(
    results.map((result) => {
      if (seen.includes(result.duplicateGroup)) {
        removed.push(result.duplicateGroup);
        return null;
      }
      seen.push(result.duplicateGroup);
      return result;
    }),
    null
  );
  return {
    results: newResults,
    duplicates: removed,
  };
};

// remove results that are invalid for the current resultsType view
export const removeInvalid = (results, resultsType) => {
  let removed = [];
  if (resultsType === "deleted") {
    // deleted page, only show deleted
    results = reduce(
      results,
      (arr, result) => {
        if (!result.isDeleted) {
          removed.push(result.id);
        } else {
          arr.push(result);
        }
        return arr;
      },
      []
    );
  } else {
    // not deleted page, only show non-deleted
    results = reduce(
      results,
      (arr, result) => {
        if (result.isDeleted) {
          removed.push(result.id);
        } else {
          arr.push(result);
        }
        return arr;
      },
      []
    );
  }
  if (resultsType === "top") {
    // all top page, exclude isNotTop
    results = reduce(
      results,
      (arr, result) => {
        if (result.isNotTop) {
          removed.push(result.id);
        } else {
          arr.push(result);
        }
        return arr;
      },
      []
    );
  }
  if (resultsType === "manualtop") {
    // manual top page, only show isTop
    results = reduce(
      results,
      (arr, result) => {
        if (!result.isTop) {
          removed.push(result.id);
        } else {
          arr.push(result);
        }
        return arr;
      },
      []
    );
  }
  return { results, removed };
};

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client">
  <mj-section mj-class="p-0">
    <mj-column>
      <mj-text mj-class="block-title">[DELETED]</mj-text>
    </mj-column>
  </mj-section>

  {{#each results}}
    <mj-section mj-class="p-0" css-class="report-builder--relative report-builder--hover">
      {{#if @root.switches.isBuilder}}
        {{> builderResultOrdering}}
        {{> builderResultActions}}
      {{/if}}
      <mj-column>
        <mj-text mj-class="result-title"><a href="{{url}}" class="report-link" target="_blank">{{title}}</a></mj-text>
        <mj-text mj-class="result-meta">{{> resultMeta}}</mj-text>
      </mj-column>
    </mj-section>
  {{/each}}
</mj-wrapper>`
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

import AppContainer from "./App";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { SENTRY_DSN } from "./lib/constants";
import { getEnv } from "./lib/env";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: getEnv(),
  integrations: [new Integrations.BrowserTracing()],
  // sample 20% of non-sandbox UI transactions
  tracesSampleRate: getEnv() === "sandbox" ? 0 : 0.2,
});

ReactDOM.render(
  // <React.StrictMode>
  <AppContainer />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

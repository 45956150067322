import { Alert, Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext, useState } from "react";
import { fnAdminClient, updateClient } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { headerFormatter, textFilter } from "../lib/tables";
import { populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import BootstrapTable from "react-bootstrap-table-next";
import ConfirmModal from "../Modals/ConfirmModal";
import FormFile from "../Forms/FormFile";
import FormInput from "../Forms/FormInput";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { adminListClientsVariables } from "../../lib/variables";
import { createConfirmation } from "react-confirm";
import filterFactory from "react-bootstrap-table2-filter";
import { getAppUrl } from "../../lib/constants";
import { listClients } from "../../graphql/queries";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const ClientsDelete = ({ client }) => {
  const appContext = useContext(AppContext);
  const [mutationFnAdminClient] = useMutation(gql(fnAdminClient));
  const [loading, setLoading] = useState(false);

  const confirmModal = createConfirmation(ConfirmModal);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    const ok = await confirmModal();
    if (!ok) {
      setLoading(false);
      return;
    }
    try {
      await mutationFnAdminClient({
        variables: { input: { op: "DELETE", id: client.id, subdomain: client.subdomain } },
      });
    } catch (e) {
      setLoading(false);
      appContext.handleError(e);
    }
  };

  return (
    <Button size="sm" className="ml-1" color="danger" disabled={loading} onClick={handleClick}>
      {loading && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
      Delete
    </Button>
  );
};

const ClientsModal = ({ data: { data, op }, toggle }) => {
  const appContext = useContext(AppContext);
  const [mutationFnAdminClient] = useMutation(gql(fnAdminClient));
  const [mutationUpdateClient] = useMutation(gql(updateClient));

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(data, {
      fields: ["subdomain", "name", "colour", "reportFooter"],
      files: ["logo", "image", "reportLogo"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      if (op === "CREATE") {
        const input = await processForm(
          { op, id: uuid(), ...values },
          {
            files: [
              ["logo", null],
              ["image", null],
              ["reportLogo", null],
            ],
          }
        );
        await mutationFnAdminClient({
          variables: { input },
          update: updateCacheCreate(gql(listClients), adminListClientsVariables()),
        });
      } else {
        const input = await processForm(
          { id: data.id, name: data.name, ...values },
          {
            files: [
              ["logo", data.logo],
              ["image", data.image],
              ["reportLogo", data.reportLogo],
            ],
          }
        );
        await mutationUpdateClient({ variables: { input } });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{title} Client</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {op === "CREATE" && (
            <FormInput
              name="subdomain"
              label="Subdomain"
              required
              helpText="Subdomain cannot be changed after the client is created, and can take up to 15 minutes to become active."
              formProps={formProps}
            />
          )}
          <FormInput
            name="name"
            label="Name"
            disabled={op === "UPDATE" && data.subdomain === "outcider"}
            required
            formProps={formProps}
          />
          <FormInput
            name="colour"
            label="Colour"
            helpText={
              <>
                Enter hex code from{" "}
                <a href="https://htmlcolorcodes.com/color-picker/" target="_new">
                  https://htmlcolorcodes.com/color-picker/
                </a>
                .
              </>
            }
            formProps={formProps}
          />
          <FormFile
            name="logo"
            label="Logo"
            accept="image/*"
            current={data ? data.logo : null}
            uploadPath="clients"
            uploadLevel="public"
            required
            helpText="Logo used on login screen, app header and emails."
            formProps={formProps}
          />
          <FormFile
            name="image"
            label="Image"
            accept="image/*"
            current={data ? data.image : null}
            uploadPath="clients"
            uploadLevel="public"
            helpText="Image used on login screen."
            formProps={formProps}
          />
          <FormGroup className="legend">Reports</FormGroup>
          <FormFile
            name="reportLogo"
            label="Logo"
            accept="image/*"
            current={data ? data.reportLogo : null}
            uploadPath="clients"
            uploadLevel="public"
            helpText="Optional logo to use in reports, should have a white background."
            formProps={formProps}
          />
          <FormInput
            name="reportFooter"
            label="Footer"
            helpText="Optional text to use in report footers."
            formProps={formProps}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Clients = ({ clients }) => {
  const [modal, toggleModal] = useState(null);

  // exclude deleted clients from table
  clients = clients.filter((x) => !x.isDeleted);

  const columns = [
    {
      dataField: "subdomain",
      text: "Subdomain",
      sort: true,
      formatter: (cell) => (
        <span className="font-bold">
          {cell}
          <i className="click ml-1 uil-external-link-alt" onClick={() => window.open(getAppUrl(cell))} />
        </span>
      ),
      filter: textFilter,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter,
      headerFormatter: headerFormatter,
      headerClasses: "bootstrap-table-stretch",
      classes: "bootstrap-table-stretch",
    },
    {
      dataField: "actions",
      text: "",
      formatter: (cell, row) => (
        <div style={{ whiteSpace: "nowrap", textAlign: "right" }}>
          <Button size="sm" onClick={() => toggleModal({ data: row, op: "UPDATE" })}>
            Edit
          </Button>
          {row.subdomain !== "outcider" && <ClientsDelete client={row} />}
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Clients">
        <Button color="success" onClick={() => toggleModal({ data: null, op: "CREATE" })}>
          <i className="uil-plus mr-1" />
          Create Client
        </Button>
      </PageTitle>
      {clients.length ? (
        <ToolkitProvider bootstrap4 keyField="id" data={clients} columns={columns}>
          {(props) => (
            <BootstrapTable
              {...props.baseProps}
              hover
              rowStyle={(row) => ({ opacity: row.subdomain === "outcider" ? 0.5 : 1 })}
              defaultSorted={[
                {
                  dataField: "subdomain",
                  order: "asc",
                },
              ]}
              filter={filterFactory()}
              classes="bootstrap-table mb-0"
              wrapperClasses="table-responsive card mb-0"
              headerWrapperClasses="card-header"
              noDataIndication={
                <div className="table-empty-admin">
                  <p className="mb-0 text-center">There are no clients to display.</p>
                </div>
              }
            />
          )}
        </ToolkitProvider>
      ) : (
        <Alert color="info" fade={false}>
          There are no clients to display.
        </Alert>
      )}

      {modal && <ClientsModal data={modal} toggle={toggleModal} />}
    </>
  );
};

export default Clients;

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { createComment, updateComment, updateResult } from "../../graphql/mutations";
import { formatDateTime, getLinkedItemName, sort } from "../../lib/utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import { Excerpt } from "../lib/excerpts";
import FormEditor from "../Forms/FormEditor";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import pick from "lodash/pick";
import { queryComments } from "../../graphql/queries";
import { queryCommentsVariables } from "../../lib/variables";
import { titleCase } from "title-case";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

const UpdateModal = ({ data, toggle, result, user }) => {
  const appContext = useContext(AppContext);
  const [mutationUpdateComment] = useMutation(gql(updateComment));
  const [mutationUpdateResult] = useMutation(gql(updateResult));

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(data, {
      editors: ["content"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // update comment
      const form = await processForm(values, { editors: ["content"] });
      const input = {
        ...pick(data, ["clientId", "projectId", "resultId", "id"]),
        userId: user.id, // changes to user of last update
        ...form,
      };
      await mutationUpdateComment({ variables: { input } });
      // if this is excerpt, copy to result
      if (result.commentId === data.id) {
        await mutationUpdateResult({
          variables: {
            input: {
              ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
              commentUserId: user.id, // changes to user of last update
              commentContent: form.content,
            },
          },
        });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="lg">
      <ModalHeader toggle={() => toggle()}>Edit Comment</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormEditor name="content" required formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Comment = ({ result, comment, last, toggleModal, user, projectData }) => {
  const appContext = useContext(AppContext);
  const [mutationUpdateComment] = useMutation(gql(updateComment));
  const [mutationUpdateResult] = useMutation(gql(updateResult));
  const alertModal = createConfirmation(AlertModal);
  const confirmModal = createConfirmation(ConfirmModal);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // unset/set excerpt on result by copying comment
  const handleExcerpt = async (comment) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const data =
      result.commentId === comment.id
        ? { commentId: null, commentContent: null, commentUserId: null, commentAiAnalyst: null }
        : {
            commentId: comment.id,
            commentContent: comment.content,
            commentUserId: comment.userId,
            commentAiAnalyst: comment.aiAnalyst,
          };
    try {
      await mutationUpdateResult({
        variables: {
          input: {
            ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
            ...data,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  // delete comment, prevent if currently set as excerpt
  const handleDelete = async (comment) => {
    if (isSubmitting) return;
    if (result.commentId === comment.id)
      return await alertModal({
        message: "This comment cannot be deleted as it is the excerpt for this result.",
      });
    const ok = await confirmModal();
    if (!ok) return;
    setIsSubmitting(true);
    try {
      await mutationUpdateComment({
        variables: {
          input: {
            ...pick(comment, ["clientId", "projectId", "resultId", "id"]),
            isDeleted: true,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  // get user name from linked items
  const userName = getLinkedItemName(projectData.users, comment.userId, "Deleted User");

  return (
    <Card className={classNames({ "mb-0": last })}>
      <CardHeader className="d-flex">
        <div className="flex-grow-1">
          {user.can.commentResult && (
            <i
              className={classNames({
                "uil-bookmark-full": true,
                "text-muted": !(result.commentId === comment.id),
                "text-primary": result.commentId === comment.id,
                click: true,
                "mr-1": true,
              })}
              onClick={() => handleExcerpt(comment)}
            />
          )}
          <span className="font-bold">{userName}</span>
          {comment.aiAnalyst && (
            <span className="ml-2 text-muted">
              <i className="uil-robot mr-1" />
              Auto {titleCase(comment.aiAnalyst.toLowerCase())}
            </span>
          )}
          {isSubmitting && (
            <Spinner className="spinner-border-sm ml-2" tag="span" style={{ position: "absolute", marginTop: "2px" }} />
          )}
        </div>
        <div>
          {formatDateTime(comment.createdAt, user.timezone)}
          {user.can.commentResult && (
            <UncontrolledDropdown className="ml-2 card-widgets">
              <DropdownToggle tag="a" className="arrow-none cursor-pointer">
                <i className="dripicons-dots-3" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => toggleModal(comment)}>
                  <i className="uil-edit mr-1" />
                  Edit
                </DropdownItem>
                <DropdownItem className="text-danger" onClick={() => handleDelete(comment)}>
                  <i className="uil-trash mr-1" />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </CardHeader>
      <CardBody className="pt-2 pb-0">
        <div className="result-content result-excerpt" dangerouslySetInnerHTML={{ __html: comment.content }} />
      </CardBody>
    </Card>
  );
};

const ResultPanelComments = ({
  result,
  highlight,
  excerptBaseIsDynamic,
  draftComment,
  setDraftComment,
  user,
  projectData,
}) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [modal, toggleModal] = useState(null);
  const [mutationCreateComment] = useMutation(gql(createComment));
  const [mutationUpdateResult] = useMutation(gql(updateResult));

  const resultHasPendingJobsAiAnalyst = !!(result.pendingJobs || []).filter((x) => x.includes("resultaianalyst"))
    .length;

  const variables = queryCommentsVariables({ clientId, projectId, resultId: result.id });

  // default form to the issue currently being viewed
  const { register, handleSubmit, errors, formState, control, setValue, reset } = useForm({
    defaultValues: populateForm(
      {
        content: draftComment || null,
      },
      {
        editors: ["content"],
      }
    ),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const { loading, data, refetch } = useQuery(gql(queryComments), { variables });

  // refetch comments when pending jobs are updated (AI analyst adds comments)
  useEffect(() => {
    async function doRefetch() {
      await refetch();
    }
    doRefetch();
  }, [resultHasPendingJobsAiAnalyst, refetch]);

  if (loading) return <Spinner className="loading-relative" />;

  const comments = sort(
    data.queryComments.items.filter((x) => !x.isDeleted),
    "createdAt",
    "desc"
  );

  // create comment
  const onSubmit = async (values) => {
    try {
      // create comment
      const form = await processForm(values, { editors: ["content"] });
      const commentId = uuid();
      const input = {
        clientId,
        projectId,
        resultId: result.id,
        id: commentId,
        userId: user.id,
        ...form,
      };
      await mutationCreateComment({
        variables: { input },
        update: updateCacheCreate(gql(queryComments), variables),
      });

      // if there is no manual comment, make this the manual comment automatically
      if (!result.commentId) {
        await mutationUpdateResult({
          variables: {
            input: {
              ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
              commentId,
              commentUserId: user.id,
              commentContent: form.content,
            },
          },
        });
      }

      // clear draft and reset
      setDraftComment(null);
      reset({ content: null });
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <>
      {resultHasPendingJobsAiAnalyst && (
        <Alert color="info" fade={false} className="mb-2">
          <div className="d-flex">
            <div className="flex-grow-1">
              <i className="uil-robot mr-1" />
              Auto Analyst is reading the article and preparing comments...
            </div>
            <div>
              <Spinner className="spinner-border-xs ml-2" tag="span" color="info" />
            </div>
          </div>
        </Alert>
      )}
      {result.commentId && (
        <Card>
          <CardHeader>
            <span className="font-bold">Excerpt</span>
            {result.commentAiAnalyst && (
              <span className="ml-2 text-muted">
                <i className="uil-robot mr-1" />
                Auto {titleCase(result.commentAiAnalyst.toLowerCase())}
              </span>
            )}
          </CardHeader>
          <CardBody className="pt-2 pb-0">
            <Excerpt result={result} highlight={highlight} excerptBaseIsDynamic={excerptBaseIsDynamic} />
          </CardBody>
        </Card>
      )}

      <Card>
        <CardHeader className="font-bold">{result.commentId ? "Dynamic Excerpt" : "Excerpt"}</CardHeader>
        <CardBody className="pt-2 pb-2">
          <Excerpt result={result} highlight={highlight} excerptBaseIsDynamic={excerptBaseIsDynamic} forceDynamic />
        </CardBody>
      </Card>

      <hr />

      {user.can.commentResult && (
        <Card>
          <CardHeader className="font-bold">Comments</CardHeader>

          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
              <FormEditor
                name="content"
                required
                onChange={(e) => setDraftComment(e.toString("html"))}
                formProps={formProps}
              />
              <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
                {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                Create
              </Button>
            </Form>
          </CardBody>
        </Card>
      )}

      {comments.map((comment, commentIndex) => (
        <Comment
          key={comment.id}
          result={result}
          comment={comment}
          last={commentIndex === comments.length - 1}
          toggleModal={toggleModal}
          user={user}
          projectData={projectData}
        />
      ))}

      {modal && <UpdateModal data={modal} toggle={toggleModal} result={result} user={user} />}
    </>
  );
};

export default ResultPanelComments;

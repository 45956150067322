import { Card, CardBody, CardHeader } from "reactstrap";
import React, { useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";

import BootstrapTable from "react-bootstrap-table-next";
import EntityModal from "../Modals/EntityModal";
import ResultsCounter from "../ResultsCounter";
import ResultsError from "../Results/ResultsError";
import Spinner from "../../components/Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { buildSearchResultsTypeParams } from "../../lib/queries";
import classNames from "classnames";
import { searchResultsAggregations } from "../../graphql/queries";
import { v4 as uuid } from "uuid";

const EntitiesTable = ({ type, title, resultsType, filterParams, user, projectData }) => {
  const history = useHistory();
  const { clientId, projectId } = useParams();
  const totalsRef = useRef({});
  const [modal, toggleModal] = useState(null);

  // get all entities of type present in the current results
  const searchAggs = {
    entities: {
      terms: {
        field: `${type}.keyword`,
        size: 50,
      },
    },
  };

  // this table only supports getting results from all/top/deleted or a single issue
  const { loading, data, refetch, error } = useQuery(gql(searchResultsAggregations), {
    variables: {
      searchParams: JSON.stringify(
        buildSearchResultsTypeParams({ source: "METABASE", resultsType, filterParams, aggs: searchAggs, projectData })
      ),
    },
  });

  if (loading || error) {
    return (
      <Card className="card-widget card-widget--loading-graybg">
        <CardBody style={{ height: 400 }}>
          {loading && <Spinner className="loading-relative" />}
          {error && <ResultsError message="table results" refetch={refetch} />}
        </CardBody>
      </Card>
    );
  }

  const records = [];

  // parse the aggregation response
  if (!data.searchResultsAggregations.aggregations) return null; // don't show table if no results
  const entitiesInResults = JSON.parse(data.searchResultsAggregations.aggregations).entities.buckets;

  // get alternative names of all stakeholders of type in project (so we can exclude them as we list based on primary name)
  const projectStakeholderAlternativeNames = [];
  projectData[type].forEach((stakeholder) => {
    const alternativeNames = (stakeholder.alternativeNames || []).map((x) => x.toLowerCase());
    projectStakeholderAlternativeNames.push(...alternativeNames);
  });

  // get primary names of stakeholders of type in project, and other data for listing (id/status)
  const projectStakeholderNames = [];
  const projectStakeholderData = {};
  projectData[type].forEach((stakeholder) => {
    // get primary name
    const entityName = stakeholder.name.toLowerCase();
    projectStakeholderNames.push(entityName);
    // get status
    const status = !stakeholder.tags ? "FOLLOWED" : "TAGGED";
    // add data keyed by primary name
    projectStakeholderData[entityName] = {
      id: stakeholder.id,
      status,
      entity: stakeholder, // for EntityModal
    };
  });

  // build results
  // doc_count may not be exactly the same as the count (as followed/tagged person may be renamed, plus have alternative names), so these counts are approx, but good enough
  entitiesInResults.forEach((entity) => {
    // get comparison name
    const entityName = entity.key.toLowerCase();

    // do not list if name is an alternative name on another result
    if (projectStakeholderAlternativeNames.includes(entityName)) return;

    // need unique keys for table render
    let id = uuid(),
      status = "UNFOLLOWED";
    if (projectStakeholderNames.includes(entityName)) {
      const data = projectStakeholderData[entityName];
      id = data.id;
      status = data.status;
    }

    records.push({
      id,
      name: entity.key,
      status,
      results:
        status === "UNFOLLOWED" ? (
          // get by keyword match
          // results in this project matching keyword search for person/org name
          <ResultsCounter
            ref={(el) => (totalsRef.current[id] = el)}
            icon="uil-list-ul"
            source="METABASE"
            // only used for clicking as total is prefetched, use doc_count from agg
            query={{ keyword: [entity.key] }}
            projectData={projectData}
            prefetchedTotal={entity.doc_count}
          />
        ) : (
          // get by stakeholder id
          // results in this project with stakeholder link to person/org
          <ResultsCounter
            ref={(el) => (totalsRef.current[id] = el)}
            icon="uil-list-ul"
            source="METABASE"
            // only used for clicking as total is prefetched, use doc_count from agg
            query={{ [type === "people" ? "person" : "organisation"]: [id] }}
            projectData={projectData}
            prefetchedTotal={entity.doc_count}
          />
        ),
    });
  });

  // build columns
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => (
        <mark
          className={classNames({
            entity: true,
            stakeholder: row.status !== "UNFOLLOWED",
            person: type === "people",
            organisation: type === "organisations",
            "entity--clickable":
              projectData.comprehendEnabled &&
              (user.can.updateStakeholders || (!user.can.updateStakeholders && row.status !== "UNFOLLOWED")),
          })}
          onClick={() => {
            if (!projectData.comprehendEnabled || (!user.can.updateStakeholders && row.status === "UNFOLLOWED"))
              return false;
            if (user.can.updateStakeholders) {
              const name = cell;
              const entity = row.status === "UNFOLLOWED" ? null : projectStakeholderData[name.toLowerCase()].entity;
              const entityType = type === "people" ? "PERSON" : "ORGANISATION";
              toggleModal({ name, entity, type: entityType });
            } else {
              history.push(`/${clientId}/${projectId}/stakeholders/${type}/all/all/${row.id}`);
            }
          }}
        >
          {cell}
          {row.status === "TAGGED" && <i className="uil-check-circle" />}
        </mark>
      ),
    },
    {
      dataField: "results",
      text: (
        <>
          Results <span style={{ fontWeight: "normal" }}>(approx)</span>
        </>
      ),
      sort: true,
      sortValue: (_, row) => {
        const totalRef = totalsRef.current[row.id];
        return totalRef ? parseInt(totalRef.innerHTML, 10) : 0;
      },
    },
  ];

  return (
    <>
      <Card className="card-widget">
        <CardHeader>{title}</CardHeader>
        {records.length ? (
          <CardBody>
            <ToolkitProvider bootstrap4 keyField="id" data={records} columns={columns}>
              {(props) => (
                <BootstrapTable
                  {...props.baseProps}
                  defaultSorted={[
                    {
                      dataField: "name",
                      order: "asc",
                    },
                  ]}
                  classes="mb-0"
                  wrapperClasses="table-responsive card mb-0"
                  headerWrapperClasses="card-header"
                />
              )}
            </ToolkitProvider>
          </CardBody>
        ) : (
          <CardBody style={{ minHeight: 400 - 46 }}>
            <p className="mb-0 text-center empty">
              <i className="uil-users-alt font-24 d-block" />
              {projectData.comprehendUsed
                ? `There are no ${type} to display.`
                : `Stakeholder analysis is not enabled for this project.`}
            </p>
          </CardBody>
        )}
      </Card>

      {modal && <EntityModal data={modal} toggle={toggleModal} projectData={projectData} />}
    </>
  );
};

export default EntitiesTable;

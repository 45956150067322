import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import NavigationPrompt from "react-router-navigation-prompt";
import React from "react";

const NavigationPromptModal = ({ when, message, pathComparison }) => (
  // alert if `when` criteria is met, pathComparison function passes (defaults to the url changing)
  <NavigationPrompt
    when={(cur, next) => when && (pathComparison ? pathComparison({ cur, next }) : cur.pathname !== next.pathname)}
  >
    {({ onConfirm, onCancel }) => (
      <Modal className="modal-dialog-centered" isOpen={true}>
        <ModalHeader toggle={onCancel}>Confirm</ModalHeader>
        <ModalBody>
          {message || <>This page has unsaved changes which will be lost. Are you sure you want to change page?</>}
        </ModalBody>
        <ModalFooter>
          <Button className="mr-auto" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="danger" onClick={onConfirm}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    )}
  </NavigationPrompt>
);

export default NavigationPromptModal;

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  {{#if (or title @root.switches.isBuilder)}}
    <mj-section mj-class="p-0">
      <mj-column>
        <mj-text mj-class="block-title">{{#if title}}{{title}}{{else}}[{{type}}]{{/if}}</mj-text>
      </mj-column>
    </mj-section>
  {{/if}}
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->

  {{> imageHeader}}
  {{> chartHeader}}

  {{#each results}}
    <mj-section mj-class="p-0" css-class="report-builder--relative report-builder--hover">
      {{#if @root.switches.isBuilder}}
        {{> builderResultOrdering}}
        {{> builderResultActions}}
      {{/if}}
      <mj-column>
        <mj-table mj-class="result-title">
          <tr>
            {{#if authorImage}}
              <td width="36" class="report-result-title-author">
                <img width="28" height="28" class="report-result-title-author-img" src="{{authorImage}}" />
              </td>
            {{/if}}
            <td>
              <a href="{{url}}" class="report-result-title-text report-link" target="_blank">{{title}}</a>
            </td>
          </tr>
        </mj-table>
        <mj-text mj-class="result-meta">{{> resultMeta}}</mj-text>
        <mj-text mj-class="result-content">
          {{#if (and thumborImage showImage)}}{{> imageFloat imageClass=imageClass imageAlign=imageAlign}}{{/if}}
          {{{this.excerpt}}}
        </mj-text>
      </mj-column>
    </mj-section>
  {{/each}}
</mj-wrapper>`
module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  <mj-section mj-class="p-0">
    <mj-column>
      {{#if title}}
        <mj-text mj-class="heading">{{title}}</mj-text>
      {{/if}}
      {{#if subtitle}}
        <mj-text mj-class="subheading">{{subtitle}}</mj-text>
      {{/if}}
    </mj-column>
  </mj-section>
</mj-wrapper>`
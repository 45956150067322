import { Document, Image, Link, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import { ConditionalWrapper } from "../lib/utils";
import Html from "react-pdf-html";
import React from "react";
import { getS3Public } from "../../lib/files";

const baseStyle = {
  fontSize: 9,
  lineHeight: 1.2,
  fontFamily: "Helvetica",
};

const styles = StyleSheet.create({
  page: {
    ...baseStyle,
    padding: 30,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "3px solid #000",
    borderBottom: "1px solid #000",
  },
  block: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #000",
  },
  table: {
    display: "table",
    width: "100%",
  },
  row: {
    margin: "auto",
    flexDirection: "row",
  },
  rowList: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ccc",
  },
  rowListFirstNoTitle: {
    paddingTop: 0,
    borderTop: "none",
  },
  rowListLast: {
    paddingBottom: 0,
  },
  col: {},
  colLeft: {
    paddingRight: 15,
  },
  colRight: {
    paddingLeft: 15,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    color: "#000",
    textDecoration: "none",
  },
  quote: {
    borderLeft: "2px solid #ccc",
    paddingLeft: 10,
  },
});

const wysiwygStyles = StyleSheet.create({
  "p, ul, ol": {
    margin: 0,
    marginBottom: 5,
  },
  "p:last-child, ul:last-child, ol:last-child": {
    marginBottom: 0,
  },
  "ul, ol": {
    padding: 0,
    margin: 0,
    marginLeft: -15,
  },
  // required to stop text overflowing page
  // A4 is 595 postscript pts wide, minus page margins, minus a bit for the bullet/number
  li: {
    width: 595 - 30 - 30 - 15,
  },
  a: {
    color: "#2762d9",
  },
  u: {
    color: "#67b617",
    fontFamily: "Helvetica-Bold",
    textDecoration: "none",
  },
  strong: {
    fontFamily: "Helvetica-Bold",
  },
});

// https://github.com/wojtekmaj/react-pdf/issues/552
const customRenderers = {
  a: (props) => {
    const src = props.element._rawAttrs.href;
    return (
      <Text>
        <Link src={src} style={props.style}>
          {/* content may be HTML e.g. bold */}
          <Html style={baseStyle} stylesheet={wysiwygStyles}>
            {props.element.content}
          </Html>
        </Link>
      </Text>
    );
  },
};

const WYSIWYG = ({ html }) => {
  return (
    // resetStyles prop does not work if content contains ul/ol so we don't use it
    <Html style={baseStyle} stylesheet={wysiwygStyles} renderers={customRenderers}>
      {html}
    </Html>
  );
};

const Header = ({ data }) => {
  if (!data.reportShowHeader)
    return (
      <View
        style={{
          ...styles.header,
          borderTopColor: data.clientColour,
          borderBottomColor: data.clientColour,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      ></View>
    );

  return (
    <View style={{ ...styles.header, borderTopColor: data.clientColour, borderBottomColor: data.clientColour }}>
      <View style={styles.table}>
        <View style={styles.row} wrap={false}>
          <View style={{ ...styles.col, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 18,
                textTransform: "uppercase",
              }}
            >
              {data.reportTitle}
            </Text>
            <Text
              style={{
                fontFamily: "Helvetica-Bold",
                fontSize: 13,
                textTransform: "uppercase",
                color: "#666",
              }}
            >
              {data.reportDate}
            </Text>
          </View>
          <View style={{ ...styles.col, ...styles.colRight, width: "30%" }}>
            {data.clientLogo && (
              <Image
                src={data.clientLogo}
                style={{
                  alignSelf: "flex-end",
                  width: 100,
                  height: 38,
                  objectFit: "contain",
                  objectPosition: "right center",
                }}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const ResultTitle = ({ result }) => (
  <Text>
    <Link src={result.url} style={styles.title}>
      {result.title}
    </Link>
  </Text>
);

const ResultMeta = ({ result, data, isLink = false }) => (
  <Text style={{ fontSize: 8, color: data.clientColour, textTransform: "uppercase", paddingTop: 3 }}>
    <ConditionalWrapper
      condition={isLink}
      wrapper={(children) => (
        <Link src={result.url} style={{ color: data.clientColour }}>
          {children}
        </Link>
      )}
    >
      {result.formattedPublishedDate} | {result.sourceName}
    </ConditionalWrapper>
  </Text>
);

const ResultExcerpt = ({ result, styles = {} }) => (
  <View style={{ paddingTop: 3, ...styles }}>
    <WYSIWYG html={result.excerpt} />
  </View>
);

const BlockTitle = ({ block }) => (
  <>
    {(block.title || block.type === "CONTENTS") && (
      <Text
        id={block.id} // for toc
        style={{
          fontFamily: "Helvetica-Bold",
          fontSize: 13,
          textTransform: "uppercase",
          paddingBottom: ["GRID", "QUOTE"].includes(block.type) ? 3 : 10,
        }}
      >
        {block.title}
        {!block.title && block.type === "CONTENTS" && "Contents"}
      </Text>
    )}
  </>
);

const BlockHeaderImage = ({ block, data }) => (
  <>
    {block.thumborImage && (
      <View style={{ borderTop: `1px solid ${data.clientColour}`, borderBottom: `1px solid ${data.clientColour}` }}>
        <Image src={block.thumborImage} />
      </View>
    )}
  </>
);

const BlockHeaderChart = ({ block, data, reportSendId }) => (
  <>
    {block.chartId && (
      <View
        style={{
          borderTop: block.thumborImage ? "none" : `1px solid ${data.clientColour}`,
          borderBottom: `1px solid ${data.clientColour}`,
        }}
      >
        <Link href={`${data.appUrl}/${data.clientId}/${data.projectId}/charts/${block.chartId}`}>
          <Image src={getS3Public(`reports/charts/${reportSendId}/${block.chartId}.png`)} />
        </Link>
      </View>
    )}
  </>
);

// images on left, title/meta/excerpt on right
const BlockBanner = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    {block.bannerShowTitle && <BlockTitle block={block} />}
    {block.results.map((result, index) => (
      <View key={result.id} style={styles.table}>
        <View
          style={{
            ...styles.row,
            ...styles.rowList,
            ...(!(block.title && block.bannerShowTitle) && index === 0 ? styles.rowListFirstNoTitle : {}),
            ...(index === block.results.length - 1 ? styles.rowListLast : {}),
          }}
          wrap={false}
        >
          {result.thumborImage && result.showImage && (
            <View style={{ ...styles.col, ...styles.colLeft, width: "25%" }}>
              <Image src={result.thumborImage} />
            </View>
          )}
          <View style={{ ...styles.col, width: result.thumborImage && result.showImage ? "75%" : "100%" }}>
            <ResultTitle result={result} />
            <ResultMeta result={result} data={data} />
            <ResultExcerpt result={result} />
          </View>
        </View>
      </View>
    ))}
  </View>
);

// full-width chart
const BlockChart = ({ block, data, reportSendId }) => (
  <View
    style={{ ...styles.block, borderBottomColor: data.clientColour, paddingTop: 0, paddingBottom: 0 }}
    wrap={false}
    break={block.pdfPageBreakBefore}
  >
    <Link href={`${data.appUrl}/${data.clientId}/${data.projectId}/charts/${block.chartId}`}>
      <Image src={getS3Public(`reports/charts/${reportSendId}/${block.chartId}.png`)} />
    </Link>
  </View>
);

const BlockContents = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    {block.text && <WYSIWYG html={block.text} />}
    {data.contentsBlocks.length && (
      <View style={styles.table}>
        <View style={styles.row} wrap={false}>
          {data.contentsBlocks.map((contentsColumn, index) => (
            <View
              key={index}
              style={{
                ...styles.col,
                width: "50%",
                paddingRight: index === 0 ? "7.5" : 0,
                paddingLeft: index === 1 ? "7.5" : 0,
                paddingTop: block.text ? 10 : 0,
              }}
            >
              <WYSIWYG
                html={`
                  <ul>
                    ${contentsColumn
                      .map((contentsItem, index) => `<li><a href="#${contentsItem.id}">${contentsItem.title}</a></li>`)
                      .join("")}
                  </ul>
                `}
              />
            </View>
          ))}
        </View>
      </View>
    )}
  </View>
);

// excerpt on left, title/meta on right
const BlockFocus = ({ block, data, reportSendId }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    <BlockHeaderImage block={block} data={data} />
    <BlockHeaderChart block={block} data={data} reportSendId={reportSendId} />
    {block.results.map((result, index) => (
      <View key={result.id} style={styles.table}>
        <View
          style={{
            ...styles.row,
            ...styles.rowList,
            ...(!block.title && index === 0 ? styles.rowListFirstNoTitle : {}),
            ...(index === block.results.length - 1 ? styles.rowListLast : {}),
          }}
          wrap={false}
        >
          <View style={{ ...styles.col, width: "65%" }}>
            <ResultExcerpt result={result} styles={{ paddingTop: 0 }} />
          </View>
          <View style={{ ...styles.col, ...styles.colRight, width: "35%" }}>
            <ResultTitle result={result} />
            <ResultMeta result={result} data={data} />
          </View>
        </View>
      </View>
    ))}
  </View>
);

const BlockGrid = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    {block.results.map((row, rowIndex) => (
      <View key={rowIndex} style={styles.table}>
        <View style={styles.row} wrap={false}>
          {row.map((result, index) => (
            <View
              key={index}
              style={{
                ...styles.col,
                width: "50%",
                paddingRight: index === 0 ? "7.5" : 0,
                paddingLeft: index === 1 ? "7.5" : 0,
                paddingTop: !block.title && rowIndex === 0 ? 0 : 10,
              }}
            >
              {result && (
                <View style={styles.table}>
                  <View style={styles.row} wrap={false}>
                    {result.thumborImage && result.showImage && (
                      <View style={{ ...styles.col, ...styles.colLeft, width: "25%" }}>
                        <Image src={result.thumborImage} />
                      </View>
                    )}
                    <View
                      style={{
                        ...styles.col,
                        width: result.thumborImage && result.showImage ? "75%" : "100%",
                      }}
                    >
                      <ResultTitle result={result} />
                      <ResultMeta result={result} data={data} />
                    </View>
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    ))}
  </View>
);

const BlockHeading = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    {block.title && (
      <Text
        id={block.id} // for toc
        style={{
          fontFamily: "Helvetica-Bold",
          fontSize: 15,
          textTransform: "uppercase",
        }}
      >
        {block.title}
      </Text>
    )}
    {block.subtitle && (
      <Text
        style={{
          fontSize: 12,
          paddingTop: 3,
        }}
      >
        {block.title}
      </Text>
    )}
  </View>
);

// title/meta/excerpt on left, images on right
const BlockHeadline = ({ block, data, reportSendId }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    <BlockHeaderImage block={block} data={data} />
    <BlockHeaderChart block={block} data={data} reportSendId={reportSendId} />
    {block.results.map((result, index) => (
      <View key={result.id} style={styles.table}>
        <View
          style={{
            ...styles.row,
            ...styles.rowList,
            ...(!block.title && index === 0 ? styles.rowListFirstNoTitle : {}),
            ...(index === block.results.length - 1 ? styles.rowListLast : {}),
          }}
          wrap={false}
        >
          <View style={{ ...styles.col, width: result.thumborImage && result.showImage ? "75%" : "100%" }}>
            <ResultTitle result={result} />
            <ResultMeta result={result} data={data} />
            <ResultExcerpt result={result} />
          </View>
          {result.thumborImage && result.showImage && (
            <View style={{ ...styles.col, ...styles.colRight, width: "25%" }}>
              <Image src={result.thumborImage} />
            </View>
          )}
        </View>
      </View>
    ))}
  </View>
);

// full-width image, caption underneath
const BlockImage = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} wrap={false} break={block.pdfPageBreakBefore}>
    <Image src={block.thumborImage} />
    {block.imageCaption && (
      <Text style={{ fontSize: 8, textAlign: "center", color: "#666", paddingTop: 3 }}>{block.imageCaption}</Text>
    )}
  </View>
);

// same as Headline
const BlockList = (props) => <BlockHeadline {...props} />;

// excerpt as quote, meta underneath
const BlockQuote = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    {block.results.map((result, index) => (
      <View key={result.id} style={{ paddingTop: !block.title && index === 0 ? 0 : 10 }} wrap={false}>
        <View style={styles.quote}>
          <ResultExcerpt result={result} />
          <View style={{ paddingBottom: 3 }}>
            <ResultMeta result={result} data={data} isLink={true} />
          </View>
        </View>
      </View>
    ))}
  </View>
);

// as Focus but using table cells not results
const BlockTable = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    {block.results.map((result, index) => (
      <View key={result.id} style={styles.table}>
        <View
          style={{
            ...styles.row,
            ...styles.rowList,
            ...(!block.title && index === 0 ? styles.rowListFirstNoTitle : {}),
            ...(index === block.results.length - 1 ? styles.rowListLast : {}),
          }}
          wrap={false}
        >
          <View style={{ ...styles.col, width: "65%" }}>
            <WYSIWYG html={result.tableColumn1} />
          </View>
          <View style={{ ...styles.col, ...styles.colRight, width: "35%" }}>
            <WYSIWYG html={result.tableColumn1} />
          </View>
        </View>
      </View>
    ))}
  </View>
);

const BlockText = ({ block, data }) => (
  <View style={{ ...styles.block, borderBottomColor: data.clientColour }} break={block.pdfPageBreakBefore}>
    <BlockTitle block={block} />
    {block.text && <WYSIWYG html={block.text} />}
  </View>
);

const ReportPDF = (props) => {
  const blocks = props.data.blocks.filter((x) => x.type !== "DELETED");

  return (
    <Document>
      <Page style={styles.page}>
        <Header {...props} />
        {blocks.map((block) => (
          <React.Fragment key={block.id}>
            {block.type === "BANNER" && <BlockBanner block={block} {...props} />}
            {block.type === "CHART" && <BlockChart block={block} {...props} />}
            {block.type === "CONTENTS" && <BlockContents block={block} {...props} />}
            {block.type === "FOCUS" && <BlockFocus block={block} {...props} />}
            {block.type === "GRID" && <BlockGrid block={block} {...props} />}
            {block.type === "HEADING" && <BlockHeading block={block} {...props} />}
            {block.type === "HEADLINE" && <BlockHeadline block={block} {...props} />}
            {block.type === "IMAGE" && <BlockImage block={block} {...props} />}
            {block.type === "LIST" && <BlockList block={block} {...props} />}
            {block.type === "QUOTE" && <BlockQuote block={block} {...props} />}
            {block.type === "TABLE" && <BlockTable block={block} {...props} />}
            {block.type === "TEXT" && <BlockText block={block} {...props} />}
          </React.Fragment>
        ))}
      </Page>
    </Document>
  );
};

export default ReportPDF;

import { Button, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { ConditionalWrapper } from "../lib/utils";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { getJob } from "../../graphql/queries";

const JobStatus = ({
  id,
  type,
  prepareMessage,
  successMessage,
  successAction,
  successButtonIcon,
  successButtonText,
  onJobComplete,
  onSuccess,
  onRestart,
  hideRestartOnSuccess = false,
  displayType,
}) => {
  const { loading, data, stopPolling } = useQuery(gql(getJob), { variables: { id }, pollInterval: 1_000 });
  const [completed, setCompleted] = useState(false);

  // job successful
  useEffect(() => {
    if (!loading && data && data.getJob.status === "SUCCESS" && !completed) {
      stopPolling();
      // pass job result to onJobComplete
      onJobComplete && onJobComplete(data.getJob.result);
      // required to prevent infinite loop while JobStatus is still rendered before success button is clicked
      setCompleted(true);
    }
  }, [loading, data, stopPolling, onJobComplete, completed]);

  const divClasses = classNames({
    "d-block": ["FULL", "MODAL"].includes(displayType),
    "d-inline": displayType === "INLINE",
    "text-center": ["FULL", "MODAL"].includes(displayType),
    "mt-3": displayType === "MODAL",
    "mb-3": displayType === "MODAL",
  });

  // retry on failure
  const renderRetryButton = () => (
    <Button color="primary" className={displayType === "MODAL" ? "ml-auto" : "mt-2"} onClick={onRestart}>
      <i className="uil-refresh mr-1" />
      Retry
    </Button>
  );

  // success
  const renderSuccessButton = () => (
    <Button
      color="primary"
      className={displayType === "MODAL" ? "ml-auto" : "mt-2"}
      // pass job result to onSuccess
      onClick={() => onSuccess(data.getJob.result)}
    >
      {successButtonIcon && <i className={`${successButtonIcon} mr-1`} />}
      {successButtonText}
    </Button>
  );

  // restart after success
  const renderRestartButton = () => {
    if (hideRestartOnSuccess) return null;
    return (
      <Button color="primary" outline className={displayType === "MODAL" ? "" : "ml-2 mt-2"} onClick={onRestart}>
        Restart
      </Button>
    );
  };

  // getting job, or job has started
  if (loading || (data && ["PENDING", "STARTED"].includes(data.getJob.status))) {
    return (
      <ConditionalWrapper condition={displayType === "MODAL"} wrapper={(children) => <ModalBody>{children}</ModalBody>}>
        <div className={divClasses}>
          {displayType === "INLINE" ? (
            <>{prepareMessage ? prepareMessage : "Pending..."}</>
          ) : (
            <>
              <Spinner />
              <p className="mt-1 mb-0">
                {prepareMessage ? prepareMessage : `Preparing your ${type}, this might take a few minutes...`}
              </p>
            </>
          )}
        </div>
      </ConditionalWrapper>
    );
  }

  // job not found, or job exists but failed
  if (!data || (data && data.getJob.status === "FAIL")) {
    stopPolling();
    return (
      <>
        <ConditionalWrapper
          condition={displayType === "MODAL"}
          wrapper={(children) => <ModalBody>{children}</ModalBody>}
        >
          <div className={divClasses}>
            {displayType === "INLINE" ? (
              <>Error</>
            ) : (
              <>
                <i className="uil-exclamation-triangle font-24 d-block" />
                <p className="mt-1 mb-0">
                  We're sorry but an error occurred when preparing your {type}.<br />
                  Our technical team have been informed of the error.
                </p>
                {displayType === "FULL" && renderRetryButton()}
              </>
            )}
          </div>
        </ConditionalWrapper>
        {displayType === "MODAL" && <ModalFooter>{renderRetryButton()}</ModalFooter>}
      </>
    );
  }

  return (
    <>
      <ConditionalWrapper condition={displayType === "MODAL"} wrapper={(children) => <ModalBody>{children}</ModalBody>}>
        <div className={divClasses}>
          {displayType === "INLINE" ? (
            <>Success</>
          ) : (
            <>
              <i className="uil-check-circle font-24 d-block" />
              <p className="mt-1 mb-0">
                {successMessage ? successMessage : `Your ${type} is ready to ${successAction}.`}
              </p>
              {displayType === "FULL" && (
                <>
                  {renderSuccessButton()}
                  {renderRestartButton()}
                </>
              )}
            </>
          )}
        </div>
      </ConditionalWrapper>
      {displayType === "MODAL" && (
        <ModalFooter>
          {renderRestartButton()}
          {renderSuccessButton()}
        </ModalFooter>
      )}
    </>
  );
};

export default JobStatus;

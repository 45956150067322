import { Button, Card, CardBody, CardHeader, Modal, ModalHeader, Table } from "reactstrap";
import React, { useContext, useState } from "react";
import { createAlertSubscription, deleteAlertSubscription, fnTriggerJob } from "../../graphql/mutations";
import { gql, useMutation, useQuery } from "@apollo/client";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import JobStatus from "../JobStatus";
import PageTitle from "../../components/PageTitle";
import ProjectModal from "../Modals/ProjectModal";
import RecipientsForm from "../Modals/RecipientsForm";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import { formatDateTime } from "../../lib/utils";
import { getAlertSubscription } from "../../graphql/queries";
import isNull from "lodash/isNull";
import { useParams } from "react-router-dom";

const SendModal = ({ toggle, projectData }) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle(false, null)}>Send Daily Summary Alerts</ModalHeader>
      <RecipientsForm type="DAILY" isModal onComplete={(jobId) => toggle(true, jobId)} projectData={projectData} />
    </Modal>
  );
};

const SubscriptionButton = ({ id, user }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationCreateAlertSubscription] = useMutation(gql(createAlertSubscription));
  const [mutationDeleteAlertSubscription] = useMutation(gql(deleteAlertSubscription));

  const subscriptionId = `${user.id}-${id}`;

  // user is subscribed if a record is returned
  const { loading, data, refetch } = useQuery(gql(getAlertSubscription), {
    variables: { id: subscriptionId },
  });
  if (loading) return <Spinner className="spinner-border-sm" />;
  const subscribed = !isNull(data.getAlertSubscription);

  const handleToggle = async (subscribe) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      if (subscribe) {
        const input = { clientId, projectId, userId: user.id, id: subscriptionId, type: id };
        await mutationCreateAlertSubscription({
          variables: { input },
        });
      } else {
        await mutationDeleteAlertSubscription({
          variables: { input: { id: subscriptionId } },
        });
        await refetch();
      }
      // doing this for now instead of dealing with hard-delete cache updates on single items
      await refetch();
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  if (subscribed)
    return (
      <Button
        color="primary"
        size="sm"
        className="ml-1 mt-1"
        disabled={isSubmitting}
        onClick={() => handleToggle(false)}
      >
        <i className="uil-bell mr-1" />
        Subscribed
      </Button>
    );

  return (
    <Button
      color="primary"
      size="sm"
      outline
      className="ml-1 mt-1"
      disabled={isSubmitting}
      onClick={() => handleToggle(true)}
    >
      <i className="uil-bell-slash mr-1" />
      Unsubscribed
    </Button>
  );
};

const DailyPreview = ({ user }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const alertModal = createConfirmation(AlertModal);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));

  // send daily preview to current user
  const handlePreview = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const jobData = { op: "PREVIEW", type: "DAILY", recipients: [user.email] };
      // trigger job, UI doesn't show status
      await mutationFnTriggerJob({
        variables: { input: { clientId, projectId, job: "ALERT", data: JSON.stringify(jobData) } },
      });
      await alertModal({
        message: (
          <>
            <p>
              A preview of the Daily Summary Alert email has been sent to <b>{user.email}</b>, based on results added
              since the last alert was sent (or from the last 24 hours).
            </p>
            <p className="text-muted">An email will not be sent if no results are found.</p>
          </>
        ),
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <Button size="sm" outline className="mt-1" disabled={isSubmitting} onClick={handlePreview}>
      {isSubmitting ? (
        <Spinner className="spinner-border-sm mr-1" tag="span" />
      ) : (
        <i className="uil-envelope-search mr-1" />
      )}
      Preview
    </Button>
  );
};

const IssueRow = ({ issue, user }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const alertModal = createConfirmation(AlertModal);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));

  // send issue preview to current user
  const handlePreview = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const jobData = { op: "PREVIEW", type: "ISSUE", issueId: issue.id, recipients: [user.email] };
      // trigger job, UI doesn't show status
      await mutationFnTriggerJob({
        variables: { input: { clientId, projectId, job: "ALERT", data: JSON.stringify(jobData) } },
      });
      await alertModal({
        message: (
          <>
            <p>
              A preview of the Hourly Issue Alert email has been sent to <b>{user.email}</b>, based on results added to
              the <b>{issue.name}</b> issue since the last alert was sent (or from the last hour).
            </p>
            <p className="text-muted">An email will not be sent if no results are found.</p>
          </>
        ),
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <tr>
      <td className="pl-3 pr-3 align-middle">{issue.name}</td>
      <td className="pl-3 pr-3 text-right table-cell-wrap-buttons">
        <Button size="sm" outline className="mt-1" disabled={isSubmitting} onClick={handlePreview}>
          {isSubmitting ? (
            <Spinner className="spinner-border-sm mr-1" tag="span" />
          ) : (
            <i className="uil-envelope-search mr-1" />
          )}
          Preview
        </Button>
        <SubscriptionButton id={`issue-${issue.id}`} user={user} />
      </td>
    </tr>
  );
};

const Alerts = ({ project, user, projectData }) => {
  const [sendModal, toggleSendModal] = useState(false);
  const [settingsModal, toggleSettingsModal] = useState(null);
  const [jobId, setJobId] = useState(null);

  const webIssues = projectData.filters.filter((x) => x.source === "METABASE");
  const socialIssues = projectData.filters.filter((x) => x.source === "SOCIAL360");

  return (
    <>
      <PageTitle title="Alerts">
        {user.can.updateProject(project.id) && (
          <Button
            color="success"
            onClick={() => {
              toggleSettingsModal({ data: project, op: "UPDATE" });
            }}
          >
            <i className="uil-cog mr-1" />
            Alert Settings
          </Button>
        )}
      </PageTitle>

      {user.can.sendAlerts && (
        <Card className="card-table">
          <CardHeader className="font-bold">Daily Summary Alerts</CardHeader>
          <CardBody className="p-0">
            <Table hover className="mb-0">
              <tbody>
                <tr>
                  <td className="pl-3 pr-3 align-middle">
                    Last sent:{" "}
                    {jobId ? (
                      <JobStatus id={jobId} onJobComplete={() => setJobId(null)} displayType="INLINE" />
                    ) : project.dailyAlertLastSent ? (
                      formatDateTime(project.dailyAlertLastSent, project.timezone)
                    ) : (
                      "Never"
                    )}
                  </td>
                  {project.dailyAlertAuto ? (
                    <td className="pl-3 pr-3 text-right table-cell-wrap-buttons">
                      <Button size="sm" outline className="mt-1" disabled>
                        Sent automatically at {project.dailyAlertHour < 10 ? "0" : ""}
                        {project.dailyAlertHour}:00 ({project.timezone})
                      </Button>
                    </td>
                  ) : (
                    <td className="pl-3 pr-3 text-right table-cell-wrap-buttons">
                      <DailyPreview user={user} />
                      <Button color="primary" size="sm" className="ml-1 mt-1" onClick={() => toggleSendModal(true)}>
                        <i className="uil-envelope-send mr-1" />
                        Send
                      </Button>
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}

      <Card className="card-table">
        <CardHeader className="font-bold">My Daily Summary Alerts</CardHeader>
        <CardBody className="p-0">
          <Table hover className="mb-0">
            <tbody>
              <tr>
                <td className="pl-3 pr-3 align-middle">
                  {project.dailyAlertAuto
                    ? `Highlights from the last 24 hours of the project, delivered at ${
                        project.dailyAlertHour < 10 ? "0" : ""
                      }${project.dailyAlertHour}:00 (${project.timezone}).`
                    : "Highlights from the project, delivered daily."}
                </td>
                <td className="pl-3 pr-3 text-right table-cell-wrap-buttons">
                  <DailyPreview user={user} />
                  <SubscriptionButton id={`daily-${project.id}`} user={user} />
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      {webIssues.length > 0 && (
        <Card className="card-table">
          <CardHeader className="font-bold">My Hourly News Issue Alerts</CardHeader>
          <CardBody className="p-0">
            <Table hover className="mb-0">
              <tbody>
                {webIssues.map((issue) => (
                  <IssueRow key={issue.id} issue={issue} user={user} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}

      {projectData.socialEnabled && socialIssues.length > 0 && (
        <Card className="card-table">
          <CardHeader className="font-bold">My Hourly Social Issue Alerts</CardHeader>
          <CardBody className="p-0">
            <Table hover className="mb-0">
              <tbody>
                {socialIssues.map((issue) => (
                  <IssueRow key={issue.id} issue={issue} user={user} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}

      {sendModal && (
        <SendModal
          toggle={(sent, jobId) => {
            toggleSendModal();
            if (sent) setJobId(jobId);
          }}
          user={user}
          projectData={projectData}
        />
      )}

      {settingsModal && <ProjectModal data={settingsModal} toggle={toggleSettingsModal} isAlerts />}
    </>
  );
};

export default Alerts;

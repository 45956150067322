import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  Label,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "reactstrap";
import React, { useContext } from "react";
import { fnReport, updateReportResult } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { inputValidateUrl, populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import FormEditor from "../Forms/FormEditor";
import FormFile from "../Forms/FormFile";
import FormInput from "../Forms/FormInput";
import FormSwitch from "../Forms/FormSwitch";
import classNames from "classnames";
import { getThumborImage } from "../../lib/files";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import omit from "lodash/omit";
import { useForm } from "react-hook-form";
import { useMediaBreakpointDownLg } from "../lib/effects";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

const ResultPanel = ({ op, data, setIsUpdating, onClose }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId, reportId } = useParams();
  const [mutationFnReport] = useMutation(gql(fnReport));
  const [mutationUpdateReportResult] = useMutation(gql(updateReportResult));

  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  const initial =
    op === "CREATE"
      ? {
          // default to now
          publishedDateDate: moment().format("YYYY-MM-DD"),
          publishedDateTime: "00:00",
        }
      : {
          ...data,
          // split publishedDate out to separate fields
          publishedDateDate: moment.utc(data.publishedDate).format("YYYY-MM-DD"),
          publishedDateTime: moment.utc(data.publishedDate).format("HH:mm"),
        };

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["title", "url", "sourceName", "publishedDateDate", "publishedDateTime"],
      files: ["imageOverride"],
      switches: [["showImage", true]],
      editors: ["excerpt"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // mutations going through fnReport require a refresh
      let refetch = false;
      setIsUpdating(true);
      const formData = await processForm(
        {
          ...omit(values, ["publishedDateDate", "publishedDateTime"]),
          // convert local time to UTC iso string
          publishedDate: moment(
            `${values.publishedDateDate} ${values.publishedDateTime}`,
            "YYYY-MM-DD HH:mm"
          ).toISOString(),
        },
        {
          files: [["imageOverride", data.imageOverride]],
          editors: ["excerpt"],
        }
      );
      if (op === "CREATE") {
        const input = { reportId, reportBlockId: data.id, id: uuid(), ...formData };
        await mutationFnReport({
          variables: { input: { clientId, projectId, op: "RESULT_CREATE", data: JSON.stringify(input) } },
        });
        refetch = true;
      } else {
        const input = { clientId, projectId, reportId, reportBlockId: data.reportBlockId, id: data.id, ...formData };
        await mutationUpdateReportResult({ variables: { input } });
      }
      onClose(refetch);
    } catch (e) {
      setIsUpdating(false);
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card
        className={classNames({
          "side-panel": true,
          "side-panel--white": true,
          "vh-with-title": !isMediaBreakpointDownLg,
          "vh-with-title-wrapped-buttons": isMediaBreakpointDownLg,
        })}
      >
        <Nav tabs className="nav-bordered">
          <NavItem className="flex-grow-1">
            <h4 className="side-panel-title text-title mt-0 mb-0">{title} Result</h4>
          </NavItem>
          <NavItem className="ml-auto side-panel--buttons">
            <Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Save
            </Button>
            <Button color="secondary" size="sm" className="side-panel--close-button-sm" onClick={() => onClose(false)}>
              <i className="uil-times" />
            </Button>
          </NavItem>
        </Nav>

        <CardBody className="pb-2 scroll">
          <FormInput name="title" label="Title" required formProps={formProps} />

          <FormEditor name="excerpt" label="Excerpt" required formProps={formProps} />

          <FormInput
            name="url"
            validate={inputValidateUrl}
            label="Link"
            required
            addon={
              data.url && (
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => {
                      window.open(data.url);
                    }}
                  >
                    <i className="uil-eye" />
                  </Button>
                </InputGroupAddon>
              )
            }
            formProps={formProps}
          />

          <FormInput name="sourceName" label="Source name" required formProps={formProps} />

          <FormGroup className="mb-0">
            <Label for="publishedDate">Published date:</Label>
            <Row noGutters>
              <Col xs={7}>
                <FormInput name="publishedDateDate" type="date" required className="mb-0" formProps={formProps} />
              </Col>
              <Col xs={5}>
                <FormInput name="publishedDateTime" type="time" required className="mb-0" formProps={formProps} />
              </Col>
            </Row>
          </FormGroup>

          <hr className="form" />

          <Row noGutters>
            {data.image && op !== "CREATE" && (
              <Col xs={2}>
                <FormGroup>
                  <Label className="d-block">Image:</Label>
                  <img
                    src={getThumborImage(data.image, 60, 38, false, true)}
                    alt=""
                    className="click"
                    onClick={() => window.open(data.image)}
                  />
                </FormGroup>
              </Col>
            )}
            <Col xs={!(data.image && op !== "CREATE") ? 12 : 10}>
              <FormFile
                name="imageOverride"
                label={data.image && op !== "CREATE" ? "Image override" : "Image"}
                accept="image/*"
                current={data.imageOverride}
                uploadPath="reports/images"
                uploadLevel="public"
                formProps={formProps}
              />
            </Col>
          </Row>

          <FormSwitch
            name="showImage"
            label="Show image"
            inlineLabelOn="Yes"
            inlineLabelOff="No"
            oneLine
            formProps={formProps}
          />
        </CardBody>
      </Card>
    </Form>
  );
};

export default ResultPanel;

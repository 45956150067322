import { Container } from "reactstrap";
import React from "react";
import Topbar from "../components/Topbar";

const NonProject = ({ children, openLeftMenu, title, user, ...rest }) => {
  return (
    <div className="layout-non-project">
      <div className="content-page">
        <div className="content">
          <Topbar {...rest} openLeftMenuCallBack={openLeftMenu} title={title} user={user} />
          <Container fluid>{children}</Container>
        </div>
      </div>
    </div>
  );
};

export default NonProject;

import { Alert, Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { fnAdminUser, fnAdminUserToggle } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { headerFormatter, selectFilter, textFilter } from "../lib/tables";
import { populateForm, processForm } from "../lib/forms";
import { sort, sortArray } from "../../lib/utils";

import { AppContext } from "../../App";
import BootstrapTable from "react-bootstrap-table-next";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import { TRANSLATE_LANGUAGES } from "../../lib/constants";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { adminListUserProfilesVariables } from "../../lib/variables";
import { createConfirmation } from "react-confirm";
import filter from "lodash/filter";
import filterFactory from "react-bootstrap-table2-filter";
import find from "lodash/find";
import { listUserProfiles } from "../../graphql/queries";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { titleCase } from "title-case";
import uniq from "lodash/uniq";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

// changes need to be replicated in fnAdminUser for validation
const ROLES = [
  { value: "SUBSCRIBER", label: "Subscriber" },
  { value: "ANALYST", label: "Analyst" },
  { value: "SUPER_ANALYST", label: "Super Analyst" },
];

// changes need to be replicated in fnAdminUser for validation
const PERMS = [
  { value: "AGENTS", label: "Agents (section)" },
  { value: "CHARTS", label: "Charts (create/edit)" },
  { value: "EXPORTS", label: "Exports (section)" },
  { value: "REPORTS", label: "Reports (section)" },
];

// for table select filter
const ROLES_TABLE = [...ROLES, { value: "ADMIN", label: "Admin" }];

// tidy up timezone list, just keep country-level ones (contain slash), plus UTC
const TIMEZONES = [
  ...filter(moment.tz.names(), (name) => name.indexOf("/") !== -1 && name.indexOf("Etc/") === -1),
  "UTC",
].map((x) => ({ value: x, label: x }));

// only translatable languages
const LANGUAGES = Object.values(TRANSLATE_LANGUAGES).map((x) => ({ value: x, label: x }));

// friendly role name
const formatRole = (role) => titleCase(role.replace("_", " ").toLowerCase());

// ids of every client and project the user can access (for table filtering)
const getAccess = (user, activeClients, activeProjects) => {
  // by default user can access all projects on client
  const access = [];
  if ((user.projects || []).length) {
    // lookup full project from the ids stored on the user
    const userProjects = activeProjects.filter((x) => user.projects.includes(x.id));
    for (const project of userProjects) {
      access.push(`client-${project.clientId}`);
      access.push(`project-${project.id}`);
    }
  } else {
    // user has access to all projects on their clients
    const userClients = activeClients.filter((x) => user.clients.includes(x.id));
    for (const client of userClients) {
      access.push(`client-${client.id}`);
      for (const project of activeProjects.filter((x) => x.clientId === client.id)) {
        access.push(`project-${project.id}`);
      }
    }
  }
  return sortArray(uniq(access));
};

// from the ids generated above, show friendly names
const formatAccess = (user, activeClients, activeProjects) => {
  let list = [];
  if ((user.projects || []).length) {
    // show the specific projects
    list = user.access
      .filter((x) => x.startsWith("project-"))
      .map((x) => find(activeProjects, (p) => p.id === x.substr(8)))
      .map((x) => `${x.client.name} - ${x.name}`);
  } else {
    // show "all" for each client
    list = user.access
      .filter((x) => x.startsWith("client-"))
      .map((x) => find(activeClients, (c) => c.id === x.substr(7)))
      .map((x) => `${x.name} - All`);
  }
  return (
    <ul className="mb-0" style={{ paddingLeft: "0.9rem" }}>
      {sortArray(list).map((x) => (
        <li key={x}>{x}</li>
      ))}
    </ul>
  );
};

const UsersToggle = ({ user }) => {
  const appContext = useContext(AppContext);
  const [mutationFnAdminUserToggle] = useMutation(gql(fnAdminUserToggle));
  const [loading, setLoading] = useState(false);

  const handleClick = async (op) => {
    if (loading) return;
    setLoading(true);
    try {
      await mutationFnAdminUserToggle({ variables: { input: { op, id: user.id } } });
    } catch (e) {
      appContext.handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      size="sm"
      className="ml-1"
      disabled={loading}
      onClick={() => handleClick(user.enabled ? "DISABLE" : "ENABLE")}
    >
      {loading && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
      {user.enabled ? "Disable" : "Enable"}
    </Button>
  );
};

const UsersDelete = ({ user }) => {
  const appContext = useContext(AppContext);
  const [mutationFnAdminUserToggle] = useMutation(gql(fnAdminUserToggle));
  const [loading, setLoading] = useState(false);

  const confirmModal = createConfirmation(ConfirmModal);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    const ok = await confirmModal();
    if (!ok) {
      setLoading(false);
      return;
    }
    try {
      await mutationFnAdminUserToggle({ variables: { input: { op: "DELETE", id: user.id } } });
    } catch (e) {
      setLoading(false);
      appContext.handleError(e);
    }
  };

  return (
    <Button size="sm" className="ml-1" color="danger" disabled={loading} onClick={handleClick}>
      {loading && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
      Delete
    </Button>
  );
};

const UsersModal = ({ data: { data, op }, toggle, clients, projects }) => {
  const appContext = useContext(AppContext);
  const [tempPassword, setTempPassword] = useState();
  const [mutationFnAdminUser] = useMutation(gql(fnAdminUser));

  const clientOptions = clients.map((x) => ({ value: x.id, label: x.name }));
  let projectOptions = projects.map((x) => ({ value: x.id, label: x.name }));

  const initial = data ? data : { timezone: "Europe/London", language: "English" };

  const { register, handleSubmit, errors, formState, control, getValues, reset, watch, setValue } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["email", "firstName", "lastName"],
      selects: [
        ["role", ROLES],
        ["timezone", TIMEZONES],
        ["language", LANGUAGES],
      ],
      selectMultis: [
        ["perms", PERMS],
        ["clients", clientOptions],
        ["projects", projectOptions],
      ],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  // watch for changes on client field and update options on projects field
  const watchClients = watch("clients");
  if (!watchClients) {
    // no clients selected, no projects available
    projectOptions = [];
  } else {
    // filter to projects from these clients
    const clientIds = watchClients.map((x) => x.value);
    projectOptions = projects
      .filter((x) => clientIds.includes(x.clientId))
      .map((x) => ({ value: x.id, label: x.name }));
  }

  // if projects field has a value, and clients are changed, remove invalid projects
  useEffect(() => {
    const values = getValues();
    if (values["projects"]) {
      const clientIds = watchClients ? watchClients.map((x) => x.value) : [];
      const validProjectsIds = projects.filter((x) => clientIds.includes(x.clientId)).map((x) => x.id);
      reset({
        ...getValues(),
        projects: values["projects"].filter((x) => validProjectsIds.includes(x.value)),
      });
    }
  }, [watchClients, projects, getValues, reset]);

  // trigger re-render when role is changed for conditional permissions field
  const watchRole = watch("role");
  const currentRole = watchRole ? watchRole.value : null;

  const onSubmit = async (values) => {
    const input = await processForm(
      { op, ...values },
      { selects: ["role", "timezone", "language"], selectMultis: ["perms", "clients", "projects"] }
    );
    input.id = op === "CREATE" ? uuid() : data.id;
    try {
      if (op === "CREATE") {
        // add user, update cache
        const response = await mutationFnAdminUser({
          variables: { input },
          update: updateCacheCreate(gql(listUserProfiles), adminListUserProfilesVariables()),
        });
        // we have a temp password to show, keep modal open
        setTempPassword({ email: values.email, password: response.data.fnAdminUser.tempPassword });
      } else {
        // update user
        await mutationFnAdminUser({
          variables: { input },
        });
        // close modal
        toggle();
      }
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{title} User</ModalHeader>
      {tempPassword ? (
        <>
          <ModalBody>
            <p>
              The user <b>{tempPassword.email}</b> has been created and assigned a temporary password:
            </p>
            <Alert color="info" fade={false} className="font-18 text-center">
              {tempPassword.password}
            </Alert>
            <p>
              <b>
                This temporary password needs to be sent to the user manually - this is the only time this password is
                available to view, so make a note of it before closing this window.
              </b>
            </p>
            <p>The user must login within 60 days and they will be prompted to reset their password on first login.</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => toggle()}>
              Close
            </Button>
          </ModalFooter>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormInput name="email" label="Email" type="email" required formProps={formProps} />
            <Row>
              <Col>
                <FormInput name="firstName" label="First name" required formProps={formProps} />
              </Col>
              <Col>
                <FormInput name="lastName" label="Last name" required formProps={formProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormSelect
                  name="timezone"
                  label="Timezone"
                  options={TIMEZONES}
                  required
                  helpText="Timezone is used to localise dates/times."
                  formProps={formProps}
                />
              </Col>
              <Col>
                <FormSelect
                  name="language"
                  label="Language"
                  options={LANGUAGES}
                  required
                  helpText="Language is used to set default translation language."
                  formProps={formProps}
                />
              </Col>
            </Row>
            {((data && data.role !== "ADMIN") || !data) && (
              <FormSelect
                name="role"
                label="Role"
                options={ROLES}
                required
                helpText={
                  <span>
                    <b>Subscriber</b>: Subscriber-level access to all projects on the clients, or specific projects
                    (define below). <b>Analyst:</b> Analyst-level access to all projects on the clients, or specific
                    projects (define below), cannot create/edit/delete projects. <b>Super Analyst:</b> Analyst-level
                    access to all projects on the clients, can create/edit/delete projects.
                  </span>
                }
                formProps={formProps}
              />
            )}
            {["ANALYST", "SUPER_ANALYST"].includes(currentRole) && (
              <FormSelect name="perms" label="Analyst Permissions" options={PERMS} isMulti formProps={formProps} />
            )}
            <FormSelect
              name="clients"
              label="Clients"
              options={clientOptions}
              required={(data && data.role !== "ADMIN") || !data}
              isMulti
              formProps={formProps}
            />
            {["SUBSCRIBER", "ANALYST"].includes(currentRole) && (
              <FormSelect
                name="projects"
                label="Projects"
                options={projectOptions}
                helpText="Leave blank for access to all projects on all of the clients added above."
                isMulti
                formProps={formProps}
              />
            )}
            {op === "UPDATE" && (
              <p className="alert alert-info">
                Users will need to logout and login to pick up any changes made to roles/clients/projects.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              {action}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const Users = ({ clients, users, projects }) => {
  const [modal, toggleModal] = useState(null);

  // exclude deleted users from table
  users = users.filter((x) => !x.isDeleted);

  // form options for modal:
  // exclude deleted clients and dont allow outcider to be selected
  clients = sort(
    clients.filter((x) => !x.isDeleted && x.subdomain !== "outcider"),
    "subdomain"
  );
  // exclude deleted projects and projects of deleted clients
  projects = sort(
    projects.filter((x) => !x.isDeleted && !x.client.isDeleted),
    "name"
  );

  // annotate each user with access list
  users = users.map((x) => ({ ...x, access: getAccess(x, clients, projects) }));

  // access filter options, must match format in getAccess
  let accessOptions = [];
  for (const client of sort(clients, "name")) {
    accessOptions.push({ value: `client-${client.id}`, label: client.name });
    for (const project of projects.filter((x) => x.clientId === client.id)) {
      accessOptions.push({ value: `project-${project.id}`, label: `${client.name} - ${project.name}` });
    }
  }

  const columns = [
    // name/email in one field, sorted by name, searchable by both
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => (
        <>
          <span className="font-bold">{cell}</span>
          <br />
          {row.email}
        </>
      ),
      filter: textFilter,
      filterValue: (cell, row) => `${cell} ${row.email}`,
      headerFormatter: headerFormatter,
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cell) => formatRole(cell),
      filter: selectFilter({ options: ROLES_TABLE }),
      headerFormatter: headerFormatter,
    },
    {
      dataField: "access",
      text: "Access",
      formatter: (cell, row) => formatAccess(row, clients, projects),
      filter: selectFilter({ options: accessOptions }),
      headerFormatter: headerFormatter,
      headerClasses: "bootstrap-table-stretch",
      classes: "bootstrap-table-stretch",
    },
    {
      dataField: "actions",
      text: "",
      formatter: (_, row) => (
        <div style={{ whiteSpace: "nowrap", textAlign: "right" }}>
          {row.enabled && (
            <Button size="sm" onClick={() => toggleModal({ data: row, op: "UPDATE" })}>
              Edit
            </Button>
          )}
          {row.role !== "ADMIN" && (
            <>
              <UsersToggle user={row} />
              <UsersDelete user={row} />
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Users">
        <Button color="success" onClick={() => toggleModal({ data: null, op: "CREATE" })}>
          <i className="uil-plus mr-1" />
          Create User
        </Button>
      </PageTitle>
      {users.length ? (
        <ToolkitProvider bootstrap4 keyField="id" data={users} columns={columns}>
          {(props) => (
            <BootstrapTable
              {...props.baseProps}
              hover
              rowStyle={(row) => ({ opacity: row.role === "ADMIN" ? 0.5 : 1 })}
              defaultSorted={[
                {
                  dataField: "name",
                  order: "asc",
                },
              ]}
              filter={filterFactory()}
              classes="bootstrap-table mb-0"
              wrapperClasses="table-responsive card mb-0"
              headerWrapperClasses="card-header"
              noDataIndication={
                <div className="table-empty-admin">
                  <p className="mb-0 text-center">There are no users to display.</p>
                </div>
              }
            />
          )}
        </ToolkitProvider>
      ) : (
        <Alert color="info" fade={false}>
          There are no users to display.
        </Alert>
      )}

      {modal && <UsersModal data={modal} toggle={toggleModal} clients={clients} projects={projects} />}
    </>
  );
};

export default Users;

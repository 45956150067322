import { formatDate, formatDateTime, sort, sortOrderable } from "./utils";
import { getS3Public, getThumborImage } from "./files";

import assign from "lodash/assign";
import chunk from "lodash/chunk";
import fill from "lodash/fill";
import { getAppUrl } from "./constants";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";

// get report with all data needed to render statistics
export const getReportStats = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      clientId
      projectId
      id
      sends {
        items {
          id
          isPreview
          createdAt
          updatedAt
          recipients {
            items {
              id
              email
              firstName
              lastName
              company
              country
              sentAt
              openedAt
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

// get report with all connections and all data needed to render report
export const getReportFull = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      clientId
      projectId
      id
      source
      name
      title
      subject
      showHeader
      sentAt
      isDeleted
      createdAt
      updatedAt
      project {
        clientId
        id
        name
        description
        image
        timezone
        topStoriesViewAll
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        isArchived
        isDeleted
        createdAt
        updatedAt
        client {
          id
          subdomain
          name
          colour
          logo
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
        }
      }
      blocks {
        items {
          clientId
          projectId
          reportId
          id
          type
          auto
          autoFilter
          title
          subtitle
          text
          image
          imageCaption
          imageLink
          chartId
          bannerShowTitle
          contentsInclude
          pdfPageBreakBefore
          order
          createdAt
          updatedAt
          results {
            items {
              clientId
              projectId
              reportId
              reportBlockId
              id
              type
              resultId
              source
              publishedDate
              title
              excerpt
              url
              image
              sourceName
              sourceType
              authorImage
              tableColumn1
              tableColumn2
              showImage
              imageOverride
              order
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const getReportData = ({ report, op }) => {
  // switches for different modes
  const switches = {
    // report builder (unsent)
    builder: {
      isBuilder: true,
      showWebVersion: false,
      showUnsubscribe: false,
      showTrack: false,
    },
    // view from report builder (unsent)
    view: {
      isBuilder: false,
      showWebVersion: false,
      showUnsubscribe: false,
      showTrack: false,
    },
    // web version linked to from email (sent)
    web: {
      isBuilder: false,
      showWebVersion: false,
      showUnsubscribe: false,
      showTrack: false,
    },
    // email preview (sent)
    preview: {
      isBuilder: false,
      showWebVersion: true,
      showUnsubscribe: false,
      showTrack: false,
    },
    // email send (sent)
    send: {
      isBuilder: false,
      showWebVersion: true,
      showUnsubscribe: true,
      showTrack: true,
    },
  };

  // build data
  const processResult = (result) => ({
    ...result,
    thumborImage: getThumborImage(result.imageOverride || result.image, 600, 400, false, true),
    authorImage: getThumborImage(result.authorImage, 56, 56, true, true),
    // image: "https://via.placeholder.com/600x400",
    formattedPublishedDate: result.publishedDate ? formatDateTime(result.publishedDate, report.project.timezone) : null,
  });

  const processResults = (block, results) => {
    // split into chunks of 2, fill with null
    // https://stackoverflow.com/a/32371039
    if (block.type === "GRID") {
      const grouped = [];
      for (const group of chunk(results, 2)) {
        const row = assign(fill(new Array(2), null), group.map(processResult));
        grouped.push(row);
      }
      return grouped;
    }

    // cycle image sides
    if (block.type === "HEADLINE") {
      return results.map((result, index) => ({
        ...processResult(result),
        imageClass: index % 2 === 0 ? "report-float-img-left" : "report-float-img-right",
        imageAlign: index % 2 === 0 ? "left" : "right",
      }));
    }

    return results.map(processResult);
  };

  // contents are blocks which are selected to be included in toc, but aren't contents/image blocks, and have titles
  // split into two lists, first column bigger if equal
  // start indexes at 1 as 0 will fail bool check in template
  const processContents = (blocks) => {
    const contentsBlocks = blocks
      .filter((block) => block.contentsInclude && !["IMAGE", "CONTENTS", "DELETED"].includes(block.type) && block.title)
      .map((block, index) => ({ id: block.id, index: index + 1, title: block.title }));
    if (!contentsBlocks.length)
      return {
        columns: [],
        index: {},
      };
    const split = Math.ceil(contentsBlocks.length / 2);
    return {
      columns: [contentsBlocks.slice(0, split), contentsBlocks.slice(split)],
      index: Object.fromEntries(contentsBlocks.map((x) => [x.id, x.index])),
    };
  };

  const orderedBlocks = sortOrderable(report.blocks.items);
  const contentsData = processContents(orderedBlocks);

  return {
    appUrl: getAppUrl(report.project.client.subdomain),
    clientId: report.project.client.id,
    projectId: report.project.id,
    // client
    clientName: report.project.client.name,
    clientColour: report.project.client.colour || "#172544",
    clientLogo: report.project.client.reportLogo ? getThumborImage(report.project.client.reportLogo, 300) : null,
    // report
    reportTitle: report.title,
    reportDate: formatDate(moment.utc(), report.project.timezone),
    reportFooter: report.project.client.reportFooter,
    reportShowHeader: report.showHeader,
    // blocks
    blocks: orderedBlocks.map((block) => ({
      ...block,
      title: block.title,
      class: block.order % 2 === 0 ? "bg-odd" : "bg-even", // for mj-class
      colour: block.order % 2 === 0 ? "#FFFFFF" : "#F2F2F2", // for quote block
      thumborImage: getThumborImage(block.image, 1200, 0, false, true),
      // results (deleted results are sorted by publishedDate in UI, less expensive than doing it in fnReport)
      results: processResults(
        block,
        block.type === "DELETED"
          ? sort(block.results.items, "publishedDate", "desc")
          : sortOrderable(block.results.items)
      ),
      // outlook does not like long hash links (using id), so we use index instead
      contentsIndex: contentsData.index[block.id],
    })),
    // contents blocks
    contentsBlocks: contentsData.columns,
    // switches
    switches: switches[op],
  };
};

// process email elements that do not change per recipient
export const processEmail = (html, report, reportSendId) => {
  // for floating images, the html generated by mj-column needs an alignment, but mjml doesn't support this, see https://stackoverflow.com/q/15561711
  html = html.replace(
    /<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">/g,
    `<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%" align="left">`
  );

  // outlook needs unitless line-height, and quote blocks seem to add spaces into this, needs to be px
  html = html.replace(/line-height:/g, "mso-line-height-rule:exactly;line-height:");

  // replace chart placeholders
  html = html.replace(
    /<!-- \[__OUTCIDER_CHART_([-a-f0-9]{36})__\] -->/g,
    getS3Public(`reports/charts/${reportSendId}/$1.png`)
  );

  // replace webversion placeholder
  html = html.replace(
    "[__OUTCIDER_WEBVERSION__]",
    `${getAppUrl(report.project.client.subdomain)}/report/web/${report.clientId}/${report.projectId}/${
      report.id
    }/${reportSendId}`
  );

  return html;
};

// process email elements that change per recipient
export const processEmailRecipient = (html, report, reportSendRecipientId) => {
  // replace unsubscribe placeholder
  html = html.replace(
    "[__OUTCIDER_UNSUBSCRIBE__]",
    `${getAppUrl(report.project.client.subdomain)}/report/unsubscribe/${report.clientId}/${report.projectId}/${
      report.id
    }/${reportSendRecipientId}`
  );

  // replace track placeholder
  html = html.replace(
    "[__OUTCIDER_TRACK__]",
    `${getAppUrl(report.project.client.subdomain)}/report/track/${report.clientId}/${report.projectId}/${
      report.id
    }/${reportSendRecipientId}`
  );

  return html;
};

import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { Component } from "react";

import { Auth } from "aws-amplify";
import { titleCase } from "title-case";
import { withRouter } from "react-router-dom";

class ProfileDropdown extends Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  async signOut() {
    await Auth.signOut();
    window.location.href = "/";
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="nav-link dropdown-toggle nav-user arrow-none mr-0"
          onClick={this.toggleDropdown}
          aria-expanded={this.state.dropdownOpen}
        >
          <span className="account-user-avatar">
            <i className="uil-user-circle" style={{ fontSize: 32 }} />
          </span>
          <span>
            <span className="account-user-name">{this.props.user.name}</span>
            <span className="account-position">{titleCase(this.props.user.role.replace("_", " ").toLowerCase())}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
          <div onClick={this.toggleDropdown}>
            {this.props.user.role === "ADMIN" && (
              <>
                <button onClick={() => this.props.history.push("/")} className="dropdown-item notify-item">
                  <i className="uil-store mr-1" />
                  <span>Home</span>
                </button>
                <button onClick={() => this.props.history.push("/admin")} className="dropdown-item notify-item">
                  <i className="uil-cog mr-1" />
                  <span>Admin</span>
                </button>
              </>
            )}
            {/* <button onClick={() => window.open("mailto:hello@unifi.media")} className="dropdown-item notify-item">
              <i className="uil-life-ring mr-1" />
              <span>Email Support</span>
            </button> */}
            <button onClick={async () => await this.signOut()} className="dropdown-item notify-item">
              <i className="uil-exit mr-1" />
              <span>Sign Out</span>
            </button>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withRouter(ProfileDropdown);

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($filter: ModelSubscriptionProjectFilterInput) {
    onUpdateProject(filter: $filter) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;

import omit from "lodash/omit";

// filter types that do not have include/exclude switch
export const NO_INCLUDE_SWITCH = ["isTop", "keyword", "sentiment", "trafficLight"];

// filter types that have dynamic options from projectData
export const HAS_DYNAMIC_OPTIONS_FROM_PROJECTDATA = [
  "topic",
  "concern",
  "agent",
  "person",
  "organisation",
  "entityGroup",
  // "authorGroups",
];

// filter types that have dynamic options from query (async FormSelect/FormCreatable)
export const HAS_DYNAMIC_OPTIONS_FROM_QUERY = [
  "sourceName",
  "sourceDomain",
  "authorName",
  "authorEmail",
  "authorGroups",
];

// filter types that have from/to fields
export const HAS_NUMBER_RANGE = [
  "socialEngagement",
  "socialReactions",
  "socialComments",
  "socialShares",
  "socialViews",
  "authorPosts",
  "authorFollowers",
  "authorFollowing",
];

// merge manual filters with chart filters
// - chart filters take precedence and overwrite any filter of the same type
// - do not save isTop (from clicking on a chart), since this is too much recursion/confusion
// (merge behaviour may get more custom/complicated later)
export const mergeFiltersAndChartFilters = (filters, chartFilters) => omit({ ...filters, ...chartFilters }, ["isTop"]);

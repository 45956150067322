import React, { useCallback, useContext, useEffect, useState } from "react";
import { getResultPrefixForSource, getTimestamp7Days } from "../../lib/utils";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { querySearch, searchResultsTotal } from "../../graphql/queries";
import { useHistory, useParams } from "react-router-dom";

import { AppContext } from "../../App";
import { buildSearchResultsParams } from "../../lib/queries";
import { createSearch } from "../../graphql/mutations";
import { numberFormat } from "humanize";
import { querySearchGetVariables } from "../../lib/variables";
import { v4 as uuid } from "uuid";

// forward to ref to ResultsCounter to the raw total
const ResultsCounter = React.forwardRef(
  (
    {
      icon,
      text,
      // result source
      source,
      // saved filter object
      filter,
      // query params
      query,
      projectData,
      prefetchedTotal,
    },
    ref
  ) => {
    const appContext = useContext(AppContext);
    const apollo = useApolloClient();
    const history = useHistory();
    const { clientId, projectId } = useParams();
    const [total, setTotal] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mutationCreateSearch] = useMutation(gql(createSearch));

    const getFilterParams = useCallback(() => {
      // start with client/project and empty query
      const filterParams = {
        clientId,
        projectId,
        maxDate: appContext.maxDate,
        query: {},
      };

      // if there is a saved filter, add the query
      if (filter) filterParams.query = JSON.parse(filter.query);

      // set any query overrides, build like search input (active and included)
      for (const [key, value] of Object.entries(query || {})) {
        // keyword filters have different structure
        if (key === "keyword") {
          filterParams.query[key] = { active: true, within: "all", any: value };
        } else {
          filterParams.query[key] = { active: true, include: true, query: value };
        }
      }

      return filterParams;
    }, [filter, query, clientId, projectId, appContext.maxDate]);

    useEffect(() => {
      async function getTotal() {
        // use prefetched if passed in prop
        if (prefetchedTotal) {
          setTotal(prefetchedTotal);
          return;
        }

        const filterParams = getFilterParams();
        const searchParams = buildSearchResultsParams({ source, filterParams, projectData });

        const resultsTotal = await apollo.query({
          query: gql(searchResultsTotal),
          variables: { searchParams: JSON.stringify(searchParams) },
        });
        try {
          setTotal(resultsTotal.data.searchResultsTotal.total);
        } catch (e) {
          setTotal(false);
        }
      }
      getTotal();
    }, [apollo, getFilterParams, source, projectData, prefetchedTotal]);

    const handleClick = async (e) => {
      e.stopPropagation();
      if (isSubmitting) return;
      setIsSubmitting(true);
      const filterParams = getFilterParams();
      const id = uuid();
      const input = {
        clientId,
        projectId,
        id,
        source,
        query: JSON.stringify(filterParams.query),
        expire: getTimestamp7Days(),
      };
      try {
        // create search
        await mutationCreateSearch({ variables: { input } });
        // prefetch
        await apollo.query({
          query: gql(querySearch),
          variables: querySearchGetVariables({ clientId, projectId, id }),
        });
        // redirect
        history.push(`/${clientId}/${projectId}/${getResultPrefixForSource(source)}/search-${id}`);
      } catch (e) {
        appContext.handleError(e);
      }
      setIsSubmitting(false);
    };

    return (
      <>
        <span className="text-nowrap d-inline-block click" onClick={handleClick}>
          <i className={`${icon} text-muted mr-1`} />
          <b>{total === null ? "..." : total === false ? "?" : numberFormat(total || 0, 0)}</b> {text || ""}
        </span>
        {/* raw total for using upstream (e.g. client-side table sorting) */}
        <span ref={ref} className="d-none">
          {total || 0}
        </span>
      </>
    );
  }
);

export default ResultsCounter;

import {
  BUILDER_CHART_TYPE_OPTIONS,
  BUILDER_CHART_TYPE_SUPPORTS_COMPARISON,
  BUILDER_METRIC_TYPE_NOT_SUPPORTS_TOP,
  BUILDER_METRIC_TYPE_OPTIONS_METABASE,
  BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360,
  BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360_COVERAGE,
  CONFIG_RESULTS,
  CONFIG_SOCIAL360_COVERAGE,
} from "./config";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createChart, updateChart } from "../../graphql/mutations";
import {
  getDateRangeOptions,
  getResultsTypeOptions,
  populateForm,
  processForm,
  validateDateRange,
  validateResultsType,
} from "../lib/forms";
import { gql, useMutation } from "@apollo/client";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import flatten from "lodash/flatten";
import { getResultSourceNameForCharts } from "../../lib/utils";
import intersection from "lodash/intersection";
import pick from "lodash/pick";
import { queryCharts } from "../../graphql/queries";
import { queryChartsVariables } from "../../lib/variables";
import { titleCase } from "title-case";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const ChartModal = ({ data: { data, op }, toggle, projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const alertModal = createConfirmation(AlertModal);
  const confirmModal = createConfirmation(ConfirmModal);

  const history = useHistory();
  const { url } = useRouteMatch();
  const [mutationCreateChart] = useMutation(gql(createChart));
  const [mutationUpdateChart] = useMutation(gql(updateChart));
  const [isDeleting, setIsDeleting] = useState(false);

  // get source of chart
  const source = op === "CREATE" ? data : data.source;

  // fixed options
  let metricTypeOptions = [];
  if (source === "METABASE") metricTypeOptions = BUILDER_METRIC_TYPE_OPTIONS_METABASE;
  if (source === "SOCIAL360") metricTypeOptions = BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360;
  if (source === "SOCIAL360_COVERAGE") metricTypeOptions = BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360_COVERAGE;

  // remove stakeholder options if comprehend never used
  if (!projectData.comprehendUsed) metricTypeOptions = metricTypeOptions.filter((x) => x.label !== "Stakeholders");

  // default options before filtered below
  let defaultAggSize = null;
  let chartTypeOptions = BUILDER_CHART_TYPE_OPTIONS;
  let resultsTypeOptions = getResultsTypeOptions(source, projectData);
  let dateRangeOptions = getDateRangeOptions(true);

  const initial =
    op === "CREATE"
      ? {
          resultsType: ["all"],
          dateRange: "1",
          startDate: "",
          startTime: "00:00",
          endDate: "",
          endTime: "00:00",
          comparisonStartDate: "",
          comparisonStartTime: "00:00",
          comparisonEndDate: "",
          comparisonEndTime: "00:00",
          showValues: false,
          sortByValue: false,
        }
      : { ...data };

  const { register, handleSubmit, errors, formState, control, setValue, getValues, watch } = useForm({
    defaultValues: populateForm(initial, {
      fields: [
        "name",
        "startDate",
        "startTime",
        "endDate",
        "endTime",
        "comparisonStartDate",
        "comparisonStartTime",
        "comparisonEndDate",
        "comparisonEndTime",
        "aggSize",
      ],
      selects: [
        ["metricType", metricTypeOptions, true],
        ["chartType", chartTypeOptions, false],
        ["dateRange", dateRangeOptions, false],
      ],
      selectMultis: [["resultsType", resultsTypeOptions, true]],
      switches: [
        ["showValues", false],
        ["sortByValue", false],
      ],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const watchDateRange = watch("dateRange");
  const selectedDateRange = watchDateRange ? watchDateRange.value : null;
  const showCustomDateRange = ["0", "comparison"].includes(selectedDateRange);
  const showComparisonDateRange = selectedDateRange === "comparison";

  const getValidChartTypesForMetricType = useCallback(
    (metricType) => {
      const configObj = source === "SOCIAL360_COVERAGE" ? CONFIG_SOCIAL360_COVERAGE : CONFIG_RESULTS;
      const config = configObj[metricType];
      const validChartTypes = Object.keys(config).filter((x) => x !== "config" && !x.startsWith("quick"));
      return BUILDER_CHART_TYPE_OPTIONS.filter((x) => validChartTypes.includes(x.value));
    },
    [source]
  );

  // return null if no aggSize should be shown (not used on chart, or used but not editable)
  const getDefaultAggSizeForMetricType = useCallback(
    (metricType) => {
      if (source === "SOCIAL360_COVERAGE") return null;
      const config = CONFIG_RESULTS[metricType];
      return config.config.aggSize && config.config.aggSizeEditable ? config.config.aggSize : null;
    },
    [source]
  );

  const getValidResultsTypesForMetricType = useCallback(
    (metricType) =>
      !BUILDER_METRIC_TYPE_NOT_SUPPORTS_TOP.includes(metricType) || !metricType
        ? getResultsTypeOptions(source, projectData)
        : getResultsTypeOptions(source, projectData, { includeTop: false }),
    [source, projectData]
  );

  const getValidDateRangesForChartType = useCallback(
    (chartType) =>
      BUILDER_CHART_TYPE_SUPPORTS_COMPARISON.includes(chartType) || !chartType
        ? getDateRangeOptions(true)
        : getDateRangeOptions(false),
    []
  );

  // watch for changes on metric type field and update chart type field options
  const watchMetricType = watch("metricType");
  defaultAggSize = watchMetricType ? getDefaultAggSizeForMetricType(watchMetricType.value) : null;
  chartTypeOptions = watchMetricType ? getValidChartTypesForMetricType(watchMetricType.value) : [];
  resultsTypeOptions = watchMetricType ? getValidResultsTypesForMetricType(watchMetricType.value) : [];

  // when metric type field changes, apply default aggSize, reset chart type and result type if no longer valid
  useEffect(() => {
    const values = getValues();
    if (values.metricType) {
      const aggSize = getDefaultAggSizeForMetricType(values.metricType.value);
      setValue("aggSize", aggSize);

      const currentChartTypeOption = values.chartType;
      const newChartTypeOptions = getValidChartTypesForMetricType(values.metricType.value);
      if (currentChartTypeOption && !newChartTypeOptions.map((x) => x.value).includes(currentChartTypeOption.value)) {
        setValue("chartType", null);
      }

      const currentResultsTypeOptions = (values.resultsType || []).map((x) => x.value);
      const newResultsTypeOptions = flatten(
        getValidResultsTypesForMetricType(values.metricType.value).map((x) => x.options.map((x) => x.value))
      );
      if (intersection(currentResultsTypeOptions, newResultsTypeOptions).length === 0) {
        setValue("resultsType", null);
      }
    }
  }, [
    watchMetricType,
    getValues,
    setValue,
    getDefaultAggSizeForMetricType,
    getValidChartTypesForMetricType,
    getValidResultsTypesForMetricType,
  ]);

  // watch for changes on chart type field and update date range field options, and show/hide sortByValue
  const watchChartType = watch("chartType");
  const selectedChartType = watchChartType ? watchChartType.value : null;
  const showSortByValue = ["pie", "bar", "column"].includes(selectedChartType);
  dateRangeOptions = getValidDateRangesForChartType(selectedChartType);

  // when chart type field changes, reset date range if no longer valid
  useEffect(() => {
    const values = getValues();
    if (values.chartType) {
      const currentDateRangeOption = values.dateRange;
      const newDateRangeOptions = getValidDateRangesForChartType(values.chartType.value);
      if (currentDateRangeOption && !newDateRangeOptions.map((x) => x.value).includes(currentDateRangeOption.value)) {
        setValue("dateRange", null);
      }
    }
  }, [watchChartType, getValues, setValue, getValidDateRangesForChartType]);

  const handleDelete = async () => {
    if (isDeleting) return;
    const ok = await confirmModal();
    if (!ok) return;
    setIsDeleting(true);
    try {
      // update url, then mutate, to avoid hitting redirectAlert in Charts
      history.push(url);
      toggle();
      const input = pick(data, ["clientId", "projectId", "id"]);
      await mutationUpdateChart({ variables: { input: { ...input, isDeleted: true } } });
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  const onSubmit = async (values) => {
    // validate isMulti results type
    let resultsType;
    try {
      const validatedResultsType = validateResultsType(values.resultsType);
      resultsType = validatedResultsType;
    } catch (e) {
      return await alertModal({
        message: e.message,
      });
    }

    // validate custom/comparison date ranges
    let startDate,
      startTime,
      endDate,
      endTime,
      comparisonStartDate,
      comparisonStartTime,
      comparisonEndDate,
      comparisonEndTime;
    if (["0", "comparison"].includes(values.dateRange.value)) {
      try {
        const validatedDates = validateDateRange(values.startDate, values.startTime, values.endDate, values.endTime);
        startDate = validatedDates.startDate;
        startTime = validatedDates.startTime;
        endDate = validatedDates.endDate;
        endTime = validatedDates.endTime;
      } catch (e) {
        return await alertModal({
          message: e.message,
        });
      }
    }
    if (values.dateRange.value === "comparison") {
      try {
        const validatedDates = validateDateRange(
          values.comparisonStartDate,
          values.comparisonStartTime,
          values.comparisonEndDate,
          values.comparisonEndTime,
          true
        );
        comparisonStartDate = validatedDates.startDate;
        comparisonStartTime = validatedDates.startTime;
        comparisonEndDate = validatedDates.endDate;
        comparisonEndTime = validatedDates.endTime;
      } catch (e) {
        return await alertModal({
          message: e.message,
        });
      }
    }

    // if aggSize is used, but is not editable, get and store default value from chart config
    let aggSize = values.aggSize ? parseInt(values.aggSize, 10) : undefined;
    if (source !== "SOCIAL360_COVERAGE") {
      const config = CONFIG_RESULTS[values.metricType.value];
      if (config.config.aggSize && !config.config.aggSizeEditable) aggSize = config.config.aggSize;
    }

    // use validated data
    const formData = await processForm(
      {
        ...values,
        aggSize,
        resultsType,
        startDate,
        startTime,
        endDate,
        endTime,
        comparisonStartDate,
        comparisonStartTime,
        comparisonEndDate,
        comparisonEndTime,
      },
      { selects: ["metricType", "chartType", "dateRange"], selectMultis: ["resultsType"] }
    );

    try {
      if (op === "CREATE") {
        const input = { id: uuid(), clientId, projectId, ...formData, source };
        await mutationCreateChart({
          variables: { input },
          update: updateCacheCreate(gql(queryCharts), queryChartsVariables({ clientId, projectId })),
        });
        history.push(`${url}/${input.id}`);
      } else {
        const input = { id: data.id, clientId, projectId, ...formData };
        await mutationUpdateChart({ variables: { input } });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  let showComprehendMessage = false;
  if (
    watchMetricType &&
    ["person", "organisation", "entityGroup"].includes(watchMetricType.value) &&
    !projectData.comprehendEnabled
  )
    showComprehendMessage = true;

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="lg">
      <ModalHeader toggle={() => toggle()}>
        {title} {titleCase(getResultSourceNameForCharts(source))} Chart
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />

          <Row>
            <Col>
              <Row>
                <Col xs={defaultAggSize ? 9 : 12}>
                  <FormSelect
                    name="metricType"
                    label="Metric type"
                    options={metricTypeOptions}
                    required
                    helpText={
                      showComprehendMessage
                        ? "Stakeholder analysis is not currently enabled for this project, therefore this chart will be restricted to historical results."
                        : ""
                    }
                    formProps={formProps}
                  />
                </Col>
                {defaultAggSize && (
                  <Col xs={3}>
                    <FormInput
                      name="aggSize"
                      label="Top"
                      required
                      type="number"
                      min={1}
                      max={100}
                      formProps={formProps}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <FormSelect
                name="chartType"
                label="Chart type"
                options={chartTypeOptions}
                required
                formProps={formProps}
              />
            </Col>
          </Row>

          <FormSelect
            name="resultsType"
            label="Results type"
            options={resultsTypeOptions}
            isMulti
            required
            formProps={formProps}
          />

          <FormSelect name="dateRange" label="Date range" options={dateRangeOptions} required formProps={formProps} />

          {showCustomDateRange && (
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label for="startDate">Start date:</Label>
                  <Row noGutters>
                    <Col xs={7}>
                      <FormInput name="startDate" type="date" required formProps={formProps} />
                    </Col>
                    <Col xs={5}>
                      <FormInput name="startTime" type="time" required formProps={formProps} />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <Label for="startDate">End date:</Label>
                  <Row noGutters>
                    <Col xs={7}>
                      <FormInput name="endDate" type="date" required formProps={formProps} />
                    </Col>
                    <Col xs={5}>
                      <FormInput name="endTime" type="time" required formProps={formProps} />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          )}

          {showComparisonDateRange && (
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label for="startDate">Comparison start date:</Label>
                  <Row noGutters>
                    <Col xs={7}>
                      <FormInput name="comparisonStartDate" type="date" required formProps={formProps} />
                    </Col>
                    <Col xs={5}>
                      <FormInput name="comparisonStartTime" type="time" required formProps={formProps} />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <Label for="startDate">Comparison end date:</Label>
                  <Row noGutters>
                    <Col xs={7}>
                      <FormInput name="comparisonEndDate" type="date" required formProps={formProps} />
                    </Col>
                    <Col xs={5}>
                      <FormInput name="comparisonEndTime" type="time" required formProps={formProps} />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          )}

          <FormSwitch
            name="showValues"
            label="Show values on chart"
            inlineLabelOn="Yes"
            inlineLabelOff="No"
            oneLine
            formProps={formProps}
          />

          {showSortByValue && (
            <FormSwitch
              name="sortByValue"
              label="Sort by value"
              inlineLabelOn="Yes"
              inlineLabelOff="No"
              oneLine
              formProps={formProps}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {op === "UPDATE" && (
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Delete
            </Button>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChartModal;

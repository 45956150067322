import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { fnTriggerJob, updateReport } from "../../graphql/mutations";
import { formatDateTime, sort } from "../../lib/utils";
import {
  getDateRangeOptions,
  getResultsSourceOptions,
  getResultsTypeOptions,
  getResultsTypeOptionsTopDefault,
  populateForm,
  processForm,
  validateDateRange,
  validateResultsType,
} from "../lib/forms";
import { getReport, queryReports } from "../../graphql/queries";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import JobStatus from "../JobStatus";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import pick from "lodash/pick";
import { queryReportsVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";

const ReportModal = ({ data: { data, op }, toggle, user, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();
  const alertModal = createConfirmation(AlertModal);
  const confirmModal = createConfirmation(ConfirmModal);

  const history = useHistory();
  const { url } = useRouteMatch();
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));
  const [mutationUpdateReport] = useMutation(gql(updateReport));
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMarkAsSent, setIsMarkAsSent] = useState(false);

  const [jobId, setJobId] = useState(null);
  const [jobCompleteFinished, setJobCompleteFinished] = useState(false);

  const resultsSourceOptions = getResultsSourceOptions(projectData);
  const initialResultsSource = resultsSourceOptions[0].value;

  const getResultsTypeOptionsParams = { includeAll: false };
  let resultsTypeOptions = getResultsTypeOptions(initialResultsSource, projectData, getResultsTypeOptionsParams);
  const initialResultsType = getResultsTypeOptionsTopDefault.value;

  let dateRangeOptions = getDateRangeOptions(false);

  // template options
  const templateOptions = [];
  const draftReports = sort(
    projectData.reports.filter((x) => !x.sentAt),
    "createdAt",
    "desc"
  );
  const sentReports = sort(
    projectData.reports.filter((x) => x.sentAt),
    "sentAt",
    "desc"
  );
  if (draftReports.length) {
    templateOptions.push({
      label: "Draft Reports",
      options: draftReports.map((report) => ({
        value: report.id,
        label: `${report.name}\nCreated: ${formatDateTime(report.createdAt, user.timezone)}`,
      })),
    });
  }
  if (sentReports.length) {
    templateOptions.push({
      label: "Sent Reports",
      options: sentReports.map((report) => ({
        value: report.id,
        label: `${report.name}\nLast sent: ${formatDateTime(report.sentAt, user.timezone)}`,
      })),
    });
  }

  const initial =
    op === "CREATE"
      ? {
          source: initialResultsSource,
          resultsType: [initialResultsType],
          dateRange: "1",
          startDate: "",
          startTime: "00:00",
          endDate: "",
          endTime: "00:00",
          includePrint: false,
          template: null,
          showHeader: true,
        }
      : { ...data };

  const { register, handleSubmit, errors, formState, control, setValue, watch, getValues, reset } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["name", "title", "subject"],
      selects: [
        ["source", resultsSourceOptions, false],
        ["dateRange", dateRangeOptions, false],
        ["template", templateOptions, true],
      ],
      selectMultis: [["resultsType", resultsTypeOptions, true]],
      switches: [
        ["showHeader", true],
        ["includePrint", false],
      ],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  // change result types when result source is changed
  // if there is no sources to select from, metabase will be the source
  const watchResultsSource = watch("source");
  const selectedResultsSource = watchResultsSource ? watchResultsSource.value : null;
  resultsTypeOptions = getResultsTypeOptions(
    selectedResultsSource ?? initialResultsSource,
    projectData,
    getResultsTypeOptionsParams
  );

  // reset result types when result source is changed
  useEffect(() => {
    const values = getValues();
    // only when the form has been populated with initial data
    if (Object.keys(values).length) {
      reset({ ...values, resultsType: [getResultsTypeOptionsTopDefault] });
    }
  }, [selectedResultsSource, getValues, reset]);

  const watchDateRange = watch("dateRange");
  const selectedDateRange = watchDateRange ? watchDateRange.value : null;
  const showCustomDateRange = selectedDateRange === "0";

  const handleDelete = async () => {
    if (isDeleting) return;
    const ok = await confirmModal();
    if (!ok) return;
    setIsDeleting(true);
    try {
      // update url, then mutate, to avoid hitting redirectAlert in Reports
      history.push(url);
      toggle();
      const input = pick(data, ["clientId", "projectId", "id"]);
      await mutationUpdateReport({ variables: { input: { ...input, isDeleted: true } } });
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  const handleMarkAsSent = async () => {
    if (isMarkAsSent) return;
    const ok = await confirmModal({
      message: "The report will be moved from Draft to Sent status, this cannot be undone. Are you sure?",
    });
    if (!ok) return;
    setIsMarkAsSent(true);
    try {
      const input = pick(data, ["clientId", "projectId", "id"]);
      await mutationUpdateReport({ variables: { input: { ...input, sentAt: new Date().toISOString() } } });
      history.push(url);
      toggle();
    } catch (e) {
      setIsMarkAsSent(false);
      appContext.handleError(e);
    }
  };

  const onSubmit = async (values) => {
    if (op === "CREATE") {
      // validate isMulti results type
      let resultsType;
      try {
        const validatedResultsType = validateResultsType(values.resultsType);
        resultsType = validatedResultsType;
      } catch (e) {
        return await alertModal({
          message: e.message,
        });
      }

      // validate custom date ranges
      let startDate, startTime, endDate, endTime;
      if (values.dateRange.value === "0") {
        try {
          const validatedDates = validateDateRange(values.startDate, values.startTime, values.endDate, values.endTime);
          startDate = validatedDates.startDate;
          startTime = validatedDates.startTime;
          endDate = validatedDates.endDate;
          endTime = validatedDates.endTime;
        } catch (e) {
          return await alertModal({
            message: e.message,
          });
        }
      }

      // use validated data
      const formData = await processForm(
        {
          ...values,
          resultsType,
          startDate,
          startTime,
          endDate,
          endTime,
        },
        { selects: ["source", "dateRange", "template"], selectMultis: ["resultsType"] }
      );
      if (!formData.source) formData.source = initialResultsSource; // field may not be rendered
      if (!formData.includePrint) formData.includePrint = false; // field may not be rendered

      // trigger job
      try {
        const res = await mutationFnTriggerJob({
          variables: { input: { clientId, projectId, job: "REPORT", data: JSON.stringify(formData) } },
        });

        // store jobId to trigger JobStatus
        const jobId = res.data.fnTriggerJob.id;
        setJobId(jobId);
      } catch (e) {
        appContext.handleError(e);
      }
    }

    if (op === "UPDATE") {
      try {
        const formData = await processForm(values);
        const input = { id: data.id, clientId, projectId, ...formData };
        await mutationUpdateReport({ variables: { input } });
        toggle();
      } catch (e) {
        appContext.handleError(e);
      }
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="lg">
      <ModalHeader toggle={() => toggle()}>{title} Report</ModalHeader>
      {jobId && (
        <JobStatus
          id={jobId}
          type="report"
          successAction="build"
          successButtonIcon="uil-grid"
          successButtonText="Build Report"
          onJobComplete={async (jobResult) => {
            // get new report (enough data for listing, not getReportFull which is called anyway when builder is opened)
            const report = await apollo.query({
              query: gql(getReport),
              variables: { id: jobResult },
            });
            // add to cache
            updateCacheCreate(gql(queryReports), queryReportsVariables({ clientId, projectId }))(apollo.cache, {
              data: report.data,
            });
            // mark as finished to enable success button
            setJobCompleteFinished(true);
          }}
          onSuccess={async (jobResult) => {
            // ensure we only redirect once new report has been added to cache
            if (!jobCompleteFinished) return;
            // redirect
            history.push(`${url}/${jobResult}/builder`);
            toggle();
          }}
          onRestart={() => setJobId(null)}
          hideRestartOnSuccess
          displayType="MODAL"
        />
      )}
      {/* keep form rendered but hidden incase of job retry */}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames({
          "d-none": jobId,
        })}
      >
        <ModalBody>
          <FormInput
            name="name"
            label="Name"
            required
            helpText="Internal name used to identify report."
            formProps={formProps}
          />

          <Row>
            <Col>
              <FormInput
                name="title"
                label="Title"
                required
                helpText="Title shown at top of report (if header is shown)."
                formProps={formProps}
              />
            </Col>
            <Col>
              <FormInput
                name="subject"
                label="Subject"
                required
                helpText="Subject when sending report by email."
                formProps={formProps}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormSwitch
                name="showHeader"
                label="Show header (logo/title/date) at top of report"
                inlineLabelOn="Yes"
                inlineLabelOff="No"
                oneLine
                formProps={formProps}
              />{" "}
            </Col>
          </Row>

          {op === "CREATE" && (
            <>
              <hr className="mt-0" />
              {projectData.resultsSources.length > 1 && (
                <FormSelect
                  name="source"
                  label="Results source"
                  options={resultsSourceOptions}
                  required
                  formProps={formProps}
                />
              )}
              <FormSelect
                name="resultsType"
                label="Results type"
                options={resultsTypeOptions}
                isMulti
                required
                formProps={formProps}
              />

              {["ALL", "METABASE"].includes(selectedResultsSource) && (
                <FormSwitch name="includePrint" label="Include print results" oneLine formProps={formProps} />
              )}

              <FormSelect
                name="dateRange"
                label="Date range"
                options={dateRangeOptions}
                required
                formProps={formProps}
              />

              {showCustomDateRange && (
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label for="startDate">Start date:</Label>
                      <Row noGutters>
                        <Col xs={7}>
                          <FormInput name="startDate" type="date" required formProps={formProps} />
                        </Col>
                        <Col xs={5}>
                          <FormInput name="startTime" type="time" required formProps={formProps} />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label for="startDate">End date:</Label>
                      <Row noGutters>
                        <Col xs={7}>
                          <FormInput name="endDate" type="date" required formProps={formProps} />
                        </Col>
                        <Col xs={5}>
                          <FormInput name="endTime" type="time" required formProps={formProps} />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <hr />

              <FormSelect
                name="template"
                label="Create from template report"
                options={templateOptions}
                helpText="The report will be created with blocks copied from the template report, with the results imported into each block according to the source/date selected above."
                formProps={formProps}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {op === "UPDATE" && (
            <>
              <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
                {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                Delete
              </Button>
              {!data.sentAt && (
                <Button color="secondary" outline disabled={isMarkAsSent} onClick={handleMarkAsSent} className="ml-1">
                  {isMarkAsSent && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                  Mark As Sent
                </Button>
              )}
            </>
          )}

          <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ReportModal;

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client p-0" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  <mj-section mj-class="p-0">
    <mj-column mj-class="p-0">
      {{> chart}}
    </mj-column>
  </mj-section>
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->
</mj-wrapper>`
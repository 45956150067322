import { CONFIG_RESULTS, CONFIG_SOCIAL360_COVERAGE, HEIGHT } from "./config";
import { Card, CardBody, Spinner } from "reactstrap";
import { METABASE_SOCIAL_MEDIA_TYPES, SOCIAL360_MEDIA_TYPES } from "../../lib/constants";
import { NEWS_RANK, SOCIAL_RANK } from "../lib/options";
import React, { useContext, useEffect, useState } from "react";
import {
  buildSearchResultsHighlightFromAgents,
  buildSearchResultsHighlightFromQuery,
  keywordFilterIsValidForHighlighting,
} from "../../lib/queries";
import { getAggQuery, getSeriesData, getSocialCoverageData, processData } from "./lib";
import { getResultPrefixForSource, getTimestamp7Days, sort } from "../../lib/utils";
import { gql, useMutation } from "@apollo/client";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import FilterModal from "./FilterModal";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ResultsError from "../Results/ResultsError";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import { createSearch } from "../../graphql/mutations";
import findKey from "lodash/findKey";
import flatten from "lodash/flatten";
import get from "lodash/get";
import { getAgentSocial360Id } from "../../lib/social360";
import { getStakeholdersInResults } from "../lib/entities";
import highchartsDrilldown from "highcharts/modules/drilldown";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsNoDataToDisplay from "highcharts/modules/no-data-to-display";
import highchartsOfflineExporting from "highcharts/modules/offline-exporting";
import highchartsWordcloud from "highcharts/modules/wordcloud";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import pick from "lodash/pick";
import { querySearch } from "../../graphql/queries";
import { querySearchGetVariables } from "../../lib/variables";
import { sleep } from "../lib/utils";
import uniqBy from "lodash/uniqBy";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";

highchartsDrilldown(Highcharts);
highchartsExporting(Highcharts);
highchartsNoDataToDisplay(Highcharts);
highchartsOfflineExporting(Highcharts);
highchartsWordcloud(Highcharts);

// required to prevent occasional errors when re-rendering
// https://github.com/highcharts/highcharts/issues/13759
window.Highcharts = Highcharts;

const Chart = ({
  source,
  title,
  subtitle,
  metricType,
  chartType,
  resultsType,
  resultsTypeAgents,
  resultsTypeIssues,
  filterParams,
  search,
  filter,
  projectData,
  isBuilder,
  chartObj,
  // UI only
  height,
  disableClick,
  whiteBg,
  className,
  // passed in as props rather than hooks
  clientId,
  projectId,
  project,
  maxDate,
  apollo,
  // passed in from report builder
  isReportBuilder,
  reportBuilderRef,
  // passed in from dashboard showing chart builder chart
  dashboardEdit,
}) => {
  if (!clientId || !projectId || !project || !maxDate || !apollo)
    throw new Error(
      "Chart must be passed as props: clientId, projectId, project, maxDate, apollo. Hooks cannot be used."
    );

  // SOCIAL TODO change this (and Chart.source) when we have web/social combined charts
  if (!(source === "METABASE" || source === "SOCIAL360" || source === "SOCIAL360_COVERAGE"))
    throw new Error("Chart must be passed a valid source.");

  const appContext = useContext(AppContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [refetch, setRefetch] = useState(null);
  const [error, setError] = useState(false);

  const [hideableData, setHideableData] = useState(null);
  const [mutationCreateSearch] = useMutation(gql(createSearch), { client: apollo });
  const [filterModal, toggleFilterModal] = useState(false);

  const alertModal = createConfirmation(AlertModal);

  // get chart config
  const configObj = source === "SOCIAL360_COVERAGE" ? CONFIG_SOCIAL360_COVERAGE : CONFIG_RESULTS;
  let metricConfig = configObj[metricType];
  if (!metricConfig) throw new Error("Invalid chart metricType.");
  metricConfig = metricConfig.config;
  const chartConfig = configObj[metricType][chartType];
  if (!chartConfig) throw new Error("Invalid chart chartType.");

  // builder has date range data in chartObj, results uses filterParams
  const objWithDateRange = isBuilder ? chartObj : filterParams;

  const handleClick = async (drilldown) => {
    if (disableClick || source === "SOCIAL360_COVERAGE") return;

    setLoading(true);
    let drilldownResultsType = drilldown.resultsType;

    // start with the current filter query/chartQuery, if they are used
    let searchQuery = filterParams.query || {};
    let searchChartQuery = filterParams.chartQuery || {};

    // if issue series clicked, start with its criteria
    let issue = null;
    let issueAddIsTopFilter = false;
    if (drilldown.resultsType.startsWith("issue-")) {
      issue = projectData.filters.find((x) => x.id === drilldown.resultsType.substr(6));
      searchQuery = JSON.parse(issue.query);
      // if chart is based on top stories, but split by issue, need to add isTop filter
      if (["top", "manualtop"].includes(resultsType)) issueAddIsTopFilter = true;
      // if chart is using issuesTopAll/issuesTopManual seriesTypes, need to add isTop filter
      if (
        chartConfig.seriesTypes &&
        chartConfig.seriesTypes.length === 1 &&
        ["issuesTopAll", "issuesTopManual"].includes(chartConfig.seriesTypes[0])
      )
        issueAddIsTopFilter = true;
    }

    // if viewing multiple issues (chart builder), and there is only one issue, use that, otherwise all results will have to do
    if (drilldown.resultsType === "issues") {
      if (resultsTypeIssues.length === 1) {
        drilldownResultsType = resultsTypeIssues[0];
        issue = projectData.filters.find((x) => x.id === resultsTypeIssues[0].substr(6));
        searchQuery = JSON.parse(issue.query);
      } else {
        drilldownResultsType = "all";
        await alertModal({
          message:
            "The chart point you have clicked contains data from multiple issues which cannot be displayed together, therefore you will be redirected to All Results with the clicked criteria applied.",
        });
      }
    }

    // add the clicked chart filters
    for (const drilldownFilter of drilldown.filters) {
      if (drilldownFilter.type === "keyword") {
        // keyword filters have different structure
        searchChartQuery[drilldownFilter.type] = { any: drilldownFilter.query, within: "all", active: true };
      } else if (drilldownFilter.type === "sourceRank") {
        // source rank is added as a mediaType filter, with mediaTypes set as ones valid for the rank chosen
        // note that mediaType/sourceRank will now override each other, but thats ok for now
        const selectedRank = drilldownFilter.query[0]; // there is only ever one and its an int
        const newsRanks = NEWS_RANK.map((x) => parseInt(x.value, 10));
        const socialRanks = SOCIAL_RANK.map((x) => parseInt(x.value, 10));
        // (broadcast has no ranks, so there will be nothing to show on a chart to click)
        const mediaTypes = newsRanks.includes(selectedRank)
          ? ["News", "Print", ...METABASE_SOCIAL_MEDIA_TYPES] // ranks 1-5 apply to both news/print and social
          : METABASE_SOCIAL_MEDIA_TYPES; // outside ranks 1-5 is social only
        searchChartQuery["mediaType"] = {
          query: mediaTypes.map((x) => `metabase-${x}`), // add metabase prefix
          newsSourceRanks: newsRanks.includes(selectedRank) ? [drilldownFilter.query.toString()] : null,
          socialSourceRanks: socialRanks.includes(selectedRank) ? [drilldownFilter.query.toString()] : null,
          include: true,
          active: true,
        };
      } else {
        let query = drilldownFilter.query;
        // mediaType needs source prefix adding, social needs id
        if (drilldownFilter.type === "mediaType") {
          if (source === "METABASE") query = query.map((x) => `metabase-${x}`);
          if (source === "SOCIAL360") {
            query = query.map((x) => `social-${findKey(SOCIAL360_MEDIA_TYPES, (v) => v === x)}`);
          }
        }
        searchChartQuery[drilldownFilter.type] = { query, include: true, active: true };
      }
    }

    // add isTop fitler
    if (issueAddIsTopFilter) searchChartQuery["isTop"] = { active: true };

    const id = uuid();
    const input = {
      clientId,
      projectId,
      id,
      source,
      query: JSON.stringify(searchQuery),
      chartQuery: JSON.stringify(searchChartQuery),
      resultsType: drilldownResultsType,
      expire: getTimestamp7Days(),
    };

    let redirectState = {};
    if (drilldown.timestamp) {
      // timestamp from line chart
      // convert to local time as if entered via the custom date timestamp form
      const startEpoch = drilldown.timestamp[0];
      const startDate = moment(startEpoch).format("YYYY-MM-DD");
      const startTime = moment(startEpoch).format("HH:mm");
      const endEpoch = drilldown.timestamp[1];
      const endDate = moment(endEpoch).format("YYYY-MM-DD");
      const endTime = moment(endEpoch).format("HH:mm");
      redirectState = { chartTimestamp: { startDate, startTime, endDate, endTime } };
    } else {
      if (["0", "comparison"].includes(objWithDateRange.dateRange)) {
        // custom or comparison chart, see if drilldown is from comparison series
        if (drilldown.isComparison) {
          const {
            comparisonStartDate: startDate,
            comparisonStartTime: startTime,
            comparisonEndDate: endDate,
            comparisonEndTime: endTime,
          } = objWithDateRange;
          redirectState = { chartTimestamp: { startDate, startTime, endDate, endTime } };
        } else {
          const { startDate, startTime, endDate, endTime } = objWithDateRange;
          redirectState = { chartTimestamp: { startDate, startTime, endDate, endTime } };
        }
      } else {
        // reset to the date range of the chart
        redirectState = { chartDateRange: objWithDateRange.dateRange };
      }
    }

    // link the new search to a filter if...
    // we've clicked a chart and are already viewing a filter
    if (filter) {
      input.filterName = filter.name;
      input.filterId = filter.id;
      input.skipFilterHasChangesCheck = true;
    }
    // we've clicked a chart and are already viewing a search, which is linked to a filter
    if (search && search.filterId) {
      input.filterName = search.filterName;
      input.filterId = search.filterId;
      input.skipFilterHasChangesCheck = true;
    }
    // we've clicked an issue series in a chart
    if (issue) {
      input.filterName = issue.name;
      input.filterId = issue.id;
      input.skipFilterHasChangesCheck = true;
    }

    // if we're already in a search, and have clicked a chart again, keep the same resultsType on the new search
    if (search && search.resultsType) input.resultsType = search.resultsType;

    try {
      // create search
      await mutationCreateSearch({ variables: { input } });
      // prefetch
      await apollo.query({
        query: gql(querySearch),
        variables: querySearchGetVariables({ clientId, projectId, id }),
      });
      // redirect
      history.push(`/${clientId}/${projectId}/${getResultPrefixForSource(source)}/search-${id}`, redirectState);
    } catch (e) {
      appContext.handleError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    // get data for each series
    const init = async () => {
      // get aggregation query based on aggType for this metric/chart
      // social coverage charts have aggregations setup as part of the query in getSocialCoverageData
      let aggQuery;
      if (source !== "SOCIAL360_COVERAGE")
        aggQuery = getAggQuery({
          source,
          metricConfig,
          chartConfig,
          chartObj,
          objWithDateRange,
          projectData,
        });

      let seriesTypesOverride;

      // builder: if chart has series for each issue, but showing top results, use top stories within each issue instead
      let chartIsSplitByIssue = false;
      if (isBuilder && (chartConfig.seriesTypes || []).length === 1 && chartConfig.seriesTypes[0] === "issues") {
        chartIsSplitByIssue = true;
        if (resultsType === "top") seriesTypesOverride = ["issuesTopAll"];
        if (resultsType === "manualtop") seriesTypesOverride = ["issuesTopManual"];
      }

      // builder: if chart is social coverage, determine whether it is split by agent
      let chartIsSplitByAgent = false;
      if (isBuilder && (chartConfig.seriesTypes || []).length === 1 && chartConfig.seriesTypes[0] === "agents") {
        chartIsSplitByAgent = true;
      }

      // the seriesTypes are the same as the resultsType (one series), unless chart has override, unless there is builder override
      let seriesTypes = seriesTypesOverride || chartConfig.seriesTypes || [resultsType];
      let seriesDataCalls = [];
      let seriesDataIsComparison = [];
      let seriesDataSocialCoverageKeywords = [];

      // some chart types need to know this for processData
      let chartIsComparison = false;

      // results: never comparison charts
      let dateRanges = [
        { ...pick(filterParams, ["dateRange", "startDate", "startTime", "endDate", "endTime"]), isComparison: false },
      ];

      // builder: add comparison range first, then main range
      if (isBuilder) {
        dateRanges = [];
        if (chartObj.dateRange === "comparison") {
          chartIsComparison = true;
          dateRanges.push({
            // important that "comparison" is used rather than "0" as this is how we tell series apart
            dateRange: "comparison",
            startDate: chartObj.comparisonStartDate,
            startTime: chartObj.comparisonStartTime,
            endDate: chartObj.comparisonEndDate,
            endTime: chartObj.comparisonEndTime,
            isComparison: true,
          });
        }
        dateRanges.push({
          dateRange: chartObj.dateRange,
          startDate: chartObj.startDate,
          startTime: chartObj.startTime,
          endDate: chartObj.endDate,
          endTime: chartObj.endTime,
          isComparison: false,
        });
      }

      // same logic for main and comparison data
      const newSeriesTypes = [];
      for (const dateRangeProps of dateRanges) {
        // handle special series types
        let ids;
        for (const seriesType of seriesTypes) {
          switch (seriesType) {
            // AGENTS (SOCIAL COVERAGE) - BUILDER ONLY
            // replace "agents" with each agent selected as results type, if agents have been selected
            case "agents":
              // builder: if chart is NOT split by agent, we have a single series containing the selected agents
              if (!chartIsSplitByAgent) {
                newSeriesTypes.push("agents");
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "agents",
                  seriesAgents: resultsTypeAgents.map((x) => x.substr(6)),
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
                break;
              }

              // as issues logic, but using social-enabled agents on project
              // NOTE: this will do one API request per agent, S360 API can do this in one request, maybe refactor later
              ids = projectData.agents
                .filter((x) => x.isEnabled && x.hasSocialSources && getAgentSocial360Id(x))
                .map((x) => x.id);
              if (isBuilder && resultsTypeAgents) ids = resultsTypeAgents.map((x) => x.substr(6));

              for (const agentId of ids) {
                newSeriesTypes.push(`agent-${agentId}`);
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "agents",
                  seriesAgents: [agentId],
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
              }
              break;

            // ISSUES
            // results: replace "issues" with each issue the user can see, of the specified source
            // builder: replace "issues" with each issue selected as results type, if issues have been selected, of the specified source
            case "issues":
              // builder: if chart is NOT split by issue, we have a single series containing the selected issues
              if (isBuilder && !chartIsSplitByIssue) {
                newSeriesTypes.push("issues");
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "issues",
                  seriesIssues: resultsTypeIssues.map((x) => x.substr(6)),
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
                break;
              }

              ids = projectData.filters.filter((x) => x.source === source && x.isVisible).map((x) => x.id);
              if (isBuilder && resultsTypeIssues) ids = resultsTypeIssues.map((x) => x.substr(6));

              // if resultsType is an issue, use that issue instead
              if (resultsType.startsWith("issue-")) ids = [resultsType.substr(6)];

              for (const issueId of ids) {
                newSeriesTypes.push(`issue-${issueId}`);
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "issues",
                  seriesIssues: [issueId],
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
              }
              break;

            // ISSUES (TOP STORIES ALL)
            // results: replace "issuesTopAll" with each issue (visible and hidden), of the specified source
            // builder: replace "issuesTopAll" with each issue selected as results source, if issues have been selected, of the specified source
            // if the issue is NOT a top story issue, only keep manually topped
            case "issuesTopAll":
              ids = projectData.filters.filter((x) => x.source === source).map((x) => x.id);
              if (isBuilder && resultsTypeIssues) ids = resultsTypeIssues.map((x) => x.substr(6));

              // if resultsType is an issue, use that issue instead
              if (resultsType.startsWith("issue-")) ids = [resultsType.substr(6)];

              for (const issueId of ids) {
                newSeriesTypes.push(`issue-${issueId}`);
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "issues",
                  seriesIssues: [issueId],
                  seriesIssuesTop: "top",
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
              }
              break;

            // ISSUES (TOP STORIES MANUAL)
            // results: replace "issuesTopManual" with each issue (visible and hidden), of the specified source
            // builder: replace "issuesTopManual" with each issue selected as results source, if issues have been selected, of the specified source
            case "issuesTopManual":
              ids = projectData.filters.filter((x) => x.source === source).map((x) => x.id);
              if (isBuilder && resultsTypeIssues) ids = resultsTypeIssues.map((x) => x.substr(6));

              // if resultsType is an issue, use that issue instead
              if (resultsType.startsWith("issue-")) ids = [resultsType.substr(6)];

              for (const issueId of ids) {
                newSeriesTypes.push(`issue-${issueId}`);
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: "issues",
                  seriesIssues: [issueId],
                  seriesIssuesTop: "manualtop",
                  filterParams: { ...filterParams, ...dateRangeProps },
                });
              }
              break;

            // KEYWORDS
            // results: replace "keywords" with each keyword being queried for
            // builder: replace "keywords" with each keyword from active agents (all/top/manualtop), which contains the source type the chart is for, or issue(s) query
            case "keywords":
              let keywords = [];

              // keywords from the current filterParams query
              if (!isBuilder) {
                const keywordFilterFromFilterParams = get(filterParams, "query.keyword");
                if (
                  keywordFilterFromFilterParams &&
                  keywordFilterFromFilterParams.active === true &&
                  keywordFilterFromFilterParams.any
                ) {
                  keywords = keywordFilterFromFilterParams.any;
                }
              }

              if (isBuilder) {
                if (resultsType === "issues") {
                  // kewords from issues selected
                  const issueIds = resultsTypeIssues.map((x) => x.substr(6));
                  for (const issueId of issueIds) {
                    const issueObj = projectData.filters.find((x) => x.id === issueId);
                    const keywordFilterFromIssue = get(JSON.parse(issueObj.query), "keyword");
                    if (keywordFilterIsValidForHighlighting(keywordFilterFromIssue)) {
                      const highlight = buildSearchResultsHighlightFromQuery(keywordFilterFromIssue);
                      keywords = [...keywords, ...highlight.terms];
                    }
                    // no fallback to agent keywords needed here as only the issue keywords should be plotted
                  }
                } else if (resultsType === "agents") {
                  // kewords from agents selected (social coverage)
                  const agentIds = resultsTypeAgents.map((x) => x.substr(6));
                  const agentObjs = projectData.agents.filter((x) => agentIds.includes(x.id));
                  const highlight = buildSearchResultsHighlightFromAgents(agentObjs);
                  keywords = highlight.terms;
                } else {
                  // keywords from active agents
                  let activeAgents = projectData.agents.filter((x) => x.isEnabled);
                  // filter to those that contain the source
                  if (source === "METABASE") activeAgents = activeAgents.filter((x) => x.hasWebSources);
                  if (source === "SOCIAL360") activeAgents = activeAgents.filter((x) => x.hasSocialSources);
                  if (source === "SOCIAL360_COVERAGE")
                    activeAgents = activeAgents.filter((x) => x.hasSocialSources && getAgentSocial360Id(x));
                  // get keywords
                  const highlight = buildSearchResultsHighlightFromAgents(activeAgents);
                  keywords = highlight.terms;
                }
              }

              // no keywords to display
              if (!keywords.length) {
                setData({ data: [], series: [] });
                setLoading(false);
                return;
              }

              // news/social results have a series call per keyword
              if (["METABASE", "SOCIAL360"].includes(source)) {
                for (const keyword of keywords) {
                  newSeriesTypes.push(`keyword-${keyword}`);
                  const keywordFilter = { keyword: { any: [keyword], within: "all", active: true } };
                  seriesDataIsComparison.push(dateRangeProps.isComparison);
                  seriesDataCalls.push({
                    seriesType: resultsType,
                    seriesIssues: resultsTypeIssues ? resultsTypeIssues.map((x) => x.substr(6)) : [],
                    filterParams: {
                      ...filterParams,
                      query: { ...(filterParams.query || {}), ...keywordFilter },
                      ...dateRangeProps,
                    },
                  });
                }
              }

              // social coverage has one series call, with a `line` entry per keyword
              if (source === "SOCIAL360_COVERAGE") {
                ids = projectData.agents
                  .filter((x) => x.isEnabled && x.hasSocialSources && getAgentSocial360Id(x))
                  .map((x) => x.id);
                if (isBuilder && resultsTypeAgents) ids = resultsTypeAgents.map((x) => x.substr(6));

                newSeriesTypes.push(keywords);
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataSocialCoverageKeywords = keywords; // used to annotate `seriesData`
                seriesDataCalls.push({
                  seriesType: "agents",
                  seriesAgents: ids,
                  // `socialCoverageKeywords` is picked out in `getSocialCoverageData`
                  filterParams: { ...filterParams, ...dateRangeProps, socialCoverageKeywords: keywords },
                });
              }
              break;

            // PEOPLE
            // replace "people" with each person in the results, filtered by stakeholderType (all/followed/tagged)
            case "people":
              let people = [];

              // results mode, or builder mode for all results
              if (!isBuilder || (isBuilder && resultsType !== "issues")) {
                // all results (top/manualtop not supported due to very complex query)
                try {
                  people = await getStakeholdersInResults({
                    entityType: "people",
                    stakeholderType: chartConfig.stakeholderType || "all",
                    filter,
                    clientId,
                    projectId,
                    projectData,
                    maxDate,
                    apollo,
                  });
                } catch (e) {
                  setError(true);
                  setLoading(false);
                  return;
                }
              } else {
                // combine stakeholders from all issues selected
                const issueIds = resultsTypeIssues.map((x) => x.substr(6));
                for (const issueId of issueIds) {
                  const issueObj = projectData.filters.find((x) => x.id === issueId);
                  try {
                    const issuePeople = await getStakeholdersInResults({
                      entityType: "people",
                      stakeholderType: chartConfig.stakeholderType || "all",
                      filter: issueObj,
                      clientId,
                      projectId,
                      projectData,
                      maxDate,
                      apollo,
                    });
                    people = [...people, ...issuePeople];
                  } catch (e) {
                    setError(true);
                    setLoading(false);
                    return;
                  }
                }
              }

              const personIds = people.map((x) => x.id);
              for (const personId of personIds) {
                newSeriesTypes.push(`person-${personId}`);
                const personFilter = { person: { query: [personId], include: true, active: true } };
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: resultsType,
                  seriesIssues: resultsTypeIssues ? resultsTypeIssues.map((x) => x.substr(6)) : [],
                  filterParams: {
                    ...filterParams,
                    query: { ...(filterParams.query || {}), ...personFilter },
                    ...dateRangeProps,
                  },
                });
              }
              break;

            // ORGANISATIONS
            // replace "organistations" with each organisation in the results, filtered by stakeholderType (all/followed/tagged)
            case "organisations":
              let organisations = [];

              /// results mode, or builder mode for all results
              if (!isBuilder || (isBuilder && resultsType !== "issues")) {
                // all results (top/manualtop not supported due to very complex query)
                try {
                  organisations = await getStakeholdersInResults({
                    entityType: "organisations",
                    stakeholderType: chartConfig.stakeholderType || "all",
                    filter,
                    clientId,
                    projectId,
                    projectData,
                    maxDate,
                    apollo,
                  });
                } catch (e) {
                  setError(true);
                  setLoading(false);
                  return;
                }
              } else {
                // combine stakeholders from all issues selected
                const issueIds = resultsTypeIssues.map((x) => x.substr(6));
                for (const issueId of issueIds) {
                  const issueObj = projectData.filters.find((x) => x.id === issueId);
                  try {
                    const issueOrganisations = await getStakeholdersInResults({
                      entityType: "organisations",
                      stakeholderType: chartConfig.stakeholderType || "all",
                      filter: issueObj,
                      clientId,
                      projectId,
                      projectData,
                      maxDate,
                      apollo,
                    });
                    organisations = [...organisations, ...issueOrganisations];
                  } catch (e) {
                    setError(true);
                    setLoading(false);
                    return;
                  }
                }
              }

              const organisationIds = organisations.map((x) => x.id);
              for (const organisationId of organisationIds) {
                newSeriesTypes.push(`organisation-${organisationId}`);
                const organisationFilter = { organisation: { query: [organisationId], include: true, active: true } };
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: resultsType,
                  seriesIssues: resultsTypeIssues ? resultsTypeIssues.map((x) => x.substr(6)) : [],
                  filterParams: {
                    ...filterParams,
                    query: { ...(filterParams.query || {}), ...organisationFilter },
                    ...dateRangeProps,
                  },
                });
              }
              break;

            // STAKEHOLDER GROUPS
            // replace "entityGroups" with each stakeholder group
            case "entityGroups":
              let entityGroupIds = projectData.tags.filter((x) => x.type === "ENTITY").map((x) => x.id);
              for (const entityGroupId of entityGroupIds) {
                newSeriesTypes.push(`entityGroup-${entityGroupId}`);
                const entityGroupFilter = { entityGroup: { query: [entityGroupId], include: true, active: true } };
                seriesDataIsComparison.push(dateRangeProps.isComparison);
                seriesDataCalls.push({
                  seriesType: resultsType,
                  seriesIssues: resultsTypeIssues ? resultsTypeIssues.map((x) => x.substr(6)) : [],
                  filterParams: {
                    ...filterParams,
                    query: { ...(filterParams.query || {}), ...entityGroupFilter },
                    ...dateRangeProps,
                  },
                });
              }
              break;

            // all / top / manualtop / issue-* (with query already in filterParams)
            default:
              newSeriesTypes.push(seriesType);
              seriesDataIsComparison.push(dateRangeProps.isComparison);
              seriesDataCalls.push({
                seriesType,
                seriesAgents: [],
                seriesIssues: [],
                filterParams: { ...filterParams, ...dateRangeProps },
              });
              break;
          }
        }
      }
      seriesTypes = newSeriesTypes;

      // await all series data, results will be in same order as seriesTypes
      const seriesDefaults = {
        source,
        seriesType: "all",
        seriesAgents: [],
        seriesIssues: [],
        seriesIssuesTop: null,
      };
      const seriesDataPromises = seriesDataCalls.map((seriesDataCall) =>
        source === "SOCIAL360_COVERAGE"
          ? getSocialCoverageData({
              ...seriesDefaults,
              project,
              projectData,
              filterParams,
              objWithDateRange,
              chartType,
              chartConfig,
              metricConfig,
              ...seriesDataCall,
            })
          : getSeriesData({
              ...seriesDefaults,
              filterParams,
              aggQuery,
              projectData,
              apollo,
              // override with anything defined in the call
              ...seriesDataCall,
            })
      );

      // set loading again on data changing
      setLoading(true);

      // get all data
      let seriesData = [];
      try {
        // stagger calls slightly
        seriesData = await Promise.all(
          seriesDataPromises.map(async (promise, index) => {
            if (!isReportBuilder) await sleep(index * 500);
            return promise;
          })
        );
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // annotate resolved promises (which will be in same order as called)
      seriesData = seriesData.map((series, index) => ({
        ...series,
        // whether this relates to comparison data or not
        isComparison: seriesDataIsComparison[index],
        // the social coverage keywords for this series (will be same for comparison and non-comparison)
        socialCoverageKeywords: seriesDataSocialCoverageKeywords,
      }));

      // social coverage - restructure data as if it has come from ES so existing logic works
      if (source === "SOCIAL360_COVERAGE") {
        // keyword charts have one series call containing multiple counts
        // pick them out so they can be processed using `countOrSum` like non-coverage charts
        // will have 1 series for normal chart, 2 for comparison and non-comparison
        if (seriesDataSocialCoverageKeywords.length) {
          let newSeriesTypes = [];
          let newSeriesData = [];
          for (const [seriesIndex, series] of seriesData.entries()) {
            for (const [keywordIndex] of series.socialCoverageKeywords.entries()) {
              // for labelling
              const seriesKey = seriesTypes[seriesIndex][keywordIndex];
              newSeriesTypes.push(`keyword-${seriesKey}`);

              // `countOrSum` will expect `count~0/count~0`
              const countKey = `count~${keywordIndex}`;
              newSeriesData.push({
                ...series,
                data: { "count~0": { "count~0": series.data[countKey][countKey] } },
              });
            }
          }
          seriesTypes = newSeriesTypes;
          seriesData = newSeriesData;
        }

        seriesData = seriesData.map((series) => {
          // mimic different types of ES aggregation response
          let buckets = [];
          let value = 0;

          if (chartConfig.processType === "time") {
            buckets = series.data["count~0"]["count~0"].map((bucket) => ({
              key_as_string: new Date(bucket[0]).toISOString(),
              key: bucket[0],
              doc_count: bucket[1],
            }));
          }

          if (["term", "termStacked"].includes(chartConfig.processType)) {
            buckets = Object.entries(series.data["count~0"]).map((bucket) => ({
              key: bucket[0],
              doc_count: bucket[1],
            }));
          }

          if (chartConfig.processType === "termThenTime") {
            buckets = Object.entries(series.data["count~0"]).map((bucket) => ({
              key: bucket[0],
              doc_count: bucket[1].map((innerBucket) => innerBucket[1]).reduce((a, b) => a + b),
              inner: {
                buckets: bucket[1].map((innerBucket) => ({
                  key_as_string: new Date(innerBucket[0]).toISOString(),
                  key: innerBucket[0],
                  doc_count: innerBucket[1],
                })),
              },
            }));
          }

          if (chartConfig.processType === "countOrSum") {
            value = series.data["count~0"]["count~0"];
          }

          return {
            ...series,
            data: {
              searchResultsAggregations: {
                aggregations: JSON.stringify({
                  chart: {
                    buckets,
                    value,
                  },
                }),
              },
            },
          };
        });
      }

      // process data
      let chartData = processData({
        source,
        resultsType,
        chartObj,
        objWithDateRange,
        projectData,
        metricConfig,
        chartConfig,
        metricType,
        chartType: chartConfig.highchartsType || chartType,
        seriesData,
        seriesTypes,
        chartIsComparison,
        chartSortBy: chartObj && chartObj.sortByValue ? "y" : "name",
        // sort by name ascending, or y value descending
        chartSortByDirection: chartObj && chartObj.sortByValue ? "desc" : "asc",
      });

      if (isBuilder) {
        // get series/categories to hide from chart
        const hidden = chartObj.hidden || [];

        // work out hideable series/categories options, filter data
        let hideableSeries = [];
        let hideableCategories = [];
        if (chartData.data.length === 1) {
          // there is one series which can't be hidden, categories can be hidden
          // NOTE: this logic duplicated in processByTerm for setting category names taking into account hidden categories
          hideableCategories = chartData.data[0].data.map((x) => ({ key: x.key, name: x.name }));
          if (hidden.length)
            chartData.data[0].data = chartData.data[0].data.filter((x) => !hidden.includes(`category-${x.key}`));
        } else {
          // there are multiple series which can be hidden, categories within each series can be hidden
          hideableSeries = chartData.data.map((x) => ({ key: x.key, name: x.name }));
          if (hidden.length) chartData.data = chartData.data.filter((x) => !hidden.includes(`series-${x.key}`));

          hideableCategories = uniqBy(
            flatten(chartData.data.map((x) => x.data.map((x) => ({ key: x.key, name: x.name })))),
            "key"
          );
          if (hidden.length)
            chartData.data = chartData.data.map((series) => ({
              ...series,
              data: series.data.filter((x) => !hidden.includes(`category-${x.key}`)),
            }));
        }

        // comparison charts series (comparison/results) are not hideable
        if (chartIsComparison) hideableSeries = [];

        // line chart categories (datetime) are not hideable
        if (chartType === "line") hideableCategories = [];

        setHideableData({ series: sort(hideableSeries, "name"), categories: sort(hideableCategories, "name") });
      }

      // done
      setData(chartData);
      setLoading(false);
    };

    init();
  }, [
    source,
    resultsType,
    resultsTypeAgents,
    resultsTypeIssues,
    filterParams,
    objWithDateRange,
    filter,
    projectData,
    metricConfig,
    chartConfig,
    metricType,
    chartType,
    clientId,
    projectId,
    project,
    maxDate,
    apollo,
    isBuilder,
    chartObj,
    isReportBuilder,
    refetch, // refresh when refetch value is changed
  ]);

  if (loading || error) {
    if (isReportBuilder)
      return (
        <div style={{ height: height || HEIGHT }}>
          {loading && <Spinner className="loading-relative" />}
          {error && <ResultsError message="chart results" refetch={() => setRefetch(Date.now())} />}
        </div>
      );
    return (
      <Card
        className={classNames({
          "card-widget": true,
          "card-widget--loading-graybg": !whiteBg,
          [className]: className,
        })}
      >
        <CardBody style={{ height: height || HEIGHT }}>
          {loading && <Spinner className="loading-relative" />}
          {error && <ResultsError message="chart results" refetch={() => setRefetch(Date.now())} />}
        </CardBody>
      </Card>
    );
  }

  let menuItems = ["viewFullscreen", "downloadPNG"];
  if (isBuilder) {
    if (hideableData && hideableData.series.length + hideableData.categories.length > 0)
      menuItems.splice(0, 0, "builderFilter");
    if (dashboardEdit) menuItems.splice(0, 0, "builderDashboardEdit");
  }

  // don't show legend if there is only one "Results" series
  let legendEnabled = true;
  if (data.data.length === 1 && data.data[0].name === "Results") legendEnabled = false;

  // change empty message if comprehend has never been used
  let noData = "There is no data to display.";
  if (!projectData.comprehendUsed && ["person", "organisation", "entityGroup"].includes(metricType))
    noData = "Stakeholder analysis is not enabled for this project.";

  const options = {
    lang: {
      noData,
    },
    chart: {
      type: chartConfig.highchartsType || chartType,
      zoomType: "Xy",
      height: height || HEIGHT,
      events: {
        drilldown: async (e) => {
          // e.category will be undefined if chart point is clicked, but integer if label is clicked (which will then be fired multiple times and error)
          if (e.category === undefined) await handleClick(e.point.drilldown);
        },
      },
      animation: false,
    },
    time: {
      moment,
      timezone: moment.tz.guess(),
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: legendEnabled,
    },
    // style axis/data labels as non-clickable as drilldown by clicking on label is disabled
    drilldown: {
      activeAxisLabelStyle: { color: "#666666", fontWeight: "inherit", textDecoration: "inherit", cursor: "inherit" },
      activeDataLabelStyle: { color: "#666666", fontWeight: "inherit", textDecoration: "inherit", cursor: "inherit" },
    },
    exporting: {
      // use our server if we fallback from client-side export
      url: "https://charts.outcider.net",
      // controls the export size when used from the chart only (overridden in ReportBuilder)
      // 16:10 aspect ratio
      sourceWidth: 800,
      sourceHeight: 500,
      scale: 2,
      buttons: {
        contextButton: {
          menuItems,
        },
      },
    },
    title: {
      text: title,
      y: 20,
    },
    series: data.data,
    plotOptions: {
      series: {
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
        },
        dataLabels: {
          // pies always have labels (show segment name, then add value if showValues is true)
          // labels disabled for quick charts where we don't have chartObj
          enabled: chartType === "pie" ? true : chartObj ? chartObj.showValues : false,
          format:
            chartType === "pie"
              ? chartObj && chartObj.showValues
                ? "{point.name} ({y:,.0f})"
                : "{point.name}"
              : "{y:,.0f}",
          // style required for consistent export appearance
          style: { fontWeight: "normal", color: "#666" },
        },
        animation: false,
        // disable legend clicking
        events: {
          legendItemClick: () => false,
        },
      },
      bar: {
        stacking: chartType === "stackedBar" ? "normal" : undefined,
      },
      column: {
        stacking: chartType === "stackedColumn" ? "normal" : undefined,
      },
      wordcloud: {
        // use default highcharts font family (which is the only font installed on our export server)
        style: { fontFamily: "Lucida Grande", fontWeight: "600" },
      },
    },
    xAxis: {
      title: null,
      ...data.xAxis,
    },
    yAxis: {
      title: null,
    },
  };
  if (subtitle) {
    options.subtitle = {
      text: subtitle,
      y: 33,
      style: {
        fontSize: 10,
      },
    };
  }

  // if builder add context menu definitions
  if (isBuilder) {
    options.exporting.menuItemDefinitions = {
      // filter option
      builderFilter: {
        text: "Filter",
        onclick: toggleFilterModal,
      },
      // dashboard edit
      builderDashboardEdit: {
        text: "Change chart",
        onclick: dashboardEdit,
      },
    };
  }

  // if report builder, remove context menu
  if (isReportBuilder) {
    options.navigation = {
      buttonOptions: {
        enabled: false,
      },
    };
  }

  if (isReportBuilder)
    return (
      <>
        {/* don't wrap in Card */}
        <HighchartsReact highcharts={Highcharts} options={options} />
        {/* used by ReportBuilder.prepareCharts to get Chart data without recalculating */}
        <div ref={reportBuilderRef} className="d-none">
          {JSON.stringify(options)}
        </div>
      </>
    );

  return (
    <>
      <Card
        className={classNames({
          "card-widget": true,
          [className]: className,
        })}
      >
        <CardBody style={{ height: height || HEIGHT }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </CardBody>
      </Card>
      {isBuilder && filterModal && (
        <FilterModal toggle={toggleFilterModal} chart={chartObj} hideableData={hideableData} />
      )}
    </>
  );
};

// only re-render chart if props have changed (use deep comparison)
// export default React.memo(Chart, isEqual);

// the above doesn't always seem to work, especially when chartQuery has changed, do this for now...
export default Chart;

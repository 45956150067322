export const AI_ANALYST_PROMPT = `
You are an experienced news analyst working on behalf of multiple clients and always analyse news articles and stories in detail, understanding the precise needs of your various clients whatever their industry or concerns.

Read the article at this URL: [URL]

Return the following:
"Summary": A brief overview summary of the article in no less than 25 words and no more than 50 that clearly communicates the important points.
"Excerpt": Get a quote from the article up to 100 words long that includes the keywords [KEYWORDS].
"Analysis": An analysis of the article from the perspective of [WHO] involved in [TOPIC] that is focusing on [FOCUS].
"Recommendations": Three recommendations on how [WHO] involved in [TOPIC] should act in response to the issues contained in this article, given the focus on [FOCUS], returned in an array. Consider that the article may be written from a different perspective or for a different audience, so the recommendations should take this into account.

Do not include links back to the article.
`;

export const buildAiAnalystPrompt = (result, keywords) =>
  AI_ANALYST_PROMPT.replaceAll("[URL]", result.url)
    .replaceAll("[KEYWORDS]", keywords.map((x) => `'${x}'`).join(", "))
    .replaceAll("[WHO]", result.project.aiAnalystPromptWho)
    .replaceAll("[TOPIC]", result.project.aiAnalystPromptTopic)
    .replaceAll("[FOCUS]", result.project.aiAnalystPromptFocus);

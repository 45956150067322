import * as blockBanner from "./partials/blockBanner";
import * as blockChart from "./partials/blockChart";
import * as blockContents from "./partials/blockContents";
import * as blockDeleted from "./partials/blockDeleted";
import * as blockFocus from "./partials/blockFocus";
import * as blockFooter from "./partials/blockFooter";
import * as blockGrid from "./partials/blockGrid";
import * as blockHeader from "./partials/blockHeader";
import * as blockHeading from "./partials/blockHeading";
import * as blockHeadline from "./partials/blockHeadline";
import * as blockImage from "./partials/blockImage";
import * as blockList from "./partials/blockList";
import * as blockQuote from "./partials/blockQuote";
import * as blockTable from "./partials/blockTable";
import * as blockText from "./partials/blockText";
import * as builderBlockActions from "./partials/builderBlockActions";
import * as builderBlockOrdering from "./partials/builderBlockOrdering";
import * as builderResultActions from "./partials/builderResultActions";
import * as builderResultOrdering from "./partials/builderResultOrdering";
import * as chart from "./partials/chart";
import * as chartHeader from "./partials/chartHeader";
import * as imageFloat from "./partials/imageFloat";
import * as imageHeader from "./partials/imageHeader";
import * as resultMeta from "./partials/resultMeta";

const templates = {
  blockBanner,
  blockChart,
  blockContents,
  blockDeleted,
  blockFocus,
  blockFooter,
  blockGrid,
  blockHeader,
  blockHeading,
  blockHeadline,
  blockImage,
  blockList,
  blockQuote,
  blockTable,
  blockText,
  builderBlockActions,
  builderBlockOrdering,
  builderResultActions,
  builderResultOrdering,
  chart,
  chartHeader,
  imageFloat,
  imageHeader,
  resultMeta,
};

export default templates;

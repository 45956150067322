import { CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { fnTriggerJob, updateResult } from "../../graphql/mutations";
import { getResultJobSentinel, getResultPendingJobsQuery, resultHasPendingJobsOfType } from "../../lib/resultJobs";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";

import { AppContext } from "../../App";
import { buildSearchResultsTypeParams } from "../../lib/queries";
import classNames from "classnames";
import pick from "lodash/pick";
import { searchResultsTotal } from "../../graphql/queries";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

const ResultControlsDuplicateGroup = ({
  result,
  resultControlsDuplicateGroupRefetchRef,
  resultsType,
  filter,
  projectData,
}) => {
  const { clientId, projectId } = useParams();
  const appContext = useContext(AppContext);

  // query for manual top stories within duplicate group, within any date range, to decide which icon to display
  // (can't use removeDuplicates since that only sees results in current date range, top story in duplicate group might be outside of the date range displayed in the results listing)
  const filterParams = {
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    duplicateGroup: result.duplicateGroup,
    isTop: true,
    query: filter ? JSON.parse(filter.query) : {},
  };
  const searchParams = buildSearchResultsTypeParams({
    source: result.source,
    resultsType,
    filterParams,
    projectData,
  });
  const { loading, data, refetch } = useQuery(gql(searchResultsTotal), {
    variables: { searchParams: JSON.stringify(searchParams) },
  });

  // pass back to ResultsListing so this can be refetched if/when the duplicates modal is closed
  useEffect(() => {
    if (!resultControlsDuplicateGroupRefetchRef) return;
    resultControlsDuplicateGroupRefetchRef.current[result.duplicateGroup] = refetch;
  }, [result.duplicateGroup, resultControlsDuplicateGroupRefetchRef, refetch]);

  if (loading) return null;

  let icon = "mdi-star-box-multiple-outline";
  if (data && data.searchResultsTotal.total > 0) icon = "mdi-star-box-multiple";

  return (
    <i
      className={classNames({
        "result-controls-top": true,
        mdi: true,
        [icon]: true,
      })}
    />
  );
};

const ResultControlsTop = ({ result, resultsType, filter, source, projectData }) => {
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();
  const appContext = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationUpdateResult] = useMutation(gql(updateResult));
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));

  // set after top flag is changed, prevents refetch of searchResultsTotal while ES changes are likely still propagating
  const [updated, setUpdated] = useState(false);

  // auto top views are either the top stories view, or an issue which is a top story issue
  const isAutoTopView = resultsType === "top" || (resultsType.startsWith("issue-") && filter && filter.isTop);

  // start with outline
  let icon = "mdi-star-outline";
  if (isAutoTopView) {
    // if in top view, get half star by default
    icon = "mdi-star-half";
    // overridden if then manually topped
    if (result.isTop) icon = "mdi-star";
    // but back to outline if manually excluded
    if (result.isNotTop) icon = "mdi-star-outline";
  } else {
    // manual top
    if (result.isTop) icon = "mdi-star";
  }

  // if we are in all results view, or a non top story issue view, we get the top status based on whether the result would appear in the top stories view
  const filterParams = { clientId, projectId, id: result.id, maxDate: appContext.maxDate };
  const searchParams = buildSearchResultsTypeParams({
    source: result.source,
    resultsType: "top",
    filterParams,
    projectData,
  });
  const { loading, data } = useQuery(gql(searchResultsTotal), {
    variables: { searchParams: JSON.stringify(searchParams) },
    skip: isAutoTopView || updated,
  });
  if (loading) return null;

  // the result, as it appears in the top story view, will use the manual top in favour of the auto top
  if (data && data.searchResultsTotal.total > 0) icon = result.isTop ? "mdi-star" : "mdi-star-half";

  const handleTop = async (priorityIssue) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const topping = !result.isTop;

    const mutation = {
      // toggle the manual top flag
      isTop: !result.isTop,
      // if currently manually topped, and therefore now untopping, set isNotTop exclusion
      isNotTop: result.isTop,
    };

    // if topping, set priority issue to the one supplied (either from the current issue being viewed (if one is being viewed), or the issue clicked in the "set priority issue" dropdown)
    // if untopping, unset priority issue
    if (!result.isTop && priorityIssue) mutation.commentFilterId = priorityIssue.id;
    if (result.isTop) mutation.commentFilterId = null;

    try {
      let job, jobId, jobData;

      // see if going to trigger AI anlayst job after updating the result
      const triggerAiAnalyst =
        // topping result
        topping &&
        // enabled on project
        result.project.aiAnalystEnabled &&
        // has a url
        result.url &&
        // not nla content
        !result.url.includes("nla-eclipsweb") &&
        // not already in progress
        !resultHasPendingJobsOfType(result, "resultaianalyst");

      // if we are then add sentinel pending job, while refreshing the result to ensure we are mutating an up-to-date version of pendingJobs
      // the sentinel triggers a re-render of ResultsListingItem, and the effect to poll for job changes
      if (triggerAiAnalyst) {
        job = "RESULTAIANALYST";
        // pre-create job id as used to match sentinel and fnResultAiAnalyst
        jobId = `${job.toLowerCase()}-${uuid()}`;
        jobData = { priorityIssueId: priorityIssue ? priorityIssue.id : null };

        const latestResult = await apollo.query({
          query: gql(getResultPendingJobsQuery),
          variables: { id: result.id },
          fetchPolicy: "network-only",
        });

        mutation.pendingJobs = [...(latestResult.pendingJobs || []), getResultJobSentinel(jobId)];
      }

      // update the result in one go
      await mutationUpdateResult({
        variables: {
          input: {
            ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
            ...mutation,
          },
        },
      });

      // prevent refetch of searchResultsTotal while ES changes are likely still propagating
      setUpdated(true);

      // trigger AI analyst
      if (triggerAiAnalyst) {
        await mutationFnTriggerJob({
          variables: {
            input: { clientId, projectId, job, jobId, data: JSON.stringify(jobData), resultId: result.id },
          },
        });
      }
    } catch (e) {
      appContext.handleError(e);
    }

    setIsSubmitting(false);
  };

  // if there are priority issues to select from, and not viewing a filter, and the result is currently untopped
  const priorityIssues = projectData.filters.filter((x) => x.source === source);
  if (priorityIssues.length && !filter && !result.isTop) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="arrow-none cursor-pointer" onClick={(e) => e.stopPropagation()}>
          <i
            className={classNames({
              click: true,
              "result-controls-top": true,
              mdi: true,
              [icon]: true,
            })}
          />
        </DropdownToggle>
        <DropdownMenu right>
          {/* top story without priority issue */}
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              handleTop(null);
            }}
          >
            Top Story
          </DropdownItem>
          <DropdownItem header>Top Story + Set Priority Issue</DropdownItem>
          {/* top story and set priority issue */}
          {priorityIssues.map((filter) => (
            <DropdownItem
              key={filter.id}
              onClick={(e) => {
                e.stopPropagation();
                handleTop(filter);
              }}
            >
              {filter.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // quick top (without dropdown, priority issue set to current filter if there is one)
  // quick untop
  return (
    <i
      className={classNames({
        click: true,
        "result-controls-top": true,
        mdi: true,
        [icon]: true,
      })}
      onClick={(e) => {
        e.stopPropagation();
        handleTop(filter);
      }}
    />
  );
};

const ResultControls = ({
  result,
  resultControlsDuplicateGroupRefetchRef,
  resultsType,
  filter,
  duplicates,
  isListing,
  listingDuplicates,
  checked,
  onCheck,
  source,
  projectData,
}) => {
  const appContext = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationUpdateResult] = useMutation(gql(updateResult));

  const handlePublicFeed = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await mutationUpdateResult({
        variables: {
          input: {
            ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
            includePublicFeed: result.includePublicFeed ? null : new Date().toISOString(),
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await mutationUpdateResult({
        variables: {
          input: {
            ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
            isDeleted: !result.isDeleted,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className={classNames({
        "result-controls": true,
        "result-controls--result": !isListing,
      })}
    >
      {isListing && !listingDuplicates && (
        <CustomInput
          type="checkbox"
          id={`result${result.id}`}
          onChange={(e) => onCheck(result.id, result.duplicateGroup, e.target.checked)}
          checked={checked}
        />
      )}
      {!(duplicates || []).includes(result.duplicateGroup) ? (
        <ResultControlsTop
          result={result}
          resultsType={resultsType}
          filter={filter}
          source={source}
          projectData={projectData}
        />
      ) : (
        <ResultControlsDuplicateGroup
          result={result}
          resultControlsDuplicateGroupRefetchRef={resultControlsDuplicateGroupRefetchRef}
          resultsType={resultsType}
          filter={filter}
          projectData={projectData}
        />
      )}
      {!(duplicates || []).includes(result.duplicateGroup) && (
        <i
          className={classNames({
            click: true,
            "result-controls-public-feed": true,
            mdi: true,
            "mdi-newspaper-plus": !result.includePublicFeed,
            "mdi-newspaper-minus": result.includePublicFeed,
          })}
          onClick={(e) => {
            e.stopPropagation();
            handlePublicFeed();
          }}
        />
      )}
      <i
        className={classNames({
          click: true,
          "result-controls-delete": true,
          // show full trash can when result is deleted (applies to deleted view and duplicates modal before refresh)
          "uil-trash": !result.isDeleted,
          "uil-trash-alt": result.isDeleted,
        })}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
      />
    </div>
  );
};

export default ResultControls;

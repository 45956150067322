import * as options from "../lib/options";

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import {
  HAS_DYNAMIC_OPTIONS_FROM_PROJECTDATA,
  HAS_DYNAMIC_OPTIONS_FROM_QUERY,
  HAS_NUMBER_RANGE,
  NO_INCLUDE_SWITCH,
} from "./lib";
import React, { useState } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import {
  inputProcessTermsIncludeQuery,
  inputValidateProximity,
  populateForm,
  processForm,
  validateNumberRange,
} from "../lib/forms";
import {
  searchResultsAllAuthorEmails,
  searchResultsAllAuthorGroups,
  searchResultsAllAuthorNames,
  searchResultsAllSourceNames,
  searchResultsAllSourceUrls,
} from "../../graphql/queries";

import AlertModal from "../Modals/AlertModal";
import ConfirmModal from "../Modals/ConfirmModal";
import FormCreatable from "../Forms/FormCreatable";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import FormToggle from "../Forms/FormToggle";
import { METABASE_SOCIAL_MEDIA_TYPES } from "../../lib/constants";
import ModalLoading from "../Modals/ModalLoading";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import debounce from "debounce-promise";
import flatten from "lodash/flatten";
import intersection from "lodash/intersection";
import pull from "lodash/pull";
import snakeCase from "lodash/snakeCase";
import { sort } from "../../lib/utils";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const ImportModal = ({ filter, projectData, user, toggle }) => {
  const filterId = filter ? filter.id : null;

  // get issues, which are not the current issue, that have keyword filters, that the user can see, categorised by source
  let webIssues = projectData.filters.filter((x) => {
    const usedQueryKeys = Object.keys(JSON.parse(x.query));
    return x.id !== filterId && usedQueryKeys.includes("keyword") && x.source === "METABASE";
  });
  // https://coda.io/d/Outcider-Product-Roadmap_d-BRdmeXG1N/Icebox_suvUJ#Icebox_tuRMh/r70&view=modal
  // if (!user.can.viewHiddenIssues) webIssues = webIssues.filter((x) => x.isVisible);

  let socialIssues = projectData.filters.filter((x) => {
    const usedQueryKeys = Object.keys(JSON.parse(x.query));
    return x.id !== filterId && usedQueryKeys.includes("keyword") && x.source === "SOCIAL360";
  });
  // https://coda.io/d/Outcider-Product-Roadmap_d-BRdmeXG1N/Icebox_suvUJ#Icebox_tuRMh/r70&view=modal
  // if (!user.can.viewHiddenIssues) socialIssues = socialIssues.filter((x) => x.isVisible);

  let issueOptions = [];
  if (webIssues.length) {
    issueOptions.push({
      label: "News Issues",
      options: webIssues.map((x) => ({ value: x, label: x.name })),
    });
  }
  if (socialIssues.length) {
    issueOptions.push({
      label: "Social Issues",
      options: socialIssues.map((x) => ({ value: x, label: x.name })),
    });
  }

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(null, {
      selects: ["issue", issueOptions, true],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    const selectedIssue = values.issue.value;
    const keywordsToImport = JSON.parse(selectedIssue.query).keyword;
    toggle(keywordsToImport);
  };

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={true}>
      <ModalHeader toggle={() => toggle(null)}>Import Keywords</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormSelect
            name="issue"
            label="Import keywords from issue"
            options={issueOptions}
            required
            helpText="Select an issue to perform a one-off import of its keywords into this issue. Existing keywords in the current filter will be overwritten with the keywords from the selected issue."
            formProps={formProps}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Import
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const FilterModal = ({
  data: { source, type, data, isChartFilter, op },
  toggle,
  filter,
  addFilter,
  deleteFilter,
  projectData,
  user,
}) => {
  const { clientId, projectId } = useParams();
  const apollo = useApolloClient();
  const alertModal = createConfirmation(AlertModal);

  const [importModal, toggleImportModal] = useState(false);

  let filterTypeOptions = options.FILTER_TYPES_METABASE;
  if (source === "SOCIAL360") filterTypeOptions = options.FILTER_TYPES_SOCIAL360;

  // get name of filter
  const filterName = flatten(filterTypeOptions.filter((x) => x.label).map((x) => x.options)).find(
    (x) => x.value === type
  ).label;

  // get top source names/urls/authors
  const { loading: loadingSourceNames, data: dataSourceNames } = useQuery(gql(searchResultsAllSourceNames), {
    variables: {
      filter: {
        clientId,
        projectId,
        source,
      },
    },
    skip: type !== "sourceName",
  });
  const { loading: loadingSourceUrls, data: dataSourceUrls } = useQuery(gql(searchResultsAllSourceUrls), {
    variables: {
      filter: {
        clientId,
        projectId,
        source,
      },
    },
    skip: type !== "sourceDomain",
  });
  const { loading: loadingAuthorNames, data: dataAuthorNames } = useQuery(gql(searchResultsAllAuthorNames), {
    variables: {
      filter: {
        clientId,
        projectId,
        source,
      },
    },
    skip: type !== "authorName",
  });
  const { loading: loadingAuthorEmails, data: dataAuthorEmails } = useQuery(gql(searchResultsAllAuthorEmails), {
    variables: {
      filter: {
        clientId,
        projectId,
        source,
      },
    },
    skip: type !== "authorEmail",
  });
  const { loading: loadingAuthorGroups, data: dataAuthorGroups } = useQuery(gql(searchResultsAllAuthorGroups), {
    variables: {
      filter: {
        clientId,
        projectId,
        source,
      },
    },
    skip: type !== "authorGroups",
  });

  // decide if to show include/exclude toggle
  let showIncludeExclude = true;
  if (isChartFilter || NO_INCLUDE_SWITCH.includes(type)) showIncludeExclude = false;

  // all filter types have active switches, include is for everything except those in NO_INCLUDE_SWITCH
  const initial = { ...data };
  if (type === "keyword") {
    if (!initial.within) initial["within"] = "all";
    if (!initial.withinExclude) initial["withinExclude"] = "all";
  }

  let dynamicOptions = [];

  // dynamic options from projectData
  if (type === "topic")
    dynamicOptions = projectData.tags.filter((x) => x.type === "TOPIC").map((x) => ({ value: x.id, label: x.name }));
  if (type === "concern")
    dynamicOptions = projectData.tags.filter((x) => x.type === "CONCERN").map((x) => ({ value: x.id, label: x.name }));
  if (type === "agent") {
    let agentOptions = projectData.agents.filter((x) => x.isEnabled);
    if (source === "METABASE") agentOptions = agentOptions.filter((x) => x.hasWebSources);
    if (source === "SOCIAL360") agentOptions = agentOptions.filter((x) => x.hasSocialSources);
    dynamicOptions = agentOptions.map((x) => ({ value: x.id, label: x.name }));
  }
  if (type === "person") dynamicOptions = projectData.people.map((x) => ({ value: x.id, label: x.name }));
  if (type === "organisation") dynamicOptions = projectData.organisations.map((x) => ({ value: x.id, label: x.name }));
  if (type === "entityGroup")
    dynamicOptions = projectData.tags.filter((x) => x.type === "ENTITY").map((x) => ({ value: x.id, label: x.name }));
  // if (type === "authorGroups")
  //   dynamicOptions = projectData.socialAuthorGroups.map((x) => ({ value: x.name, label: x.name })); // value is name not id

  // dynamic options for source name/url/authors
  if (type === "sourceName")
    dynamicOptions = loadingSourceNames
      ? []
      : dataSourceNames.searchResultsAllSourceNames.sourceName.map((x) => ({
          value: x.key,
          label: `${x.key} (${x.doc_count})`,
        }));
  if (type === "sourceDomain")
    dynamicOptions = loadingSourceUrls
      ? []
      : dataSourceUrls.searchResultsAllSourceUrls.sourceUrl.map((x) => ({
          value: x.key,
          label: `${x.key} (${x.doc_count})`,
        }));
  if (type === "authorName")
    dynamicOptions = loadingAuthorNames
      ? []
      : dataAuthorNames.searchResultsAllAuthorNames.authorName.map((x) => ({
          value: x.key,
          label: `${x.key} (${x.doc_count})`,
        }));
  if (type === "authorEmail")
    dynamicOptions = loadingAuthorEmails
      ? []
      : dataAuthorEmails.searchResultsAllAuthorEmails.authorEmail.map((x) => ({
          value: x.key,
          label: `${x.key} (${x.doc_count})`,
        }));
  if (type === "authorGroups")
    dynamicOptions = loadingAuthorGroups
      ? []
      : dataAuthorGroups.searchResultsAllAuthorGroups.authorGroups.map((x) => ({
          value: x.key,
          label: `${x.key} (${x.doc_count})`,
        }));

  // mediaType options vary based on the source, and are NOT grouped
  let mediaTypeOptions = [];
  if (source === "METABASE") mediaTypeOptions = options.MEDIA_TYPE_METABASE;
  if (source === "SOCIAL360") mediaTypeOptions = options.MEDIA_TYPE_SOCIAL360;

  // language options vary based on the source
  let languageOptions = [];
  if (source === "METABASE") languageOptions = options.LANGUAGE;
  if (source === "SOCIAL360") {
    // stored as language name, but the options have codes as values (as required by agents), not names
    languageOptions = options.SOCIAL360_LANGUAGE.map((x) => ({ value: x.label, label: x.label }));
  }

  let formConfig = {
    switches: showIncludeExclude
      ? [
          ["include", true],
          ["active", true],
        ]
      : [["active", true]],
  };
  if (type === "keyword") {
    // keywords has the 4 keyword fields
    formConfig.fields = ["proximity1", "proximity2"];
    formConfig.selects = [
      ["within", options.WITHIN, false],
      ["withinExclude", options.WITHIN, false],
      ["proximityDistance", options.PROXIMITY, false],
    ];
    formConfig.creatables = ["any", "inConnectionWithAny", "inConnectionWithAll", "none"];
  } else if (type === "mediaType") {
    // media type also has rank fields
    formConfig.selectMultis = [
      ["query", mediaTypeOptions],
      ["newsSourceRanks", options.NEWS_RANK],
      ["socialSourceRanks", options.SOCIAL_RANK],
    ];
  } else if (HAS_DYNAMIC_OPTIONS_FROM_PROJECTDATA.includes(type)) {
    // dynamic options defined above into selectMulti
    formConfig.selectMultis = [["query", dynamicOptions, false]];
  } else if (HAS_DYNAMIC_OPTIONS_FROM_QUERY.includes(type)) {
    // dynamic options defined above into creatable
    formConfig.creatables = ["query"];
  } else if (HAS_NUMBER_RANGE.includes(type)) {
    // from/to fields
    formConfig.fields = ["queryFrom", "queryTo"];
  } else {
    // all other filter types are selectMultis from constant options
    const shoutingSnakeCase = snakeCase(type).toUpperCase();
    formConfig.selectMultis = [["query", options[shoutingSnakeCase], false]];
  }

  const { register, handleSubmit, errors, formState, control, setValue, getValues, watch } = useForm({
    defaultValues: populateForm(initial, formConfig),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue, getValues };

  let selectedMediaTypes = [];
  let selectedMediaTypesWithoutPrefix = [];
  if (type === "mediaType") {
    const watchMediaTypes = watch("query");
    selectedMediaTypes = watchMediaTypes ? watchMediaTypes.map((x) => x.value) : [];

    let strIndex = 0;
    if (source === "METABASE") strIndex = 9;
    if (source === "SOCIAL360") strIndex = 7;
    selectedMediaTypesWithoutPrefix = selectedMediaTypes.map((x) => x.substr(strIndex));
  }

  const title = op === "CREATE" ? "Add" : "Edit";
  const action = "Apply";

  const onSubmit = async (values) => {
    // work out processForm config (mirrors formConfig above except `fields` is not required)
    let processFormConfig = {};
    if (type === "keyword") {
      processFormConfig.creatables = ["any", "inConnectionWithAny", "inConnectionWithAll", "none"];
      processFormConfig.selects = ["within", "withinExclude", "proximityDistance"];
    } else if (type === "mediaType") {
      // media type also has ranks
      processFormConfig.selectMultis = ["query", "newsSourceRanks", "socialSourceRanks"];
    } else if (HAS_DYNAMIC_OPTIONS_FROM_QUERY.includes(type)) {
      processFormConfig.creatables = ["query"];
    } else if (HAS_NUMBER_RANGE.includes(type)) {
      // nothing to do, just don't want the default else clause
    } else {
      processFormConfig.selectMultis = ["query"];
    }

    // if chart filter, include should always be set despite form input not being shown
    if (!showIncludeExclude) values.include = true;

    // validate number range
    if (HAS_NUMBER_RANGE.includes(type)) {
      try {
        validateNumberRange(values.queryFrom, values.queryTo);
      } catch (e) {
        return await alertModal({
          message: e.message,
        });
      }
    }

    // process values
    const data = await processForm(values, processFormConfig);

    // get the keys which contain data that actually filters the results by removing the ones that don't
    const dataKeys = pull(
      Object.keys(data),
      "within",
      "withinExclude",
      "proximityDistance",
      "newsSourceRanks",
      "socialSourceRanks",
      "include",
      "active"
    );
    const usedKeys = [];
    dataKeys.forEach((key) => {
      if (data[key] && data[key].length > 0) usedKeys.push(key);
    });

    // if the resulting values amount to nothing actually being filtered out of results, delete the filter
    if (!usedKeys.length) {
      deleteFilter(type, isChartFilter, false);
    } else {
      addFilter(type, data, isChartFilter);
    }
    toggle();
  };

  const confirmModal = createConfirmation(ConfirmModal);

  // no options, remove arrow
  const noOptionsFieldProps = {
    options: [],
    noOptionsMessage: () => null,
    classNamePrefix: "react-select--no-options",
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size={type === "keyword" ? "lg" : "md"} isOpen={true}>
        <ModalHeader toggle={() => toggle()}>
          {title} {filterName} Filter
        </ModalHeader>
        {HAS_DYNAMIC_OPTIONS_FROM_QUERY.includes(type) &&
        (loadingSourceNames ||
          loadingSourceUrls ||
          loadingAuthorNames ||
          loadingAuthorEmails ||
          loadingAuthorGroups) ? (
          <ModalLoading />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              {/* include/exclude toggle for all except keywords, sentiment, traffic (stored as true) */}
              {showIncludeExclude && (
                <FormSwitch
                  name="include"
                  label="Filter behaviour"
                  inlineLabelOn="Include"
                  inlineLabelOff="Exclude"
                  oneLine
                  formProps={formProps}
                />
              )}

              {type === "keyword" && (
                <>
                  <FormCreatable
                    name="any"
                    label="Any of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormCreatable
                    name="inConnectionWithAny"
                    label="In connection with any of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormCreatable
                    name="inConnectionWithAll"
                    label="In connection with all of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormSelect name="within" label="Within" options={options.WITHIN} formProps={formProps} />
                  <hr />
                  <FormCreatable
                    name="none"
                    label="None of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormSelect name="withinExclude" label="Within" options={options.WITHIN} formProps={formProps} />
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <label>Proximity:</label>
                    </Col>
                    <Col xs={4}>
                      <FormInput
                        name="proximity1"
                        validate={inputValidateProximity}
                        placeholder="Term 1"
                        formProps={formProps}
                      />
                    </Col>
                    <Col xs={4}>
                      <FormInput
                        name="proximity2"
                        validate={inputValidateProximity}
                        placeholder="Term 2"
                        formProps={formProps}
                      />
                    </Col>
                    <Col xs={4}>
                      <FormSelect
                        name="proximityDistance"
                        placeholder="Distance"
                        options={options.PROXIMITY}
                        formProps={formProps}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {type === "sourceName" && (
                // alter margin due to showEditModal
                <div style={{ marginTop: "-1.4rem" }}>
                  <FormCreatable
                    name="query"
                    type="source name"
                    placeholder="Select top source name or search all..."
                    showEditModal
                    required
                    formProps={formProps}
                    // async behaviour
                    isAsync
                    // show top options before searching
                    defaultOptions={dynamicOptions}
                    // search rest on demand
                    // use debounce-promise rather than lodash/debounce
                    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                    loadOptions={debounce(async (value, callback) => {
                      const results = await apollo.query({
                        query: gql(searchResultsAllSourceNames),
                        variables: {
                          filter: {
                            clientId,
                            projectId,
                            source,
                            query: inputProcessTermsIncludeQuery(value),
                          },
                        },
                      });
                      callback(
                        sort(results.data.searchResultsAllSourceNames.sourceName, "key").map((x) => ({
                          value: x.key,
                          label: `${x.key} (${x.doc_count})`,
                        }))
                      );
                    }, 250)}
                  />
                </div>
              )}

              {type === "sourceDomain" && (
                // alter margin due to showEditModal
                <div style={{ marginTop: "-1.4rem" }}>
                  <FormCreatable
                    name="query"
                    type="domain name"
                    placeholder="Select top domain name or search all..."
                    showEditModal
                    required
                    formProps={formProps}
                    // async behaviour
                    isAsync
                    // show top options before searching
                    defaultOptions={dynamicOptions}
                    // search rest on demand
                    // use debounce-promise rather than lodash/debounce
                    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                    loadOptions={debounce(async (value, callback) => {
                      const results = await apollo.query({
                        query: gql(searchResultsAllSourceUrls),
                        variables: {
                          filter: {
                            clientId,
                            projectId,
                            source,
                            query: inputProcessTermsIncludeQuery(value),
                          },
                        },
                      });
                      callback(
                        sort(results.data.searchResultsAllSourceUrls.sourceUrl, "key").map((x) => ({
                          value: x.key,
                          label: `${x.key} (${x.doc_count})`,
                        }))
                      );
                    }, 250)}
                  />
                </div>
              )}

              {type === "authorName" && (
                // alter margin due to showEditModal
                <div style={{ marginTop: "-1.4rem" }}>
                  <FormCreatable
                    name="query"
                    type="author name"
                    placeholder="Select top author name or search all..."
                    showEditModal
                    required
                    formProps={formProps}
                    // async behaviour
                    isAsync
                    // show top options before searching
                    defaultOptions={dynamicOptions}
                    // search rest on demand
                    // use debounce-promise rather than lodash/debounce
                    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                    loadOptions={debounce(async (value, callback) => {
                      const results = await apollo.query({
                        query: gql(searchResultsAllAuthorNames),
                        variables: {
                          filter: {
                            clientId,
                            projectId,
                            source,
                            query: inputProcessTermsIncludeQuery(value),
                          },
                        },
                      });
                      callback(
                        sort(results.data.searchResultsAllAuthorNames.authorName, "key").map((x) => ({
                          value: x.key,
                          label: `${x.key} (${x.doc_count})`,
                        }))
                      );
                    }, 250)}
                  />
                </div>
              )}

              {type === "authorEmail" && (
                // alter margin due to showEditModal
                <div style={{ marginTop: "-1.4rem" }}>
                  <FormCreatable
                    name="query"
                    type="author contact"
                    placeholder="Select top author contact or search all..."
                    showEditModal
                    required
                    formProps={formProps}
                    // async behaviour
                    isAsync
                    // show top options before searching
                    defaultOptions={dynamicOptions}
                    // search rest on demand
                    // use debounce-promise rather than lodash/debounce
                    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                    loadOptions={debounce(async (value, callback) => {
                      const results = await apollo.query({
                        query: gql(searchResultsAllAuthorEmails),
                        variables: {
                          filter: {
                            clientId,
                            projectId,
                            source,
                            query: inputProcessTermsIncludeQuery(value),
                          },
                        },
                      });
                      callback(
                        sort(results.data.searchResultsAllAuthorEmails.authorEmail, "key").map((x) => ({
                          value: x.key,
                          label: `${x.key} (${x.doc_count})`,
                        }))
                      );
                    }, 250)}
                  />
                </div>
              )}

              {type === "authorGroups" && (
                // alter margin due to showEditModal
                <div style={{ marginTop: "-1.4rem" }}>
                  <FormCreatable
                    name="query"
                    type="author group"
                    placeholder="Select top author group or search all..."
                    showEditModal
                    required
                    formProps={formProps}
                    // async behaviour
                    isAsync
                    // show top options before searching
                    defaultOptions={dynamicOptions}
                    // search rest on demand
                    // use debounce-promise rather than lodash/debounce
                    // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                    loadOptions={debounce(async (value, callback) => {
                      const results = await apollo.query({
                        query: gql(searchResultsAllAuthorGroups),
                        variables: {
                          filter: {
                            clientId,
                            projectId,
                            source,
                            query: inputProcessTermsIncludeQuery(value),
                          },
                        },
                      });
                      callback(
                        sort(results.data.searchResultsAllAuthorGroups.authorGroups, "key").map((x) => ({
                          value: x.key,
                          label: `${x.key} (${x.doc_count})`,
                        }))
                      );
                    }, 250)}
                  />
                </div>
              )}

              {type === "sentiment" && (
                <FormToggle name="query" options={options.SENTIMENT} isMulti formProps={formProps} />
              )}

              {type === "trafficLight" && (
                <FormToggle name="query" options={options.TRAFFIC_LIGHT} isMulti formProps={formProps} />
              )}

              {type === "mediaType" && (
                <>
                  <FormSelect
                    name="query"
                    label="Media types"
                    options={mediaTypeOptions}
                    isMulti
                    formProps={formProps}
                    switchAddon={
                      <span
                        className="click textlink"
                        onClick={() => {
                          const existing = formProps.getValues()["query"] ?? [];
                          const existingValues = existing.map((x) => x.value);
                          formProps.setValue(
                            "query",
                            sort(
                              [...existing, ...mediaTypeOptions.filter((x) => !existingValues.includes(x.value))],
                              "label"
                            )
                          );
                        }}
                      >
                        Add all
                      </span>
                    }
                  />
                  {(selectedMediaTypesWithoutPrefix.includes("News") ||
                    selectedMediaTypesWithoutPrefix.includes("Print")) && (
                    <FormSelect
                      name="newsSourceRanks"
                      label="News source ranks"
                      options={options.NEWS_RANK}
                      isMulti
                      helpText={
                        <>
                          Select the ranking of News sources to include. Leave blank for all source ranks.{" "}
                          <span
                            className="click textlink"
                            onClick={async () => {
                              return await alertModal({
                                message: (
                                  <>
                                    <p>
                                      <strong>Rank 1:</strong>
                                      <br />
                                      Top international news sources
                                    </p>
                                    <p>
                                      <strong>Rank 2:</strong>
                                      <br />
                                      Top industry and regional sources
                                    </p>
                                    <p>
                                      <strong>Rank 3:</strong>
                                      <br />
                                      Industry, regional and sports sources, and press from international organisations
                                      and governmental departments
                                    </p>
                                    <p>
                                      <strong>Rank 4:</strong>
                                      <br />
                                      Regional and local news sources, press releases and wires, corporate and political
                                      party website press release pages
                                    </p>
                                    <p>
                                      <strong>Rank 5:</strong>
                                      <br />
                                      Non-news and miscellaneous sources
                                    </p>
                                  </>
                                ),
                              });
                            }}
                          >
                            More information
                          </span>
                        </>
                      }
                      formProps={formProps}
                    />
                  )}
                  {intersection(selectedMediaTypesWithoutPrefix, METABASE_SOCIAL_MEDIA_TYPES).length > 0 && (
                    <FormSelect
                      name="socialSourceRanks"
                      label="Social source ranks"
                      options={options.SOCIAL_RANK}
                      isMulti
                      helpText="Select the ranking of Social sources to include. Leave blank for all source ranks."
                      formProps={formProps}
                    />
                  )}
                </>
              )}

              {type === "language" && (
                <FormSelect name="query" options={languageOptions} isMulti formProps={formProps} />
              )}

              {type === "country" && (
                <FormSelect name="query" options={options.COUNTRY} isMulti formProps={formProps} />
              )}

              {type === "category" && (
                <FormSelect name="query" options={options.CATEGORY} isMulti formProps={formProps} />
              )}

              {type === "articleTopic" && (
                <FormSelect name="query" options={options.ARTICLE_TOPIC} isMulti formProps={formProps} />
              )}

              {type === "reach" && <FormSelect name="query" options={options.REACH} isMulti formProps={formProps} />}

              {type === "mozrank" && (
                <FormSelect name="query" options={options.MOZRANK} isMulti formProps={formProps} />
              )}

              {HAS_NUMBER_RANGE.includes(type) && (
                <Row>
                  <Col xs={6}>
                    <FormInput
                      name="queryFrom"
                      type="number"
                      min={0}
                      placeholder="From (inclusive)"
                      formProps={formProps}
                    />
                  </Col>
                  <Col xs={6}>
                    <FormInput
                      name="queryTo"
                      type="number"
                      min={0}
                      placeholder="To (inclusive)"
                      formProps={formProps}
                    />
                  </Col>
                </Row>
              )}

              {HAS_DYNAMIC_OPTIONS_FROM_PROJECTDATA.includes(type) && (
                <FormSelect
                  name="query"
                  options={dynamicOptions}
                  isMulti
                  helpText={
                    ["person", "organisation", "entityGroup"].includes(type) && !projectData.comprehendEnabled
                      ? "Stakeholder analysis is not currently enabled for this project, therefore this filter will be restricted to historical results."
                      : ""
                  }
                  formProps={formProps}
                />
              )}

              {type === "isTop" && <p>This filter includes Top Stories within an issue.</p>}
            </ModalBody>
            <ModalFooter>
              <div className="mr-auto">
                {op === "UPDATE" && (
                  <Button
                    color="danger"
                    className="mr-2"
                    onClick={async () => {
                      const ok = await confirmModal();
                      if (!ok) return;
                      deleteFilter(type, isChartFilter, false);
                      toggle();
                    }}
                  >
                    Delete
                  </Button>
                )}
                {type === "keyword" && <Button onClick={() => toggleImportModal(true)}>Import Keywords</Button>}
              </div>
              <FormSwitch
                name="active"
                inlineLabelOn="Active"
                inlineLabelOff="Disabled"
                className="mr-2"
                formProps={formProps}
              />
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                {action}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>

      {importModal && (
        <ImportModal
          filter={filter}
          projectData={projectData}
          user={user}
          toggle={(keywordsToImport) => {
            if (!keywordsToImport) return toggleImportModal();
            for (const key of ["any", "inConnectionWithAny", "inConnectionWithAll", "none"]) {
              formProps.setValue(
                key,
                (keywordsToImport[key] ?? []).map((x) => ({ value: x, label: x }))
              );
            }
            toggleImportModal();
          }}
        />
      )}
    </>
  );
};

export default FilterModal;

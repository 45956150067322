import { Auth } from "aws-amplify";
import { getUserProfile } from "../../graphql/queries";
import { gql } from "@apollo/client";

// role checks
const isAdmin = (clients, role) => clients.includes("outcider") && role === "ADMIN";
const isSuperAnalyst = (role) => role === "SUPER_ANALYST";
const isAnalyst = (role) => role === "ANALYST";
const isSubscriber = (role) => role === "SUBSCRIBER";

// perms checks
const hasAgents = (perms) => perms.includes("AGENTS");
const hasCharts = (perms) => perms.includes("CHARTS");
const hasExports = (perms) => perms.includes("EXPORTS");
const hasReports = (perms) => perms.includes("REPORTS");

// projects checks (if projects field used, otherwise all)
// NOTE: you can't have e.g. two clients assigned, all projects on one (by default), specific projects on another - the specific projects will always override, so if this field is used then each project (on each client) must be specified
const hasProject = (projects, id) => (projects.length ? projects.includes(id) : true);

// get user profile from session
export const getUser = async (apollo) => {
  let auth;
  try {
    auth = await Auth.currentSession();
  } catch (e) {
    return null; // not logged in
  }

  const id = auth.idToken.payload["cognito:username"];

  let profile = await apollo.query({
    query: gql(getUserProfile),
    variables: { id },
  });
  profile = profile.data.getUserProfile;

  const email = profile.email;
  const clients = profile.clients;
  const role = profile.role;
  const perms = profile.perms || [];
  const projects = profile.projects || [];

  let can = {
    admin: isAdmin(clients, role),

    createProject: isSuperAnalyst(role),
    readProject: (id) =>
      (isSubscriber(role) && hasProject(projects, id)) ||
      (isAnalyst(role) && hasProject(projects, id)) ||
      isSuperAnalyst(role),
    updateProject: (id) => isSuperAnalyst(role) && hasProject(projects, id),
    deleteProject: (id) => isSuperAnalyst(role) && hasProject(projects, id),

    updateResult: isAnalyst(role) || isSuperAnalyst(role),
    tagResult: isAnalyst(role) || isSuperAnalyst(role),
    commentResult: isAnalyst(role) || isSuperAnalyst(role),
    viewDeletedResults: isAnalyst(role) || isSuperAnalyst(role),

    updateIssues: isAnalyst(role) || isSuperAnalyst(role),
    viewHiddenIssues: isAnalyst(role) || isSuperAnalyst(role),

    sendAlerts: isAnalyst(role) || isSuperAnalyst(role),

    updateStakeholders: isAnalyst(role) || isSuperAnalyst(role),
    updateTags: isAnalyst(role) || isSuperAnalyst(role),

    updateDashboardCharts: isSuperAnalyst(role) && hasCharts(perms),

    // actions controlled by analyst permissions (route visible to all)
    updateCharts: (isAnalyst(role) || isSuperAnalyst(role)) && hasCharts(perms),

    // route visibility controlled by analyst permisisons
    agents: (isAnalyst(role) || isSuperAnalyst(role)) && hasAgents(perms),
    exports: (isAnalyst(role) || isSuperAnalyst(role)) && hasExports(perms),
    reports: (isAnalyst(role) || isSuperAnalyst(role)) && hasReports(perms),

    // debugging for admin users
    debug: isAdmin(clients, role),
  };

  // if admin, overwrite permissions/functions with true
  if (isAdmin(clients, role)) {
    for (const [key, value] of Object.entries(can)) {
      can[key] = typeof value === "boolean" ? true : () => true;
    }
  }

  // for testing locally...
  // can.updateResult = false;
  // can.viewDeletedResults = false;
  // can.updateIssues = false;
  // can.viewHiddenIssues = false;
  // can.updateCharts = false;
  // can.updateStakeholders = false;
  // can.updateDashboardCharts = false;

  // store user details for sentry reporting
  const sentryUser = {
    id,
    username: profile.name,
    email,
  };
  localStorage.setItem("SentryUser", JSON.stringify(sentryUser));

  return {
    ...profile,
    // add can permissions
    can,
  };
};

import { formatDateTime, sortArray } from "./utils";

import flatten from "lodash/flatten";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { numberFormat } from "humanize";

// web: mailto if it is actually an email address; social: link to profile
export const getAuthorContactLink = (result) =>
  result.source === "SOCIAL360"
    ? result.authorUrl
    : result.authorEmail.includes("@")
    ? `mailto:${result.authorEmail}`
    : result.authorEmail;

export const getResultMetadata = (result, user, output) => {
  if (!(output === "RESULT_ANALYSIS" || output === "RESULT_PDF" || output === "PUBLIC_FEED"))
    throw new Error("Invalid output.");

  let timezone = "UTC";
  let includeAiMetadata = true;
  if (user) {
    // user is not defined for PUBLIC_FEED
    // use user timezone
    timezone = user.timezone;
    // show AI metadata for admins only
    includeAiMetadata = user.role === "ADMIN";
  }

  // return: display name, field name (used in PUBLIC_FEED feed and also for custom formatting of lists/links etc), value

  // ARTICLE
  const articleMetadata = [];
  if (result.sourceType) {
    articleMetadata.push(["Media Type", "articleMediaType", result.sourceType]);
  }
  if (result.articleTopics && result.articleTopics.length > 0) {
    articleMetadata.push(["Topics", "articleTopics", sortArray(result.articleTopics)]);
  }
  if (result.language) {
    articleMetadata.push(["Language", "articleLanguage", result.language]);
  }
  if (!(isNull(result.reach) || isUndefined(result.reach)) && result.reach > 0) {
    articleMetadata.push([
      `Reach (${result.sourceType === "Print" ? "Circulation" : "Unique Visitors"})`,
      "articleReach",
      `${numberFormat(result.reach, 0)}${result.sourceType === "Print" ? ` (${result.reachFrequency})` : ""}`,
    ]);
  }
  if (!(isNull(result.mozrank) || isUndefined(result.mozrank)) && result.mozrank > 0) {
    articleMetadata.push(["Mozrank", "articleMozrank", numberFormat(result.mozrank, 0)]);
  }
  if (!(isNull(result.mediaValue) || isUndefined(result.mediaValue)) && result.mediaValue > 0) {
    articleMetadata.push([
      "Media Value",
      "articleMediaValue",
      `${numberFormat(result.mediaValue, 0)} ${result.mediaValueCurrency}`,
    ]);
  }
  if (output !== "PUBLIC_FEED") {
    if (result.outboundUrls && result.outboundUrls.length > 0) {
      articleMetadata.push(["Outbound Links", "articleOutboundLinks", result.outboundUrls]);
    }
  }
  if (result.loginStatus) {
    articleMetadata.push(["Login Status", "articleLoginStatus", result.loginStatus]);
  }
  if (result.copyright) {
    articleMetadata.push(["Copyright", "articleCopyright", result.copyright]);
  }

  // SOCIAL
  const socialMetadata = [];
  if (!(isNull(result.socialEngagement) || isUndefined(result.socialEngagement))) {
    socialMetadata.push(["Engagement", "socialEngagement", numberFormat(result.socialEngagement, 0)]);
  }
  if (!(isNull(result.socialReactions) || isUndefined(result.socialReactions))) {
    socialMetadata.push(["Reactions", "socialReactions", numberFormat(result.socialReactions, 0)]);
  }
  if (!(isNull(result.socialComments) || isUndefined(result.socialComments))) {
    socialMetadata.push(["Comments", "socialComments", numberFormat(result.socialComments, 0)]);
  }
  if (!(isNull(result.socialShares) || isUndefined(result.socialShares))) {
    socialMetadata.push(["Shares", "socialShares", numberFormat(result.socialShares, 0)]);
  }
  if (!(isNull(result.socialViews) || isUndefined(result.socialViews))) {
    socialMetadata.push(["Views", "socialViews", numberFormat(result.socialViews, 0)]);
  }

  // SOURCE
  const sourceMetadata = [];
  if (result.sourceName) {
    sourceMetadata.push(["Name", "sourceName", result.sourceName]);
  }
  if (result.sourceUrl) {
    sourceMetadata.push(["Domain Name", "sourceDomainName", result.sourceUrl]);
  }
  if (result.sourceCategory) {
    sourceMetadata.push(["Category", "sourceCategory", result.sourceCategory]);
  }
  if (result.sourceRegion) {
    sourceMetadata.push(["Region", "sourceRegion", result.sourceRegion]);
  }
  if (result.sourceCountry) {
    sourceMetadata.push(["Country", "sourceCountry", result.sourceCountry]);
  }
  if (result.sourceRank) {
    sourceMetadata.push(["Rank", "sourceRank", result.sourceRank]);
  }
  if (result.sourceFeedGenre) {
    sourceMetadata.push(["Genre", "sourceGenre", result.sourceFeedGenre]);
  }
  if (!(isNull(result.sourceFeedAutoRank) || isUndefined(result.sourceFeedAutoRank)) && result.sourceFeedAutoRank > 0) {
    sourceMetadata.push(["Auto Rank", "sourceAutoRank", result.sourceFeedAutoRank]);
  }
  if (
    !(isNull(result.sourceFeedAutoRankOrder) || isUndefined(result.sourceFeedAutoRankOrder)) &&
    result.sourceFeedAutoRankOrder > 0
  ) {
    sourceMetadata.push(["Auto Rank Order", "sourceAutoRankOrder", result.sourceFeedAutoRankOrder]);
  }
  if (result.sourceFeedAutoTopics && result.sourceFeedAutoTopics.length > 0) {
    sourceMetadata.push(["Auto Topics", "sourceAutoTopics", sortArray(result.sourceFeedAutoTopics)]);
  }
  if (result.sourceFeedEditorialTopics) {
    sourceMetadata.push(["Editorial Topics", "sourceEditorialTopics", result.sourceFeedEditorialTopics]);
  }
  if (
    !(isNull(result.sourceFeedInboundLinkCount) || isUndefined(result.sourceFeedInboundLinkCount)) &&
    result.sourceFeedInboundLinkCount > 0
  ) {
    sourceMetadata.push(["Inbound Links", "sourceInboundLinkCount", result.sourceFeedInboundLinkCount]);
  }

  // AUTHOR
  const authorMetadata = [];
  if (result.authorName) {
    authorMetadata.push(["Name", "authorName", result.authorName]);
  }
  if (result.authorEmail) {
    // web: email, social: handle
    authorMetadata.push(["Contact", "authorContact", result.authorEmail]);
  }
  if (!(isNull(result.authorPosts) || isUndefined(result.authorPosts))) {
    authorMetadata.push(["Posts", "authorPosts", numberFormat(result.authorPosts, 0)]);
  }
  if (!(isNull(result.authorFollowers) || isUndefined(result.authorFollowers))) {
    authorMetadata.push(["Followers", "authorFollowers", numberFormat(result.authorFollowers, 0)]);
  }
  if (!(isNull(result.authorFollowing) || isUndefined(result.authorFollowing))) {
    authorMetadata.push(["Following", "authorFollowing", numberFormat(result.authorFollowing, 0)]);
  }
  if (result.authorGroups && result.authorGroups.length > 0) {
    authorMetadata.push(["Groups", "authorGroups", sortArray(result.authorGroups)]);
  }

  // AI ANALYST
  const aiMetadata = [];
  if (includeAiMetadata) {
    // only show in PUBLIC_FEED as on Comments tab in UI
    if (output === "PUBLIC_FEED") {
      if (result.aiSummary) {
        aiMetadata.push(["Summary", "autoAnalystSummary", result.aiSummary]);
      }
    }
    if (result.aiExcerpt) {
      aiMetadata.push(["Excerpt", "autoAnalystExcerpt", result.aiExcerpt]);
    }
    if (result.aiAnalysis) {
      aiMetadata.push(["Analysis", "autoAnalystAnalysis", result.aiAnalysis]);
    }
    if (result.aiRecommendations && result.aiRecommendations.length > 0) {
      aiMetadata.push(["Recommendations", "autoAnalystRecommendations", result.aiRecommendations]);
    }
  }

  // DATES
  const datesMetadata = [];
  if (output !== "PUBLIC_FEED") {
    datesMetadata.push(["Created Date (UTC)", "createdDate", formatDateTime(result.createdDate)]);
    if (timezone !== "UTC") {
      datesMetadata.push([
        `Created Date (${timezone})`,
        "createdDateLocal",
        formatDateTime(result.createdDate, timezone),
      ]);
    }
    datesMetadata.push(["Published Date (UTC)", "publishedDate", formatDateTime(result.publishedDate)]);
    if (timezone !== "UTC") {
      datesMetadata.push([
        `Published Date (${timezone})`,
        "publishedDateLocal",
        formatDateTime(result.publishedDate, timezone),
      ]);
    }
  }

  // group into sections
  let metadata = [];
  if (articleMetadata.length > 0) metadata.push(["Article", articleMetadata]);
  if (socialMetadata.length > 0) metadata.push(["Social", socialMetadata]);
  if (sourceMetadata.length > 0) metadata.push(["Source", sourceMetadata]);
  if (authorMetadata.length > 0) metadata.push(["Author", authorMetadata]);
  if (aiMetadata.length > 0) metadata.push(["Auto Analyst", aiMetadata]);
  if (datesMetadata.length > 0) metadata.push(["Dates", datesMetadata]);

  // for public feed, flatten to get values in each section
  if (output === "PUBLIC_FEED") {
    metadata = flatten(metadata.map((x) => x[1]));
  }

  return metadata;
};

import * as options from "../lib/options";

import { Col, Row } from "reactstrap";
import React, { useContext } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import { Form } from "reactstrap";
import FormCreatable from "../Forms/FormCreatable";
import FormToggle from "../Forms/FormToggle";
import camelCase from "lodash/camelCase";
import { fnTagResult } from "../../graphql/mutations";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Toggle = ({ result, user, type, label, options }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();
  const [mutationFnTagResult] = useMutation(gql(fnTagResult));

  const key = camelCase(type); // also field name
  const defaultValues = { [key]: result[key] };
  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(defaultValues, {
      selects: [[key, options]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // create/update sentiment/traffic
      const input = await processForm({ clientId, projectId, id: result.id, op: type, ...values }, { selects: [key] });
      const updatedResult = await mutationFnTagResult({ variables: { input } });
      // apply sentiment changes across any cached results in this duplicate group, excluding this one
      if (type === "SENTIMENT") {
        const cachedResultsInDuplicateGroup = Object.values(apollo.cache.extract()).filter(
          (x) =>
            x.__typename === "Result" &&
            x.clientId === clientId &&
            x.projectId === projectId &&
            x.id !== result.id &&
            x.duplicateGroup === result.duplicateGroup
        );
        // update with returned tags
        for (const cachedResult of cachedResultsInDuplicateGroup) {
          apollo.cache.modify({
            id: apollo.cache.identify(cachedResult),
            fields: {
              [key]: () => updatedResult.data.fnTagResult[key],
            },
            // do not update any queries, cache update is enough, results are loaded when opened
            broadcast: false,
          });
        }
      }
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Form>
      <FormToggle
        name={camelCase(type)}
        label={label}
        options={options}
        onChange={handleSubmit(onSubmit)}
        isDisabled={!user.can.tagResult || isSubmitting}
        formProps={formProps}
      />
    </Form>
  );
};

const Tag = ({ result, user, type, label, name, tags }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();
  const [mutationFnTagResult] = useMutation(gql(fnTagResult));

  const options = tags.filter((x) => x.type === type).map((x) => ({ value: x.id, label: x.name }));

  const key = `${type.toLowerCase()}s`;
  const defaultValues = { tags: result[key] };
  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(defaultValues, {
      selectMultis: [["tags", options]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // create/update tags
      const input = await processForm(
        { clientId, projectId, id: result.id, op: "TAG", tagType: type, ...values },
        { selectMultis: ["tags"] }
      );
      const updatedResult = await mutationFnTagResult({
        variables: { input },
        refetchQueries: ["QueryTags"],
        awaitRefetchQueries: true,
      });
      const updatedTags = updatedResult.data.fnTagResult[key];
      // apply tag changes across any cached results in this duplicate group, excluding this one
      const cachedResultsInDuplicateGroup = Object.values(apollo.cache.extract()).filter(
        (x) =>
          x.__typename === "Result" &&
          x.clientId === clientId &&
          x.projectId === projectId &&
          x.id !== result.id &&
          x.duplicateGroup === result.duplicateGroup
      );
      // update with returned tags
      for (const cachedResult of cachedResultsInDuplicateGroup) {
        apollo.cache.modify({
          id: apollo.cache.identify(cachedResult),
          fields: {
            [key]: () => updatedTags,
          },
          // do not update any queries, cache update is enough, results are loaded when opened
          broadcast: false,
        });
      }
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Form>
      <FormCreatable
        name="tags"
        label={label}
        isLoading={isSubmitting}
        options={options}
        placeholder={`Select or create new ${name} tag...`}
        type={name}
        onChange={handleSubmit(onSubmit)}
        isDisabled={!user.can.tagResult || isSubmitting}
        formProps={formProps}
      />
    </Form>
  );
};

const ResultPanelTags = ({ result, user, projectData }) => (
  <>
    <Row>
      <Col>
        <Toggle result={result} user={user} type="SENTIMENT" label="Sentiment" options={options.SENTIMENT} />
      </Col>
      <Col>
        <Toggle
          result={result}
          user={user}
          type="TRAFFIC_LIGHT"
          label="Traffic Light"
          options={options.TRAFFIC_LIGHT}
        />
      </Col>
    </Row>
    <Tag result={result} user={user} type="TOPIC" label="Topics" name="topic" tags={projectData.tags} />
    <Tag result={result} user={user} type="CONCERN" label="Key Concerns" name="key concern" tags={projectData.tags} />
  </>
);

export default ResultPanelTags;

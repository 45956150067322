import React, { useState } from "react";

import { Button } from "reactstrap";
import { fnIntercomToggleTooltips } from "../graphql/queries";
import { gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client";

const IntercomToggleTooltips = ({ user }) => {
  const apollo = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await apollo.query({
        query: gql(fnIntercomToggleTooltips),
      });
    } catch (e) {
      setLoading(false);
    } finally {
      // full refresh needed to pickup changes
      window.location.reload();
    }
  };

  return (
    <Button color="link" className="nav-link nav-intercom-toggle" onClick={handleClick} disabled={loading}>
      <i className="uil-map-marker-question" style={{ fontSize: 20 }} />
    </Button>
  );
};

export default IntercomToggleTooltips;

import { useHistory, useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import React from "react";

const ReportTitle = ({ report, toggleModal, children }) => {
  const history = useHistory();
  const { clientId, projectId } = useParams();

  return (
    <PageTitle
      title={
        <>
          <span className="click" onClick={() => history.push(`/${clientId}/${projectId}/reports`)}>
            Reports
          </span>
          {report && (
            <>
              <span className="text-title">
                <i className="uil-angle-right ml-1 mr-1" />
                {report.name}
              </span>
              {toggleModal && (
                <i
                  className="mdi mdi-pencil-outline click ml-1"
                  style={{ opacity: 0.5 }}
                  onClick={() => toggleModal({ data: report, op: "UPDATE" })}
                />
              )}
            </>
          )}
        </>
      }
    >
      {children}
    </PageTitle>
  );
};

export default ReportTitle;

import { FormGroup, Label } from "reactstrap";
import React, { useCallback } from "react";

import { Controller } from "react-hook-form";
import FormError from "./FormError";
import ReactDOM from "react-dom";
import RichTextEditor from "react-rte";
import classNames from "classnames";

const TOOLBAR_CONFIG = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    // use built in underline style for highlighting
    { label: "Highlight", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "Bulleted list", style: "unordered-list-item" },
    { label: "Numbered list", style: "ordered-list-item" },
  ],
};

const STYLE_MAP = {
  UNDERLINE: {
    borderBottom: "2px solid #67B617",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
  },
};

const FormEditor = ({
  name,
  label,
  required,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  onChange,
  formProps,
  ...rest
}) => {
  // hack to change underline icon to highlight
  const ref = useCallback((el) => {
    if (el) {
      let node = ReactDOM.findDOMNode(el).querySelector('button[title="Highlight"]');
      node.innerHTML = '<span class="form-editor__highlight"><i class="uil-pen" /></span>';
    }
  }, []);

  return (
    <FormGroup
      className={classNames({
        "form-error": formProps.errors[name],
        [`${className}`]: className,
      })}
    >
      {label && (
        <Label for={name}>
          {label}: {required && "*"}
        </Label>
      )}
      <Controller
        name={name}
        control={formProps.control}
        rules={{ required }}
        defaultValue={null}
        render={(props) => (
          <RichTextEditor
            ref={ref}
            className="form-editor"
            value={props.value ?? RichTextEditor.createEmptyValue()}
            toolbarConfig={TOOLBAR_CONFIG}
            customStyleMap={STYLE_MAP}
            toolbarClassName="form-editor__toolbar"
            editorClassName="form-editor__editor"
            stripPastedStyles={true}
            onChange={(e) => {
              props.onChange(e); // react-hook-form onChange
              onChange && onChange(e); // custom onChange if provided
            }}
            {...rest}
          />
        )}
      />
      {!hideErrors && (
        <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
      )}
    </FormGroup>
  );
};

export default FormEditor;

module.exports=`<!--[if mso | IE]>
<table border="0" cellpadding="0" cellspacing="0" width="190" height="132" align="{{imageAlign}}" style="width:190px;height:132px;font-size:0;line-height:0;"><tr><td style="text-align:{{imageAlign}};padding-top:2px;padding-bottom:10px;font-size:0;line-height:0;">
<![endif]-->
<a href="{{url}}">
  <img
    class="{{imageClass}}"
    align="{{imageAlign}}"
    src="{{thumborImage}}"
    width="180"
    height="120"
    alt="{{title}}"
  />
</a>
<!--[if mso | IE]>
</td></tr></table>
<![endif]-->
`
import * as options from "../lib/options";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useContext, useState } from "react";
import { fnPreviewAgent, fnUpdateAgent } from "../../graphql/mutations";
import { formatDateTime, sort } from "../../lib/utils";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import FormCreatable from "../Forms/FormCreatable";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import { METABASE_SOCIAL_MEDIA_TYPES } from "../../lib/constants";
import NavigationPromptModal from "../Modals/NavigationPromptModal";
import ResultPanelAnalysis from "../Results/ResultPanelAnalysis";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import intersection from "lodash/intersection";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { numberFormat } from "humanize";
import omit from "lodash/omit";
import { useForm } from "react-hook-form";

const AnalysisModal = ({ data, toggle, user }) => (
  <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
    <ModalHeader toggle={() => toggle()}>Result Analysis</ModalHeader>
    <ModalBody>
      <ResultPanelAnalysis result={data} user={user} />
    </ModalBody>
  </Modal>
);

const Agent = ({ agent, project, user }) => {
  const appContext = useContext(AppContext);
  const [submitting, setSubmitting] = useState(null);
  const [analysisModal, toggleAnalysisModal] = useState(null);
  const alertModal = createConfirmation(AlertModal);
  const [activeTab, setActiveTab] = useState("settings");

  const [mutationFnPreviewAgent, mutationFnPreviewAgentStatus] = useMutation(gql(fnPreviewAgent));
  const [mutationFnUpdateAgent] = useMutation(gql(fnUpdateAgent));

  const [previewSubmitting, setPreviewSubmitting] = useState(null);

  const defaultValue = { ...agent };

  // add social options if ever been used
  let mediaTypeHelpText =
    "Select the media types you would like to search. News sources are refreshed every 2 minutes.";
  let mediaTypeOptions = [
    {
      label: "News Sources",
      options: options.MEDIA_TYPE_METABASE,
    },
  ];
  if (project.socialUsed) {
    mediaTypeOptions.push({
      label: "Social Influencer Sources",
      options: options.MEDIA_TYPE_SOCIAL360,
    });
    if (project.socialEnabled)
      mediaTypeHelpText = `${mediaTypeHelpText} Social Influencer sources are refreshed every 15 minutes.`;
  }

  const formConfig = {
    fields: ["name"],
    dates: ["metabaseSearchBackfillDate", "socialBackfillDate"],
    selectMultis: [
      ["mediaTypes", mediaTypeOptions, true],
      ["newsSourceRanks", options.NEWS_RANK],
      ["socialSourceRanks", options.SOCIAL_RANK],
      ["sourceCategories", options.CATEGORY],
      ["articleTopics", options.ARTICLE_TOPIC],
      ["languages", options.LANGUAGE],
      ["countries", options.COUNTRY],
      ["socialLanguages", options.SOCIAL360_LANGUAGE],
    ],
    creatables: ["any", "inConnectionWithAny", "inConnectionWithAll", "none", "sourceNames", "sourceDomains"],
    switches: [
      ["sourceNamesInclude", true],
      ["sourceDomainsInclude", true],
      ["sourceCategoriesInclude", true],
      ["articleTopicsInclude", true],
    ],
  };

  const { register, handleSubmit, getValues, control, errors, formState, trigger, setValue, watch, reset } = useForm({
    defaultValues: populateForm(defaultValue, formConfig),
    // needed for NavigationPromptModal isDirty check to work
    shouldUnregister: false,
  });
  const { isSubmitting, isDirty } = formState;
  const formProps = { errors, register, control, setValue, getValues };

  const minDateMetabase = moment.utc().subtract(100, "days").format("YYYY-MM-DD");
  const minDateSocial = moment.utc().subtract(30, "days").format("YYYY-MM-DD");
  const maxDate = moment.utc().format("YYYY-MM-DD");

  const watchMediaTypes = watch("mediaTypes");
  const selectedMediaTypes = watchMediaTypes ? watchMediaTypes.map((x) => x.value) : [];

  const selectedMediaTypesMetabase = selectedMediaTypes.filter((x) => x.startsWith("metabase-"));
  const selectedMediaTypesIncludeMetabase = selectedMediaTypesMetabase.length > 0;
  const selectedMediaTypesMetabaseWithoutPrefix = selectedMediaTypesMetabase.map((x) => x.substr(9));

  const selectedMediaTypesSocial = selectedMediaTypes.filter((x) => x.startsWith("social-"));
  const selectedMediaTypesIncludeSocial = selectedMediaTypesSocial.length > 0;
  // const selectedMediaTypesSocialWithoutPrefix = selectedMediaTypesSocial.map((x) => x.substr(7));

  let tabs = [
    {
      id: "settings",
      title: "Settings",
    },
  ];
  if (selectedMediaTypesIncludeMetabase)
    tabs.push({
      id: "web",
      title: "News Sources",
    });
  if (selectedMediaTypesIncludeSocial)
    tabs.push({
      id: "social",
      title: "Social Influencer Sources",
    });

  const creatables = ["any", "inConnectionWithAny", "inConnectionWithAll", "none", "sourceNames"];
  const creatablesLowercase = ["sourceDomains"];

  const onPreview = (source) => async () => {
    if (isSubmitting) return;
    setPreviewSubmitting(source);
    const valid = await trigger();
    if (!valid) return;
    const values = getValues();
    try {
      const omitValues = ["name", "metabaseSearchBackfillDate", "socialBackfillDate"];
      const processFormConfig = {
        selectMultis: [
          "mediaTypes",
          "newsSourceRanks",
          "socialSourceRanks",
          "sourceCategories",
          "articleTopics",
          "languages",
          "countries",
          "socialLanguages",
        ],
        creatables,
        creatablesLowercase,
      };

      const input = await processForm(omit(values, omitValues), processFormConfig);
      await mutationFnPreviewAgent({ variables: { input: { ...input, source } } });
    } catch (e) {
      appContext.handleError(e);
    }
    setPreviewSubmitting(null);
  };

  const onSubmit = (activate) => async (values) => {
    let res, updatedAgent;
    setSubmitting(activate);
    try {
      const ids = { clientId: agent.clientId, projectId: agent.projectId, id: agent.id };

      const processFormConfig = {
        dates: ["metabaseSearchBackfillDate", "socialBackfillDate"],
        selectMultis: [
          "mediaTypes",
          "newsSourceRanks",
          "socialSourceRanks",
          "sourceCategories",
          "articleTopics",
          "languages",
          "countries",
          "socialLanguages",
        ],
        creatables,
        creatablesLowercase,
      };

      // save changes (to agent and metafilter if it already exists)
      const input = await processForm({ ...ids, ...values }, processFormConfig);
      res = await mutationFnUpdateAgent({
        variables: {
          input: {
            ...input,
            activate,
          },
        },
      });
      updatedAgent = res.data.fnUpdateAgent;
    } catch (e) {
      appContext.handleError(e);
    } finally {
      setSubmitting(null);
      // reset form with updated agent so that isDirty becomes false again
      const resetValues = populateForm(updatedAgent, formConfig);
      reset(resetValues);
    }
  };

  // no options, remove arrow
  const noOptionsFieldProps = {
    options: [],
    noOptionsMessage: () => null,
    classNamePrefix: "react-select--no-options",
  };

  return (
    <>
      <NavigationPromptModal
        when={isDirty}
        message="This search agent has unsaved changes which will be lost. Are you sure you want to change page?"
      />

      <Col xs={5} xl={5} className="scroll">
        <Form className="h-100">
          <Card className="mb-0 h-100">
            <CardHeader>
              <CardTitle tag="h4" className="mt-1 mb-1">
                {agent.name}
              </CardTitle>
            </CardHeader>

            <Nav tabs className="nav-bordered pl-3 pr-3">
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <NavLink
                    href="#"
                    className={classNames({ active: activeTab === tab.id })}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <CardBody className="scroll pb-2">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="settings">
                  <FormInput name="name" label="Name" required formProps={formProps} />
                  <hr />

                  <FormSelect
                    name="mediaTypes"
                    label="Media types"
                    className="mb-0"
                    helpTextClassName="mb-0"
                    options={mediaTypeOptions}
                    required
                    isMulti
                    helpText={mediaTypeHelpText}
                    formProps={formProps}
                    switchAddon={
                      <>
                        {project.socialUsed ? (
                          <>
                            Add all:{" "}
                            <span
                              className="click textlink"
                              onClick={() => {
                                const existing = formProps.getValues()["mediaTypes"] ?? [];
                                const existingValues = existing.map((x) => x.value);
                                formProps.setValue(
                                  "mediaTypes",
                                  sort(
                                    [
                                      ...existing,
                                      ...options.MEDIA_TYPE_METABASE.filter((x) => !existingValues.includes(x.value)),
                                    ],
                                    "label"
                                  )
                                );
                              }}
                            >
                              News
                            </span>
                            {" | "}
                            <span
                              className="click textlink"
                              onClick={() => {
                                const existing = formProps.getValues()["mediaTypes"] ?? [];
                                const existingValues = existing.map((x) => x.value);
                                formProps.setValue(
                                  "mediaTypes",
                                  sort(
                                    [
                                      ...existing,
                                      ...options.MEDIA_TYPE_SOCIAL360.filter((x) => !existingValues.includes(x.value)),
                                    ],
                                    "label"
                                  )
                                );
                              }}
                            >
                              Social Influencer
                            </span>
                          </>
                        ) : (
                          <span
                            className="click textlink"
                            onClick={() => {
                              const existing = formProps.getValues()["mediaTypes"] ?? [];
                              const existingValues = existing.map((x) => x.value);
                              formProps.setValue(
                                "mediaTypes",
                                sort(
                                  [
                                    ...existing,
                                    ...options.MEDIA_TYPE_METABASE.filter((x) => !existingValues.includes(x.value)),
                                  ],
                                  "label"
                                )
                              );
                            }}
                          >
                            Add all
                          </span>
                        )}
                      </>
                    }
                  />
                  {project.socialUsed && !project.socialEnabled && (
                    <p className="invalid-feedback text-muted d-block mb-0 mt-1">
                      Social Influencer results are not currently enabled for this project, therefore this agent will be
                      restricted to historical social results.
                    </p>
                  )}
                  <hr />

                  <FormCreatable
                    name="any"
                    label="Any of these terms"
                    type="keyword"
                    required
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormCreatable
                    name="inConnectionWithAny"
                    label="In connection with any of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormCreatable
                    name="inConnectionWithAll"
                    label="In connection with all of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />
                  <FormCreatable
                    name="none"
                    label="None of these terms"
                    type="keyword"
                    showEditModal
                    {...noOptionsFieldProps}
                    formProps={formProps}
                  />

                  {selectedMediaTypesIncludeMetabase && agent.isDraft && (
                    <>
                      <hr />
                      <FormInput
                        name="metabaseSearchBackfillDate"
                        label="Start date for news sources"
                        type="date"
                        min={minDateMetabase}
                        max={maxDate}
                        helpText="Select a start date from the last 100 days. This cannot be changed once the agent is activated."
                        formProps={formProps}
                      />
                    </>
                  )}

                  {selectedMediaTypesIncludeSocial && agent.isDraft && (
                    <>
                      <hr />
                      <FormInput
                        name="socialBackfillDate"
                        label="Start date for social influencer sources"
                        type="date"
                        min={minDateSocial}
                        max={maxDate}
                        helpText="Select a start date from the last 30 days. This cannot be changed once the agent is activated."
                        formProps={formProps}
                      />
                    </>
                  )}
                </TabPane>

                {selectedMediaTypesIncludeMetabase && (
                  <TabPane tabId="web">
                    <FormSelect
                      name="languages"
                      label="Languages"
                      options={options.LANGUAGE}
                      isMulti
                      helpText="Select the languages of content you would like to search. Leave blank for all languages."
                      formProps={formProps}
                    />

                    {(selectedMediaTypesMetabaseWithoutPrefix.includes("News") ||
                      selectedMediaTypesMetabaseWithoutPrefix.includes("Print")) && (
                      <FormSelect
                        name="newsSourceRanks"
                        label="News source ranks"
                        options={options.NEWS_RANK}
                        isMulti
                        helpText={
                          <>
                            Select the ranking of News sources to include. Leave blank for all source ranks.{" "}
                            <span
                              className="click textlink"
                              onClick={async () => {
                                return await alertModal({
                                  message: (
                                    <>
                                      <p>
                                        <strong>Rank 1:</strong>
                                        <br />
                                        Top international news sources
                                      </p>
                                      <p>
                                        <strong>Rank 2:</strong>
                                        <br />
                                        Top industry and regional sources
                                      </p>
                                      <p>
                                        <strong>Rank 3:</strong>
                                        <br />
                                        Industry, regional and sports sources, and press from international
                                        organisations and governmental departments
                                      </p>
                                      <p>
                                        <strong>Rank 4:</strong>
                                        <br />
                                        Regional and local news sources, press releases and wires, corporate and
                                        political party website press release pages
                                      </p>
                                      <p>
                                        <strong>Rank 5:</strong>
                                        <br />
                                        Non-news and miscellaneous sources
                                      </p>
                                    </>
                                  ),
                                });
                              }}
                            >
                              More information
                            </span>
                          </>
                        }
                        formProps={formProps}
                      />
                    )}
                    {intersection(selectedMediaTypesMetabaseWithoutPrefix, METABASE_SOCIAL_MEDIA_TYPES).length > 0 && (
                      <FormSelect
                        name="socialSourceRanks"
                        label="Social source ranks"
                        options={options.SOCIAL_RANK}
                        isMulti
                        helpText="Select the ranking of Social sources to include. Leave blank for all source ranks."
                        formProps={formProps}
                      />
                    )}
                    <hr />
                    <FormCreatable
                      name="sourceNames"
                      label="Source names"
                      type="source name"
                      helpText="Enter the source names of sources to include/exclude. Leave blank to include all sources."
                      showEditModal
                      {...noOptionsFieldProps}
                      formProps={formProps}
                      switchAddon={
                        <FormSwitch
                          name="sourceNamesInclude"
                          className="mb-0"
                          inlineLabelOn="Include"
                          inlineLabelOff="Exclude"
                          oneLine
                          formProps={formProps}
                        />
                      }
                    />
                    <FormCreatable
                      name="sourceDomains"
                      label="Source domain names"
                      type="domain name"
                      helpText="Enter the domain names of sources to include/exclude. Leave blank to include all sources."
                      showEditModal
                      {...noOptionsFieldProps}
                      formProps={formProps}
                      switchAddon={
                        <FormSwitch
                          name="sourceDomainsInclude"
                          className="mb-0"
                          inlineLabelOn="Include"
                          inlineLabelOff="Exclude"
                          oneLine
                          formProps={formProps}
                        />
                      }
                    />
                    <FormSelect
                      name="sourceCategories"
                      label="Source categories"
                      options={options.CATEGORY}
                      isMulti
                      helpText="Select the categories of source you would like to search. Leave blank for all categories."
                      formProps={formProps}
                      switchAddon={
                        <FormSwitch
                          name="sourceCategoriesInclude"
                          className="mb-0"
                          inlineLabelOn="Include"
                          inlineLabelOff="Exclude"
                          oneLine
                          formProps={formProps}
                        />
                      }
                    />
                    <FormSelect
                      name="countries"
                      label="Source countries"
                      options={options.COUNTRY}
                      isMulti
                      helpText="Select the countries of origin you would like to search. Leave blank for all countries."
                      formProps={formProps}
                    />
                    <hr />
                    <FormSelect
                      name="articleTopics"
                      label="Topics"
                      options={options.ARTICLE_TOPIC}
                      isMulti
                      helpText="Select the topics of content you would like to search. Leave blank for all topics."
                      formProps={formProps}
                      switchAddon={
                        <FormSwitch
                          name="articleTopicsInclude"
                          className="mb-0"
                          inlineLabelOn="Include"
                          inlineLabelOff="Exclude"
                          oneLine
                          formProps={formProps}
                        />
                      }
                    />
                  </TabPane>
                )}

                {selectedMediaTypesIncludeSocial && (
                  <TabPane tabId="social">
                    <FormSelect
                      name="socialLanguages"
                      label="Languages"
                      options={options.SOCIAL360_LANGUAGE}
                      isMulti
                      helpText="Select the languages of content you would like to search. Leave blank for all languages."
                      formProps={formProps}
                    />
                  </TabPane>
                )}
              </TabContent>
            </CardBody>
            <CardFooter className="d-flex">
              <div className="mr-auto">
                {selectedMediaTypesIncludeMetabase && (
                  <Button
                    disabled={isSubmitting || mutationFnPreviewAgentStatus.loading}
                    onClick={onPreview("METABASE")}
                  >
                    {previewSubmitting === "METABASE" ? (
                      <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
                    ) : (
                      <i className="uil-file-search-alt mr-1" />
                    )}
                    Preview News
                  </Button>
                )}
                {selectedMediaTypesIncludeSocial && (
                  <Button
                    disabled={isSubmitting || mutationFnPreviewAgentStatus.loading}
                    onClick={onPreview("SOCIAL360")}
                    className={classNames({ "ml-1": selectedMediaTypesIncludeMetabase })}
                  >
                    {previewSubmitting === "SOCIAL360" ? (
                      <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
                    ) : (
                      <i className="uil-file-search-alt mr-1" />
                    )}
                    Preview Social
                  </Button>
                )}
              </div>
              <div className="text-right">
                <Button
                  color={agent.isDraft ? "secondary" : "primary"}
                  className="ml-1"
                  disabled={isSubmitting || mutationFnPreviewAgentStatus.loading}
                  onClick={handleSubmit(onSubmit(false))}
                >
                  {submitting === false ? (
                    <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
                  ) : (
                    <i className="uil-check mr-1" />
                  )}
                  Save
                </Button>
                {agent.isDraft && (
                  <Button
                    color="primary"
                    className="ml-1"
                    disabled={isSubmitting || mutationFnPreviewAgentStatus.loading}
                    onClick={handleSubmit(onSubmit(true))}
                  >
                    {submitting === true ? (
                      <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
                    ) : (
                      <i className="uil-check-square mr-1" />
                    )}
                    Activate
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
      <Col xs={4} xl={5} className="scroll">
        {mutationFnPreviewAgentStatus.data ? (
          <Card className="mb-0 h-100">
            <CardHeader>
              <CardTitle tag="h4" className="mt-1">
                Preview
              </CardTitle>
              <CardSubtitle className="float-left clear-left">
                {mutationFnPreviewAgentStatus.data.fnPreviewAgent.total > 0 && (
                  <>Showing 50 most recent results of a total of </>
                )}
                {numberFormat(mutationFnPreviewAgentStatus.data.fnPreviewAgent.total, 0)} results found over the last{" "}
                {mutationFnPreviewAgentStatus.data.fnPreviewAgent.source === "METABASE" ? 100 : 30} days.
              </CardSubtitle>
            </CardHeader>
            <CardBody className="scroll results-preview">
              {mutationFnPreviewAgentStatus.data.fnPreviewAgent.results.map((result, i) => (
                <div key={i} className="results-preview-item">
                  <p className="mb-1">
                    <a href={result.url} target="_new" className="textlink">
                      {result.title || "No Title"}
                    </a>
                  </p>
                  <p className="mb-1">{result.content100 || "No article excerpt available."}</p>
                  <p className="mb-0">
                    {formatDateTime(result.publishedDate, user.timezone)} | {result.sourceName} | {result.sourceType}
                    <i className="uil-info-circle click ml-1" onClick={() => toggleAnalysisModal(result)} />
                  </p>
                </div>
              ))}
            </CardBody>
          </Card>
        ) : (
          <Card body className="mb-0 h-100 card-prompt">
            <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
              <p className="mb-0 text-center">
                <i className="uil-file-search-alt font-24 d-block" />
                Click <strong>Preview</strong> to preview this agent.
              </p>
            </div>
          </Card>
        )}
      </Col>

      {analysisModal && <AnalysisModal data={analysisModal} toggle={toggleAnalysisModal} user={user} />}
    </>
  );
};

export default Agent;

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative"> 
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  {{#if (or title @root.switches.isBuilder)}}
    <mj-section mj-class="p-0">
      <mj-column>
        <mj-text mj-class="block-title">{{#if title}}{{title}}{{else}}[{{type}}]{{/if}}</mj-text>
      </mj-column>
    </mj-section>
  {{/if}}
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->

  {{> imageHeader}}
  {{> chartHeader}}

  {{#each results}}
    <mj-section mj-class="{{#if @last}}p-0{{else}}p-0-pb-10{{/if}}" css-class="report-section-with-columns report-builder--relative report-builder--hover">
      {{#if @root.switches.isBuilder}}
        {{> builderResultOrdering}}
        {{> builderResultActions}}
      {{/if}}
      <mj-column mj-class="width-65">
        <mj-text mj-class="result-content-small" css-class="report-focus-left">
          {{{this.excerpt}}}
        </mj-text>
      </mj-column>
      <mj-column mj-class="width-35">
        <mj-text mj-class="result-title-small" css-class="report-focus-right">
          <a href="{{url}}" class="report-link" target="_blank">{{title}}</a>
        </mj-text>
        <mj-text mj-class="result-meta-small" css-class="report-focus-right{{#unless @last}} report-focus-right-meta{{/unless}}">{{> resultMeta multiline=true}}</mj-text>
      </mj-column>
    </mj-section>
  {{/each}}
</mj-wrapper>`
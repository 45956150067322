import { useEffect, useRef } from "react";

import { useMediaQuery } from "react-responsive";

// add/remove body class for fixed layout
export const bodyFixedEffect = () => {
  if (document.body) document.body.classList.add("fixed");
  return () => {
    if (document.body) document.body.classList.remove("fixed");
  };
};

export const bodyFixedLgEffect = () => {
  if (document.body) document.body.classList.add("fixed--lg");
  return () => {
    if (document.body) document.body.classList.remove("fixed--lg");
  };
};

export const useMediaBreakpointDownSm = () => useMediaQuery({ maxWidth: 576 });
export const useMediaBreakpointDownMd = () => useMediaQuery({ maxWidth: 768 });
export const useMediaBreakpointDownLg = () => useMediaQuery({ maxWidth: 992 });
export const useMediaBreakpointDownLgXl = () => useMediaQuery({ maxWidth: 1178 });
export const useMediaBreakpointDownXl = () => useMediaQuery({ maxWidth: 1367 });

// get results across all pages (needs merge function implemented in cache)
export const fetchAllEffect = (data, error, fetchMore, setLoading, skip = false) => {
  // done if useQuery is skipped
  if (skip) {
    setLoading(false);
    return;
  }

  // throw unhandled for handling in ErrorBoundary
  const onError = (e) => {
    throw e;
  };

  // handle error resulting from useQuery call
  if (error) {
    onError(error);
  }

  // if we have a page of data, fetch next page
  if (data) {
    const queryName = Object.keys(data)[0];
    const queryResult = data[queryName];
    const nextToken = queryResult.nextToken;
    if (nextToken) {
      fetchMore({ variables: { nextToken } }).catch((e) => {
        onError(e);
      });
    } else {
      setLoading(false);
    }
  }
};

// useful before react upgrade
// https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
export const useTraceUpdate = (props) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("TRACE UPDATE", changedProps);
    }
    prev.current = props;
  });
};

import React, { useEffect, useState } from "react";
import {
  adminListClientsVariables,
  adminListProjectsVariables,
  adminListUserProfilesVariables,
} from "../../lib/variables";
import { gql, useQuery } from "@apollo/client";
import { listClients, listProjects, listUserProfiles } from "../../graphql/queries";

import Clients from "./Clients";
import Spinner from "../../components/Spinner";
import Users from "./Users";
import { fetchAllEffect } from "../lib/effects";

const Admin = () => {
  const [loadingClients, setLoadingClients] = useState(true);
  const {
    data: dataClients,
    error: errorClients,
    fetchMore: fetchMoreClients,
  } = useQuery(gql(listClients), {
    variables: adminListClientsVariables(),
  });
  useEffect(
    () => fetchAllEffect(dataClients, errorClients, fetchMoreClients, setLoadingClients),
    [dataClients, errorClients, fetchMoreClients]
  );

  const [loadingUserProfiles, setLoadingUserProfiles] = useState(true);
  const {
    data: dataUserProfiles,
    error: errorUserProfiles,
    fetchMore: fetchMoreUserProfiles,
  } = useQuery(gql(listUserProfiles), {
    variables: adminListUserProfilesVariables(),
  });
  useEffect(
    () => fetchAllEffect(dataUserProfiles, errorUserProfiles, fetchMoreUserProfiles, setLoadingUserProfiles),
    [dataUserProfiles, errorUserProfiles, fetchMoreUserProfiles]
  );

  const [loadingProjects, setLoadingProjects] = useState(true);
  const {
    data: dataProjects,
    error: errorProjects,
    fetchMore: fetchMoreProjects,
  } = useQuery(gql(listProjects), {
    variables: adminListProjectsVariables(),
    // projects refresh on loading this component so they are always up to date (changed elsewhere in app)
    fetchPolicy: "network-only",
  });
  useEffect(
    () => fetchAllEffect(dataProjects, errorProjects, fetchMoreProjects, setLoadingProjects),
    [dataProjects, errorProjects, fetchMoreProjects]
  );

  if (loadingClients || loadingUserProfiles || loadingProjects) return <Spinner className="loading-full" />;

  const clients = dataClients.listClients.items;
  const users = dataUserProfiles.listUserProfiles.items;
  const projects = dataProjects.listProjects.items;

  return (
    <>
      <Clients clients={clients} />
      <Users clients={clients} users={users} projects={projects} />
    </>
  );
};

export default Admin;

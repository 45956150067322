import {
  BUILDER_CHART_TYPE_OPTIONS,
  BUILDER_METRIC_TYPE_OPTIONS_METABASE,
  BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360,
  BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360_COVERAGE,
} from "./config";
import {
  DATETIME_FORMAT_SHORT,
  getResultSourceNameForCharts,
  getSentimentTrafficLightColour,
  sort,
} from "../../lib/utils";
import { SOCIAL360_CHART_URL, SOCIAL360_TOKEN } from "../../lib/constants";
import { getAgentSocial360Id, getProjectSocial360Id } from "../../lib/social360";
import { getDateRangeOptions, getResultsTypeOptions } from "../lib/forms";

import Color from "color";
import Highcharts from "highcharts";
import axios from "axios";
import { buildSearchResultsTypeParams } from "../../lib/queries";
import find from "lodash/find";
import flatten from "lodash/flatten";
import { gql } from "@apollo/client";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import pull from "lodash/pull";
import { searchResultsAggregations } from "../../graphql/queries";
import sum from "lodash/sum";
import { titleCase } from "title-case";
import uniq from "lodash/uniq";

export const getInterval = (source, objWithDateRange) => {
  // IMPORTANT: if these interval buckets are updated, also change `intervalMs` below
  const { dateRange, startDate, startTime, endDate, endTime } = objWithDateRange;
  // 24 hour preset
  if (dateRange === "1") return source === "SOCIAL360_COVERAGE" ? "hour" : "1h";
  // different intervals based on size of specify diff
  if (dateRange === "0") {
    const startEpoch = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm").unix();
    const endEpoch = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm").unix();
    const diff = endEpoch - startEpoch;
    if (diff / 3600 <= 12) return source === "SOCIAL360_COVERAGE" ? "minute" : "30m";
    if (diff / 3600 <= 24) return source === "SOCIAL360_COVERAGE" ? "hour" : "1h";
    if (diff / 3600 <= 48) return source === "SOCIAL360_COVERAGE" ? "hour" : "2h";
    if (diff / 3600 <= 72) return source === "SOCIAL360_COVERAGE" ? "hour" : "4h";
  }
  // fallback to 1 day
  return source === "SOCIAL360_COVERAGE" ? "day" : "1d";
};

// get aggregation query based on config, with size from chartObj if supplied
export const getAggQuery = ({ source, metricConfig, chartConfig, chartObj, objWithDateRange, projectData }) => {
  // using the searchResultsAggregations query but don't actually need to aggregate
  // for now do this, can refactor to use searchResultsTotal later if this proves slow
  if (chartConfig.aggType === "count") {
    return {
      cardinality: {
        field: "id.keyword",
      },
    };
  }

  // bucket for each term
  if (chartConfig.aggType === "term") {
    // if projectDataLookup then only include ids if they are non-deleted in projectData
    let include = null;
    if (metricConfig.projectDataLookup)
      include = projectData[metricConfig.projectDataLookup].filter((x) => !x.isDeleted).map((x) => x.id);

    const query = {
      terms: {
        field: metricConfig.aggField,
        size: chartObj ? chartObj.aggSize : metricConfig.aggSize,
      },
    };

    if (include) query.terms.include = include;
    return query;
  }

  // bucket for each day/hour
  if (chartConfig.aggType === "time") {
    return {
      date_histogram: {
        field: "createdDate",
        interval: getInterval(source, objWithDateRange),
      },
    };
  }

  // total sum of values
  if (chartConfig.aggType === "sum") {
    return {
      sum: {
        field: metricConfig.aggField,
      },
    };
  }

  // bucket for each term, split into buckets for each day/hour
  if (chartConfig.aggType === "termThenTime") {
    return {
      terms: {
        field: metricConfig.aggField,
        size: chartObj ? chartObj.aggSize : metricConfig.aggSize,
      },
      aggs: {
        inner: {
          date_histogram: {
            field: "createdDate",
            interval: getInterval(source, objWithDateRange),
          },
        },
      },
    };
  }

  // buckets for each day/hour with sum for each
  if (chartConfig.aggType === "timeThenSum") {
    return {
      date_histogram: {
        field: "createdDate",
        interval: getInterval(source, objWithDateRange),
      },
      aggs: {
        inner: {
          sum: {
            field: metricConfig.aggField,
          },
        },
      },
    };
  }
};

// get series name based on seriesKey
export const getSeriesName = ({ metricConfig, chartType, seriesKey, projectData }) => {
  // lookup from projectData, we have prefixes on series, split off at first hyphen
  if (metricConfig.projectDataLookup) {
    const seriesKeyWithoutPrefix = seriesKey.substring(seriesKey.indexOf("-") + 1);
    const lookup = projectData[metricConfig.projectDataLookup].find((x) => x.id === seriesKeyWithoutPrefix);
    return lookup ? lookup.name : "Results";
  }

  // lookup agent names
  if (seriesKey.startsWith("agent-")) {
    const agent = projectData.agents.find((x) => x.id === seriesKey.substr(6));
    return agent ? agent.name : "Results";
  }

  // lookup issue names
  if (seriesKey.startsWith("issue-")) {
    const issue = projectData.filters.find((x) => x.id === seriesKey.substr(6));
    return issue ? issue.name : "Results";
  }

  // handle keywords
  if (seriesKey.startsWith("keyword-")) return seriesKey.substr(8);

  // fallback to resultsType for line charts
  if (chartType === "line") {
    return (
      {
        all: "All Results",
        top: "Top Stories",
        manualtop: "Top Stories (Manual)",
        deleted: "Deleted Results",
      }[seriesKey] || "Results"
    );
  }

  return "Results";
};

// get bucket name (term aggregation only)
export const getBucketName = ({ metricConfig, bucketKey, projectData }) => {
  // lookup from projectData
  if (metricConfig.projectDataLookup) {
    const lookup = projectData[metricConfig.projectDataLookup].find((x) => x.id === bucketKey);
    return lookup ? lookup.name : "Results";
  }

  // title case
  if (metricConfig.nameFormat === "titleCase") return titleCase(bucketKey.toLowerCase());

  // everything else
  return bucketKey;
};

// get data for a single series, return promise
export const getSeriesData = ({
  source,
  seriesType,
  seriesIssues,
  seriesIssuesTop,
  filterParams,
  aggQuery,
  projectData,
  apollo,
}) => {
  // get filter with the aggregation applied
  const seriesParams = buildSearchResultsTypeParams({
    source,
    resultsType: seriesType,
    resultsTypeIssues: seriesIssues,
    resultsTypeIssuesTop: seriesIssuesTop,
    filterParams,
    aggs: { chart: aggQuery },
    projectData,
  });

  // return promise to get data for series
  return apollo.query({
    query: gql(searchResultsAggregations),
    variables: {
      searchParams: JSON.stringify(seriesParams),
    },
  });
};

// get data for social coverage, return promise
export const getSocialCoverageData = ({
  source,
  seriesAgents,
  project,
  projectData,
  filterParams,
  objWithDateRange,
  chartType,
  chartConfig,
  metricConfig,
}) => {
  // one series per API call (S360 can do multiple but this makes our logic more complex, maybe refactor later)
  // series filtered down to the agents selected
  // seriesAgents will be equal to resultsTypeAgents (i.e. all agents on the project) if all results is selected
  let lines = [
    {
      ...metricConfig.socialAPI,
      query: {
        AF_category: seriesAgents
          .map((agentId) => getAgentSocial360Id(projectData.agents.find((x) => x.id === agentId)))
          .join("|"),
      },
    },
  ];

  // keyword charts have agent filter at top level, and a `line` for each word (all done under one series call)
  let topLevelQuery = null;
  if (filterParams.socialCoverageKeywords) {
    topLevelQuery = {
      AF_category: seriesAgents
        .map((agentId) => getAgentSocial360Id(projectData.agents.find((x) => x.id === agentId)))
        .join("|"),
    };
    lines = filterParams.socialCoverageKeywords.map((keyword) => ({
      ...metricConfig.socialAPI,
      query: { AQ_text: keyword },
    }));
  }

  // build date range relative to now
  let dateFrom = moment.utc();
  let dateTo = moment.utc();
  if (["0", "comparison"].includes(objWithDateRange.dateRange)) {
    // filterParams dates will have been overridden whether series is comparison or not
    const { startDate, startTime, endDate, endTime } = filterParams;
    dateFrom = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm").utc();
    dateTo = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm").utc();
  } else {
    dateFrom.subtract(parseInt(objWithDateRange.dateRange, 10), "days");
  }

  const params = {
    // get counts not Highcharts object
    counts: true,
    // S360 client ID corresponds to Outcider project ID
    client: getProjectSocial360Id(project),
    // always use date from/to so we can use relative ranges to now (to match ES searches)
    date_from: dateFrom.format("YYYY-MM-DDTHH:mm:ss"),
    date_to: dateTo.format("YYYY-MM-DDTHH:mm:ss"),
    interval: getInterval(source, objWithDateRange),
    chart_type: chartConfig.socialAPIChartType || chartType,
    time_based: chartType === "line",
    // top level query is used for keyword charts
    query: topLevelQuery,
    // add the series (one except for keyword charts)
    lines,
  };

  return axios.put(SOCIAL360_CHART_URL, params, {
    timeout: 60 * 1000,
    headers: {
      Authorization: `Token ${SOCIAL360_TOKEN}`,
    },
  });
};

// if series is a special one (keywords, people, organisations, entityGroups) then there is a filter
// otherwise it just a count of results so we go to the resultsType with no filters
export const handleSpecialDrilldownFilters = ({ resultsType, key }) => {
  let drilldownFilters = { resultsType: key, filters: [] };
  if (
    key.startsWith("keyword-") ||
    key.startsWith("person-") ||
    key.startsWith("organisation") ||
    key.startsWith("entityGroup-")
  ) {
    drilldownFilters.resultsType = resultsType;

    if (key.startsWith("keyword-")) drilldownFilters.filters.push({ type: "keyword", query: [key.substr(8)] });

    if (key.startsWith("person-")) drilldownFilters.filters.push({ type: "person", query: [key.substr(7)] });

    if (key.startsWith("organisation-"))
      drilldownFilters.filters.push({ type: "organisation", query: [key.substr(13)] });

    if (key.startsWith("entityGroup-")) drilldownFilters.filters.push({ type: "entityGroup", query: [key.substr(12)] });
  }
  return drilldownFilters;
};

// process data as required by aggregation type
export const processData = ({
  source,
  resultsType,
  chartObj,
  objWithDateRange,
  projectData,
  metricConfig,
  chartConfig,
  metricType,
  chartType,
  seriesData,
  seriesTypes,
  chartIsComparison,
  chartSortBy,
  chartSortByDirection,
}) => {
  const interval = getInterval(source, objWithDateRange);
  // ES milliseconds epoch for the intervals specified by `getInterval`
  const intervalsMs = {
    "30m": 60_000 * 30,
    "1h": 60_000 * 60,
    "2h": 60_000 * 60 * 2,
    "4h": 60_000 * 60 * 4,
    "1d": 60_000 * 60 * 24,
    minute: 60_000 * 1, // S360 coverage
    hour: 60_000 * 60, // S360 coverage
    day: 60_000 * 60 * 24, // S360 coverage
  };
  const intervalMs = intervalsMs[interval];

  // first series colour, used for comparison charts (overridden by sentiment/traffic buckets)
  const comparisonSeriesColour = Highcharts.getOptions().colors[0];
  const comparisonSeriesFadedColour = Color(comparisonSeriesColour).lighten(0.2).hex();

  const processByTerm = () => {
    const data = [];
    let categories = null; // x-axis categories will be picked up automatically from names

    seriesData.forEach((series, index) => {
      const key = seriesTypes[index];
      const name = series.isComparison
        ? "Comparison"
        : getSeriesName({
            metricConfig,
            chartType,
            seriesKey: seriesTypes[index],
            projectData,
          });

      // order by key
      const agg = series.data.searchResultsAggregations.aggregations;
      const aggData = agg ? JSON.parse(agg).chart.buckets : [];

      // skip series if has no data
      if (!aggData.length) return;

      const getColourForKey = (key) =>
        ["sentiment", "trafficLight"].includes(metricType)
          ? chartIsComparison
            ? // comparison, use sentiment/traffic colours, lighten comparison
              series.isComparison
              ? Color(getSentimentTrafficLightColour(key)).lighten(0.2).hex()
              : getSentimentTrafficLightColour(key)
            : // non-comparison, use sentiment/traffic colours
              getSentimentTrafficLightColour(key)
          : // non sentiment/traffic bucket, use defaults
            null;

      data.push({
        key,
        name,
        color: chartIsComparison ? (series.isComparison ? comparisonSeriesFadedColour : comparisonSeriesColour) : null,
        data: sort(
          aggData
            // exclude 0 values
            .filter((bucket) => bucket.doc_count > 0)
            .map((bucket) => ({
              key: bucket.key,
              // `name` is used in category axis type
              name: getBucketName({ metricConfig, bucketKey: bucket.key, projectData }),
              y: bucket.doc_count,
              color: getColourForKey(bucket.key),
              drilldown: {
                resultsType: key,
                filters: [{ type: metricType, query: [bucket.key] }],
                isComparison: series.isComparison,
              },
              // keep any inner sub-aggregation
              inner: bucket.inner || null,
            })),
          // sort by name/y from chartObj
          chartSortBy,
          chartSortByDirection
        ),
      });
    });

    // if there is only one series, the x-axis categories aren't picked up, get them from each bucket
    if (data.length === 1) {
      categories = data[0].data;
      // need to take into account what is hidden
      if (chartObj) {
        const hidden = chartObj.hidden || [];
        if (hidden.length) categories = categories.filter((x) => !hidden.includes(`category-${x.key}`));
      }
      // the x-axis categories are the names
      categories = categories.map((x) => x.name);
    }

    return {
      data,
      xAxis: {
        categories,
        type: "category",
      },
    };
  };

  const processByTime = () => {
    let data = [];

    seriesData.forEach((series, index) => {
      const key = seriesTypes[index];
      const name = getSeriesName({
        metricConfig,
        chartType,
        seriesKey: seriesTypes[index],
        projectData,
      });

      // data is already ordered by date ascending
      const agg = series.data.searchResultsAggregations.aggregations;
      const aggData = agg ? sort(JSON.parse(agg).chart.buckets, "key") : [];

      // skip series if has no data
      if (!aggData.length) return;

      data.push({
        key,
        name,
        // keep 0 values as line chart
        data: aggData.map((bucket) => ({
          key: bucket.key,
          // `x` is used in datetime axis type
          x: bucket.key,
          y: bucket.doc_count,
          drilldown: {
            ...handleSpecialDrilldownFilters({ resultsType, key }),
            timestamp: [bucket.key, bucket.key + intervalMs],
            isComparison: series.isComparison,
          },
          // keep any inner sub-aggregation
          inner: bucket.inner || null,
        })),
      });
    });

    // if there is an aggSize on the chart config, sum each series, take top aggSize from chartObj (fallback to chart config), sort back by name
    if (metricConfig.aggSize) {
      data.forEach((series) => (series.total = sum(series.data.map((x) => x.y))));
      data = sort(sort(data, "total", "desc").slice(0, chartObj ? chartObj.aggSize : metricConfig.aggSize), "name");
    } else {
      // sort by name (cannot sort by y values over time)
      data = sort(data, "name");
    }

    return {
      data,
      xAxis: {
        type: "datetime",
      },
    };
  };

  const processByCountOrSum = () => {
    let data = [];
    let hasData = false; // needed to show empty message if all series have 0 data

    seriesData.forEach((series, index) => {
      const key = seriesTypes[index];
      const name = getSeriesName({
        metricConfig,
        chartType,
        seriesKey: seriesTypes[index],
        projectData,
      });

      // get count from the cardinality agg
      const agg = series.data.searchResultsAggregations.aggregations;
      const aggData = agg ? JSON.parse(agg).chart.value : 0;
      if (aggData > 0) hasData = true;

      // skip series if count is 0
      if (aggData === 0) return;

      data.push({
        key,
        name,
        y: aggData,
        // wordcloud also requires `weight` to control sizing
        weight: aggData,
        drilldown: {
          ...handleSpecialDrilldownFilters({ resultsType, key }),
          isComparison: series.isComparison,
        },
      });
    });

    // if there is an aggSize on the chart config, sort by value, take top aggSize from chartObj (fallback to chart config), sort back by name/y from chartObj
    if (metricConfig.aggSize) {
      data = sort(
        sort(data, "y", "desc").slice(0, chartObj ? chartObj.aggSize : metricConfig.aggSize),
        chartSortBy,
        chartSortByDirection
      );
    } else {
      // sort by name/y from chartObj
      data = sort(data, chartSortBy, chartSortByDirection);
    }

    // for non-comparion charts, there is one series called "Results"
    // otherwise pick out which is comparison/non-comparison data and create two series
    if (!chartIsComparison) {
      return {
        data: [
          {
            key: "Results",
            name: "Results",
            data: hasData ? data : [],
          },
        ],
        xAxis: {
          type: "category",
        },
      };
    } else {
      return {
        data: [
          {
            key: "Comparison",
            name: "Comparison",
            color: comparisonSeriesFadedColour,
            data: data.filter((x) => x.drilldown.isComparison),
          },
          {
            key: "Results",
            name: "Results",
            color: comparisonSeriesColour,
            data: data.filter((x) => !x.drilldown.isComparison),
          },
        ],
        xAxis: {
          type: "category",
        },
      };
    }
  };

  // straight through the term process
  if (chartConfig.processType === "term") {
    return processByTerm();
  }

  // straight through the time process
  if (chartConfig.processType === "time") {
    return processByTime();
  }

  // straight through the count/sum process
  if (chartConfig.processType === "countOrSum") {
    return processByCountOrSum();
  }

  // process by term, then handle each term like a series over time
  if (chartConfig.processType === "termThenTime") {
    const data = [];
    let termData = processByTerm();

    // can only handle one series
    if (termData.data.length > 1) throw new Error("termThenTime has a limit of 1 series.");
    termData = termData.data.length ? termData.data[0].data : [];

    // handle each term like a series
    for (const term of termData) {
      data.push({
        key: term.key,
        name: term.name,
        color: term.color, // copy color which might have been set in processByTerm
        data: term.inner.buckets.map((bucket) => ({
          key: bucket.key,
          // `x` is used in datetime axis type
          x: bucket.key,
          y: bucket.doc_count,
          drilldown: {
            resultsType,
            filters: [{ type: metricType, query: [term.key] }],
            timestamp: [bucket.key, bucket.key + intervalMs],
            isComparison: term.drilldown.isComparison,
          },
        })),
      });
    }

    return {
      data,
      xAxis: {
        type: "datetime",
      },
    };
  }

  // process by time, then sum up each time bucket
  if (chartConfig.processType === "timeThenSum") {
    const data = processByTime().data; // process by time as normal

    // for each point, replace the `y` value with `inner.value` which is the sum agg
    data.forEach((series) => {
      series.data = series.data.map((x) => ({ ...x, y: x.inner.value }));
    });

    return {
      data,
      xAxis: {
        type: "datetime",
      },
    };
  }

  // the default stacking behaviour would e.g. break down a positive sentiment bar into sections per issue
  // we want a bar per issue, broken down into sentiment, so we need to flip the data round
  if (chartConfig.processType === "termStacked") {
    const data = [];
    const stackedData = {};
    const termData = processByTerm().data; // process by term as normal

    // get data keys e.g. sentiment
    const dataKeys = uniq(flatten(termData.map((x) => x.data.map((d) => d.key))));
    // get series keys e.g. all, issue-xxx
    const seriesKeys = termData.map((x) => x.key);

    // transpose the data, add 0 values
    for (const dataKey of dataKeys) {
      stackedData[dataKey] = [];
      for (const seriesKey of seriesKeys) {
        const seriesMatch = find(termData, (x) => x.key === seriesKey);
        const dataMatch = find(seriesMatch.data, (x) => x.key === dataKey);
        if (dataMatch) {
          stackedData[dataKey].push({
            key: seriesKey,
            // `name` is used in category axis type
            name: getSeriesName({ metricConfig, chartType, seriesKey, projectData }),
            color: dataMatch.color, // copy color which might have been set in processByTerm
            y: dataMatch.y,
            drilldown: dataMatch.drilldown,
          });
        } else {
          stackedData[dataKey].push({
            key: seriesKey,
            // `name` is used in category axis type
            name: getSeriesName({ metricConfig, chartType, seriesKey, projectData }),
            y: 0,
          });
        }
      }
    }

    for (const dataKey of dataKeys) {
      data.push({
        key: dataKey,
        name: getBucketName({ metricConfig, bucketKey: dataKey, projectData }),
        // for legend colours (don't need to handle comparison stacked)
        color: ["sentiment", "trafficLight"].includes(metricType) ? getSentimentTrafficLightColour(dataKey) : null,
        data: stackedData[dataKey],
      });
    }

    const stacked = {
      data: sort(data, "name"), // sort the legend
      xAxis: {
        type: "category",
      },
    };
    return stacked;
  }

  if (chartConfig.processType === "countOrSumMulti") {
    const countOrSumData = processByCountOrSum().data; // process by count/sum as normal

    // there is one series which is split by issue already
    return {
      data: [
        {
          key: "Results",
          name: "Results",
          data: countOrSumData[0].data,
        },
      ],
      xAxis: {
        type: "category",
      },
    };
  }
};

export const formatMetricType = (value, source) => {
  let options = [];
  if (source === "METABASE") options = BUILDER_METRIC_TYPE_OPTIONS_METABASE;
  if (source === "SOCIAL360") options = BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360;
  if (source === "SOCIAL360_COVERAGE") options = BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360_COVERAGE;
  const name = flatten(options.map((x) => x.options)).find((x) => x.value === value);
  return name ? name.label : "-";
};

export const formatChartType = (value) => {
  const name = BUILDER_CHART_TYPE_OPTIONS.find((x) => x.value === value);
  return name ? name.label : "-";
};

export const formatResultsType = (resultsType, source, projectData) => {
  const lookup = flatten(getResultsTypeOptions(source, projectData).map((x) => x.options));
  return [
    ...pull(
      resultsType.map((type) => {
        const name = lookup.find((x) => x.value === type);
        return name ? name.label : null;
      }),
      null
    ),
  ];
};

export const formatDateRange = (value, row) => {
  if (["0", "comparison"].includes(value)) {
    let range = [];
    if (value === "comparison") {
      const comparisonStartDateFormatted = moment(
        `${row.comparisonStartDate} ${row.comparisonStartTime}`,
        "YYYY-MM-DD HH:mm"
      ).format(DATETIME_FORMAT_SHORT);
      const comparisonEndDateFormatted = moment(
        `${row.comparisonEndDate} ${row.comparisonEndTime}`,
        "YYYY-MM-DD HH:mm"
      ).format(DATETIME_FORMAT_SHORT);
      range.push(`${comparisonStartDateFormatted} - ${comparisonEndDateFormatted} (vs)`);
    }
    const startDateFormatted = moment(`${row.startDate} ${row.startTime}`, "YYYY-MM-DD HH:mm").format(
      DATETIME_FORMAT_SHORT
    );
    const endDateFormatted = moment(`${row.endDate} ${row.endTime}`, "YYYY-MM-DD HH:mm").format(DATETIME_FORMAT_SHORT);
    range.push(`${startDateFormatted} - ${endDateFormatted}`);
    return range;
  }
  const lookup = getDateRangeOptions(true);
  const name = lookup.find((x) => x.value === value);
  return name ? [name.label] : ["-"];
};

export const getChartBuilderData = ({ clientId, projectId, maxDate, projectData, chartObj }) => {
  // work out resultsType, resultsTypeIssues
  // `validateResultsType` will have already validated this
  let resultsType = "all",
    resultsTypeAgents,
    resultsTypeIssues;
  if (chartObj.resultsType.length === 1 && ["all", "top", "manualtop"].includes(chartObj.resultsType[0])) {
    // there is one resultsType, and it is all/top/manualtop
    resultsType = chartObj.resultsType[0];
  } else if (chartObj.resultsType.map((x) => x.startsWith("agent-")).filter(Boolean).length) {
    // there are one or more agents
    resultsType = "agents";
    resultsTypeAgents = chartObj.resultsType;
  } else if (chartObj.resultsType.map((x) => x.startsWith("issue-")).filter(Boolean).length) {
    // there are one or more issues
    resultsType = "issues";
    resultsTypeIssues = chartObj.resultsType;
  }

  // build props
  const chartProps = {
    title: chartObj.name,
    subtitle: `${formatMetricType(chartObj.metricType, chartObj.source)}, ${formatResultsType(
      chartObj.resultsType,
      chartObj.source,
      projectData
    ).join(" + ")}${
      projectData.resultsSources.length > 1 ? ` (${titleCase(getResultSourceNameForCharts(chartObj.source))})` : ""
    }, ${formatDateRange(chartObj.dateRange, chartObj).join(" ")}`,
    metricType: chartObj.metricType,
    chartType: chartObj.chartType,
    resultsType,
    resultsTypeAgents,
    resultsTypeIssues,
    // chart builder filterParams does NOT contain date range data (it is in chartObj instead)
    filterParams: {
      clientId,
      projectId,
      maxDate,
    },
    projectData,
    // this must be set as logic varies for chart builder-created charts (e.g. comparison)
    isBuilder: true,
    chartObj,
  };

  return chartProps;
};

export const getChartEmbedOptions = (projectData) =>
  projectData.charts.map((chartObj) => ({
    value: chartObj.id,
    label: `${chartObj.name}:\n${formatMetricType(chartObj.metricType, chartObj.source)}, ${formatResultsType(
      chartObj.resultsType,
      chartObj.source,
      projectData
    ).join(" + ")}${
      projectData.resultsSources.length > 1 ? ` (${titleCase(getResultSourceNameForCharts(chartObj.source))})` : ""
    }, ${formatDateRange(chartObj.dateRange, chartObj).join(" ")}`,
  }));

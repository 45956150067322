import { ListGroup, ListGroupItem } from "reactstrap";
import { useHistory, useParams } from "react-router";

import React from "react";

const StakeholdersMenu = ({ projectData }) => {
  const history = useHistory();
  const { clientId, projectId, entityType, stakeholderType } = useParams();

  const filters = projectData.filters.filter((x) => x.isVisible);

  return (
    <div className="results-menu vh-with-title scroll">
      <ListGroup flush className="font-16 font-bold">
        <ListGroupItem
          className="click"
          onClick={() => history.push(`/${clientId}/${projectId}/stakeholders/${entityType}/${stakeholderType}/all`)}
        >
          <i className="uil-file-search-alt mr-1" />
          All Results
        </ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem className="d-flex">
          <div className="flex-grow-1 font-16 font-bold">Issues</div>
        </ListGroupItem>
        {filters.length ? (
          <>
            {filters.map((filter) => (
              <ListGroupItem
                key={filter.id}
                className="d-flex click"
                onClick={() =>
                  history.push(
                    `/${clientId}/${projectId}/stakeholders/${entityType}/${stakeholderType}/issue-${filter.id}`
                  )
                }
              >
                <div className="flex-grow-1">{filter.name}</div>
              </ListGroupItem>
            ))}
          </>
        ) : (
          <p className="text-muted">There are no issues to display.</p>
        )}
      </ListGroup>
    </div>
  );
};

export default StakeholdersMenu;

import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { createTag, updateTag } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";
import { useHistory, useParams } from "react-router-dom";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import PageTitle from "../../components/PageTitle";
import ResultsCounter from "../ResultsCounter";
import Spinner from "../../components/Spinner";
import { bodyFixedLgEffect } from "../lib/effects";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import { getResultSourceName } from "../../lib/utils";
import pick from "lodash/pick";
import { queryTags } from "../../graphql/queries";
import { queryTagsVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const TagModal = ({ data, toggle, projectData }) => {
  const appContext = useContext(AppContext);
  const [mutationUpdateTag] = useMutation(gql(updateTag));
  const alertModal = createConfirmation(AlertModal);

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(data, {
      fields: ["name"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // enforce uniqueness (only enforced via UI as not important), exclude the tag being edited
      const currentUniqueNames = projectData.tags
        .filter((x) => x.type === data.type && x.id !== data.id)
        .map((x) => x.uniqueName);
      const uniqueName = values.name.toLowerCase();
      if (currentUniqueNames.includes(uniqueName))
        return await alertModal({ message: "A tag with this name already exists." });
      // update
      const input = await processForm({
        ...pick(data, ["clientId", "projectId", "id", "type", "name"]),
        ...values,
        uniqueName,
      });
      await mutationUpdateTag({ variables: { input } });
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Edit Tag</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Tag = ({ type, title, name, tags, user, projectData }) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { clientId, projectId } = useParams();

  const [mutationCreateTag] = useMutation(gql(createTag));
  const [modal, toggleModal] = useState(null);
  const [mutationUpdateTag] = useMutation(gql(updateTag));
  const alertModal = createConfirmation(AlertModal);
  const [isDeleting, setIsDeleting] = useState(false);

  const { register, handleSubmit, errors, formState, control, setValue, watch, reset } = useForm({
    defaultValues: populateForm(null),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const watchName = watch("name");

  const onSubmit = async (values) => {
    try {
      // enforce uniqueness (only enforced via UI as not important)
      const currentUniqueNames = projectData.tags.filter((x) => x.type === type).map((x) => x.uniqueName);
      const uniqueName = values.name.toLowerCase();
      if (currentUniqueNames.includes(uniqueName))
        return await alertModal({ message: "A tag with this name already exists." });
      // create
      const input = await processForm({ id: uuid(), clientId, projectId, type, ...values, uniqueName });
      await mutationCreateTag({
        variables: { input },
        update: updateCacheCreate(gql(queryTags), queryTagsVariables({ clientId, projectId })),
      });
      reset();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const handleStakeholder = async (tag, entityType) => {
    // goto stakeholder listing with pre-set table filter
    history.push(`/${clientId}/${projectId}/stakeholders/${entityType}/all/all`, { group: tag.name });
  };

  const confirmModal = createConfirmation(ConfirmModal);

  const handleDelete = async (tag) => {
    if (isDeleting) return;
    const message =
      type === "ENTITY"
        ? "Deleting this tag will unassign it from any stakeholders it is currently assigned to. The stakeholders, and any results mentioning those stakeholders, will remain otherwise unchanged. Are you sure you want to delete this tag?"
        : "Deleting this tag will unassign it from any results it is currently assigned to. This cannot be undone. Are you sure you want to delete this tag?";
    const ok = await confirmModal({ message });
    if (!ok) return;
    setIsDeleting(true);
    try {
      await mutationUpdateTag({
        variables: {
          input: {
            ...pick(tag, ["clientId", "projectId", "id", "type", "uniqueName"]),
            isDeleted: true,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsDeleting(false);
  };

  return (
    <>
      <Col xs={12} lg={!projectData.comprehendUsed ? 6 : 4}>
        <h4
          className={classNames({
            "text-title": true,
            "mt-0": true,
            "mb-2": true,
            "tags-title": true,
            "tags-title--first": type === "ENTITY",
          })}
        >
          {title}
        </h4>

        {user.can.updateTags && (
          <Form onSubmit={handleSubmit(onSubmit)} className="mb-2">
            <div className="d-flex">
              <div className="flex-grow-1">
                <FormInput
                  name="name"
                  placeholder={`Create new ${name} tag...`}
                  required
                  className="mb-0"
                  hideErrors
                  formProps={formProps}
                />
              </div>
              <Button className="ml-1" color="primary" type="submit" disabled={isSubmitting || !watchName}>
                {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                <i className="uil-plus mr-1" />
                Create
              </Button>
            </div>
          </Form>
        )}

        {tags.length ? (
          <ListGroup className="tags-list scroll">
            {tags.map((tag) => (
              <ListGroupItem key={tag.id} action={user.can.updateTags} disabled={isSubmitting || isDeleting}>
                <div className="d-flex">
                  <div className="flex-grow-1">{tag.name}</div>
                  <div className="ml-2">
                    {type === "ENTITY" ? (
                      <ResultsCounter
                        icon="uil-list-ul"
                        source="METABASE"
                        query={{ entityGroup: [tag.id] }}
                        projectData={projectData}
                      />
                    ) : (
                      <>
                        {projectData.resultsSources.map((resultsSource) => (
                          <span key={resultsSource} className="ml-2">
                            <ResultsCounter
                              icon="uil-list-ul"
                              source={resultsSource}
                              text={projectData.resultsSources.length === 1 ? "" : getResultSourceName(resultsSource)}
                              query={{ [type.toLowerCase()]: [tag.id] }}
                              projectData={projectData}
                            />
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                  {(type === "ENTITY" || (type !== "ENTITY" && user.can.updateTags)) && (
                    <UncontrolledDropdown className="ml-2 card-widgets">
                      <DropdownToggle
                        tag="a"
                        className="arrow-none cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="dripicons-dots-3" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {type === "ENTITY" && (
                          <>
                            <DropdownItem
                              onClick={(e) => {
                                e.stopPropagation();
                                handleStakeholder(tag, "people");
                              }}
                            >
                              <i className="uil-users-alt mr-1" />
                              View People
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                e.stopPropagation();
                                handleStakeholder(tag, "organisations");
                              }}
                            >
                              <i className="uil-building mr-1" />
                              View Organisations
                            </DropdownItem>
                          </>
                        )}
                        {user.can.updateTags && (
                          <>
                            <DropdownItem
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleModal(tag);
                              }}
                            >
                              <i className="uil-edit mr-1" />
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              className="text-danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(tag);
                              }}
                            >
                              <i className="uil-trash mr-1" />
                              Delete
                            </DropdownItem>
                          </>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        ) : (
          <Card body className="mb-0 card-prompt tags-list">
            <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
              <p className="mb-0 text-center">
                <i className="uil-tag-alt font-24 d-block" />
                {type === "ENTITY"
                  ? `Create tags to organise stakeholders.`
                  : `Create ${name} tags to organise results.`}
              </p>
            </div>
          </Card>
        )}
      </Col>
      {modal && <TagModal data={modal} toggle={toggleModal} projectData={projectData} />}
    </>
  );
};

const Tags = ({ user, projectData }) => {
  useEffect(bodyFixedLgEffect);

  const tagsEntity = projectData.tags.filter((x) => x.type === "ENTITY");
  const tagsTopic = projectData.tags.filter((x) => x.type === "TOPIC");
  const tagsConcern = projectData.tags.filter((x) => x.type === "CONCERN");

  return (
    <>
      <PageTitle title="Tags" />
      <Row>
        {projectData.comprehendUsed && (
          <Tag
            type="ENTITY"
            title="Stakeholders"
            name="stakeholder"
            tags={tagsEntity}
            user={user}
            projectData={projectData}
          />
        )}
        <Tag type="TOPIC" title="Topics" name="topic" tags={tagsTopic} user={user} projectData={projectData} />
        <Tag
          type="CONCERN"
          title="Key Concerns"
          name="key concern"
          tags={tagsConcern}
          user={user}
          projectData={projectData}
        />
      </Row>
    </>
  );
};

export default Tags;

import { API } from "aws-amplify";
import { onUpdateProject } from "../../graphql/subscriptions";

export const subscribeToProjectUpdate = (id, handle) =>
  API.graphql({
    query: onUpdateProject,
    variables: {
      filter: { id: { eq: id } },
    },
    // needed to override default set in aws-exports
    authMode: "AMAZON_COGNITO_USER_POOLS",
  }).subscribe({
    next: ({ value }) => {
      handle(value.data.onUpdateProject);
    },
  });

import { Button, Card, CardBody, Nav, NavItem } from "reactstrap";
import React, { useContext, useState } from "react";
import { getOrganisation, getPerson } from "../../graphql/queries";
import { gql, useApolloClient, useQuery } from "@apollo/client";

import { AppContext } from "../../App";
import Chart from "../Charts/Chart";
import EntityModal from "../Modals/EntityModal";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { handleDeleted } from "../lib/utils";
import { useMediaBreakpointDownLg } from "../lib/effects";
import { useParams } from "react-router-dom";

const StakeholderPanel = ({ stakeholderId, filter, onClose, user, project, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId, entityType } = useParams();
  const [modal, toggleModal] = useState(null);

  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  const chartProps = {
    height: 300,
    // all results with current issue query plus entity query
    resultsType: "all",
    filterParams: {
      clientId,
      projectId,
      dateRange: "30",
      maxDate: appContext.maxDate,
      query: {
        ...(filter ? JSON.parse(filter.query) : {}),
        [entityType === "people" ? "person" : "organisation"]: { query: [stakeholderId], include: true, active: true },
      },
    },
    filter,
    user,
    projectData,
    clientId,
    projectId,
    project,
    maxDate: appContext.maxDate,
    apollo,
  };

  // get stakeholder
  const { loading, data } = useQuery(gql(entityType === "people" ? getPerson : getOrganisation), {
    variables: { id: stakeholderId },
  });

  if (loading) {
    return (
      <Card
        className={classNames({
          "side-panel": true,
          "side-panel--white": true,
          "vh-with-title": !isMediaBreakpointDownLg,
          "vh-with-title-wrapped-buttons": isMediaBreakpointDownLg,
        })}
      >
        <CardBody>
          <Spinner className="loading-relative" />
        </CardBody>
      </Card>
    );
  }

  // check it exists
  const stakeholder = data[entityType === "people" ? "getPerson" : "getOrganisation"] || null;
  const deleted = handleDeleted(
    stakeholder,
    "stakeholder",
    `/${clientId}/${projectId}/stakeholders/${entityType}/all/all`
  );
  if (deleted) return deleted;

  return (
    <>
      <Card
        className={classNames({
          "side-panel": true,
          "side-panel--white": true,
          "vh-with-title": !isMediaBreakpointDownLg,
          "vh-with-title-wrapped-buttons": isMediaBreakpointDownLg,
        })}
      >
        <Nav tabs className="nav-bordered">
          <NavItem>
            <h4 className="side-panel-title text-title mt-0 mb-0">
              {stakeholder.name}
              {user.can.updateStakeholders && projectData.comprehendEnabled && (
                <i
                  className="mdi mdi-pencil-outline click ml-1"
                  style={{ opacity: 0.5 }}
                  onClick={() => {
                    toggleModal({
                      name: stakeholder.name,
                      entity: stakeholder,
                      type: entityType === "people" ? "PERSON" : "ORGANISATION",
                    });
                  }}
                />
              )}
            </h4>
          </NavItem>
          <NavItem className="ml-auto side-panel--buttons">
            <Button color="secondary" size="sm" className="side-panel--close-button-sm" onClick={onClose}>
              <i className="uil-times" />
            </Button>
          </NavItem>
        </Nav>

        <CardBody className="scroll">
          <div className="mb-3">
            <Chart
              source="METABASE"
              title="Result Frequency"
              subtitle={`from the last 30 days across ${filter ? "this issue" : "all results"}`}
              metricType="resultFrequency"
              chartType="line"
              {...chartProps}
            />
          </div>
          <div className="mb-3">
            <Chart
              source="METABASE"
              title="Sentiment"
              subtitle={`over the last 30 days across ${filter ? "this issue" : "all results"}`}
              metricType="sentiment"
              chartType="pie"
              {...chartProps}
            />
          </div>
          <div className="mb-0">
            <Chart
              source="METABASE"
              title="Source Rank"
              subtitle={`over the last 30 days across ${filter ? "this issue" : "all results"}`}
              metricType="sourceRank"
              chartType="bar"
              {...chartProps}
            />
          </div>
        </CardBody>
      </Card>

      {modal && (
        <EntityModal data={modal} toggle={toggleModal} isInStakeholdersSection={true} projectData={projectData} />
      )}
    </>
  );
};

export default StakeholderPanel;

module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  <mj-section mj-class="p-0">
    <mj-column>
      <mj-text mj-class="block-title">{{#if title}}{{title}}{{else}}Contents{{/if}}</mj-text>
      {{#if text}}
        <mj-text mj-class="block-text">{{{text}}}</mj-text>
      {{/if}}
    </mj-column>
  </mj-section>
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->

  {{#if @root.contentsBlocks.length}}
    <mj-section mj-class="p-0" css-class="report-section-with-columns">
      {{#each @root.contentsBlocks}}
        <mj-column mj-class="width-50" css-class="report-contents-column report-contents-column-{{#if @first}}left{{else}}right{{/if}}">
          <mj-text mj-class="contents">
            <ul>
              {{#each this}}
                <li><a href="#toc-{{index}}" class="toc-link">{{title}}</a></li>
              {{/each}}
            </ul>
          </mj-text>
        </mj-column>
      {{/each}}
    </mj-section>
  {{/if}}
</mj-wrapper>`
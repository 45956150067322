import * as options from "../lib/options";

import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupAddon,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { HAS_NUMBER_RANGE, NO_INCLUDE_SWITCH, mergeFiltersAndChartFilters } from "./lib";
import React, { useContext, useState } from "react";
import { createSearch, updateFilter, updateSearch } from "../../graphql/mutations";
import { getTimestamp7Days, sortArray } from "../../lib/utils";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { queryFilters, querySearch } from "../../graphql/queries";
import { queryFiltersGetVariables, querySearchGetVariables } from "../../lib/variables";
import { useHistory, useParams } from "react-router-dom";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import FilterModal from "./FilterModal";
import FormCreatable from "../Forms/FormCreatable";
import NavigationPromptModal from "../Modals/NavigationPromptModal";
import SaveModal from "./SaveModal";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import flatten from "lodash/flatten";
import { numberFormat } from "humanize";
import { populateForm } from "../lib/forms";
import reduce from "lodash/reduce";
import startCase from "lodash/startCase";
import { titleCase } from "title-case";
import uniqBy from "lodash/uniqBy";
import { useForm } from "react-hook-form";
import { useMediaBreakpointDownLg } from "../lib/effects";
import { v4 as uuid } from "uuid";

const PLURALS = {
  reach: ["reach bands", "reach bands"],
  mozrank: ["mozrank bands", "mozrank bands"],
  sourceName: ["source name", "source names"],
  sourceDomain: ["domain name", "domain names"],
  category: ["source category", "source categories"],
  country: ["source country", "source countries"],
  topic: ["topic tag", "topic tags"],
  concern: ["key concern tag", "key concern tags"],
  person: ["person", "people"],
  agent: ["search agent", "search agents"],
  authorGroups: ["author group", "author groups"],
  authorName: ["author name", "author names"],
  authorEmail: ["author contact", "author contacts"],
  // number range overwrites (not actually plurals, just need the output changing from the field name)
  socialEngagement: "engagement",
  socialReactions: "reactions",
  socialComments: "comments",
  socialShares: "shares",
  socialViews: "views",
};

const Filters = ({ source, resultsType, search, filter, user, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const history = useHistory();
  const { clientId, projectId } = useParams();

  let filterTypeOptions = options.FILTER_TYPES_METABASE;
  if (source === "SOCIAL360") filterTypeOptions = options.FILTER_TYPES_SOCIAL360;

  // keys of all user-selectable filters
  const filterTypesKeys = flatten(filterTypeOptions.filter((x) => x.label).map((x) => x.options)).map((x) => x.value);

  // initial state from Search.query or Filter.query
  const initialFilters = search || filter ? JSON.parse((search || filter).query) : {};
  const [filters, setFilters] = useState(initialFilters);

  // initial state from Search.chartQuery
  const initialChartFilters = search && search.chartQuery ? JSON.parse(search.chartQuery) : {};
  const [chartFilters, setChartFilters] = useState(initialChartFilters);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, toggleModal] = useState(null);
  const [saveModal, toggleSaveModal] = useState(null);
  const [mutationCreateSearch] = useMutation(gql(createSearch));
  const [mutationUpdateSearch] = useMutation(gql(updateSearch));
  const [mutationUpdateFilter] = useMutation(gql(updateFilter));

  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  const { register, control, errors, setValue, watch, reset } = useForm({
    defaultValues: populateForm(null),
  });
  const formProps = { errors, register, control, setValue };

  const watchSearch = watch("search");
  const searchDisabled = watchSearch ? watchSearch.length === 0 : true;

  const alertModal = createConfirmation(AlertModal);
  const confirmModal = createConfirmation(ConfirmModal);

  // add a quick search query as a keyword "any of" manual filter, or add to existing keyword filter if there is one
  const addSearch = (searchQuery) => {
    if (Object.keys(filters).includes("keyword")) {
      // update existing any keywords, removing any resulting duplicates and sorting
      const newAny = uniqBy(filters.keyword.any.concat(searchQuery), (x) => x.toLowerCase());
      persistFilters(
        {
          ...filters,
          keyword: { ...filters.keyword, any: sortArray(newAny) },
        },
        false
      );
    } else {
      // create new keyword filter, default to active (no include switch on this field)
      persistFilters({ ...filters, keyword: { any: sortArray(searchQuery), within: "all", active: true } }, false);
    }
  };

  // toggle active value (manual or chart filter)
  const toggleFilter = (type, isChartFilter) => {
    const newFilters = { ...(isChartFilter ? chartFilters : filters) };
    newFilters[type].active = !newFilters[type].active;
    persistFilters(newFilters, isChartFilter);
  };

  // add filter (manual or chart filter), data from form has already been sanitised by processForm
  const addFilter = (type, data, isChartFilter) => {
    const newFilters = { ...(isChartFilter ? chartFilters : filters) };
    newFilters[type] = data;
    persistFilters(newFilters, isChartFilter);
  };

  // delete filter (manual or chart filter)
  const deleteFilter = async (type, isChartFilter, confirm) => {
    if (confirm) {
      const ok = await confirmModal();
      if (!ok) return;
    }
    const newFilters = { ...(isChartFilter ? chartFilters : filters) };
    delete newFilters[type];
    persistFilters(newFilters, isChartFilter);
  };

  // save filters to a temporary search
  const persistFilters = async (state, isChartFilter) => {
    setIsSubmitting(true);

    // update UI with new filters
    if (isChartFilter) {
      setChartFilters(state);
    } else {
      setFilters(state);
    }

    const input = {
      clientId,
      projectId,
      source,
      // only need to create/update the query type that has changed
      [isChartFilter ? "chartQuery" : "query"]: JSON.stringify(state),
      // starting point for search is what we are currently viewing
      resultsType,
      expire: getTimestamp7Days(),
    };

    // if editing a saved filter, create a new temporary search with the new criteria, linked to this filter
    if (filter) {
      input.filterName = filter.name;
      input.filterId = filter.id;
    }

    if (!search) {
      const id = uuid();
      input.id = id;
      try {
        // create search
        await mutationCreateSearch({ variables: { input } });
        // prefetch
        await apollo.query({
          query: gql(querySearch),
          variables: querySearchGetVariables({ clientId, projectId, id }),
        });
        // redirect
        history.push(`search-${id}`);
      } catch (e) {
        appContext.handleError(e);
      }
    } else {
      input.id = search.id;
      input.skipFilterHasChangesCheck = false;
      try {
        await mutationUpdateSearch({ variables: { input } });
      } catch (e) {
        appContext.handleError(e);
      }
      setIsSubmitting(false);
    }
  };

  // save changes to a filter (which has become a temporary search for the purposes of editing)
  const saveChanges = async () => {
    setIsSubmitting(true);
    setIsSaving(true);

    const input = {
      clientId,
      projectId,
      id: search.filterId,
      query: JSON.stringify(mergeFiltersAndChartFilters(filters, chartFilters)),
    };
    try {
      // save changes
      await mutationUpdateFilter({ variables: { input } });
      // prefetch
      await apollo.query({
        query: gql(queryFilters),
        variables: queryFiltersGetVariables({ clientId, projectId, id: search.filterId }),
      });
      // redirect
      history.push(`issue-${search.filterId}`);
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
    setIsSaving(false);
  };

  const formatKeywordBadge = (filter) => {
    let count = 0;
    for (const key of ["any", "inConnectionWithAny", "inConnectionWithAll", "none"])
      count += (filter[key] || []).length;
    for (const key of ["proximity1", "proximity2"]) count += filter[key] ? 1 : 0;
    return count;
  };

  const formatToggleBadge = (filter) => filter.query.map((x) => titleCase(x.toLowerCase())).join(" or ");

  const renderFilter = (filterType, isChartFilter) => {
    const state = isChartFilter ? chartFilters : filters;
    if (!state[filterType]) return null;

    let showIncludeExclude = true;
    if (isChartFilter || NO_INCLUDE_SWITCH.includes(filterType)) showIncludeExclude = false;

    return (
      <span
        className={classNames({
          badge: true,
          // include colour
          "badge-success-lighten":
            !isChartFilter &&
            (!Object.keys(state[filterType]).includes("include") ||
              (Object.keys(state[filterType]).includes("include") && state[filterType].include === true)),
          // exclude colour
          "badge-danger-lighten":
            !isChartFilter && Object.keys(state[filterType]).includes("include") && state[filterType].include === false,
          // chart filter colour takes precedence over include/exclude
          "badge-info-lighten": isChartFilter,
          // disabled fade out
          "badge--disabled": !state[filterType].active,
          "text-primary": true,
          "mt-1": true,
          "mr-1": true,
          click: true,
        })}
        onClick={() => toggleModal({ source, type: filterType, data: state[filterType], isChartFilter, op: "UPDATE" })}
      >
        {isChartFilter ? <i className="uil-chart-line" /> : <i className="uil-filter" />}
        {/* show "include" or "exclude" if it is a switch on the filter type */}
        {showIncludeExclude ? (state[filterType].include ? " Include" : " Exclude") : ""}{" "}
        {/* show one criteria otherwise number */}
        {NO_INCLUDE_SWITCH.includes(filterType) ? (
          <strong>
            {filterType === "isTop" && "Top Stories"}

            {filterType === "keyword" && (
              <>
                {/* show count of keywords */}
                {formatKeywordBadge(state[filterType])} Keyword
                {formatKeywordBadge(state[filterType]) > 1 ? "s" : ""}
              </>
            )}

            {["sentiment", "trafficLight"].includes(filterType) && (
              <>
                {/* list toggle options selected */}
                {formatToggleBadge(state[filterType])} {startCase(filterType)}
              </>
            )}
          </strong>
        ) : HAS_NUMBER_RANGE.includes(filterType) ? (
          <strong>
            {/* show range */}
            {state[filterType].queryFrom ? numberFormat(state[filterType].queryFrom, 0) : 0}
            {state[filterType].queryTo ? `-${numberFormat(state[filterType].queryTo, 0)}` : "+"}{" "}
            {PLURALS[filterType] ? titleCase(PLURALS[filterType]) : startCase(filterType)}
          </strong>
        ) : (
          <strong>
            {/* show count of options */}
            {state[filterType].query.length}{" "}
            {PLURALS[filterType]
              ? state[filterType].query.length > 1
                ? titleCase(PLURALS[filterType][1])
                : titleCase(PLURALS[filterType][0])
              : state[filterType].query.length > 1
              ? `${startCase(filterType)}s`
              : startCase(filterType)}
          </strong>
        )}
        <i
          className={classNames({
            "ml-1": true,
            "uil-minus-circle": state[filterType].active,
            "uil-plus-circle": !state[filterType].active,
          })}
          onClick={(e) => {
            e.stopPropagation();
            if (isSubmitting || isSaving) return;
            toggleFilter(filterType, isChartFilter);
          }}
        />
        <i
          className="uil-trash"
          onClick={(e) => {
            e.stopPropagation();
            if (isSubmitting || isSaving) return;
            deleteFilter(filterType, isChartFilter, true);
          }}
        />
      </span>
    );
  };

  // cannot create issues based on top/deleted results
  // (top would be confusing due to recursion and top story issues, deleted not required)
  let canCreateIssue = true;
  if (["top", "manualtop", "deleted"].includes(resultsType)) canCreateIssue = false;

  // if issue contains isTop, due to a chart being clicked, this won't be saved so warn about this
  let saveWillExcludeIsTop = false;
  if (resultsType.startsWith("issue-") && Object.keys(chartFilters).includes("isTop")) saveWillExcludeIsTop = true;

  // number of filters/chartFilters present
  const numFilters = Object.keys(filters).length + Object.keys(chartFilters).length;

  const renderButtons = (isMobile) => {
    // options in the null label are not user-selectable, only used via clicking a chart
    let addOptionGroups = filterTypeOptions.filter((x) => x.label);

    // remove stakeholder options if never used
    if (!projectData.comprehendUsed) addOptionGroups = addOptionGroups.filter((x) => x.label !== "Stakeholders");

    // remove used options from groups
    addOptionGroups = reduce(
      addOptionGroups,
      (arr, optionGroup) => {
        arr.push({
          ...optionGroup,
          options: optionGroup.options.filter((x) => !Object.keys(filters).includes(x.value)),
        });
        return arr;
      },
      []
    );

    // remove any groups which are now empty
    addOptionGroups = reduce(
      addOptionGroups,
      (arr, optionGroup) => {
        if (optionGroup.options.length) arr.push(optionGroup);
        return arr;
      },
      []
    );

    return (
      <>
        {/* only show button if there are manual filter types left to add */}
        {addOptionGroups.length > 0 && (
          <UncontrolledButtonDropdown size={isMobile ? "sm" : "md"} className={classNames({ "ml-2": !isMobile })}>
            <DropdownToggle caret color="primary">
              <i className="uil-plus mr-1" />
              Add
            </DropdownToggle>
            <DropdownMenu>
              {/* options for filter types not already used */}
              {addOptionGroups.map((optionGroup) => (
                <React.Fragment key={optionGroup.label}>
                  <DropdownItem header>{optionGroup.label}</DropdownItem>
                  {optionGroup.options.map((filter) => (
                    <DropdownItem
                      key={filter.value}
                      onClick={() =>
                        !isSubmitting &&
                        !isSaving &&
                        toggleModal({ source, type: filter.value, isChartFilter: false, op: "CREATE" })
                      }
                    >
                      {filter.label}
                    </DropdownItem>
                  ))}
                </React.Fragment>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
        {user.can.updateIssues && search && !search.filterId && canCreateIssue && numFilters > 0 && (
          <Button
            size={isMobile ? "sm" : "md"}
            color="success"
            className="text-nowrap ml-2"
            onClick={() => !isSubmitting && !isSaving && toggleSaveModal({ source, filters, chartFilters })}
          >
            Save as Issue
          </Button>
        )}
        {user.can.updateIssues && filter && (
          <Button
            size={isMobile ? "sm" : "md"}
            color="success"
            className="text-nowrap ml-2"
            onClick={() => !isSubmitting && !isSaving && toggleSaveModal({ source, filters, chartFilters })}
          >
            Save as new Issue
          </Button>
        )}
        {user.can.updateIssues && search && search.filterId && (
          <>
            {/* this is being rendered so there will be changes, but don't alert when we are saving them, or when opening a result */}
            {!search.skipFilterHasChangesCheck && !isSaving && (
              <NavigationPromptModal
                when={!isSaving}
                message="This issue has unsaved changes which will be lost. Are you sure you want to change page?"
                pathComparison={({ next }) => {
                  // this is hacky
                  const openingResult =
                    (next.pathname.includes("/results/") || next.pathname.includes("/social/")) &&
                    next.pathname.split("/").length === 6;
                  // if opening result, fail check, i.e. allow result to open
                  return !openingResult;
                }}
              />
            )}
            <UncontrolledButtonDropdown size={isMobile ? "sm" : "md"} className="results-save-dropdown ml-2">
              <Button
                color="success"
                className="text-nowrap"
                onClick={async () => {
                  if (isSubmitting || isSaving) return;
                  if (saveWillExcludeIsTop)
                    await alertModal({
                      message:
                        "The current criteria contains a Top Stories filter (from clicking on a chart) which cannot be saved into an issue, however all other criteria will be saved.",
                    });
                  saveChanges();
                }}
              >
                {isSaving && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                Save Changes
              </Button>
              <DropdownToggle caret color="success"></DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={async () => {
                    if (isSubmitting || isSaving) return;
                    if (saveWillExcludeIsTop)
                      await alertModal({
                        message:
                          "The current criteria contains a Top Stories filter (from clicking on a chart) which cannot be saved into an issue, however all other criteria will be saved.",
                      });
                    toggleSaveModal({ source, filters, chartFilters });
                  }}
                >
                  Save as new Issue
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={classNames({
          "d-flex": !isMediaBreakpointDownLg,
          "mb-1": !isMediaBreakpointDownLg,
        })}
      >
        <FormCreatable
          name="search"
          options={[]}
          noOptionsMessage={() => null}
          classNamePrefix="react-select--no-options"
          placeholder={
            Object.keys(filters).includes("keyword")
              ? "Add keywords to existing keyword filter..."
              : "Filter by keywords..."
          }
          className="w-100 mb-0"
          addon={
            <InputGroupAddon addonType="append">
              <Button
                onClick={() => {
                  if (isSubmitting || isSaving || searchDisabled) return;
                  addSearch(watchSearch.map((x) => x.value));
                  reset();
                }}
                disabled={isSubmitting || isSaving || searchDisabled}
              >
                Search
              </Button>
            </InputGroupAddon>
          }
          type="keyword"
          formProps={formProps}
        />
        {!isMediaBreakpointDownLg && renderButtons(false)}
      </div>

      {/* show search resultsType if it isn't based on a saved filter and is either all/top/manualtop/deleted */}
      {search && !search.filterId && ["all", "top", "manualtop", "deleted"].includes(search.resultsType) && (
        <>
          <span className="badge badge-dark-lighten badge--disabled text-primary mt-1 mr-1">
            <i className="uil-filter" />{" "}
            <strong>
              {search.resultsType === "all" && "All Results"}
              {search.resultsType === "top" && "Top Stories"}
              {search.resultsType === "manualtop" && "Top Stories (Manual)"}
              {search.resultsType === "deleted" && "Deleted Results"}
            </strong>
          </span>
          {numFilters >= 0 && <i className="uil-plus mr-1" />}
        </>
      )}

      {search && numFilters === 0 && (
        <span className="badge badge-dark-lighten badge--disabled text-primary mt-1 mr-1">
          <strong>No Criteria</strong>
        </span>
      )}

      {/* output filters and chart filters in same order as list */}
      {filterTypesKeys.map((filterType) => (
        <React.Fragment key={filterType}>{renderFilter(filterType, false)}</React.Fragment>
      ))}
      {Object.keys(filters).length > 0 && Object.keys(chartFilters).length > 0 && <i className="uil-plus mr-1" />}
      {filterTypesKeys.map((filterType) => (
        <React.Fragment key={filterType}>{renderFilter(filterType, true)}</React.Fragment>
      ))}

      {isMediaBreakpointDownLg && <div className="mt-1 text-right">{renderButtons(true)}</div>}

      {modal && (
        <FilterModal
          data={modal}
          toggle={toggleModal}
          filter={filter}
          addFilter={addFilter}
          deleteFilter={deleteFilter}
          projectData={projectData}
          user={user}
        />
      )}

      {saveModal && (
        <SaveModal
          data={saveModal}
          toggle={async (id) => {
            toggleSaveModal();
            if (id) {
              // prefetch
              await apollo.query({
                query: gql(queryFilters),
                variables: queryFiltersGetVariables({ clientId, projectId, id }),
              });
              // redirect
              history.push(`issue-${id}`);
            }
          }}
        />
      )}
    </>
  );
};

export default Filters;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://7u4gchbygvc4vj7cimgsuysuiu.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-birhstodm5agdnrvvlreprnfbe",
    "aws_cloud_logic_custom": [
        {
            "name": "public",
            "endpoint": "https://5k19yd0ea1.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:dd5af677-f7dd-4928-a3dd-10474f593e20",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_z4v2oecfk",
    "aws_user_pools_web_client_id": "5duic6dtuqt3bbe059n5283o0j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "outcider79245b9c081d4ce79afb65b1a2e212fb120928-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;

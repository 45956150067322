import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { bodyFixedEffect, useMediaBreakpointDownLg } from "../lib/effects";
import { fnDeleteAgent, fnDisableAgent, fnDuplicateAgent, fnEnableAgent } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";

import Agent from "./Agent";
import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import CreateAgentModal from "./CreateAgentModal";
import MobileNoAccess from "../lib/mobile";
import PageTitle from "../../components/PageTitle";
import ResultsCounter from "../ResultsCounter";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import find from "lodash/find";
import { getResultSourceName } from "../../lib/utils";
import { handleDeleted } from "../lib/utils";
import { queryAgents } from "../../graphql/queries";
import { queryAgentsVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";

const Agents = ({ project, user, projectData }) => {
  const appContext = useContext(AppContext);
  useEffect(bodyFixedEffect);

  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [modal, toggleModal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mutationFnDisableAgent] = useMutation(gql(fnDisableAgent));
  const [mutationFnEnableAgent] = useMutation(gql(fnEnableAgent));
  const [mutationFnDuplicateAgent] = useMutation(gql(fnDuplicateAgent));
  const [mutationFnDeleteAgent] = useMutation(gql(fnDeleteAgent));

  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  if (isMediaBreakpointDownLg) return <MobileNoAccess title="Search Agents" />;

  // for nested routes
  const agentId = history.location.pathname.split("/")[4];
  if (agentId) {
    const deleted = handleDeleted(
      find(projectData.agents, (x) => x.id === agentId),
      "agent",
      `/${clientId}/${projectId}/agents`
    );
    if (deleted) return deleted;
  }

  const confirmModal = createConfirmation(ConfirmModal);

  const handleUpdate = async (agent, op) => {
    if (isSubmitting) return;
    const messages = {
      DISABLE: (
        <>
          <p>The following actions will happen:</p>
          <ul>
            <li>This search agent will be disabled and will no longer find any results.</li>
            <li>If the search agent is enabled later, results will be found from that point onwards.</li>
            <li>All results already found by this search agent will be kept.</li>
          </ul>
          <p>Are you sure?</p>
        </>
      ),
      ENABLE: (
        <>
          <p>The following actions will happen:</p>
          <ul>
            <li>This search agent will be enabled and will find results from this point onwards.</li>
            <li>New results will be added to any already found by this search agent.</li>
          </ul>
          <p>Are you sure?</p>
        </>
      ),
      DELETE: (
        <>
          <p>The following actions will happen:</p>
          <ul>
            <li>This search agent will be deleted and will no longer find any results.</li>
            <li>All results already found by this search agent will be deleted.</li>
          </ul>
          <p>Are you sure?</p>
        </>
      ),
    };
    const ok = await confirmModal({ message: messages[op] });
    if (!ok) return;
    setIsSubmitting(true);
    const mutations = {
      DISABLE: mutationFnDisableAgent,
      ENABLE: mutationFnEnableAgent,
      DELETE: mutationFnDeleteAgent,
    };
    try {
      // if deleted active agent, go up to listing page
      if (agentId === agent.id && op === "DELETE") {
        history.push(url);
      }
      await mutations[op]({
        variables: {
          input: {
            clientId,
            projectId,
            id: agent.id,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  const handleDuplicate = async (agent) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const result = await mutationFnDuplicateAgent({
        variables: { input: { clientId, projectId, id: agent.id } },
        update: updateCacheCreate(gql(queryAgents), queryAgentsVariables({ clientId, projectId })),
      });
      // go to new agent
      history.push(`${url}/${result.data.fnDuplicateAgent.id}`);
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageTitle title="Search Agents">
        {!project.isArchived && (
          <Button color="success" onClick={() => toggleModal(true)}>
            <i className="uil-plus mr-1" />
            Create Agent
          </Button>
        )}
      </PageTitle>

      {projectData.agents.length ? (
        <Row className="vh-with-title">
          <Col xs={3} xl={2} className="scroll">
            {projectData.agents.map((agent, i) => (
              <Card
                key={agent.id}
                role="button"
                className={classNames({
                  "d-block": true,
                  "bg-active": agentId && agentId === agent.id,
                  "mb-2": i < projectData.agents.length - 1,
                  "mb-0": i === projectData.agents.length - 1,
                })}
                onClick={() => (isSubmitting ? false : history.push(`${url}/${agent.id}`))}
                style={{ opacity: isSubmitting ? 0.5 : 1 }}
              >
                <CardBody>
                  {!project.isArchived && (
                    <UncontrolledDropdown className="card-widgets" disabled={isSubmitting}>
                      <DropdownToggle
                        tag="a"
                        className="arrow-none cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="dripicons-dots-3" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {!agent.isDraft && agent.isEnabled && (
                          <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdate(agent, "DISABLE");
                            }}
                          >
                            <i className="uil-bolt-slash mr-1" />
                            Disable
                          </DropdownItem>
                        )}
                        {!agent.isDraft && !agent.isEnabled && (
                          <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdate(agent, "ENABLE");
                            }}
                          >
                            <i className="uil-bolt mr-1" />
                            Enable
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDuplicate(agent);
                          }}
                        >
                          <i className="uil-copy mr-1" />
                          Duplicate
                        </DropdownItem>
                        <DropdownItem
                          className="text-danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdate(agent, "DELETE");
                          }}
                        >
                          <i className="uil-trash mr-1" />
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}

                  <h4 className="mt-0 mb-2">{agent.name}</h4>

                  {projectData.resultsSources.map((resultsSource) => (
                    <p key={resultsSource} className="m-0">
                      <ResultsCounter
                        icon="uil-list-ul"
                        source={resultsSource}
                        text={
                          projectData.resultsSources.length === 1
                            ? "results"
                            : `${getResultSourceName(resultsSource)} results`
                        }
                        query={{ agent: [agent.id] }}
                        projectData={projectData}
                      />
                    </p>
                  ))}

                  {agent.isDraft ? (
                    <div className="badge badge-secondary-lighten p-1 mt-2">Draft</div>
                  ) : agent.isEnabled ? (
                    <div className="badge badge-success p-1 mt-2">Active</div>
                  ) : (
                    <div className="badge badge-secondary p-1 mt-2">Disabled</div>
                  )}
                </CardBody>
              </Card>
            ))}
          </Col>
          <Switch>
            <Route exact path={path}>
              <Col xs={9} xl={10}>
                <Card body className="mb-0 h-100 card-prompt">
                  <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
                    <p className="mb-0 text-center">
                      <i className="uil-search-alt font-24 d-block" />
                      Click to edit an agent.
                    </p>
                  </div>
                </Card>
              </Col>
            </Route>
            <Route path={`${path}/:agentId`}>
              <Agent
                key={agentId}
                agent={find(projectData.agents, (x) => x.id === agentId)}
                project={project}
                user={user}
              />
            </Route>
          </Switch>
        </Row>
      ) : (
        <Card body className="mb-0 card-prompt vh-with-title">
          <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
            <p className="mb-0 text-center">
              <i className="uil-tag-alt font-24 d-block" />
              There are no agents to display.
            </p>
          </div>
        </Card>
      )}

      {modal && <CreateAgentModal data={modal} toggle={toggleModal} />}
    </>
  );
};

export default Agents;

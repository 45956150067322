import { Col, Row } from "reactstrap";
import React, { useContext } from "react";

import { AppContext } from "../../App";
import Chart from "../Charts/Chart";
import EntitiesTable from "../Charts/EntitiesTable";
import pick from "lodash/pick";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router";

const ResultsPanelEntities = (props) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  const chartProps = {
    ...pick(props, ["source", "resultsType", "filterParams", "search", "filter", "user", "project", "projectData"]),
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    apollo,
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Chart title="Stakeholder Groups" metricType="entityGroup" chartType="pie" {...chartProps} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={12} xl={6} className="mb-3">
          <EntitiesTable type="people" title="Top People" {...props} />
        </Col>
        <Col xs={12} md={6} lg={12} xl={6} className="mb-3">
          <EntitiesTable type="organisations" title="Top Organisations" {...props} />
        </Col>
      </Row>
    </>
  );
};

export default ResultsPanelEntities;

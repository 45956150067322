import { Card, CardBody } from "reactstrap";

import React from "react";
import StakeholdersPanelAll from "./StakeholdersPanelAll";
import StakeholdersPanelFollowed from "./StakeholdersPanelFollowed";
import StakeholdersPanelTagged from "./StakeholdersPanelTagged";
import classNames from "classnames";
import { useMediaBreakpointDownLg } from "../lib/effects";

const StakeholdersPanel = (props) => {
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  return (
    <Card
      className={classNames({
        "side-panel": true,
        "side-panel--gray": true,
        "vh-with-title": !isMediaBreakpointDownLg,
        "vh-with-title-wrapped-buttons": isMediaBreakpointDownLg,
      })}
    >
      <CardBody className="scroll">
        {props.stakeholderType === "all" && <StakeholdersPanelAll {...props} />}
        {props.stakeholderType === "followed" && <StakeholdersPanelFollowed {...props} />}
        {props.stakeholderType === "tagged" && <StakeholdersPanelTagged {...props} />}
      </CardBody>
    </Card>
  );
};

export default StakeholdersPanel;

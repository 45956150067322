import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { useContext, useState } from "react";
import {
  buildSearchResultsHighlightFromAgents,
  buildSearchResultsSort,
  buildSearchResultsTypeParams,
} from "../../lib/queries";
import { fnDashboard, searchResultsRaw } from "../../graphql/queries";
import { formatDateTime, sortOrderable } from "../../lib/utils";
import { getChartBuilderData, getChartEmbedOptions } from "../Charts/lib";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";
import {
  useMediaBreakpointDownLg,
  useMediaBreakpointDownLgXl,
  useMediaBreakpointDownMd,
  useMediaBreakpointDownSm,
  useMediaBreakpointDownXl,
} from "../lib/effects";

import { AppContext } from "../../App";
import { COOKIE_EXPIRY } from "../../lib/constants";
import Chart from "../Charts/Chart";
import { Excerpt } from "../lib/excerpts";
import FormSelect from "../Forms/FormSelect";
import Masonry from "react-masonry-css";
import PageTitle from "../../components/PageTitle";
import ResultPanelStory from "../Results/ResultPanelStory";
import ResultsError from "../Results/ResultsError";
import Skeleton from "react-loading-skeleton";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import find from "lodash/find";
import { getBreakpoint } from "../lib/utils";
import { updateProject } from "../../graphql/mutations";
import useCookie from "react-use-cookie";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const CHART_HEIGHT = 400;
const TOP_ROW_HEIGHT = 400;

const ResultModal = ({ data: { result, highlight }, toggle, user, projectData }) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="xl">
      <ModalHeader toggle={() => toggle()}>Story</ModalHeader>
      <ModalBody className="vh-result-preview-modal scroll">
        <ResultPanelStory result={result} highlight={highlight} isPreview user={user} projectData={projectData} />
      </ModalBody>
    </Modal>
  );
};

const Result = ({ result, isTopResult, user, projectData }) => {
  const isMediaBreakpointDownSm = useMediaBreakpointDownSm();

  // modal state in here, rather then Dashboard, to prevent chart re-renders when modals are opened
  const [modal, toggleModal] = useState(null);

  // excerpts are based on first 100 words, highlighted using active agents
  const excerptBaseIsDynamic = false;
  const activeAgents = projectData.agents.filter((x) => x.isEnabled);
  const highlight = buildSearchResultsHighlightFromAgents(activeAgents);

  // if result has an excerpt comment, only use it if it matches this issue (and only if viewing an issue, TopResult isn't)
  let forceDynamic = false;
  // OUT-39 this will probably not be required if dash is updated to group by priority issue
  // OUT-77 this logic isn't used as we only show excerpt on TopResult currently, check if still required
  // if (!isTopResult && result.commentId && result.commentFilterId !== issue.id) forceDynamic = true;

  // use half star icon unless manually topped
  let icon = "mdi-star-half";
  if (result.isTop) icon = "mdi-star";

  return (
    <>
      <Card
        key={result.id}
        role="button"
        className={classNames({
          "card-result": true,
          "d-flex": isTopResult,
        })}
        onClick={() => toggleModal({ result, highlight })}
        style={{
          marginBottom: isTopResult ? 0 : "1.5rem",
          height: isTopResult ? TOP_ROW_HEIGHT : "auto",
          flexDirection: isTopResult ? (isMediaBreakpointDownSm ? "column" : "row") : "column",
        }}
      >
        {result.image && (
          <div
            className={isTopResult ? "card-img-side" : "card-img-top"}
            style={{ backgroundImage: `url("${result.image}")` }}
          />
        )}
        <CardBody>
          <p className="text-primary font-18 mb-1">{result.title}</p>
          {/* for now we only show excerpts on TopResult */}
          {isTopResult && (
            <Excerpt
              result={result}
              highlight={highlight}
              excerptBaseIsDynamic={excerptBaseIsDynamic}
              forceDynamic={forceDynamic}
            />
          )}
          <p className="text-muted mb-0">
            <i
              className={classNames({
                mdi: true,
                [icon]: true,
              })}
            />
            {formatDateTime(result.publishedDate, user.timezone)} | {result.sourceName} | {result.sourceType}
          </p>
        </CardBody>
      </Card>

      {modal && <ResultModal data={modal} toggle={toggleModal} user={user} projectData={projectData} />}
    </>
  );
};

const Results = ({ project, issues, dateRange, viewAll, timeframe, user, projectData }) => {
  const { clientId, projectId } = useParams();

  const { loading, data, refetch, error } = useQuery(gql(fnDashboard), {
    variables: {
      input: {
        clientId,
        projectId,
        dateRange,
        viewAll,
      },
    },
    // do not cache, refetch every time dashboard is loaded
    fetchPolicy: "network-only",
  });

  const isMediaBreakpointDownSm = useMediaBreakpointDownSm();
  const isMediaBreakpointDownMd = useMediaBreakpointDownMd();
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  const isMediaBreakpointDownLgXl = useMediaBreakpointDownLgXl();
  const isMediaBreakpointDownXl = useMediaBreakpointDownXl();
  // num columns at LESS than this breakpoint
  const numColumns = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    lgXl: 4,
    xl: 5,
  };
  const breakpoint = getBreakpoint(
    isMediaBreakpointDownSm,
    isMediaBreakpointDownMd,
    isMediaBreakpointDownLg,
    isMediaBreakpointDownLgXl,
    isMediaBreakpointDownXl
  );
  const loadingColumns = numColumns[breakpoint];

  // const error = false;
  // const loading = false;

  if (error) {
    return (
      <Row>
        <Col>
          <div className="title-divider" style={{ borderTopColor: project.client.colour }}>
            <PageTitle title="Results" small />
            <div className="card-columns" style={{ columnCount: 1 }}>
              <Card className="p-3" style={{ marginBottom: "1.5rem" }}>
                <ResultsError refetch={refetch} />
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  if (loading) {
    return (
      <Row>
        <Col>
          <div className="title-divider" style={{ borderTopColor: project.client.colour }}>
            <PageTitle title="Results" small />
            <div className="card-columns" style={{ columnCount: loadingColumns }}>
              {[...Array(loadingColumns * 2)].map((x, index) => (
                <Card key={index} style={{ marginBottom: "1.5rem" }}>
                  <div className="card-img-top" />
                  <CardBody>
                    <p className="text-primary font-18 mb-1">
                      <Skeleton />
                    </p>
                    <p className="text-muted mb-0">
                      <Skeleton />
                    </p>
                  </CardBody>
                </Card>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  const response = JSON.parse(data.fnDashboard);

  return (
    <>
      {response
        .filter((x) => x.results.length)
        .map((section) => (
          <Row key={section.issueId}>
            <Col>
              <div className="title-divider" style={{ borderTopColor: project.client.colour }}>
                <PageTitle
                  title={
                    section.issueId === "ALL_METABASE"
                      ? "All Results"
                      : find(issues, (x) => x.id === section.issueId).name
                  }
                  subtitle={timeframe}
                  small
                />
                <Masonry
                  // maxWidths where columns change
                  breakpointCols={{
                    575: numColumns.xs,
                    991: numColumns.md,
                    1178: numColumns.lg, // this one is not a css breakpoint
                    1366: numColumns.lgXl,
                    default: numColumns.xl,
                  }}
                  className="masonry"
                  columnClassName="masonry-column"
                >
                  {section.results.map((result) => (
                    <Result key={result.id} result={result} user={user} projectData={projectData} />
                  ))}
                </Masonry>
              </div>
            </Col>
          </Row>
        ))}
    </>
  );
};

const TopResult = ({ dateRange, user, projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();

  const isMediaBreakpointDownSm = useMediaBreakpointDownSm();

  // get most recent top result with an image (auto or manual)
  // (anything that needs multiple sorting needs to wait for https://github.com/aws-amplify/amplify-cli/issues/6729)
  const searchParams = buildSearchResultsTypeParams({
    source: "METABASE",
    resultsType: "top",
    filterParams: {
      clientId,
      projectId,
      dateRange,
      maxDate: appContext.maxDate,
      hasImage: true,
    },
    projectData,
  });
  const searchSort = buildSearchResultsSort("-publishedDate");

  const { loading, data, refetch, error } = useQuery(gql(searchResultsRaw), {
    variables: { searchParams: JSON.stringify(searchParams), sort: searchSort, limit: 1 },
    // do not cache, refetch every time dashboard is loaded
    fetchPolicy: "network-only",
  });

  if (error) {
    return (
      <Card
        className="card-result d-flex mb-0"
        style={{ height: TOP_ROW_HEIGHT, flexDirection: isMediaBreakpointDownSm ? "column" : "row" }}
      >
        <ResultsError message="top stories" refetch={refetch} />
      </Card>
    );
  }

  if (loading) {
    return (
      <Card
        className="card-result d-flex mb-0"
        style={{ height: TOP_ROW_HEIGHT, flexDirection: isMediaBreakpointDownSm ? "column" : "row" }}
      >
        <div className="card-img-side" />
        <CardBody>
          <p className="text-primary font-20 mb-1">
            <Skeleton />
          </p>
          <p className="result-content mb-1">
            <Skeleton count={20} />
          </p>
          <p className="text-muted mb-0">
            <Skeleton />
          </p>
        </CardBody>
      </Card>
    );
  }

  const result = data.searchResultsRaw.items.length ? data.searchResultsRaw.items[0] : null;

  if (!result)
    return (
      <Card body className="card-widget card-widget--empty" style={{ height: TOP_ROW_HEIGHT }}>
        <p className="mb-0 text-center empty">
          <i className="uil-file-search-alt font-24 d-block" />
          There is no top result to display.
        </p>
      </Card>
    );

  return <Result result={result} isTopResult user={user} projectData={projectData} />;
};

const DashboardChartModal = ({ data: { name, chartId, op }, toggle, projectData }) => {
  const appContext = useContext(AppContext);
  const { projectId } = useParams();
  const [mutationUpdateProject] = useMutation(gql(updateProject));

  const initial = { [name]: chartId };

  const chartOptions = getChartEmbedOptions(projectData);

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      selects: [[name, chartOptions, false]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      const input = await processForm({ id: projectId, ...values }, { selects: [name] });
      await mutationUpdateProject({ variables: { input } });
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Add" : "Change";
  const action = op === "CREATE" ? "Add" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{title} Chart</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormSelect name={name} label="Chart" options={chartOptions} isClearable={true} formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const DashboardChart = ({ name, project, user, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  // modal state in here, rather then Dashboard, to prevent chart re-renders when modals are opened
  const [modal, toggleModal] = useState(null);

  const chartId = project[name];
  const chart = projectData.charts.find((x) => x.id === chartId);

  // no chart selected, or chart is selected but it does not exist or has been deleted, prompt to add
  if (!chartId || (chartId && !chart) || (chartId && chart && chart.isDeleted))
    return (
      <>
        <Card
          body
          className={classNames({
            "mb-0": true,
            "card-prompt": true,
            click: user.can.updateDashboardCharts,
          })}
          onClick={() => toggleModal({ name, chartId, op: "CREATE" })}
          style={{ height: CHART_HEIGHT }}
        >
          <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
            <p className="mb-0 text-center">
              <i className="uil-chart-line font-24 d-block" />
              {user.can.updateDashboardCharts ? "Click to add a chart." : "There is no chart to display."}
            </p>
          </div>
        </Card>
        {modal && <DashboardChartModal data={modal} toggle={toggleModal} projectData={projectData} />}
      </>
    );

  const builderProps = getChartBuilderData({
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    projectData,
    chartObj: chart,
  });
  const chartProps = {
    ...builderProps,
    clientId,
    projectId,
    project,
    maxDate: appContext.maxDate,
    apollo,
    isDashboard: true,
    dashboardEdit: () => toggleModal({ name, chartId, op: "UPDATE" }),
  };

  return (
    <>
      <Chart key={chartId} source={chart.source} height={CHART_HEIGHT} {...chartProps} />
      {modal && <DashboardChartModal data={modal} toggle={toggleModal} projectData={projectData} />}
    </>
  );
};

const Dashboard = ({ project, user, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  const [dateRange, setDateRange] = useCookie("DashboardDateRange", "1");

  // type of top story dashboard is defined in project settings
  // - `true` is as the normal top stories analyst view (manual + auto - exclusions)
  // - `false` is manually topped stories only
  const [viewAll, setViewAll] = useState(project.topStoriesViewAll);

  // all issues (visible and hidden), in preferred order
  // SOCIAL TODO this will change with social dashboard
  const issues = sortOrderable(projectData.filters.filter((x) => x.source === "METABASE"));

  const chartProps = {
    resultsType: "all",
    filterParams: {
      clientId,
      projectId,
      dateRange,
      maxDate: appContext.maxDate,
    },
    user,
    projectData,
    clientId,
    projectId,
    project,
    maxDate: appContext.maxDate,
    apollo,
  };
  const topStoriesChartType = viewAll ? "quickTopStoriesDashboardLineAll" : "quickTopStoriesDashboardLineManual";

  // friendly timeframe description
  const timeframe = {
    1: "from the last 24 hours",
    7: "from the last 7 days",
  }[dateRange];

  // force a re-render of Chart if the controls change
  const key = `${viewAll}/${dateRange}`;

  return (
    <>
      <PageTitle title="Dashboard" />

      <Row
        className={classNames({
          "mb-1": true,
          "mt-3": isMediaBreakpointDownLg,
        })}
      >
        <Col xs={12} lg={6} className="mb-3">
          <DashboardChart name="dashboardChart1" project={project} user={user} projectData={projectData} />
        </Col>
        <Col xs={12} lg={6} className="mb-3">
          <DashboardChart name="dashboardChart2" project={project} user={user} projectData={projectData} />
        </Col>
      </Row>

      {/* <Row
        className={classNames({
          "mb-1": true,
          "mt-3": isMediaBreakpointDownLg,
        })}
      >
        <Col xs={12} lg={6} className="mb-3">
          <Chart
            key={key}
            // SOCIAL TODO this will change with social dashboard
            source="METABASE"
            title="Issue Frequency"
            metricType="issueFrequency"
            chartType="quickDashboardLineIssues"
            whiteBg
            {...chartProps}
          />
        </Col>
        <Col xs={12} lg={6} className="mb-3">
          <Chart
            key={key}
            // SOCIAL TODO this will change with social dashboard
            source="METABASE"
            title="Reach Frequency"
            metricType="reachFrequency"
            chartType="quickDashboardLineIssues"
            whiteBg
            {...chartProps}
          />
        </Col>
      </Row> */}

      <Row>
        <Col>
          <div className="title-divider" style={{ borderTopColor: project.client.colour }}>
            <PageTitle title="Top Stories" subtitle={timeframe} wrapButtonsMediaBreakpointDownLg>
              <CustomInput
                type="switch"
                id="topStoriesView"
                label={viewAll ? "Show all top stories" : "Show manual top stories"}
                onChange={(e) => setViewAll(e.target.checked)}
                defaultChecked={viewAll}
                className="mr-2"
              />
              <ButtonGroup>
                <Button
                  outline={dateRange !== "1"}
                  color="secondary"
                  onClick={() => setDateRange("1", { days: COOKIE_EXPIRY })}
                >
                  24{dateRange === "1" && ` hours`}
                </Button>
                <Button
                  outline={dateRange !== "7"}
                  color="secondary"
                  onClick={() => setDateRange("7", { days: COOKIE_EXPIRY })}
                >
                  7{dateRange === "7" && ` days`}
                </Button>
              </ButtonGroup>
            </PageTitle>
          </div>
        </Col>
      </Row>

      <Row className="mb-1">
        <Col xs={12} md={6} className="mb-3">
          <TopResult dateRange={dateRange} user={user} projectData={projectData} />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <Chart
            key={key}
            // SOCIAL TODO this will change with social dashboard
            source="METABASE"
            title="Top Story Frequency"
            metricType="issueFrequency"
            chartType={topStoriesChartType}
            whiteBg
            {...chartProps}
          />
        </Col>
      </Row>

      <Results
        project={project}
        issues={issues}
        dateRange={dateRange}
        viewAll={viewAll}
        timeframe={timeframe}
        user={user}
        projectData={projectData}
      />
    </>
  );
};

export default Dashboard;

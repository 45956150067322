import React, { useContext, useEffect } from "react";

import { AppContext } from "../../App";
import Chart from "./Chart";
import ChartTitle from "./ChartTitle";
import { SizeMe } from "react-sizeme";
import { bodyFixedEffect } from "../lib/effects";
import { getChartBuilderData } from "./lib";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";

const ChartBuilder = ({ chart, toggleModal, user, project, projectData }) => {
  useEffect(bodyFixedEffect);
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  // build props
  const builderProps = getChartBuilderData({
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    projectData,
    chartObj: chart,
  });
  const chartProps = {
    ...builderProps,
    clientId,
    projectId,
    project,
    maxDate: appContext.maxDate,
    apollo,
  };

  return (
    <>
      <ChartTitle chart={chart} toggleModal={toggleModal} user={user} />
      <div>
        <SizeMe monitorHeight>
          {({ size }) => (
            <div className="vh-with-title">
              <Chart source={chart.source} height={size.height} {...chartProps} />
            </div>
          )}
        </SizeMe>
      </div>
    </>
  );
};

export default ChartBuilder;

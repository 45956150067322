import { CustomInput, FormGroup, Label } from "reactstrap";

import { Controller } from "react-hook-form";
import FormError from "./FormError";
import React from "react";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

const FormSwitch = ({
  name,
  label,
  inlineLabelOn,
  inlineLabelOff,
  oneLine,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  onChange,
  isDisabled,
  formProps,
  ...rest
}) => (
  <FormGroup
    className={classNames({
      "form-error": formProps.errors[name],
      "form-switch-oneline": oneLine,
      [`${className}`]: className,
    })}
  >
    {label && <Label for={name}>{label}:</Label>}
    <Controller
      name={name}
      control={formProps.control}
      // OUT-128 defaultValues are set in populateForm and passed to useForm
      render={(props) => (
        <CustomInput
          type="switch"
          id={uuid()}
          label={
            inlineLabelOn && inlineLabelOff ? (
              <span className="custom-switch-inline-label">{props.value ? inlineLabelOn : inlineLabelOff}</span>
            ) : (
              ""
            )
          }
          checked={props.value}
          onChange={(e) => {
            props.onChange(e.target.checked); // react-hook-form onChange
            onChange && onChange(); // custom onChange if provided
          }}
        />
      )}
    />
    {!hideErrors && (
      <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
    )}
  </FormGroup>
);

export default FormSwitch;

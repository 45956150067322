module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  {{#if (or title @root.switches.isBuilder)}}
    <mj-section mj-class="p-0-pb-10">
      <mj-column>
        <mj-text mj-class="block-title">{{#if title}}{{title}}{{else}}[{{type}}]{{/if}}</mj-text>
      </mj-column>
    </mj-section>
  {{/if}}
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->

  {{#each results}}
    <mj-section mj-class="{{#if @last}}p-0-pb-10{{else}}p-0-pb-20{{/if}}" css-class="report-builder--relative report-builder--hover">
      {{#if @root.switches.isBuilder}}
        {{> builderResultOrdering}}
        {{> builderResultActions}}
      {{/if}}
      <mj-column>
        {{#if (eq source "SOCIAL360")}}
          <mj-text css-class="report-quote-title" mj-class="pt-0 pb-0 result-title-quote">
            <a href="{{url}}" class="report-link" target="_blank">{{title}}</a>
          </mj-text>
        {{/if}}
        <mj-text css-class="report-quote-text" mj-class="pt-0 pb-0">{{{this.excerpt}}}</mj-text>
        <mj-text css-class="report-quote-meta" mj-class="result-meta pt-0 pb-0">
          <!--[if mso | IE]><div style="border-top:10px solid {{../colour}};"><![endif]-->
          <a href="{{url}}" class="report-quote-link">{{> resultMeta}}</a>
          <!--[if mso | IE]></div><![endif]-->
        </mj-text>
      </mj-column>
    </mj-section>
  {{/each}}
</mj-wrapper>`
import { Modal, ModalHeader } from "reactstrap";

import React from "react";
import RecipientsForm from "../Modals/RecipientsForm";

const ShareModal = ({ data, toggle, onComplete, user, projectData }) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Share</ModalHeader>
      <RecipientsForm
        type="SHARE"
        shareResultIds={data}
        isModal
        onComplete={onComplete || toggle}
        user={user}
        projectData={projectData}
      />
    </Modal>
  );
};

export default ShareModal;

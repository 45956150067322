import { Col, Row } from "reactstrap";
import React, { useContext } from "react";

import { AppContext } from "../../App";
import Chart from "../Charts/Chart";
import get from "lodash/get";
import pick from "lodash/pick";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router";

const ResultsPanelOverview = (props) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  const chartProps = {
    ...pick(props, ["source", "resultsType", "filterParams", "search", "filter", "user", "project", "projectData"]),
    clientId,
    projectId,
    maxDate: appContext.maxDate,
    apollo,
  };

  // if there is an isTop chartFilter, results frequency chart cannot be rendered (requires refactor)
  const chartFilterHasIsTop = Boolean(get(props.filterParams, "chartQuery.isTop"));

  const renderResultFrequencyChart = () => {
    let chartType = "line";
    if (props.resultsType === "all") chartType = "quickResultsPanelLineAll";
    if (props.resultsType === "top") chartType = "quickResultsPanelLineTop";
    if (props.resultsType === "manualtop") chartType = "quickResultsPanelLineManualTop";
    if (props.resultsType.startsWith("issue-")) chartType = "quickResultsPanelLineIssue";
    return <Chart title="Result Frequency" metricType="resultFrequency" chartType={chartType} {...chartProps} />;
  };

  return (
    <>
      {!chartFilterHasIsTop && (
        <Row className="mb-3">
          <Col>{renderResultFrequencyChart()}</Col>
        </Row>
      )}
      {props.resultsType.startsWith("issue-") && (
        // className is on Chart as chart may have no keywords
        <Row>
          <Col>
            <Chart
              title="Keyword Frequency"
              metricType="keywordFrequency"
              chartType="wordcloud"
              className="mb-3"
              {...chartProps}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} xl={4} className="mb-3">
          {props.source === "METABASE" && (
            <Chart title="Top Countries" metricType="country" chartType="pie" {...chartProps} />
          )}
          {props.source === "SOCIAL360" && (
            <Chart title="Media Type" metricType="mediaType" chartType="pie" {...chartProps} />
          )}
        </Col>
        <Col xs={12} xl={4} className="mb-3">
          <Chart title="Top Languages" metricType="language" chartType="pie" {...chartProps} />
        </Col>
        <Col xs={12} xl={4} className="mb-3">
          <Chart title="Top Sources" metricType="sourceName" chartType="pie" {...chartProps} />
        </Col>
      </Row>
    </>
  );
};

export default ResultsPanelOverview;

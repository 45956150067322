import React, { useContext } from "react";

import { AppContext } from "../../App";
import Chart from "../Charts/Chart";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";

const StakeholdersPanelAll = ({ entityType, filter, user, project, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const { clientId, projectId } = useParams();

  const chartProps = {
    height: 300,
    // all results with current issue query
    resultsType: "all",
    filterParams: {
      clientId,
      projectId,
      dateRange: "7",
      maxDate: appContext.maxDate,
      query: filter ? JSON.parse(filter.query) : {},
    },
    filter,
    user,
    projectData,
    clientId,
    projectId,
    project,
    maxDate: appContext.maxDate,
    apollo,
  };

  return (
    <>
      <div className="mb-3">
        <Chart
          source="METABASE"
          title={`Most Active ${entityType === "people" ? "People" : "Organisations"}`}
          subtitle={`from the last 7 days across ${filter ? "this issue" : "all results"}`}
          metricType={entityType === "people" ? "person" : "organisation"}
          chartType="quickStakeholdersPanelPieAll"
          {...chartProps}
        />
      </div>
      <div className="mb-0">
        <Chart
          source="METABASE"
          title={`Most Active ${entityType === "people" ? "People" : "Organisations"}`}
          subtitle={`from the last 7 days across ${filter ? "this issue" : "all results"}`}
          metricType={entityType === "people" ? "person" : "organisation"}
          chartType="quickStakeholdersPanelLineAll"
          {...chartProps}
        />
      </div>
    </>
  );
};

export default StakeholdersPanelAll;

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import React from "react";
import { confirmable } from "react-confirm";

const AlertModal = ({ show, proceed, message }) => (
  <Modal className="modal-dialog-centered modal-confirmable" isOpen={show}>
    <ModalHeader toggle={() => proceed(false)}>Message</ModalHeader>
    <ModalBody>{message}</ModalBody>
    <ModalFooter>
      <Button onClick={() => proceed(false)}>OK</Button>
    </ModalFooter>
  </Modal>
);

export default confirmable(AlertModal);

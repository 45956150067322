import { CustomInput, ListGroup, ListGroupItem } from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useContext, useState } from "react";
import { getResultPrefixForSource, sortOrderable } from "../../lib/utils";
import { gql, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router";

import { AppContext } from "../../App";
import Spinner from "../../components/Spinner";
import UpdateModal from "../Filters/UpdateModal";
import classNames from "classnames";
import { fnOrder } from "../../graphql/custom/mutations";
import { getResultSourceName } from "../../lib/utils";
import { reorderDraggables } from "../lib/utils";
import useDeepCompareEffect from "use-deep-compare-effect";

const Filter = ({ source, filter, toggleModal, dragHandleProps, user }) => {
  const history = useHistory();
  const { clientId, projectId } = useParams();

  const sourcePrefix = getResultPrefixForSource(source);

  return (
    <ListGroupItem
      key={filter.id}
      className={classNames({
        "d-flex": true,
        click: true,
        "text-muted": !filter.isVisible,
      })}
      onClick={() => history.push(`/${clientId}/${projectId}/${sourcePrefix}/issue-${filter.id}`)}
    >
      <div className="flex-grow-1">
        {filter.isTop && <i className="mdi mdi-star mr-1" />}
        {filter.name}
      </div>
      {user.can.updateIssues && (
        <>
          {dragHandleProps && <i className="uil-arrows-v ml-1" {...dragHandleProps} />}
          <i
            className="mdi mdi-pencil-outline ml-1"
            onClick={(e) => {
              e.stopPropagation();
              toggleModal(filter);
            }}
          />
        </>
      )}
    </ListGroupItem>
  );
};

const ResultsMenu = ({ source, user, projectData }) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { clientId, projectId } = useParams();
  const [showHidden, setShowHidden] = useState(false);
  const [modal, toggleModal] = useState(null);

  const visibleFilters = projectData.filters.filter((x) => x.source === source && x.isVisible);
  const hiddenFilters = projectData.filters.filter((x) => x.source === source && !x.isVisible);
  const numFilters = visibleFilters.length + hiddenFilters.length;

  const orderableItems = sortOrderable(visibleFilters);
  const [optimisticItems, setOptimisticItems] = useState(null);
  const [stateItems, setStateItems] = useState(orderableItems);
  const [isOrdering, setIsOrdering] = useState(false);
  const [mutationFnOrder] = useMutation(gql(fnOrder));

  const sourcePrefix = getResultPrefixForSource(source);

  // needed to handle items moving between visible/hidden lists
  useDeepCompareEffect(() => {
    setStateItems(orderableItems);
  }, [orderableItems]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    setIsOrdering(true);

    try {
      // reorder and provide optimistic UI while persist happens
      const reordered = reorderDraggables(orderableItems, result.source.index, result.destination.index);
      setOptimisticItems(reordered);

      // persist changes
      const ids = reordered.map((x) => x.id);
      await mutationFnOrder({
        variables: {
          input: {
            clientId,
            projectId,
            ids,
            model: "Filter",
          },
        },
      });

      // switch back to using stateItems
      setOptimisticItems(null);
    } catch (e) {
      appContext.handleError(e);
      // fallback to last known state
      setOptimisticItems(null);
    }

    setIsOrdering(false);
  };

  return (
    <>
      <div className="results-menu vh-with-title scroll">
        <ListGroup flush className="font-16 font-bold">
          <ListGroupItem
            className="click"
            onClick={() => history.push(`/${clientId}/${projectId}/${sourcePrefix}/all`)}
          >
            <i className="uil-file-search-alt mr-1" />
            All Results
          </ListGroupItem>
          <ListGroupItem
            className="click"
            onClick={() => history.push(`/${clientId}/${projectId}/${sourcePrefix}/top`)}
          >
            <i className="mdi mdi-star mr-1" />
            Top Stories
          </ListGroupItem>
          <ListGroupItem
            className="click"
            onClick={() => history.push(`/${clientId}/${projectId}/${sourcePrefix}/manualtop`)}
          >
            <i className="mdi mdi-star mr-1" />
            Top Stories (Manual)
          </ListGroupItem>
          {user.can.viewDeletedResults && (
            <ListGroupItem
              className="click"
              onClick={() => history.push(`/${clientId}/${projectId}/${sourcePrefix}/deleted`)}
            >
              <i className="uil-trash-alt mr-1" />
              Deleted Results
            </ListGroupItem>
          )}
        </ListGroup>

        <ListGroup flush>
          <ListGroupItem className="d-flex align-items-center font-16 font-bold">
            <div className="flex-grow-1">Issues</div>
            {isOrdering && <Spinner className="spinner-border-sm ml-2" tag="span" />}
          </ListGroupItem>
          {numFilters > 0 ? (
            <>
              {visibleFilters.length > 0 && (
                <DragDropContext isDropDisabled={isOrdering || !user.can.updateIssues} onDragEnd={onDragEnd}>
                  <Droppable droppableId="issues">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {(optimisticItems || stateItems).map((filter, index) => (
                          <Draggable key={filter.id} draggableId={filter.id} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps}>
                                <Filter
                                  source={source}
                                  filter={filter}
                                  toggleModal={toggleModal}
                                  dragHandleProps={provided.dragHandleProps}
                                  user={user}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {hiddenFilters.length > 0 && user.can.viewHiddenIssues && (
                <>
                  <ListGroupItem className="d-flex text-muted">
                    <div className="flex-grow-1">
                      <i className="uil-eye-slash mr-1" />
                      {hiddenFilters.length} hidden issue{hiddenFilters.length !== 1 && "s"}
                    </div>
                    <CustomInput
                      type="switch"
                      id="showHidden"
                      label="Show"
                      onChange={(e) => setShowHidden(e.target.checked)}
                      defaultChecked={showHidden}
                      className="ml-2"
                    />
                  </ListGroupItem>
                  {showHidden && (
                    <>
                      {hiddenFilters.map((filter) => (
                        <Filter key={filter.id} source={source} filter={filter} toggleModal={toggleModal} user={user} />
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <p className="text-muted">There are no {getResultSourceName(source)} issues to display.</p>
          )}
        </ListGroup>
      </div>

      {modal && <UpdateModal data={modal} toggle={toggleModal} />}
    </>
  );
};

export default ResultsMenu;

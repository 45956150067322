import * as md5 from "md5";

import Highlighter from "react-highlight-words";
import React from "react";
import escapeStringRegexp from "escape-string-regexp";
import { languageWordBoundary } from "../../lib/constants";

export const getExcerptHash = (excerptQuery) => md5(excerptQuery);

export const Excerpt = ({ result, highlight, excerptBaseIsDynamic, forceDynamic }) => {
  // if result has a excerpt comment, use this, already HTML
  if (result.commentId && !forceDynamic) {
    return (
      <div className="result-content result-excerpt" dangerouslySetInnerHTML={{ __html: result.commentContent }} />
    );
  }

  let excerptBase = null;
  if (excerptBaseIsDynamic) {
    // for search/issues, use keyword-oriented excerpt generated by ES as base
    const excerptHash = getExcerptHash(highlight.query);
    excerptBase = result.excerpt[excerptHash];
  } else {
    // use first 100 words as base, fallback to null
    excerptBase = result.content100 || null;
  }

  // handle when there is nothing to use
  if (!excerptBase) return <div className="result-content">No article excerpt available.</div>;

  const wordBoundary = languageWordBoundary(result.language);
  return (
    <div className="result-content">
      <Highlighter
        searchWords={highlight.terms.map((word) => `${wordBoundary}${escapeStringRegexp(word)}${wordBoundary}`)} // match full words
        autoEscape={false}
        textToHighlight={excerptBase}
        highlightTag="u"
      />
    </div>
  );
};

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { fnArchiveProject, fnDeleteProject, fnUnarchiveProject } from "../../graphql/mutations";
import { gql, useMutation, useQuery } from "@apollo/client";
import { listClients, listProjects } from "../../graphql/queries";
import { listClientsVariables, listProjectsVariables } from "../../lib/variables";

import { AppContext } from "../../App";
import ConfirmModal from "../Modals/ConfirmModal";
import PageTitle from "../../components/PageTitle";
import ProjectModal from "../Modals/ProjectModal";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import { fetchAllEffect } from "../lib/effects";
import { getThumborImage } from "../../lib/files";
import orderBy from "lodash/orderBy";
import { sort } from "../../lib/utils";
import { useHistory } from "react-router";

const Projects = ({ user }) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);

  // get projects on the users clients
  const { data, error, fetchMore } = useQuery(gql(listProjects), { variables: listProjectsVariables() });
  useEffect(() => fetchAllEffect(data, error, fetchMore, setLoading), [data, error, fetchMore]);
  const { data: dataClients, error: errorClients, fetchMore: fetchMoreClients } = useQuery(gql(listClients), {
    variables: listClientsVariables(),
  });
  useEffect(() => fetchAllEffect(dataClients, errorClients, fetchMoreClients, setLoadingClients), [
    dataClients,
    errorClients,
    fetchMoreClients,
  ]);

  const [modal, toggleModal] = useState(null);
  const [mutationFnArchiveProject] = useMutation(gql(fnArchiveProject));
  const [mutationFnUnarchiveProject] = useMutation(gql(fnUnarchiveProject));
  const [mutationFnDeleteProject] = useMutation(gql(fnDeleteProject));

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (loading || loadingClients) return <Spinner className="loading-full" />;

  // order clients by name, outcider first
  let clients = dataClients.listClients.items.filter((x) => user.clients.includes(x.id));
  clients = sort(
    clients.filter((x) => x.id === "outcider"),
    "name"
  ).concat(
    sort(
      clients.filter((x) => x.id !== "outcider"),
      "name"
    )
  );

  const confirmModal = createConfirmation(ConfirmModal);

  const handleUpdate = async (project, op) => {
    if (isSubmitting) return;
    const messages = {
      ARCHIVE: (
        <>
          <p>The following actions will happen:</p>
          <ul>
            <li>All search agents will be disabled, no new results will be found while the project is archived.</li>
            <li>If the project is unarchived later, results will be found from that point onwards.</li>
            <li>All alert subscriptions on the project will be paused.</li>
            <li>All data will be kept but the project will not be accessible while archived.</li>
          </ul>
          <p>Are you sure?</p>
        </>
      ),
      UNARCHIVE: (
        <>
          <p>The following actions will happen:</p>
          <ul>
            <li>All search agents will be enabled, results will be found from this point onwards.</li>
            <li>All alert subscriptions on the project will be restarted.</li>
          </ul>
          <p>Are you sure?</p>
        </>
      ),
      DELETE: "All data for this project will be deleted. Are you sure?",
    };
    const ok = await confirmModal({ message: messages[op] });
    if (!ok) return;
    setIsSubmitting(true);
    const mutations = {
      ARCHIVE: mutationFnArchiveProject,
      UNARCHIVE: mutationFnUnarchiveProject,
      DELETE: mutationFnDeleteProject,
    };
    try {
      await mutations[op]({
        variables: {
          input: {
            clientId: project.clientId,
            projectId: project.id,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  const getClientProjects = (clientId) =>
    orderBy(
      data.listProjects.items.filter((x) => x.clientId === clientId && !x.isDeleted && user.can.readProject(x.id)),
      [(x) => x.name.toLowerCase(), (x) => x.isArchived],
      ["asc", "desc"]
    );

  return (
    <>
      {clients.map((client) => (
        <React.Fragment key={client.id}>
          <PageTitle
            title={
              <img
                src={getThumborImage(client.logo, 300, 92, true)}
                alt={client.name}
                style={{ maxWidth: 150, maxHeight: 46 }}
              />
            }
          >
            {user.can.createProject && (
              <Button color="success" onClick={() => toggleModal({ data: client.id, op: "CREATE" })}>
                <i className="uil-plus mr-1" />
                Create Project
              </Button>
            )}
          </PageTitle>
          <div className="title-divider mb-3" style={{ borderTopColor: client.colour }} />
          <Row className="mb-2">
            {getClientProjects(client.id).length ? (
              getClientProjects(client.id).map((project) => (
                <Col xs={12} sm={6} md={4} lg={3} key={project.id}>
                  <Card
                    role="button"
                    className="d-block"
                    onClick={() => (isSubmitting ? false : history.push(`/${client.id}/${project.id}/dashboard`))}
                    style={{ opacity: isSubmitting ? 0.5 : 1 }}
                  >
                    <div
                      className="card-img-top"
                      style={{
                        backgroundImage: project.image
                          ? `url(${getThumborImage(project.image, 1000, 1000, false, false)})`
                          : "",
                      }}
                    />
                    <div className="card-img-overlay">
                      <div
                        className={classNames(
                          "badge",
                          {
                            "badge-success": project.isArchived === false,
                            "badge-secondary": project.isArchived === true,
                          },
                          "p-1"
                        )}
                      >
                        {project.isArchived ? "Archived" : "Active"}
                      </div>
                    </div>
                    <CardBody className="position-relative">
                      {(user.can.updateProject(project.id) || user.can.deleteProject(project.id)) && (
                        <UncontrolledDropdown className="card-widgets" disabled={isSubmitting}>
                          <DropdownToggle
                            tag="a"
                            className="arrow-none cursor-pointer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <i className="dripicons-dots-3" />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {user.can.updateProject(project.id) && (
                              <>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleModal({ data: project, op: "UPDATE" });
                                  }}
                                >
                                  <i className="uil-edit mr-1" />
                                  Edit
                                </DropdownItem>
                                {!project.isArchived && (
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdate(project, "ARCHIVE");
                                    }}
                                  >
                                    <i className="uil-archive mr-1" />
                                    Archive
                                  </DropdownItem>
                                )}
                                {project.isArchived && (
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdate(project, "UNARCHIVE");
                                    }}
                                  >
                                    <i className="uil-archive mr-1" />
                                    Unarchive
                                  </DropdownItem>
                                )}
                              </>
                            )}
                            {user.can.deleteProject(project.id) && (
                              <DropdownItem
                                className="text-danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUpdate(project, "DELETE");
                                }}
                              >
                                <i className="uil-trash mr-1" />
                                Delete
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                      <h4 className="text-title mt-0">{project.name}</h4>
                      <p className="text-muted font-13 mb-3">{project.description}</p>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col>
                <Alert color="info" fade={false} className="mb-3">
                  There are no projects to display.
                </Alert>
              </Col>
            )}
          </Row>
        </React.Fragment>
      ))}

      {modal && <ProjectModal data={modal} toggle={toggleModal} />}
    </>
  );
};

export default Projects;

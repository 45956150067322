module.exports=`<mj-wrapper mj-class="{{class}} border-top-2px-client" css-class="report-builder--relative">
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  {{#if @root.switches.isBuilder}}
    {{> builderBlockOrdering}}
    {{> builderBlockActions}}
  {{/if}}

  {{#if (or title @root.switches.isBuilder)}}
    <mj-section mj-class="p-0">
      <mj-column>
        <mj-text mj-class="block-title grid-">{{#if title}}{{title}}{{else}}[{{type}}]{{/if}}</mj-text>
      </mj-column>
    </mj-section>
  {{/if}}
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->

  {{#each results}}
    <mj-section mj-class="p-0" css-class="report-section-with-columns">
      {{#each this}}
        {{#if this}}
          <mj-column mj-class="width-50 pb-10" css-class="report-builder--relative report-builder--hover">
            {{#if (and thumborImage showImage)}}
              <mj-image css-class="{{#if @first}}report-grid-left{{else}}report-grid-right{{/if}}" src="{{thumborImage}}" href="{{url}}" fluid-on-mobile="true" mj-class="pb-0" />
            {{/if}}

            <mj-table css-class="{{#if @first}}report-grid-left{{else}}report-grid-right{{/if}}" mj-class="result-title-small {{#if @first}}report-grid-left-title-table{{else}}report-grid-right-title-table{{/if}}">
              <tr>
                {{#if authorImage}}
                  <td width="36" class="report-result-title-author">
                    <img width="28" height="28" class="report-result-title-author-img" src="{{authorImage}}" />
                  </td>
                {{/if}}
                <td>
                  <a href="{{url}}" class="report-result-title-small-text report-link" target="_blank">{{title}}</a>
                </td>
              </tr>
            </mj-table>
            
            {{#if (eq source "SOCIAL360")}}
              <mj-text css-class="{{#if @first}}report-grid-left{{else}}report-grid-right{{/if}}" mj-class="result-content">
                {{{this.excerpt}}}
              </mj-text>
            {{/if}}
            <mj-text css-class="{{#if @first}}report-grid-left{{else}}report-grid-right{{/if}}" mj-class="result-meta-small">{{> resultMeta multiline=true}}</mj-text>
            <div class="{{#if @first}}report-grid-left{{else}}report-grid-right{{/if}} report-builder--ordering-actions-grid">
              {{#if @root.switches.isBuilder}}
                {{> builderResultOrdering}}
                {{> builderResultActions}}
              {{/if}}
            </div>
          </mj-column>
        {{else}}
          <mj-column />
        {{/if}}
      {{/each}}
    </mj-section>
  {{/each}} 
</mj-wrapper>`
import React, { Component } from "react";

import NonProject from "./NonProject";
import Project from "./Project";
import { rootRoutes } from "../routes";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "react-router-dom";

class VerticalLayout extends Component {
  constructor(props) {
    super(props);
    this.openLeftMenu = this.openLeftMenu.bind(this);
  }

  // left menu for mobile
  openLeftMenu = () => {
    if (document.body) document.body.classList.add("sidebar-enable");
  };

  render() {
    // hardcode layout as redux has been removed (<body> tags hardcoded too)
    const layout = {
      layoutType: "vertical",
      layoutWidth: "fluid",
      leftSideBarTheme: "dark",
      leftSideBarType: "condensed",
      showRightSidebar: false,
    };
    const layoutProps = { ...this.props, layout, openLeftMenu: this.openLeftMenu };

    const rootPaths = rootRoutes.map((x) => x.path);
    const rootNames = rootRoutes.reduce((a, x) => ({ ...a, [x.path]: x.name }), {});

    return (
      <div className="app">
        <div className="wrapper">
          {rootPaths.includes(this.props.location.pathname) ? (
            <NonProject {...layoutProps} title={rootNames[this.props.location.pathname]} user={this.props.user} />
          ) : (
            <Project {...layoutProps} user={this.props.user} />
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(withRouter(VerticalLayout));

import { Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import React, { useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { formatDateTime, sort } from "../../lib/utils";

import PageTitle from "../../components/PageTitle";
import ReportBuilder from "./ReportBuilder";
import ReportModal from "./ReportModal";
import ReportStatistics from "./ReportStatistics";
import classNames from "classnames";
import find from "lodash/find";
import { handleDeleted } from "../lib/utils";
import { useParams } from "react-router-dom";

const ReportTable = ({ reports, isDraft, user }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const openBuilder = (report) => history.push(`${url}/${report.id}/builder`);
  const openStats = (report) => history.push(`${url}/${report.id}/statistics`);

  return (
    <Card className={classNames({ "card-table": true, "mb-0": !isDraft })}>
      <CardHeader className="font-bold">{isDraft ? "Draft" : "Sent"} Reports</CardHeader>
      <CardBody className="p-0">
        <Table hover className="mb-0">
          <tbody>
            {reports.length === 0 && (
              <tr className="empty">
                <td className="pl-3 pr-3 text-muted">There are no {isDraft ? "draft" : "sent"} reports to display.</td>
              </tr>
            )}
            {reports.map((report) => (
              <tr key={report.id} className="click" onClick={() => (isDraft ? openBuilder(report) : openStats(report))}>
                <td className="pl-3 pr-3 align-middle">
                  <span className="font-bold">{report.name}</span>
                  {isDraft && (
                    <span className="d-block text-muted">
                      Created: {formatDateTime(report.createdAt, user.timezone)}
                    </span>
                  )}
                  {!isDraft && (
                    <span className="d-block text-muted">
                      Last sent: {formatDateTime(report.sentAt, user.timezone)}
                    </span>
                  )}
                </td>
                <td className="pl-3 pr-3 text-right align-middle table-cell-wrap-buttons">
                  <Button
                    size="sm"
                    color={isDraft ? "primary" : "secondary"}
                    className="mt-1"
                    outline={!isDraft}
                    onClick={(e) => {
                      e.stopPropagation();
                      openBuilder(report);
                    }}
                  >
                    <i className="uil-grid mr-1" />
                    Build
                  </Button>
                  {!isDraft && (
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-1 mt-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        openStats(report);
                      }}
                    >
                      <i className="uil-chart-line mr-1" />
                      Statistics
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const Reports = ({ user, client, project, projectData }) => {
  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [modal, toggleModal] = useState(null);

  // for nested routes
  const reportId = history.location.pathname.split("/")[4];
  if (reportId) {
    const deleted = handleDeleted(
      find(projectData.reports, (x) => x.id === reportId),
      "report",
      `/${clientId}/${projectId}/reports`
    );
    if (deleted) return deleted;
  }

  const draftReports = sort(
    projectData.reports.filter((x) => !x.sentAt),
    "createdAt",
    "desc"
  );
  const sentReports = sort(
    projectData.reports.filter((x) => x.sentAt),
    "sentAt",
    "desc"
  );
  const numReports = draftReports.length + sentReports.length;

  return (
    <>
      <Row>
        <Col>
          <Switch>
            <Route exact path={path}>
              <PageTitle title="Reports">
                <Button color="success" onClick={() => toggleModal({ data: null, op: "CREATE" })}>
                  <i className="uil-plus mr-1" />
                  Create Report
                </Button>
              </PageTitle>

              {numReports === 0 ? (
                <Card body className="mb-0 card-prompt vh-with-title">
                  <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
                    <p className="mb-0 text-center">
                      <i className="uil-newspaper font-24 d-block" />
                      There are no reports to display.
                    </p>
                  </div>
                </Card>
              ) : (
                <>
                  <ReportTable reports={draftReports} isDraft={true} user={user} />
                  <ReportTable reports={sentReports} isDraft={false} user={user} />
                </>
              )}
            </Route>

            <Route path={`${path}/:reportId/builder`}>
              <ReportBuilder
                key={reportId}
                report={find(projectData.reports, (x) => x.id === reportId)}
                toggleModal={toggleModal}
                user={user}
                client={client}
                project={project}
                projectData={projectData}
              />
            </Route>

            <Route path={`${path}/:reportId/statistics`}>
              <ReportStatistics
                key={reportId}
                report={find(projectData.reports, (x) => x.id === reportId)}
                user={user}
                client={client}
                projectData={projectData}
              />
            </Route>
          </Switch>
        </Col>
      </Row>

      {modal && <ReportModal data={modal} toggle={toggleModal} user={user} projectData={projectData} />}
    </>
  );
};

export default Reports;

import { Button, Card } from "reactstrap";
import React, { useEffect, useState } from "react";
import { bodyFixedEffect, useMediaBreakpointDownMd } from "../lib/effects";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import StakeholdersListing from "./StakeholdersListing";
import StakeholdersSettings from "./StakeholdersSettings";
import { handleDeleted } from "../lib/utils";
import { queryFilters } from "../../graphql/queries";
import { queryFiltersGetVariables } from "../../lib/variables";

const Stakeholders = ({ user, project, projectData }) => {
  useEffect(bodyFixedEffect);

  const history = useHistory();
  const { clientId, projectId, entityType, stakeholderType, resultsType } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);

  // mobile
  const isMediaBreakpointDownMd = useMediaBreakpointDownMd();
  const stakeholderId = history.location.pathname.split("/")[7];
  const [mobileViewSettings, setMobileViewSettings] = useState(false);

  // for nested routes
  let filterId;
  if (resultsType.startsWith("issue-")) filterId = resultsType.substr(6);

  // get filter
  const { loading, data } = useQuery(gql(queryFilters), {
    variables: queryFiltersGetVariables({ clientId, projectId, id: filterId }),
    skip: !filterId,
  });

  if (loading) {
    return <Spinner className="loading-full" />;
  }

  // if filtering check it exists
  let filter = null;
  if (filterId) {
    filter = data.queryFilters.items.length ? data.queryFilters.items[0] : null;
    const deleted = handleDeleted(filter, "issue", `/${clientId}/${projectId}/stakeholders/${entityType}/all/all`);
    if (deleted) return deleted;
  }

  const settingsProps = { filterId };

  // upsell when comprehend never used
  if (!projectData.comprehendUsed)
    return (
      <>
        <PageTitle title="Stakeholders" />
        <Card body className="mb-0 card-prompt vh-with-title">
          <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
            <p className="mb-0 text-center">
              <i className="uil-users-alt font-24 d-block" />
              Stakeholder analysis is not enabled for this project.
            </p>
          </div>
        </Card>
      </>
    );

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="uil-bars click mr-2" onClick={() => setMenuOpen(!menuOpen)} />
            <span
              className="click"
              onClick={() =>
                history.push(`/${clientId}/${projectId}/stakeholders/${entityType}/${stakeholderType}/all`)
              }
            >
              Stakeholders
            </span>
            {filter && (
              <span className="text-title">
                <i className="uil-angle-right ml-1 mr-1" />
                {filter.name}
              </span>
            )}
          </>
        }
        wrapButtonsMediaBreakpointDownLg
      >
        {isMediaBreakpointDownMd ? (
          <div className="settings-popup-container">
            <Button color="success" disabled={stakeholderId} onClick={() => setMobileViewSettings(!mobileViewSettings)}>
              <i className="uil-cog" />
            </Button>
            {mobileViewSettings && !stakeholderId && (
              <div className="settings-popup">
                <StakeholdersSettings {...settingsProps} isPopup />
              </div>
            )}
          </div>
        ) : (
          <StakeholdersSettings {...settingsProps} />
        )}
      </PageTitle>

      <StakeholdersListing
        // needed to clear filters if entity/stakeholder type changes
        key={`${entityType}/${stakeholderType}`}
        filter={filter}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        user={user}
        project={project}
        projectData={projectData}
      />
    </>
  );
};

export default Stakeholders;

module.exports=`{{#if (or (and title bannerShowTitle) @root.switches.isBuilder)}}
  <!-- [__OUTCIDER_START_BLOCK_HEADER__] -->
  <mj-wrapper mj-class="{{class}} border-top-2px-client pb-15" css-class="report-builder--relative">
    {{#if @root.switches.isBuilder}}
      {{> builderBlockOrdering}}
      {{> builderBlockActions}}
    {{/if}}

    <mj-section mj-class="p-0">
      <mj-column>
        <mj-text mj-class="block-title">{{#if (and title bannerShowTitle)}}{{title}}{{else}}[{{type}}]{{/if}}</mj-text>
      </mj-column>
    </mj-section>
  </mj-wrapper>
  <!-- [__OUTCIDER_END_BLOCK_HEADER__] -->
{{/if}}

{{#each results}}
  <mj-wrapper mj-class="{{class}} border-top-2px-client p-0-pb-15">
    <mj-section mj-class="p-0" css-class="report-builder--relative report-builder--hover">
      {{#if @root.switches.isBuilder}}
        {{> builderResultOrdering}}
        {{> builderResultActions}}
      {{/if}}
      <mj-column>
        {{#if (and thumborImage showImage)}}
          <mj-image src="{{thumborImage}}" href="{{url}}" fluid-on-mobile="true" mj-class="p-0-pb-10" />
        {{/if}}
        <mj-table mj-class="result-title{{#unless (and thumborImage showImage)}} pt-20{{/unless}}">
          <tr>
            {{#if authorImage}}
              <td width="36" class="report-result-title-author">
                <img width="28" height="28" class="report-result-title-author-img" src="{{authorImage}}" />
              </td>
            {{/if}}
            <td>
              <a href="{{url}}" class="report-result-title-text report-link" target="_blank">{{title}}</a>
            </td>
          </tr>
        </mj-table>
        <mj-text mj-class="result-meta">{{> resultMeta}}</mj-text>
        <mj-text mj-class="result-content">{{{this.excerpt}}}</mj-text>
        <mj-button css-class="report-button-link" target="_blank" mj-class="result-button" href="{{url}}">Read more</mj-button>
      </mj-column>
    </mj-section>
  </mj-wrapper>
{{/each}}`
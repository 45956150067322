import { ModalBody } from "reactstrap";
import React from "react";
import Spinner from "../../components/Spinner";

const ModalLoading = () => (
  <ModalBody>
    <div className="text-center pb-1">
      <Spinner className="spinner-border-sm" />
    </div>
  </ModalBody>
);

export default ModalLoading;

import React from "react";
import classNames from "classnames";
import get from "lodash/get";

const FormError = ({ name, errors, helpText, className }) => {
  const error = get(errors, name);
  if (!error && !helpText) return null;
  return (
    <p className={className}>
      {helpText ? <span className="invalid-feedback text-muted d-block">{helpText}</span> : ""}
      {error ? (
        <span
          className={classNames({
            "invalid-feedback": true,
            "d-block": true,
            "invalid-feedback-error-with-helptext": helpText,
          })}
        >
          {error.type === "required"
            ? "This field is required."
            : error.message.length
            ? error.message
            : "Error validating field."}
        </span>
      ) : (
        ""
      )}
    </p>
  );
};

export default FormError;

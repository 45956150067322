import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  buildSearchResultsHighlightFromAgents,
  buildSearchResultsHighlightFromQuery,
  buildSearchResultsSort,
  buildSearchResultsTypeParams,
  keywordFilterIsValidForHighlighting,
  removeDuplicates,
  removeInvalid,
} from "../../lib/queries";
import { gql, useMutation, useQuery } from "@apollo/client";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import { COOKIE_EXPIRY } from "../../lib/constants";
import Filters from "../Filters";
import ResultPanel from "./ResultPanel";
import ResultsError from "./ResultsError";
import ResultsListingItem from "./ResultsListingItem";
import ResultsMenu from "./ResultsMenu";
import ResultsPanel from "./ResultsPanel";
import ShareModal from "../Modals/ShareModal";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import findIndex from "lodash/findIndex";
import { fnBulkResult } from "../../graphql/mutations";
import get from "lodash/get";
import { getResultSourceName } from "../../lib/utils";
import intersection from "lodash/intersection";
import { numberFormat } from "humanize";
import { searchResultsRaw } from "../../graphql/queries";
import useCookie from "react-use-cookie";
import { useMediaBreakpointDownLg } from "../lib/effects";

const MAX_CHECKED = 20;

const SORT_OPTIONS_METABASE = [
  { key: "-publishedDate", label: "Date (newest first)" },
  { key: "publishedDate", label: "Date (oldest first)" },
  { key: "sourceRank", label: "Rank (highest first)" },
  { key: "-sourceRank", label: "Rank (lowest first)" },
  { key: "-reach", label: "Reach (highest first)" },
  { key: "reach", label: "Reach (lowest first)" },
  { key: "sourceCountry", label: "Country (A-Z)" },
  { key: "-sourceCountry", label: "Country (Z-A)" },
  { key: "language", label: "Language (A-Z)" },
  { key: "-language", label: "Language (Z-A)" },
];

const SORT_OPTIONS_SOCIAL360 = [
  { key: "-publishedDate", label: "Date (newest first)" },
  { key: "publishedDate", label: "Date (oldest first)" },
  { key: "language", label: "Language (A-Z)" },
  { key: "-language", label: "Language (Z-A)" },
];

const DuplicatesModal = ({ data, toggle, source, resultsType, search, filter, user, project, projectData }) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={true} size="xl">
      <ModalHeader toggle={() => toggle()}>Duplicates</ModalHeader>
      <ModalBody className="p-0">
        {/* render listing for this duplicateGroup, no dateRange */}
        <ResultsListing
          source={source}
          resultsType={resultsType}
          listingDuplicates={data}
          // do not remove invalid results once changed (e.g. untop all)
          disableRemoveInvalid={true}
          search={search}
          filter={filter}
          user={user}
          project={project}
          projectData={projectData}
        />
      </ModalBody>
    </Modal>
  );
};

const ResultsListing = ({
  source,
  resultsType,
  listingDuplicates,
  disableRemoveInvalid = false,
  showDuplicates,
  dateRange,
  startDate,
  startTime,
  endDate,
  endTime,
  timeframe,
  search,
  filter,
  menuOpen,
  setMenuOpen,
  user,
  project,
  projectData,
}) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { clientId, projectId } = useParams();
  const { path, url } = useRouteMatch();
  const alertModal = createConfirmation(AlertModal);
  const { maxDate, setMaxDate } = appContext;

  // duplicates modal, active duplicate result
  const [modal, toggleModal] = useState(null);
  const [duplicateResultId, setDuplicateResultId] = useState(null);
  const [isDuplicatesUntopAll, setIsDuplicatesUntopAll] = useState(false);

  // share modal
  const [shareModal, toggleShareModal] = useState(null);

  // sorting state (different for main and duplicates listing, as well as results source, as changes in the duplicate won't be reflected back in main once closed)
  let sortOptions = SORT_OPTIONS_METABASE;
  let sortCookie = listingDuplicates ? "ResultsListingDuplicatesSort" : "ResultsListingSort";
  if (source === "SOCIAL360") {
    sortOptions = SORT_OPTIONS_SOCIAL360;
    sortCookie = listingDuplicates ? "ResultsListingDuplicatesSortSocial" : "ResultsListingSortSocial";
  }
  const [sort, setSort] = useCookie(sortCookie, "-publishedDate");

  // checked state
  let [checked, setChecked] = useState([]);
  let [checkedDuplicateGroups, setCheckedDuplicateGroups] = useState([]);

  // mobile
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  const [mobileViewClosedPanel, setMobileViewClosedPanel] = useState(false);

  // get active item, from url for main results listing, from state for duplicates modal
  const resultId = listingDuplicates ? duplicateResultId : history.location.pathname.split("/")[5];

  // get query from search/filter
  let query = null;
  if (search || filter) query = JSON.parse((search || filter).query);

  // get chart query from search
  let chartQuery = null;

  if (search && search.chartQuery) chartQuery = JSON.parse(search.chartQuery);

  // resultsType defaults to the prop, but can be overridden by the type from a search
  // (i.e. the base where results come from before search criteria are applied)
  let resultsTypeDerived = resultsType;
  if (search && search.resultsType) resultsTypeDerived = search.resultsType;

  // build excerpt querystring from search/filter query (if it has keywords and they are active)
  // fallback to active agents (enabled, non-deleted)
  let highlight,
    excerptBaseIsDynamic = false;
  if (query && keywordFilterIsValidForHighlighting(query.keyword)) {
    highlight = buildSearchResultsHighlightFromQuery(query.keyword);
    excerptBaseIsDynamic = true;
  } else {
    const activeAgents = projectData.agents.filter((x) => x.isEnabled);
    highlight = buildSearchResultsHighlightFromAgents(activeAgents);
  }

  // build search filter/sort params
  const filterParams = {
    clientId,
    projectId,
    dateRange,
    startDate,
    startTime,
    endDate,
    endTime,
    maxDate,
    query,
    chartQuery,
  };
  if (listingDuplicates) filterParams.duplicateGroup = listingDuplicates;
  const searchParams = buildSearchResultsTypeParams({
    source,
    resultsType: resultsTypeDerived,
    filterParams,
    highlight: highlight.query,
    projectData,
  });
  const searchSort = buildSearchResultsSort(sort);

  const { loading, data, previousData, refetch, fetchMore, networkStatus, error } = useQuery(gql(searchResultsRaw), {
    variables: {
      searchParams: JSON.stringify(searchParams),
      excerptQuery: highlight.query, // used in cache
      sort: searchSort,
      // content is included, keep under 1mb
      limit: 20,
    },
    notifyOnNetworkStatusChange: true, // needed for listening to fetchMore
  });

  const [mutationFnBulkResult, mutationFnBulkResultStatus] = useMutation(gql(fnBulkResult));

  // for next/prev buttons
  const resultsRef = useRef([]);
  const [activeResultIndex, setActiveResultIndex] = useState(null);

  // for passing back refetch function from ResultControlsDuplicateGroup to be triggered when modal closes
  const resultControlsDuplicateGroupRefetchRef = useRef({});

  // when data changes (in main listing), make new list of refs to the result divs in the listing
  useEffect(() => {
    if (data && !listingDuplicates) {
      // get results, remove duplicates/invalid
      let results = data.searchResultsRaw.items;
      if (showDuplicates === "false") results = removeDuplicates(results).results;
      results = removeInvalid(results, resultsTypeDerived).results;

      // make ref for each result that will be displayed
      resultsRef.current = resultsRef.current.slice(0, results.length);

      // set active index for next/prev buttons
      const indexInResults = findIndex(results, (x) => x.id === resultId);
      setActiveResultIndex(resultId ? (indexInResults >= 0 ? indexInResults : null) : 0);
    }
  }, [resultId, data, listingDuplicates, showDuplicates, resultsTypeDerived]);

  // show loading only for initial fetch and keep existing results visible while new state is being fetched
  const emptyState = { items: [], total: 0, nextToken: null };
  let { items, total, nextToken } =
    (loading && networkStatus) === 1
      ? emptyState
      : get(networkStatus === 2 ? previousData : data, "searchResultsRaw", emptyState);
  let results = items,
    duplicates = [],
    removed = [];

  // remove duplicates for main results listing (results will be ALL displayed, not just the current page)
  if (!listingDuplicates && showDuplicates === "false") {
    ({ results, duplicates } = removeDuplicates(results));
  }

  // remove invalid results for the current view (not in duplicates modal)
  if (!disableRemoveInvalid) ({ results, removed } = removeInvalid(results, resultsTypeDerived));

  // reduce total by the number of invalid results removed
  // (duplicates are not subtracted, total count is for all results including duplicates, same as charts)
  total = total - removed.length;

  // remove any checked that have been filtered out
  if (checked.length && removed.length) {
    checked = checked.filter((x) => !removed.includes(x));
  }

  // go back if there are previous results
  const prevResult = () => {
    const gotoIndex = activeResultIndex - 1;
    if (gotoIndex >= 0) {
      const listing = document.getElementById("results-listing");
      const el = resultsRef.current[gotoIndex];
      el.click();
      listing.scrollTo({ top: el.offsetTop - 56, behavior: "smooth" }); // 56 is height of CardHeader
    }
  };

  // go forward and/or click load more button
  const nextResult = () => {
    const gotoIndex = activeResultIndex + 1;
    const listing = document.getElementById("results-listing");
    if (gotoIndex > resultsRef.current.length - 1) {
      if (nextToken) {
        // fetch more results (if there's a more button), then goto next result
        fetchMore({ variables: { nextToken } }).then(() => {
          try {
            const el = resultsRef.current[gotoIndex];
            el.click();
            listing.scrollTo({ top: el.offsetTop - 56, behavior: "smooth" }); // 56 is height of CardHeader
          } catch (e) {}
        });
      }
    } else {
      // next result
      const el = resultsRef.current[gotoIndex];
      el.click();
      listing.scrollTo({ top: el.offsetTop - 56, behavior: "smooth" }); // 56 is height of CardHeader
    }
  };

  // url changes for main results listing, state changes for duplicates modal
  const openResult = (result, index) => {
    if (listingDuplicates) {
      setDuplicateResultId(result.id);
    } else {
      history.push(`${url}/${result.id}`);
      setActiveResultIndex(index);
      setMenuOpen(false);
    }
  };

  // url changes for main results listing, state changes for duplicates modal
  const closeResult = () => {
    if (listingDuplicates) {
      setDuplicateResultId(null);
    } else {
      history.push(`${url}`);
      setActiveResultIndex(null);
    }
  };

  const checkResult = async (resultId, resultDuplicateGroup, isChecked) => {
    let newChecked = [...checked];
    let newCheckedDuplicateGroups = [...checkedDuplicateGroups];
    if (isChecked) {
      if (newChecked.length >= MAX_CHECKED)
        return await alertModal({
          message: `A maximum of ${MAX_CHECKED} results can be selected for bulk operations.`,
        });
      newChecked.push(resultId);
      newCheckedDuplicateGroups.push(resultDuplicateGroup);
    } else {
      newChecked = newChecked.filter((x) => x !== resultId);
      newCheckedDuplicateGroups = newCheckedDuplicateGroups.filter((x) => x !== resultDuplicateGroup);
    }
    setChecked(newChecked);
    setCheckedDuplicateGroups(newCheckedDuplicateGroups);
  };

  const checkClear = () => {
    setChecked([]);
    setCheckedDuplicateGroups([]);
  };

  const checkBulk = async (op) => {
    // if TOP or UNTOP, and any duplicate results are checked, stop
    if (["TOP", "UNTOP"].includes(op) && intersection(duplicates, checkedDuplicateGroups).length) {
      return await alertModal({
        message:
          "One or more of the results selected is part of a duplicate group. To set the top story status, click on the Duplicates button to view all results in the duplicate group, and use the Top Story buttons against each result.",
      });
    }

    if (op === "SHARE") {
      toggleShareModal(checked);
      return;
    }

    try {
      await mutationFnBulkResult({
        variables: {
          input: {
            clientId,
            projectId,
            ids: checked,
            op,
          },
        },
      });
      checkClear();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const duplicatesUntopAll = async () => {
    if (isDuplicatesUntopAll) return;
    setIsDuplicatesUntopAll(true);
    try {
      await mutationFnBulkResult({
        variables: {
          input: {
            clientId,
            projectId,
            applyAcrossDuplicateGroup: listingDuplicates,
            op: "UNTOP",
          },
        },
      });
      checkClear();
    } catch (e) {
      appContext.handleError(e);
    }
    setIsDuplicatesUntopAll(true);
  };

  const duplicatesToggleModal = (duplicateGroup) => {
    if (duplicateGroup) {
      // open modal
      toggleModal(duplicateGroup);
    } else {
      // get duplicateGroup from modal being closed from state
      const modalDuplicateGroup = modal;
      // close modal
      toggleModal(null);
      // trigger refetch
      if (
        resultControlsDuplicateGroupRefetchRef &&
        resultControlsDuplicateGroupRefetchRef.current &&
        resultControlsDuplicateGroupRefetchRef.current[modalDuplicateGroup]
      ) {
        // bit hacky, but poll for changes a few times to allow time for ES propagation
        var pollRefetch = function () {
          let counter = 1;
          (function poll() {
            resultControlsDuplicateGroupRefetchRef.current[modalDuplicateGroup]();
            if (counter < 5) {
              counter++;
              setTimeout(poll, 1000);
            }
          })();
        };
        pollRefetch();
      }
    }
  };

  // don't show spinner for initial load
  const showSpinner = networkStatus === 2 || mutationFnBulkResultStatus.loading;
  // disable buttons on initial load, refresh, or bulk operations
  const disableButtons = networkStatus === 2 || networkStatus === 4 || mutationFnBulkResultStatus.loading;

  // force a re-render of Filters if the search/filter/resultsType changes
  let filtersKey = [];
  if (search) filtersKey.push(search.id);
  if (filter) filtersKey.push(filter.id);
  filtersKey = filtersKey.length ? filtersKey.join("/") : resultsTypeDerived;

  return (
    <>
      {!listingDuplicates && isMediaBreakpointDownLg && (
        <ButtonGroup className="responsive-closed-panel-buttons">
          <Button outline={mobileViewClosedPanel} disabled={resultId} onClick={() => setMobileViewClosedPanel(false)}>
            <i className="uil-table" />
          </Button>
          <Button outline={!mobileViewClosedPanel} disabled={resultId} onClick={() => setMobileViewClosedPanel(true)}>
            <i className="uil-chart-line" />
          </Button>
        </ButtonGroup>
      )}
      <Row
        className={classNames({
          "responsive-panel--container": true,
          "mt-2": !listingDuplicates && isMediaBreakpointDownLg,
        })}
      >
        {menuOpen && (
          <Col lg={2}>
            <ResultsMenu source={source} user={user} projectData={projectData} />
          </Col>
        )}

        <Col lg={5} xl={4}>
          <Card
            className={classNames({
              "results-listing": true,
              "vh-with-title": !listingDuplicates && !isMediaBreakpointDownLg,
              "vh-with-title-wrapped-buttons": !listingDuplicates && isMediaBreakpointDownLg,
              "vh-duplicates-modal": listingDuplicates,
              // "results-listing--main": !listingDuplicates,
              // "results-listing--duplicates": listingDuplicates,
            })}
          >
            {error ? (
              <ResultsError refetch={refetch} />
            ) : (
              <>
                {total === 0 ? (
                  <Card body className="mb-0 card-prompt">
                    <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
                      <p className="mb-0 text-center">
                        {loading && networkStatus === 1 ? (
                          <Spinner tag="span" />
                        ) : (
                          <>
                            <i className="uil-file-search-alt font-24 d-block" />
                            There are no {getResultSourceName(source)} results to display {timeframe}.
                          </>
                        )}
                      </p>
                    </div>
                  </Card>
                ) : (
                  <>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <div
                          className="text-title h4 mr-auto pr-1"
                          style={{ lineHeight: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                        >
                          {numberFormat(total, 0)} result{total !== 1 && "s"}
                        </div>
                        {showSpinner && <Spinner className="spinner-border-sm mr-1" tag="span" />}
                        {!listingDuplicates && (
                          <Button
                            size="sm"
                            className="mr-1"
                            disabled={disableButtons}
                            onClick={() => setMaxDate(Date.now())}
                          >
                            <i className="uil-refresh" />
                          </Button>
                        )}
                        {listingDuplicates && (
                          <Button size="sm" className="mr-1" disabled={disableButtons} onClick={duplicatesUntopAll}>
                            Untop all
                          </Button>
                        )}
                        <UncontrolledButtonDropdown size="sm" className="d-block">
                          <DropdownToggle color="secondary" caret disabled={disableButtons}>
                            Sort...
                          </DropdownToggle>
                          <DropdownMenu>
                            {sortOptions.map((option) => (
                              <DropdownItem
                                key={option.key}
                                active={option.key === sort}
                                onClick={() => setSort(option.key, { days: COOKIE_EXPIRY })}
                              >
                                {option.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        {checked.length > 0 && (
                          <UncontrolledButtonDropdown size="sm" className="ml-1 d-block">
                            <DropdownToggle color="secondary" caret disabled={disableButtons}>
                              {checked.length} result{checked.length !== 1 && "s"}...
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* removed in OUT-39, if reinstated will need update to handle priority issues */}
                              {/* <DropdownItem onClick={() => checkBulk("TOP")}>Top</DropdownItem> */}
                              {/* <DropdownItem onClick={() => checkBulk("UNTOP")}>Untop</DropdownItem> */}
                              {resultsTypeDerived !== "deleted" && (
                                <DropdownItem onClick={() => checkBulk("DELETE")}>Delete</DropdownItem>
                              )}
                              {resultsTypeDerived === "deleted" && (
                                <DropdownItem onClick={() => checkBulk("UNDELETE")}>Undelete</DropdownItem>
                              )}
                              <DropdownItem onClick={() => checkBulk("SHARE")}>Share</DropdownItem>
                              <DropdownItem onClick={() => checkClear()}>Clear selection</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                      </div>
                    </CardHeader>
                    {/* id required for next/prev scrolling, CardBody doesn't use forwardRef */}
                    <CardBody className="scroll" id="results-listing">
                      {isMediaBreakpointDownLg && (
                        <div className="results-filters">
                          <Filters
                            key={filtersKey}
                            source={source}
                            resultsType={resultsType}
                            search={search}
                            filter={filter}
                            user={user}
                            projectData={projectData}
                          />
                        </div>
                      )}
                      <div className="results-listing-items">
                        {results.map((result, index) => (
                          <ResultsListingItem
                            key={result.id}
                            result={result}
                            index={index}
                            resultId={resultId}
                            source={source}
                            resultsRef={resultsRef}
                            resultControlsDuplicateGroupRefetchRef={resultControlsDuplicateGroupRefetchRef}
                            listingDuplicates={listingDuplicates}
                            resultsTypeDerived={resultsTypeDerived}
                            filter={filter}
                            duplicates={duplicates}
                            checked={checked}
                            checkResult={checkResult}
                            highlight={highlight}
                            excerptBaseIsDynamic={excerptBaseIsDynamic}
                            isMediaBreakpointDownLg={isMediaBreakpointDownLg}
                            closeResult={closeResult}
                            openResult={openResult}
                            duplicatesToggleModal={duplicatesToggleModal}
                            user={user}
                            projectData={projectData}
                          />
                        ))}
                      </div>

                      {nextToken && (
                        <CardFooter className="text-center">
                          <Button
                            disabled={networkStatus === 3}
                            onClick={() => fetchMore({ variables: { nextToken } })}
                          >
                            {networkStatus === 3 && (
                              <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
                            )}
                            View more results
                          </Button>
                        </CardFooter>
                      )}
                    </CardBody>
                  </>
                )}
              </>
            )}
          </Card>
        </Col>

        {((listingDuplicates && !isMediaBreakpointDownLg) || !listingDuplicates) && (
          <Col lg={menuOpen ? 5 : 7} xl={menuOpen ? 6 : 8} className="responsive-panel--panel">
            {listingDuplicates ? (
              // state-based routes for duplicates modal
              duplicateResultId ? (
                <ResultPanel
                  key={resultId}
                  source={source}
                  resultId={resultId}
                  resultsType={resultsTypeDerived}
                  filter={filter}
                  highlight={highlight}
                  excerptBaseIsDynamic={excerptBaseIsDynamic}
                  listingDuplicates={true}
                  showNav={false}
                  onPrev={prevResult}
                  onNext={nextResult}
                  onClose={closeResult}
                  user={user}
                  project={project}
                  projectData={projectData}
                />
              ) : (
                <ResultsPanel
                  source={source}
                  resultsType={resultsTypeDerived}
                  filterParams={filterParams}
                  listingDuplicates={true}
                  search={search}
                  filter={filter}
                  filtersKey={filtersKey}
                  user={user}
                  project={project}
                  projectData={projectData}
                />
              )
            ) : (
              // url routes for main results listing
              <Switch>
                {((isMediaBreakpointDownLg && mobileViewClosedPanel) || !isMediaBreakpointDownLg) && (
                  <Route exact path={path}>
                    <ResultsPanel
                      source={source}
                      resultsType={resultsTypeDerived}
                      filterParams={filterParams}
                      listingDuplicates={false}
                      search={search}
                      filter={filter}
                      filtersKey={filtersKey}
                      user={user}
                      project={project}
                      projectData={projectData}
                    />
                  </Route>
                )}
                <Route path={`${path}/:resultId`}>
                  <ResultPanel
                    key={resultId}
                    source={source}
                    resultId={resultId}
                    resultsType={resultsTypeDerived}
                    filter={filter}
                    duplicates={duplicates}
                    highlight={highlight}
                    excerptBaseIsDynamic={excerptBaseIsDynamic}
                    listingDuplicates={false}
                    showNav={activeResultIndex !== null}
                    onPrev={prevResult}
                    onNext={nextResult}
                    onClose={closeResult}
                    user={user}
                    project={project}
                    projectData={projectData}
                  />
                </Route>
              </Switch>
            )}
          </Col>
        )}
      </Row>

      {modal && (
        <DuplicatesModal
          data={modal}
          toggle={duplicatesToggleModal}
          source={source}
          resultsType={resultsTypeDerived}
          search={search}
          filter={filter}
          user={user}
          project={project}
          projectData={projectData}
        />
      )}

      {shareModal && (
        <ShareModal
          data={shareModal}
          toggle={toggleShareModal}
          onComplete={() => {
            toggleShareModal();
            checkClear();
          }}
          user={user}
          projectData={projectData}
        />
      )}
    </>
  );
};

export default ResultsListing;

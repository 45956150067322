import { Modal, ModalHeader } from "reactstrap";
import React, { useState } from "react";

import JobStatus from "../JobStatus";
import RecipientsForm from "../Modals/RecipientsForm";
import classNames from "classnames";
import { useApolloClient } from "@apollo/client";

const SendModal = ({ data, toggle, user, projectData }) => {
  const apollo = useApolloClient();
  const [jobId, setJobId] = useState(null);

  let preview = false;
  let title = "Report";
  let prepareMessage = "Sending your report, this might take a few minutes...";
  let successMessage = "Your report has been sent to the selected mailing lists.";
  if (data.op === "PREVIEW") {
    preview = true;
    title = "Preview";
    prepareMessage = "Sending your report preview, this might take a few minutes...";
    successMessage = "Your report preview has been sent to the selected email addresses.";
  }

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Send {title}</ModalHeader>
      {jobId && (
        <JobStatus
          id={jobId}
          type="report"
          prepareMessage={prepareMessage}
          successMessage={successMessage}
          successButtonText="OK"
          onJobComplete={async () => {
            // nothing to do if previewing
            if (preview) return;
            // manually update cache to move to sent list
            apollo.cache.modify({
              id: apollo.cache.identify({ id: data.id, __typename: "Report" }),
              fields: {
                sentAt: () => new Date().toISOString(),
              },
              // update queries which reference this report (the listing)
              broadcast: true,
            });
          }}
          onSuccess={() => toggle(null)}
          onRestart={() => setJobId(null)}
          hideRestartOnSuccess
          displayType="MODAL"
        />
      )}
      {/* keep form rendered but hidden incase of job retry */}
      <div
        className={classNames({
          "d-none": jobId,
        })}
      >
        <RecipientsForm
          type={`REPORT_${data.op}`}
          sendReportData={data}
          isModal
          onComplete={(jobId) => setJobId(jobId)}
          user={user}
          projectData={projectData}
        />
      </div>
    </Modal>
  );
};

export default SendModal;

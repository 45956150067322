import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import AppMenu from "./AppMenu";
import { rootRoutes } from "../routes";

class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.menuNodeRef = React.createRef();
    this.handleOtherClick = this.handleOtherClick.bind(this);
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleOtherClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleOtherClick, false);
  };

  handleOtherClick = (e) => {
    if (this.menuNodeRef.contains(e.target)) return;
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove("sidebar-enable");
    }
  };

  render() {
    const { project, clientId, projectId, user, showIntercom } = this.props;
    const rootPaths = rootRoutes.map((x) => x.path);

    return (
      <>
        <div className="left-side-menu" ref={(node) => (this.menuNodeRef = node)}>
          {/* add home menu item at top */}
          <ul className="metismenu side-nav">
            <li className="side-nav-item side-nav-home">
              <Link to="/" className="side-nav-link">
                <i className="uil-store"></i>
              </Link>
            </li>
          </ul>

          {/* if this is a rootRoute, add other items accessible by user */}
          {/* currently unused as NonProject has no sidebar */}
          {rootPaths.includes(this.props.location.pathname) ? (
            <ul className="metismenu side-nav">
              {rootRoutes
                .filter((route) => route.path !== "/" && (!route.can || (route.can && user.can[route.can])))
                .map((route) => (
                  <li key={route.path} className="side-nav-item" data-route-section={route.section}>
                    <Link to={route.path} className="side-nav-link">
                      <i className={route.icon}></i>
                      <span>{route.name(project)}</span>
                    </Link>
                  </li>
                ))}
            </ul>
          ) : (
            <AppMenu project={project} clientId={clientId} projectId={projectId} user={user} />
          )}

          {/* add intercom at bottom */}
          <ul className="metismenu side-nav">
            <li className="side-nav-item side-nav-intercom">
              <div className="side-nav-link click" onClick={showIntercom}>
                <i className="uil-comment-question"></i>
                <span>Help</span>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(LeftSidebar);

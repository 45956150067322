import { projectRoutes } from "../../routes";

let uuid = 1;

// unpicked from redux, needed for nesting menus
const assignIdAndParent = (menuItems, parentId) => {
  menuItems = menuItems || [];
  menuItems.forEach((item) => {
    const id = item.id || uuid;
    uuid += 1;
    item.id = id;
    item.parentId = item.parentId || parentId;
    item.active = false;
    if (typeof item.children !== "undefined") {
      assignIdAndParent(item.children, id);
    }
  });
  return menuItems;
};

export const getMenuItems = () => assignIdAndParent(projectRoutes);

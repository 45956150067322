import { getResultSourceName, sortArray } from "../../lib/utils";

import RichTextEditor from "react-rte";
import { Storage } from "aws-amplify";
import concat from "lodash/concat";
import find from "lodash/find";
import { getAgentSocial360Id } from "../../lib/social360";
import isEmail from "validator/lib/isEmail";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import isURL from "validator/lib/isURL";
import isUndefined from "lodash/isUndefined";
import mime from "mime-types";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import pull from "lodash/pull";
import { titleCase } from "title-case";
import { v4 as uuid } from "uuid";

// populate a form with existing values, used in useForm defaultValues where we already have data
export const populateForm = (
  data,
  {
    fields = [],
    dates = [],
    selects = [],
    selectMultis = [],
    creatables = [],
    files = [],
    switches = [],
    editors = [],
  } = {}
) => {
  const defaults = {};

  // if there is no data at all (e.g. creating) everything is null
  if (!data) {
    const keys = concat(
      fields,
      dates,
      selects.map((x) => x[0]),
      selectMultis.map((x) => x[0]),
      creatables,
      files,
      switches.map((x) => x[0]),
      editors
    );
    keys.forEach((key) => (defaults[key] = null));
    return defaults;
  }

  // set normal inputs
  fields.forEach((field) => (defaults[field] = data[field] ? data[field] : null));

  // set datepicker inputs
  dates.forEach((field) => (defaults[field] = data[field] ? moment(data[field]).format("YYYY-MM-DD") : null));

  // set single select values (if value is no longer a valid option, don't keep existing value)
  selects.forEach((field) => {
    const key = field[0];
    let options = field[1];
    const isGrouped = field[2];
    if (isGrouped) {
      // flatten options
      options = [];
      for (const group of field[1]) {
        options.push(...group.options);
      }
    }
    // isNull used here as selects can have `0` values (e.g. dailyAlertHour)
    if (isNull(data[key]) || isUndefined(data[key])) {
      defaults[key] = null;
    } else {
      const foundOption = find(options, (x) => x.value === data[key]);
      defaults[key] = foundOption ? foundOption : null;
    }
  });

  // set multiple select values (if value is no longer a valid option, don't keep existing value)
  selectMultis.forEach((field) => {
    const key = field[0];
    let options = field[1];
    const isGrouped = field[2];
    if (isGrouped) {
      // flatten options
      options = [];
      for (const group of field[1]) {
        options.push(...group.options);
      }
    }
    // isNull used here as selects can have `0` values (e.g. dailyAlertHour)
    if (isNull(data[key]) || isUndefined(data[key])) {
      defaults[key] = null;
    } else {
      const filteredOptions = options.filter((x) => data[key].includes(x.value));
      defaults[key] = filteredOptions.length ? filteredOptions : null;
    }
  });

  // set creatables values (stored as strings, so make value/label combo from these)
  // NOTE this is only ones where values are stored as strings (e.g. agents) not ids (e.g. tags)
  creatables.forEach(
    (field) => (defaults[field] = data[field] ? data[field].map((option) => ({ value: option, label: option })) : null)
  );

  // files don't have initial values, the FormFile component expects `current` prop instead

  // switches, with default values
  // defaultValue should match any @defaults set in the schema
  switches.forEach((field) => {
    // OUT-128 ensure new logic is used, expecting an array of field names and default values
    if (Object.prototype.toString.call(field) !== "[object Array]")
      console.error("OUT-128 switch has no default value:", field);
    const key = field[0];
    const defaultValue = field[1];
    defaults[key] = data[key] ?? defaultValue;
  });

  // create draftjs state from html, or empty value
  editors.forEach(
    (field) =>
      (defaults[field] = data[field]
        ? RichTextEditor.createValueFromString(data[field], "html")
        : RichTextEditor.createEmptyValue())
  );

  return defaults;
};

// serialize form for mutation, handle file uploads
// null values kept as null, so update mutations can unset existing values
export const processForm = async (
  data,
  {
    dates = [],
    selects = [],
    selectMultis = [],
    creatables = [],
    creatablesLowercase = [],
    files = [],
    editors = [],
  } = {}
) => {
  const values = { ...data };

  // convert all empty strings to null, trim all strings
  for (const [key, value] of Object.entries(values)) {
    if (value === "") {
      values[key] = null;
    } else if (isString(values[key])) {
      values[key] = value.trim();
    }
  }

  // for the moment we assume that browser supports date/time <input> and therefore formats are YYYY-MM-DD and HH24:MM
  // dont send empty strings, convert "yyyy-mm-dd" from local time to midnight UTC iso string
  dates.forEach(
    (field) =>
      (values[field] = data[field]
        ? data[field] === ""
          ? null
          : moment(data[field], "YYYY-MM-DD").toISOString()
        : null)
  );

  // change single value/label to value
  selects.forEach((field) => (values[field] = data[field] ? data[field].value : null));

  // change multi value/label to list of values (sorted)
  // send null instead of empty lists (widget behaviour is different between using isClearable button, and deleting the last-selected item)
  selectMultis.forEach(
    (field) =>
      (values[field] =
        values[field] && values[field].length
          ? data[field]
            ? sortArray(data[field].map((x) => x.value))
            : null
          : null)
  );
  creatables.forEach(
    (field) =>
      (values[field] =
        values[field] && values[field].length
          ? data[field]
            ? sortArray(data[field].map((x) => x.value))
            : null
          : null)
  );
  creatablesLowercase.forEach(
    (field) =>
      (values[field] =
        values[field] && values[field].length
          ? data[field]
            ? sortArray(data[field].map((x) => x.value.toLowerCase()))
            : null
          : null)
  );

  // upload any files
  for (const field of files) {
    const actionField = `${field[0]}_FILE_ACTION`;
    const uploadField = `${field[0]}_FILE_UPLOAD`;

    // get values of file field and action field
    const file = values[field[0]];
    const action = values[actionField];

    // field may not be in data if the field is conditionally rendered
    if (!Object.keys(values).includes(field[0]) || (file === undefined && action === undefined)) {
      // delete supporting fields
      delete values[actionField];
      delete values[uploadField];
      // clear out existing value
      values[field[0]] = null;
      continue;
    }

    const upload = JSON.parse(values[uploadField]);

    // delete supporting fields
    delete values[actionField];
    delete values[uploadField];

    // get existing value
    const existing = field[1];

    // if KEEP, keep image as is
    if (action === "KEEP") {
      values[field[0]] = existing;
      continue;
    }

    // if DELETE, clear out existing value and file (file is a string)
    if (action === "DELETE") {
      values[field[0]] = null;
      await Storage.remove(existing, { level: upload.level });
      continue;
    }

    // if UPLOAD, add file and store value (file is a File object)
    if (action === "UPLOAD" && file) {
      const contentType = file.type;
      const extension = mime.extension(contentType);
      const filename = `${upload.path}/${uuid()}.${extension}`;
      values[field[0]] = filename;
      await Storage.put(filename, file, { level: upload.level, contentType });
      // delete existing file if there was one
      if (existing) await Storage.remove(existing, { level: upload.level });
    }

    // if no file, consistently set file field to null and stop
    if (!file) {
      values[field[0]] = null;
      continue;
    }
  }

  // convert draftjs state to html, or null
  const emptyEditorValue = RichTextEditor.createEmptyValue().toString("html");
  editors.forEach(
    (field) =>
      (values[field] = data[field]
        ? data[field].toString("html") === emptyEditorValue
          ? null
          : data[field].toString("html")
        : null)
  );

  return values;
};

export const getResultsSourceOptions = (projectData, { includeAll = true } = {}) => {
  let options = projectData.resultsSources.map((resultsSource) => ({
    value: resultsSource,
    label: titleCase(getResultSourceName(resultsSource)),
  }));
  if (includeAll && options.length > 1) {
    options = [
      {
        value: "ALL",
        label: "All",
      },
    ].concat(options);
  }
  return options;
};

export const getResultsTypeOptionsAllDefault = { value: "all", label: "All Results" };
export const getResultsTypeOptionsTopDefault = { value: "top", label: "Top Stories" };

// results type options are results types plus visible issues, for the specified results source
export const getResultsTypeOptions = (
  source,
  projectData,
  { includeAll = true, includeTop = true, includeIssues = true } = {}
) => {
  // if social coverage, return agents with social sources that have been setup on S360
  if (source === "SOCIAL360_COVERAGE") {
    const socialAgents = projectData.agents.filter((x) => x.isEnabled && x.hasSocialSources && getAgentSocial360Id(x));
    return [
      { label: "Social Coverage Results", options: [getResultsTypeOptionsAllDefault] },
      { label: "Search Agents", options: socialAgents.map((x) => ({ value: `agent-${x.id}`, label: x.name })) },
    ];
  }
  // otherwise build options
  const options = [];
  const resultsOptions = [];
  if (includeAll) {
    resultsOptions.push(getResultsTypeOptionsAllDefault);
  }
  if (includeTop) {
    resultsOptions.push({ value: "top", label: "Top Stories" });
    resultsOptions.push({ value: "manualtop", label: "Top Stories (Manual)" });
  }
  if (resultsOptions.length) {
    options.push({
      label: `${titleCase(getResultSourceName(source))} Results`,
      options: resultsOptions,
    });
  }
  if (includeIssues && source !== "ALL") {
    const issues = projectData.filters.filter((x) => x.source === source && x.isVisible);
    if (issues.length) {
      options.push({
        label: `${titleCase(getResultSourceName(source))} Issues`,
        options: issues.map((x) => ({ value: `issue-${x.id}`, label: x.name })),
      });
    }
  }
  return options;
};

// standard date range options
export const getDateRangeOptions = (includeComparison) => {
  const options = [
    { value: "1", label: "24 hours" },
    { value: "7", label: "7 days" },
    { value: "30", label: "30 days" },
    { value: "90", label: "90 days" },
    { value: "0", label: "Custom" },
  ];
  if (includeComparison) options.push({ value: "comparison", label: "Custom with comparison" });
  return options;
};

// validate that an isMulti results source field is either all/top/manualtop (one only), or one or more agents/issues
export const validateResultsType = (value) => {
  const msg =
    "Results type must be one of: All Results, Top Stories, Top Stories (Manual); or one or more agents/issues.";
  const sources = value.map((x) => x.value);
  // one source is always fine
  if (sources.length === 1) return value;
  // no more than one results type
  const typesSelected = sources.filter((x) => ["all", "top", "manualtop"].includes(x));
  if (typesSelected.length > 1) throw new Error(msg);
  // if agents/issues selected, they must all be agents/issues
  if (
    typesSelected.length &&
    (sources.filter((x) => x.startsWith("agent-")).length || sources.filter((x) => x.startsWith("issue-")).length)
  )
    throw new Error(msg);
  return value;
};

// validate custom date range, if any fields left blank then use start/end of current month
export const validateDateRange = (inputStartDate, inputStartTime, inputEndDate, inputEndTime, isComparison = false) => {
  const startDate = inputStartDate || moment().startOf("month").format("YYYY-MM-DD");
  const startTime = inputStartTime || "00:00";
  const startEpoch = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm").unix();
  const endDate = inputEndDate || moment().endOf("month").format("YYYY-MM-DD");
  const endTime = inputEndTime || "00:00";
  const endEpoch = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm").unix();
  if (!(endEpoch >= startEpoch)) throw new Error(`Enter a valid ${isComparison ? "comparison " : ""}date range.`);
  return { startDate, startTime, endDate, endTime };
};

export const validateNumberRange = (valueFrom, valueTo) => {
  if (!isNull(valueFrom) && !isNull(valueTo)) {
    const intFrom = parseInt(valueFrom, 10);
    const intTo = parseInt(valueTo, 10);
    if (intFrom > intTo || intFrom === intTo) throw new Error("Enter a valid range.");
  }
};

export const inputValidateTimestamp = (value) =>
  value ? moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() || "Enter a valid timestamp." : true;

export const inputValidateProximity = (value) => !value.includes(" ") || "Proximity terms cannot contain spaces.";

export const inputValidateUrl = (value) =>
  value ? isURL(value, { protocols: ["http", "https"], require_protocol: true }) || "Enter a valid URL." : true;

// allows validation of RecipientsForm free-text emails before getting to function (which may be fnTriggerJob where we do not wait for sync response)
export const inputValidateRecipients = (recipients) => {
  const emails = recipients.map((x) => x.value).filter((x) => !x.startsWith("__OUTCIDER"));
  if (!emails.length) return true;
  for (const email of emails) {
    if (!isEmail(email)) return "One or more of the email addresses entered is not valid.";
  }
  return true;
};

// https://react-select.com/upgrade#from-v3-to-v4
// empty arrays are not valid
export const inputValidateSelectMulti = (value) => {
  if (value && value.length === 0) return "This field is required.";
  return true;
};

// input: `kennel club`
// output `.*[kK][eE][nN][nN][eE][lL].*[cC][lL][uU][bB].*`
// this matches the input string anywhere within the bucket aggregation key and is case insensitive
// removes all special characters to avoid possible regex issues
export const inputProcessTermsIncludeQuery = (value) => {
  // make lowercase
  let val = value.toLowerCase();

  // remove special characters
  val = val.replace(/[&/\\#^+()[\]$~%.'":*?<>{}!@|\-=]/g, " ");

  // split into words
  const words = pull(val.split(" "), "");

  // replace a-zA-Z with case insensitive
  let output = [];
  for (const word of words) {
    let wordOutput = "";
    for (const char of word) {
      const upperChar = char.toUpperCase();
      if (char === upperChar) {
        // lower/upper may be the same e.g. numbers
        wordOutput += char;
      } else {
        wordOutput += `[${char}${upperChar}]`;
      }
    }
    output.push(wordOutput);
  }

  return `.*${output.join(".*")}.*`;
};

import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";

import { AppContext } from "../../App";
import FormInput from "../Forms/FormInput";
import Spinner from "../../components/Spinner";
import { createFilter } from "../../graphql/mutations";
import { mergeFiltersAndChartFilters } from "./lib";
import { populateForm } from "../lib/forms";
import { queryFilters } from "../../graphql/queries";
import { queryFiltersVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

const SaveModal = ({ data, toggle }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [mutationCreateFilter] = useMutation(gql(createFilter));

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(null),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    const id = uuid();
    const input = {
      clientId,
      projectId,
      id,
      source: data.source,
      name: values.name,
      query: JSON.stringify(mergeFiltersAndChartFilters(data.filters, data.chartFilters)),
    };
    try {
      await mutationCreateFilter({
        variables: { input },
        update: updateCacheCreate(gql(queryFilters), queryFiltersVariables({ clientId, projectId })),
      });
      await toggle(id);
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Create Issue</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Create
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SaveModal;

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";

import { AppContext } from "../../App";
import FormSwitch from "../Forms/FormSwitch";
import Spinner from "../../components/Spinner";
import { populateForm } from "../lib/forms";
import { updateChart } from "../../graphql/mutations";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const FilterModal = ({ toggle, chart, hideableData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [mutationUpdateChart] = useMutation(gql(updateChart));

  // fullstops in series/category names will break form field names
  // https://stackoverflow.com/a/72345458
  const prepareKey = (x) => x.replace(/\./g, "__OUTCIDER_FULLSTOP__");
  const serialiseKey = (x) => x.replace(/__OUTCIDER_FULLSTOP__/g, ".");

  const hidden = chart.hidden ? chart.hidden.map(prepareKey) : [];

  // get switch fields, default to true unless hidden
  const switches = [
    ...hideableData.series.map((x) => {
      const field = `series-${prepareKey(x.key)}`;
      return [field, !hidden.includes(field)];
    }),
    ...hideableData.categories.map((x) => {
      const field = `category-${prepareKey(x.key)}`;
      return [field, !hidden.includes(field)];
    }),
  ];

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(
      {},
      {
        switches,
      }
    ),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      let hidden = [];
      for (const [key, value] of Object.entries(values)) {
        if (!value) hidden.push(serialiseKey(key));
      }
      const input = { id: chart.id, clientId, projectId, hidden };
      await mutationUpdateChart({ variables: { input } });

      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Filter</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            {hideableData.series.length > 0 && (
              <Col>
                <p className="font-bold mb-1">Series:</p>
                {hideableData.series.map((item) => (
                  <div key={prepareKey(item.key)} className="d-flex mb-1">
                    <FormSwitch name={`series-${prepareKey(item.key)}`} className="mb-0" formProps={formProps} />
                    <div className="flex-grow-1">{item.name}</div>
                  </div>
                ))}
              </Col>
            )}
            {hideableData.categories.length > 0 && (
              <Col>
                <p className="font-bold mb-1">Categories:</p>
                {hideableData.categories.map((item) => (
                  <div key={prepareKey(item.key)} className="d-flex mb-1">
                    <FormSwitch name={`category-${prepareKey(item.key)}`} className="mb-0" formProps={formProps} />
                    <div className="flex-grow-1">{item.name}</div>
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Apply
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FilterModal;

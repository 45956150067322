import {
  METABASE_COUNTRIES,
  METABASE_LANGUAGES,
  METABASE_NEWS_MEDIA_TYPES,
  METABASE_OTHER_MEDIA_TYPES,
  METABASE_SOCIAL_MEDIA_TYPES,
  SOCIAL360_LANGUAGES,
  SOCIAL360_MEDIA_TYPES,
} from "../../lib/constants";

import range from "lodash/range";
import { sort } from "../../lib/utils";

export const FILTER_TYPES_METABASE = [
  {
    label: null,
    options: [
      // these options can be used as a filter (via clicking a chart), but are not user-selectable
      { value: "isTop", label: "Top Stories" },
    ],
  },
  {
    label: "Article",
    options: [
      { value: "keyword", label: "Keyword" },
      { value: "mediaType", label: "Media Type & Rank" },
      { value: "articleTopic", label: "Topic" },
      { value: "language", label: "Language" },
      { value: "reach", label: "Reach" },
      { value: "mozrank", label: "Mozrank" },
      { value: "agent", label: "Search Agent" },
    ],
  },
  {
    label: "Source",
    options: [
      { value: "sourceName", label: "Source Name" },
      { value: "authorName", label: "Author Name" },
      { value: "sourceDomain", label: "Domain Name" },
      { value: "category", label: "Source Category" },
      { value: "country", label: "Source Country" },
    ],
  },
  {
    label: "Tags",
    options: [
      { value: "sentiment", label: "Sentiment" },
      { value: "trafficLight", label: "Traffic Light" },
      { value: "topic", label: "Topic Tag" },
      { value: "concern", label: "Key Concern Tag" },
    ],
  },
  {
    label: "Stakeholders", // update Filters if this label is changed
    options: [
      { value: "person", label: "People" },
      { value: "organisation", label: "Organisations" },
      { value: "entityGroup", label: "Stakeholder Groups" },
    ],
  },
];

export const FILTER_TYPES_SOCIAL360 = [
  {
    label: null,
    options: [
      // these options can be used as a filter (via clicking a chart), but are not user-selectable
      { value: "isTop", label: "Top Stories" },
    ],
  },
  {
    label: "Article",
    options: [
      { value: "keyword", label: "Keyword" },
      { value: "mediaType", label: "Media Type" },
      { value: "language", label: "Language" },
      { value: "socialEngagement", label: "Engagement" },
      { value: "socialReactions", label: "Reactions" },
      { value: "socialComments", label: "Comments" },
      { value: "socialShares", label: "Shares" },
      { value: "socialViews", label: "Views" },
      { value: "agent", label: "Search Agent" },
    ],
  },
  {
    label: "Source",
    options: [
      { value: "sourceName", label: "Source Name" },
      { value: "authorGroups", label: "Author Groups" },
      { value: "authorName", label: "Author Name" },
      { value: "authorEmail", label: "Author Contact" },
      { value: "authorPosts", label: "Author Posts" },
      { value: "authorFollowers", label: "Author Followers" },
      { value: "authorFollowing", label: "Author Following" },
    ],
  },
  {
    label: "Tags",
    options: [
      { value: "sentiment", label: "Sentiment" },
      { value: "trafficLight", label: "Traffic Light" },
      { value: "topic", label: "Topic Tag" },
      { value: "concern", label: "Key Concern Tag" },
    ],
  },
];

export const SENTIMENT = [
  { value: "NEGATIVE", label: "Negative" },
  { value: "NEUTRAL", label: "Neutral" },
  { value: "POSITIVE", label: "Positive" },
];

export const TRAFFIC_LIGHT = [
  { value: "THREAT", label: "Threat" },
  { value: "ALERT", label: "Alert" },
  { value: "OPPORTUNITY", label: "Opportunity" },
];

export const MEDIA_TYPE_METABASE = [
  ...METABASE_NEWS_MEDIA_TYPES.map((x) => ({ value: `metabase-${x}`, label: x })),
  ...METABASE_SOCIAL_MEDIA_TYPES.map((x) => ({ value: `metabase-${x}`, label: x })),
  ...METABASE_OTHER_MEDIA_TYPES.map((x) => ({ value: `metabase-${x}`, label: x })),
];

export const MEDIA_TYPE_SOCIAL360 = Object.entries(SOCIAL360_MEDIA_TYPES).map((x) => ({
  value: `social-${x[0]}`,
  label: x[1],
}));

// metabase news / print
export const NEWS_RANK = [
  { value: "1", label: "Rank 1" },
  { value: "2", label: "Rank 2" },
  { value: "3", label: "Rank 3" },
  { value: "4", label: "Rank 4" },
  { value: "5", label: "Rank 5" },
];

// metabase social
export const SOCIAL_RANK = [
  { value: "1", label: "Rank 1" },
  { value: "2", label: "Rank 2" },
  { value: "3", label: "Rank 3" },
  { value: "4", label: "Rank 4" },
  { value: "5", label: "Rank 5" },
  { value: "6", label: "Rank 6" },
  { value: "7", label: "Rank 7" },
  { value: "8", label: "Rank 8" },
  { value: "9", label: "Rank 9" },
  { value: "10", label: "Rank 10" },
];

export const LANGUAGE = METABASE_LANGUAGES.map((x) => ({ value: x, label: x }));

export const COUNTRY = METABASE_COUNTRIES.map((x) => ({ value: x, label: x }));

export const CATEGORY = [
  { value: "Academic", label: "Academic" },
  { value: "Consumer", label: "Consumer" },
  { value: "Corporate", label: "Corporate" },
  { value: "General", label: "General" },
  { value: "Government", label: "Government" },
  { value: "Journal", label: "Journal" },
  { value: "Local", label: "Local" },
  { value: "Miscellaneous", label: "Miscellaneous" },
  { value: "National", label: "National" },
  { value: "Organization", label: "Organization" },
  { value: "Press Wire", label: "Press Wire" },
  { value: "Trade", label: "Trade" },
];

export const REACH = [
  { value: "0-10000", label: "0 - 10k" },
  { value: "10000-25000", label: "10k - 25k" },
  { value: "25000-50000", label: "25k - 50k" },
  { value: "50000-100000", label: "50k - 100k" },
  { value: "100000-250000", label: "100k - 250k" },
  { value: "250000-500000", label: "250k - 500k" },
  { value: "500000-1000000", label: "500k - 1m" },
  { value: "1000000-2500000", label: "1m - 2.5m" },
  { value: "2500000-5000000", label: "2.5m - 5m" },
  { value: "5000000-", label: "5m+" },
];

export const MOZRANK = range(1, 11).map((x) => ({ value: x.toString(), label: x.toString() }));

export const WITHIN = [
  { value: "all", label: "Whole story (title and content)" },
  { value: "content100", label: "First 100 words of story content" },
  { value: "title", label: "Title only" },
];

export const PROXIMITY = [
  { value: "0", label: "Adjacent" },
  { value: "1", label: "1 word" },
  { value: "5", label: "5 words" },
  { value: "10", label: "10 words" },
  { value: "20", label: "20 words" },
  { value: "50", label: "50 words" },
];

export const COUNTRIES = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
].map((x) => ({ value: x, label: x }));

// provided by LN in Excel file
export const ARTICLE_TOPIC = [
  "3M news",
  "Accounting news",
  "Advertising industry news",
  "Aerospace and defense industry news",
  "Afghanistan news",
  "Africa news",
  "Agriculture news",
  "AIG news",
  "Airline industry news",
  "Albania news",
  "Alcoa news",
  "Algeria news",
  "American Express news",
  "Android news",
  "Angola news",
  "Antarctic news",
  "Apple news",
  "Apps news",
  "Architecture and design news",
  "Arctic news",
  "Argentina news",
  "Armenia news",
  "Asia-Pacific latest",
  "ASP news",
  "AT&T news",
  "Atlanta news",
  "Augmented reality news",
  "Austin news",
  "Australia news",
  "Austria news",
  "Automotive industry news",
  "Azerbaijan news",
  "Bahrain news",
  "Balkans news",
  "Baltic states news",
  "Baltimore news",
  "Bangladesh news",
  "Bank of America news",
  "Bank of England news",
  "Banking news",
  "Bankruptcy news",
  "Beijing news",
  "Belarus news",
  "Belgium news",
  "Benelux news",
  "Benin news",
  "Berlin news",
  "Beverages industry news",
  "Bhutan news",
  "Biological science news",
  "Biotech news",
  "Birmingham-Hoover news",
  "Black interest news",
  "Blogging news",
  "BMW news",
  "Boeing news",
  "Bolivia news",
  "Book publishing news",
  "Bosnia and Herzegovina news",
  "Boston news",
  "Botswana news",
  "BP news",
  "Brazil news",
  "British Broadcasting Corporation news",
  "Broadcasting industry news",
  "Brunei news",
  "BT Group news",
  "Buffalo-Niagara Falls news",
  "Bulgaria news",
  "Burkina Faso news",
  "Burma news",
  "Burundi news",
  "Business latest",
  "Cable industry news",
  "Cambodia news",
  "Cameroon news",
  "Canada news",
  "Cancer news",
  "Cape Verde news",
  "Caribbean news",
  "Caterpillar Inc news",
  "Caucasus news",
  "Central African Republic news",
  "Central America news",
  "Central Asia news",
  "Central banks news",
  "Chad news",
  "Charities news",
  "Charlotte news",
  "Chemicals industry news",
  "Chevron news",
  "Chicago news",
  "Chile news",
  "China news",
  "Chrysler Group news",
  "Cincinnati news",
  "Cisco Systems news",
  "Citigroup news",
  "Cleveland news",
  "Climate change news",
  "Clothing industry news",
  "Cloud computing news",
  "Coal industry news",
  "Coca-Cola Company news",
  "Colombia news",
  "Columbus news",
  "Commercial spaceflight news",
  "Commodities news",
  "Communications equipment news",
  "Competition law news",
  "Computer security news",
  "Computer services news",
  "ConocoPhillips news",
  "Construction news",
  "Consumer durables news",
  "Consumer electronics news",
  "Consumer non-durables news",
  "Consumer protection news",
  "Consumer: biking news",
  "Consumer: fashion news",
  "Consumer: fitness news",
  "Consumer: food and drink news",
  "Consumer: green news",
  "Consumer: health news",
  "Consumer: home and garden news",
  "Consumer: mens news",
  "Consumer: motoring news",
  "Consumer: natural health news",
  "Consumer: outdoor recreation news",
  "Consumer: parenting news",
  "Consumer: pets news",
  "Consumer: photography news",
  "Consumer: recipes",
  "Consumer: relationships news",
  "Consumer: seniors news",
  "Consumer: top stories",
  "Consumer: travel news",
  "Consumer: womens news",
  "Corporate finance news",
  "Corporate governance news",
  "Costa Rica news",
  "Crime and punishment news",
  "CRM news",
  "Croatia news",
  "Cuba news",
  "Cyberculture news",
  "Cyprus news",
  "Czech Republic news",
  "Dallas-Fort Worth news",
  "Data storage news",
  "Database industry news",
  "Delaware Valley news",
  "Denmark news",
  "Denver-Aurora news",
  "Derivatives news",
  "Detroit news",
  "Diamond industry news",
  "Digital distribution news",
  "Discoveryplus",
  "Disney+",
  "Djibouti news",
  "Domain name news",
  "Dominican Republic news",
  "DR Congo news",
  "Dubai news",
  "DuPont news",
  "E-commerce news",
  "Earnings news",
  "Eastern Europe news",
  "Economics news",
  "Ecuador news",
  "Education news",
  "Egypt news",
  "El Salvador news",
  "Emerging markets news",
  "Energy industry news",
  "Engineering news",
  "England news",
  "Enterprise computing news",
  "Entertainment: Bollywood news",
  "Entertainment: book news",
  "Entertainment: book reviews",
  "Entertainment: celebrity gossip",
  "Entertainment: fine arts news",
  "Entertainment: latest",
  "Entertainment: movie news",
  "Entertainment: movie reviews",
  "Entertainment: movie trailers",
  "Entertainment: pop music news",
  "Entertainment: pop music reviews",
  "Entertainment: superhero news",
  "Entertainment: television news",
  "Entertainment: theater news",
  "Entertainment: video game news",
  "Entrepreneur news",
  "Environment news",
  "Equatorial Guinea news",
  "Equity markets news",
  "Eritrea news",
  "Estonia news",
  "Ethiopia news",
  "Europe news",
  "European banking news",
  "European business news",
  "European Internet news",
  "European media news",
  "European Union news",
  "Eurozone news",
  "Executive moves news",
  "Exxon Mobil news",
  "Facebook news",
  "Fake news, satire and parody",
  "Fed watch",
  "FedEx news",
  "Ferrari news",
  "Fiat Chrysler Automobiles news",
  "Fiji news",
  "Finance latest",
  "Finland news",
  "Firearms industry news",
  "Fishing industry news",
  "Fixed income news",
  "Food industry news",
  "Ford Motor Company news",
  "Forex markets news",
  "France news",
  "FTSE 100 Companies",
  "FTSE 250 Companies",
  "Fund management news",
  "Gabon news",
  "Gambia news",
  "Gaming news",
  "General Electric news",
  "General Motors news",
  "Genetics news",
  "Georgia news",
  "Germany news",
  "Ghana news",
  "Global news",
  "GMO news",
  "Gold news",
  "Goldman Sachs news",
  "Google news",
  "Graphics industry news",
  "Greater Hartford news",
  "Greater Orlando news",
  "Greece news",
  "Guatemala news",
  "Guinea news",
  "Guinea-Bissau news",
  "Haiti news",
  "Hampton Roads news",
  "Handhelds news",
  "Health care industry news",
  "Health insurance news",
  "Healthcare management news",
  "Hewlett-Packard news",
  "HIV and AIDS news",
  "Home Depot news",
  "Honduras news",
  "Hong Kong news",
  "Hospitality industry news",
  "Houston news",
  "HSBC news",
  "Human resources news",
  "Human rights news",
  "Human science news",
  "Hungary news",
  "Hyundai Motor Company news",
  "IBM news",
  "Iceland news",
  "Illegal drugs news",
  "Imaging equipment news",
  "India news",
  "Indian subcontinent news",
  "Indianapolis news",
  "Indonesia news",
  "Inland Empire news",
  "Insurance industry news",
  "Intel news",
  "International development news",
  "International relations news",
  "Internet access news",
  "Internet browsers news",
  "Internet latest",
  "Internet telephony news",
  "Investment banking news",
  "IP and patents news",
  "IPO news",
  "Iran news",
  "Iraq news",
  "Ireland news",
  "ISPs news",
  "Israel news",
  "Italy news",
  "Ivory Coast news",
  "Jacksonville news",
  "Jamaica news",
  "Japan news",
  "Java news",
  "Jewish news",
  "Job markets news",
  "Johannesburg news",
  "Johnson & Johnson news",
  "Jordan news",
  "Journalism news",
  "JPMorgan Chase news",
  "Kansas City news",
  "Kazakhstan news",
  "Kenya news",
  "Knowledge management news",
  "Kosovo news",
  "Kraft Foods news",
  "Kuwait news",
  "Kyrgyzstan news",
  "Laos news",
  "Las Vegas news",
  "Latino news",
  "Latvia news",
  "Law news",
  "Lawsuits news",
  "Lebanon news",
  "Leisure goods news",
  "Lesotho news",
  "LGBT news",
  "Liberia news",
  "Libya news",
  "Linux news",
  "Lithuania news",
  "Local government news",
  "Logistics news",
  "London news",
  "Los Angeles news",
  "Louisville news",
  "Luxembourg news",
  "Luxury goods news",
  "Mac news",
  "Macedonia news",
  "Madagascar news",
  "Malawi news",
  "Malaysia news",
  "Maldives news",
  "Mali news",
  "Malta news",
  "Management news",
  "Manufacturing industry news",
  "Market data",
  "Marketing news",
  "Mauritania news",
  "Mauritius news",
  "McDonalds news",
  "Media conglomerates news",
  "Media latest",
  "Medicaid and Medicare news",
  "Medical news",
  "Memphis news",
  "Merck & Co news",
  "Mergers and acquisitions news",
  "Metals industry news",
  "Mexico news",
  "Microsoft news",
  "Middle East news",
  "Middle Eastern business news",
  "Military news",
  "Milwaukee news",
  "Mining industry news",
  "Minneapolis-St Paul news",
  "Mobile industry news",
  "Moldova news",
  "Money laundering news",
  "Mongolia news",
  "Montenegro news",
  "Morgan Stanley news",
  "Morocco news",
  "Mortgage industry news",
  "Moscow news",
  "Movie business news",
  "Mozambique news",
  "Mumbai news",
  "Music business news",
  "Mutual funds news",
  "Namibia news",
  "Nanotechnology news",
  "Nashville news",
  "National oil companies news",
  "Natural disasters news",
  "Nature news",
  "Nepal news",
  "Netherlands news",
  "New Orleans news",
  "New York metro area news",
  "New Zealand news",
  "Newspaper publishing news",
  "NHS news",
  "Niger news",
  "Nigeria news",
  "Nike news",
  "North Africa news",
  "North Korea news",
  "Northern Ireland news",
  "Norway news",
  "Nuclear power news",
  "Nuclear proliferation news",
  "Obituaries",
  "Oceania latest",
  "Offbeat news",
  "Oil and gas news",
  "Oklahoma City news",
  "Oman news",
  "Online auction news",
  "Online banking news",
  "Online broker news",
  "Online content news",
  "Online gambling news",
  "Online legal issues news",
  "Online marketing news",
  "Online portals news",
  "Open source news",
  "Opinion latest",
  "OS news",
  "Outsourcing news",
  "Packaging and paper news",
  "Pakistan news",
  "Palestinian territories news",
  "Panama news",
  "Papua New Guinea news",
  "Paraguay news",
  "Paris news",
  "Pay television industry news",
  "PC industry news",
  "PC software news",
  "Pensions news",
  "PepsiCo news",
  "Personal data privacy news",
  "Personal finance news",
  "Personal technology news",
  "Peru news",
  "Pfizer news",
  "Pharma industry news",
  "Philippines news",
  "Phoenix news",
  "Physical science news",
  "Pittsburgh news",
  "Plastics industry news",
  "Poland news",
  "Police news",
  "Political unrest news",
  "Politics latest",
  "Portland news",
  "Portugal news",
  "Private equity news",
  "Private finance initiative news",
  "Procter & Gamble news",
  "Procurement news",
  "Product launch news",
  "Product recall news",
  "Programming news",
  "Providence news",
  "Public health news",
  "Public sector news",
  "Puerto Rico news",
  "Qatar news",
  "R&D news",
  "Radio industry news",
  "Rail transport news",
  "Raleigh-Durham news",
  "Real estate news",
  "Regulatory news",
  "Religion news",
  "Renewable energy news",
  "Republic of the Congo news",
  "Restaurant industry news",
  "Retail banking news",
  "Retail industry news",
  "RFID news",
  "Richmond-Petersburg news",
  "Robotics news",
  "Romania news",
  "Royal Dutch Shell news",
  "Russia news",
  "Rwanda news",
  "Sacramento news",
  "Salt Lake City news",
  "San Antonio news",
  "San Diego news",
  "San Francisco Bay Area news",
  "San Jose news",
  "Saudi Arabia news",
  "Scandinavia news",
  "Science news",
  "Scotland news",
  "Search engines news",
  "Seattle-Tacoma news",
  "Securities industry news",
  "Security services news",
  "Semiconductor industry news",
  "Senegal news",
  "Serbia news",
  "Seychelles news",
  "Shanghai news",
  "Shipping industry news",
  "Sierra Leone news",
  "Silicon Valley news",
  "Singapore news",
  "Slovakia news",
  "Slovenia news",
  "Small business news",
  "Small investor news",
  "Social media news",
  "Social networks news",
  "Society news",
  "Software downloads",
  "Somalia news",
  "South Africa news",
  "South America news",
  "South Florida news",
  "South Korea news",
  "South Pacific Islands news",
  "South Sudan news",
  "Southeast Asia news",
  "Space science news",
  "Spain news",
  "Sponsorship news",
  "Sports business news",
  "Sports: American football news",
  "Sports: athletics news",
  "Sports: Australian rules football news",
  "Sports: baseball news",
  "Sports: basketball news",
  "Sports: boxing news",
  "Sports: Bundesliga news",
  "Sports: college basketball news",
  "Sports: college football news",
  "Sports: cricket news",
  "Sports: EPL - AFC Bournemouth news",
  "Sports: EPL - Arsenal news",
  "Sports: EPL - Aston Villa news",
  "Sports: EPL - Brighton and Hove Albion news",
  "Sports: EPL - Chelsea news",
  "Sports: EPL - Crystal Palace news",
  "Sports: EPL - Everton news",
  "Sports: EPL - Huddersfield FC news",
  "Sports: EPL - Leicester City news",
  "Sports: EPL - Liverpool news",
  "Sports: EPL - Manchester City news",
  "Sports: EPL - Manchester United news",
  "Sports: EPL - Newcastle United news",
  "Sports: EPL - Norwich City news",
  "Sports: EPL - Southampton news",
  "Sports: EPL - Stoke City news",
  "Sports: EPL - Sunderland news",
  "Sports: EPL - Swansea City news",
  "Sports: EPL - Tottenham Hotspur news",
  "Sports: EPL - Watford news",
  "Sports: EPL - West Bromwich Albion news",
  "Sports: EPL - West Ham United news",
  "Sports: extreme sports news",
  "Sports: field hockey news",
  "Sports: FIFA World Cup news",
  "Sports: fishing news",
  "Sports: Formula 1 news",
  "Sports: golf news",
  "Sports: gymnastics news",
  "Sports: horse racing news",
  "Sports: ice hockey news",
  "Sports: IPL news",
  "Sports: La Liga news",
  "Sports: lacrosse news",
  "Sports: latest",
  "Sports: latest scores",
  "Sports: Minor League Baseball news",
  "Sports: MLB - Arizona Diamondbacks news",
  "Sports: MLB - Atlanta Braves news",
  "Sports: MLB - Baltimore Orioles news",
  "Sports: MLB - Boston Red Sox news",
  "Sports: MLB - Chicago Cubs news",
  "Sports: MLB - Chicago White Sox news",
  "Sports: MLB - Cincinnati Reds news",
  "Sports: MLB - Cleveland Indians news",
  "Sports: MLB - Colorado Rockies news",
  "Sports: MLB - Detroit Tigers news",
  "Sports: MLB - Florida Marlins news",
  "Sports: MLB - Houston Astros news",
  "Sports: MLB - Kansas City Royals news",
  "Sports: MLB - LA Angels of Anaheim news",
  "Sports: MLB - Los Angeles Dodgers news",
  "Sports: MLB - Milwaukee Brewers news",
  "Sports: MLB - Minnesota Twins news",
  "Sports: MLB - New York Mets news",
  "Sports: MLB - New York Yankees news",
  "Sports: MLB - Oakland Athletics news",
  "Sports: MLB - Philadelphia Phillies news",
  "Sports: MLB - Pittsburgh Pirates news",
  "Sports: MLB - San Diego Padres news",
  "Sports: MLB - San Francisco Giants news",
  "Sports: MLB - Seattle Mariners news",
  "Sports: MLB - St Louis Cardinals news",
  "Sports: MLB - Tampa Bay Devil Rays news",
  "Sports: MLB - Texas Rangers news",
  "Sports: MLB - Toronto Blue Jays news",
  "Sports: MLB - Washington Nationals news",
  "Sports: MLB news",
  "Sports: MLS news",
  "Sports: MMA news",
  "Sports: MotoGP news",
  "Sports: motor sports news",
  "Sports: NASCAR news",
  "Sports: NBA - Atlanta Hawks news",
  "Sports: NBA - Boston Celtics news",
  "Sports: NBA - Brooklyn Nets news",
  "Sports: NBA - Charlotte Bobcats news",
  "Sports: NBA - Chicago Bulls news",
  "Sports: NBA - Cleveland Cavaliers news",
  "Sports: NBA - Dallas Mavericks news",
  "Sports: NBA - Denver Nuggets news",
  "Sports: NBA - Detroit Pistons news",
  "Sports: NBA - Golden State Warriors news",
  "Sports: NBA - Houston Rockets news",
  "Sports: NBA - Indiana Pacers news",
  "Sports: NBA - Los Angeles Clippers news",
  "Sports: NBA - Los Angeles Lakers news",
  "Sports: NBA - Memphis Grizzlies news",
  "Sports: NBA - Miami Heat news",
  "Sports: NBA - Milwaukee Bucks news",
  "Sports: NBA - Minnesota Timberwolves news",
  "Sports: NBA - New Orleans Hornets news",
  "Sports: NBA - New York Knicks news",
  "Sports: NBA - Oklahoma City Thunder news",
  "Sports: NBA - Orlando Magic news",
  "Sports: NBA - Philadelphia 76ers news",
  "Sports: NBA - Phoenix Suns news",
  "Sports: NBA - Portland Trail Blazers news",
  "Sports: NBA - Sacramento Kings news",
  "Sports: NBA - San Antonio Spurs news",
  "Sports: NBA - Toronto Raptors news",
  "Sports: NBA - Utah Jazz news",
  "Sports: NBA - Washington Wizards news",
  "Sports: NBA news",
  "Sports: NFL - Arizona Cardinals news",
  "Sports: NFL - Atlanta Falcons news",
  "Sports: NFL - Baltimore Ravens news",
  "Sports: NFL - Buffalo Bills news",
  "Sports: NFL - Carolina Panthers news",
  "Sports: NFL - Chicago Bears news",
  "Sports: NFL - Cincinnati Bengals news",
  "Sports: NFL - Cleveland Browns news",
  "Sports: NFL - Dallas Cowboys news",
  "Sports: NFL - Denver Broncos news",
  "Sports: NFL - Detroit Lions news",
  "Sports: NFL - Green Bay Packers news",
  "Sports: NFL - Houston Texans news",
  "Sports: NFL - Indianapolis Colts news",
  "Sports: NFL - Jacksonville Jaguars news",
  "Sports: NFL - Kansas City Chiefs news",
  "Sports: NFL - Miami Dolphins news",
  "Sports: NFL - Minnesota Vikings news",
  "Sports: NFL - New England Patriots news",
  "Sports: NFL - New Orleans Saints news",
  "Sports: NFL - New York Giants news",
  "Sports: NFL - New York Jets news",
  "Sports: NFL - Oakland Raiders news",
  "Sports: NFL - Philadelphia Eagles news",
  "Sports: NFL - Pittsburgh Steelers news",
  "Sports: NFL - San Diego Chargers news",
  "Sports: NFL - San Francisco 49ers news",
  "Sports: NFL - Seattle Seahawks news",
  "Sports: NFL - St Louis Rams news",
  "Sports: NFL - Tampa Bay Buccaneers news",
  "Sports: NFL - Tennessee Titans news",
  "Sports: NFL - Washington Redskins news",
  "Sports: NFL gossip",
  "Sports: NFL news",
  "Sports: NHL - Anaheim Ducks news",
  "Sports: NHL - Arizona Coyotes news",
  "Sports: NHL - Boston Bruins news",
  "Sports: NHL - Buffalo Sabres news",
  "Sports: NHL - Calgary Flames news",
  "Sports: NHL - Carolina Hurricanes news",
  "Sports: NHL - Chicago Blackhawks news",
  "Sports: NHL - Colorado Avalanche news",
  "Sports: NHL - Columbus Blue Jackets news",
  "Sports: NHL - Dallas Stars news",
  "Sports: NHL - Detroit Red Wings news",
  "Sports: NHL - Edmonton Oilers news",
  "Sports: NHL - Florida Panthers news",
  "Sports: NHL - Los Angeles Kings news",
  "Sports: NHL - Minnesota Wild news",
  "Sports: NHL - Montreal Canadiens news",
  "Sports: NHL - Nashville Predators news",
  "Sports: NHL - New Jersey Devils news",
  "Sports: NHL - New York Islanders news",
  "Sports: NHL - New York Rangers news",
  "Sports: NHL - Ottawa Senators news",
  "Sports: NHL - Philadelphia Flyers news",
  "Sports: NHL - Pittsburgh Penguins news",
  "Sports: NHL - San Jose Sharks news",
  "Sports: NHL - St Louis Blues news",
  "Sports: NHL - Tampa Bay Lightning news",
  "Sports: NHL - Toronto Maple Leafs news",
  "Sports: NHL - Vancouver Canucks news",
  "Sports: NHL - Washington Capitals news",
  "Sports: NHL - Winnipeg Jets news",
  "Sports: NHL news",
  "Sports: Olympic Games news",
  "Sports: Premier League news",
  "Sports: road cycling news",
  "Sports: rowing news",
  "Sports: rugby league news",
  "Sports: rugby union news",
  "Sports: sailing news",
  "Sports: Serie A news",
  "Sports: snooker news",
  "Sports: soccer gossip",
  "Sports: soccer news",
  "Sports: swimming news",
  "Sports: tennis news",
  "Sports: top stories",
  "Sports: UEFA Champions League news",
  "Sports: US college sports news",
  "Sports: volleyball news",
  "Sports: winter sports news",
  "Sports: World Rally Championship news",
  "Sports: wrestling news",
  "Sri Lanka news",
  "St Louis news",
  "Starbucks news",
  "Steelmaking news",
  "Stock Exchanges news",
  "Stockwatch",
  "Sudan news",
  "Swaziland news",
  "Sweden news",
  "Switzerland news",
  "Sydney news",
  "Syria news",
  "Taiwan news",
  "Tajikistan news",
  "Tampa Bay Area news",
  "Tanzania news",
  "Tax news",
  "Tech events",
  "Tech latest",
  "Tech policy news",
  "Tech stocks news",
  "Tel Aviv news",
  "Telecom news",
  "Television industry news",
  "Terrorism news",
  "Tesco news",
  "Textiles news",
  "Thailand news",
  "Tobacco industry news",
  "Togo news",
  "Tokyo news",
  "Top Africa stories",
  "Top Asia-Pacific stories",
  "Top Australia stories",
  "Top business stories",
  "Top Canada stories",
  "Top environment stories",
  "Top Europe stories",
  "Top finance stories",
  "Top internet stories",
  "Top media stories",
  "Top Middle East stories",
  "Top stories",
  "Top technology stories",
  "Top UK stories",
  "Top US stories",
  "Toronto news",
  "Total SA news",
  "Toyota Motor news",
  "Trade news",
  "Trade union news",
  "Transportation industry news",
  "Travel industry news",
  "Travelers Companies news",
  "Trinidad and Tobago news",
  "Trucking industry news",
  "Tunisia news",
  "Turkey news",
  "Turkmenistan news",
  "Uganda news",
  "UK business news",
  "UK education news",
  "UK immigration news",
  "UK law news",
  "UK media news",
  "UK news",
  "UK politics news",
  "UK security news",
  "UK social policy news",
  "Ukraine news",
  "United Arab Emirates news",
  "United Technologies news",
  "UnitedHealth Group news",
  "UPS news",
  "Uruguay news",
  "US banking news",
  "US education news",
  "US Federal Government news",
  "US immigration news",
  "US news",
  "US political columnists",
  "US politics news",
  "US security news",
  "US social policy news",
  "US State Government news",
  "Utilities news",
  "Uzbekistan news",
  "Venezuela news",
  "Venture capital news",
  "Verizon Communications news",
  "Veterans news",
  "Video game industry news",
  "Vietnam news",
  "Visa Inc news",
  "Vodafone Group news",
  "Volkswagen news",
  "Wal-Mart Stores news",
  "Wales news",
  "Walt Disney Company news",
  "Washington DC news",
  "Waste management news",
  "Water industry news",
  "Weather news",
  "Web 2.0 news",
  "Web development news",
  "Wells Fargo news",
  "Windows news",
  "Wireless industry news",
  "Womens rights news",
  "Yahoo Inc news",
  "Yemen news",
  "Zambia news",
  "Zimbabwe news",
].map((x) => ({ value: x, label: x }));

// social 360
export const SOCIAL360_LANGUAGE = sort(
  Object.entries(SOCIAL360_LANGUAGES).map((x) => ({ value: x[0], label: x[1] })),
  "label"
);

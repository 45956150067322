import * as Sentry from "@sentry/react";

import {
  Button,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { addableBlocks, nonEditableBlocks, nonResultBlockOptions, resultBlockOptions } from "./lib";
import { bodyFixedEffect, useMediaBreakpointDownLg } from "../lib/effects";
import { getReportData, getReportFull } from "../../lib/reports";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import BlockPanel from "./BlockPanel";
import Chart from "../Charts/Chart";
import ConfirmModal from "../Modals/ConfirmModal";
import Handlebars from "handlebars";
import JustHandlebarsHelpers from "just-handlebars-helpers";
import MobileNoAccess from "../lib/mobile";
import ReactDOM from "react-dom";
import ReportPDF from "./ReportPDF";
import ReportTitle from "./ReportTitle";
import ResultPanel from "./ResultPanel";
import SendModal from "./SendModal";
import Spinner from "../../components/Spinner";
import { Storage } from "aws-amplify";
import TableRowPanel from "./TableRowPanel";
import axios from "axios";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import flatten from "lodash/flatten";
import { fnReport } from "../../graphql/mutations";
import { getChartBuilderData } from "../Charts/lib";
import { getExportDate } from "../../lib/utils";
import { getPublicApiUrlPrefix } from "../../lib/constants";
import { pdf } from "@react-pdf/renderer";
import pick from "lodash/pick";
import pool from "@ricokahler/pool";
import pull from "lodash/pull";
import { saveAs } from "file-saver";
import templates from "../../templates/report";
import { titleCase } from "title-case";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

// setup Handlebars
// - pre-compile each block template
// - register partials (blocks and partials)
const handlebarsTemplates = {};
JustHandlebarsHelpers.registerHelpers(Handlebars);
for (const [name, template] of Object.entries(templates)) {
  const compiled = Handlebars.compile(template);
  handlebarsTemplates[name] = compiled;
  Handlebars.registerPartial(name, compiled);
}

const ResultActions = ({ result, block, allBlocks, isDisabled, onOpen, onMove, onDelete }) => (
  <ButtonGroup size="sm">
    {block.type !== "DELETED" && (
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled}
        onClick={() => onOpen({ op: block.type === "TABLE" ? "TABLEROW_UPDATE" : "RESULT_UPDATE", data: result })}
      >
        <i className="mdi mdi-pencil-outline" />
      </Button>
    )}
    {block.type !== "TABLE" && (
      <UncontrolledButtonDropdown>
        <DropdownToggle color="secondary" className="report-builder--action" size="sm" disabled={isDisabled}>
          <i className="mdi mdi-grid-large" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{block.type === "DELETED" ? "Restore" : "Move"} to...</DropdownItem>
          {allBlocks
            // can only move into blocks that can contain results BUT NOT TABLE
            .filter((x) => x.id !== block.id && addableBlocks.includes(x.type) && x.type !== "TABLE")
            .map((option) => (
              <DropdownItem key={option.id} onClick={() => onMove(result.id, option.id)}>
                {option.title}{" "}
                <span className="report-builder--move-type">({titleCase(option.type.toLowerCase())})</span>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )}
    {block.type !== "DELETED" && (
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled}
        onClick={() => onDelete(result.id)}
      >
        <i className="uil-trash" />
      </Button>
    )}
  </ButtonGroup>
);

const ResultOrdering = ({ result, allResults, block, isDisabled, onOrder }) => {
  if (allResults.length === 1 || block.type === "DELETED") return null;

  return (
    <ButtonGroup size="sm">
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled || result.order === 0}
        onClick={() => onOrder(result.id, true)}
      >
        <i className={block.type === "GRID" ? "uil-arrow-left" : "uil-arrow-up"} />
      </Button>
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled || result.order === allResults.length - 1}
        onClick={() => onOrder(result.id, false)}
      >
        <i className={block.type === "GRID" ? "uil-arrow-right" : "uil-arrow-down"} />
      </Button>
    </ButtonGroup>
  );
};

const BlockActions = ({ block, isDisabled, onOpen, onDelete }) => (
  <ButtonGroup size="sm">
    {/* can only add into blocks that can contain results */}
    {addableBlocks.includes(block.type) && (
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled}
        onClick={() => onOpen({ op: block.type === "TABLE" ? "TABLEROW_CREATE" : "RESULT_CREATE", data: block })}
      >
        <i className="uil-plus" />
      </Button>
    )}
    <Button
      color="secondary"
      className="report-builder--action"
      disabled={isDisabled}
      onClick={() => onOpen({ op: "BLOCK_UPDATE", data: block })}
    >
      <i className="mdi mdi-pencil-outline" />
    </Button>
    <Button color="secondary" className="report-builder--action" disabled={isDisabled} onClick={onDelete}>
      <i className="uil-trash" />
    </Button>
  </ButtonGroup>
);

const BlockOrdering = ({ block, allBlocks, isDisabled, onOrder, onReorderResults }) => {
  if (allBlocks.length === 1) return null;

  return (
    <ButtonGroup size="sm">
      {resultBlockOptions.map((x) => x.value).includes(block.type) && (
        <Button color="secondary" className="report-builder--action" onClick={onReorderResults}>
          <i className="uil-sort-amount-down" />
        </Button>
      )}
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled || block.order === 0}
        onClick={() => onOrder(true)}
      >
        <i className="uil-arrow-up" />
      </Button>
      <Button
        color="secondary"
        className="report-builder--action"
        disabled={isDisabled || block.order === allBlocks.length - 1}
        onClick={() => onOrder(false)}
      >
        <i className="uil-arrow-down" />
      </Button>
    </ButtonGroup>
  );
};

const Block = ({
  type,
  block,
  data,
  allBlocks,
  isDisabled,
  onOpen,
  isUpdating,
  setIsUpdating,
  refetchReport,
  project,
  projectData,
  chartRefs,
}) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const confirmModal = createConfirmation(ConfirmModal);
  const [mutationFnReport] = useMutation(gql(fnReport));

  const ref = useRef();
  const chartRef = useRef();

  useEffect(() => {
    if (ref.current) {
      // NOTE: injected components don't have access to app-wide wrappers (e.g. apollo, router) so event handlers are in this component
      // workarounds have been made for Chart component (clientId, projectId, apollo passed in to each Chart)
      const { clientId, projectId, reportId } = block || {};

      const handleBlockDelete = async () => {
        if (isUpdating) return;
        const suffix = addableBlocks.includes(block.type)
          ? block.type === "TABLE"
            ? "Any rows will be deleted."
            : "Any results will be moved to the [Deleted] block."
          : "";
        const ok = await confirmModal({
          message: `Are you sure you want to delete this block? ${suffix}`,
        });
        if (!ok) return;
        setIsUpdating(true);
        try {
          const input = { reportId, id: block.id };
          await mutationFnReport({
            variables: { input: { clientId, projectId, op: "BLOCK_DELETE", data: JSON.stringify(input) } },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      const handleBlockOrder = async (moveUp) => {
        if (isUpdating) return;
        setIsUpdating(true);
        try {
          const input = { reportId, id: block.id };
          await mutationFnReport({
            variables: {
              input: {
                clientId,
                projectId,
                op: moveUp ? "BLOCK_MOVE_UP" : "BLOCK_MOVE_DOWN",
                data: JSON.stringify(input),
              },
            },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      const handleBlockReorderResults = async () => {
        if (isUpdating) return;
        setIsUpdating(true);
        try {
          const input = { reportId, id: block.id };
          await mutationFnReport({
            variables: {
              input: {
                clientId,
                projectId,
                op: "BLOCK_REORDER_RESULTS",
                data: JSON.stringify(input),
              },
            },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      const handleResultDelete = async (resultId) => {
        if (isUpdating) return;
        setIsUpdating(true);
        try {
          const input = { reportId, reportBlockId: block.id, id: resultId };
          await mutationFnReport({
            variables: {
              input: {
                clientId,
                projectId,
                op: block.type === "TABLE" ? "TABLEROW_DELETE" : "RESULT_DELETE",
                data: JSON.stringify(input),
              },
            },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      const handleResultMove = async (resultId, destinationBlockId) => {
        if (isUpdating) return;
        setIsUpdating(true);
        try {
          const input = { reportId, sourceBlockId: block.id, destinationBlockId, id: resultId };
          await mutationFnReport({
            variables: { input: { clientId, projectId, op: "RESULT_MOVE_BLOCK", data: JSON.stringify(input) } },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      const handleResultOrder = async (resultId, moveUp) => {
        if (isUpdating) return;
        setIsUpdating(true);
        try {
          const input = { reportId, reportBlockId: block.id, id: resultId };
          await mutationFnReport({
            variables: {
              input: {
                clientId,
                projectId,
                op: moveUp ? "RESULT_MOVE_UP" : "RESULT_MOVE_DOWN",
                data: JSON.stringify(input),
              },
            },
          });
          await refetchReport();
          setIsUpdating(false);
        } catch (e) {
          setIsUpdating(false);
          appContext.handleError(e);
        }
      };

      // inject block actions controls
      const blockActionsNode = ref.current.querySelector(".report-builder--actions-block");
      if (blockActionsNode)
        ReactDOM.render(
          <BlockActions block={block} isDisabled={isDisabled} onOpen={onOpen} onDelete={handleBlockDelete} />,
          blockActionsNode
        );

      // inject block ordering controls
      const blockOrderingNode = ref.current.querySelector(".report-builder--ordering-block");
      if (blockOrderingNode)
        ReactDOM.render(
          <BlockOrdering
            block={block}
            allBlocks={allBlocks}
            isDisabled={isDisabled}
            onOrder={handleBlockOrder}
            onReorderResults={handleBlockReorderResults}
          />,
          blockOrderingNode
        );

      // inject charts
      for (const node of ref.current.querySelectorAll(".report-builder--chart")) {
        if (!node) return;
        const chartId = node.id.substr(6);
        const chart = projectData.charts.find((x) => x.id === chartId);
        if (!chart || (chart && chart.isDeleted)) {
          ReactDOM.render(
            <div className="report-builder--chart-empty">
              The chart does not exist or has been deleted.
              <br />
              Edit the block to select a different chart.
            </div>,
            node
          );
          continue;
        }
        const builderProps = getChartBuilderData({
          clientId,
          projectId,
          maxDate: appContext.maxDate,
          projectData,
          chartObj: chart,
        });
        const chartProps = {
          ...builderProps,
          // we don't have apollo/router wrappers, so these are passed down
          clientId,
          projectId,
          project,
          maxDate: appContext.maxDate,
          apollo,
          // customisation for report builder
          isReportBuilder: true,
          disableClick: true,
          whiteBg: true,
        };
        ReactDOM.render(<Chart source={chart.source} height={375} {...chartProps} reportBuilderRef={chartRef} />, node);
        chartRefs.current[chart.id] = chartRef;
      }

      if (block && block.results) {
        // GRID blocks are grouped (see getData.processResults) so flatten and remove nulls
        const allResults = pull(flatten(block.results), null);

        // inject result actions controls
        for (const node of ref.current.querySelectorAll(".report-builder--actions-result")) {
          if (!node) return;
          const resultId = node.id.substr(7);
          const result = allResults.find((x) => x.id === resultId);
          if (!result) return;
          ReactDOM.render(
            <ResultActions
              result={result}
              block={block}
              allBlocks={allBlocks}
              isDisabled={isDisabled}
              onOpen={onOpen}
              onMove={handleResultMove}
              onDelete={handleResultDelete}
            />,
            node
          );
        }

        // inject result ordering controls
        for (const node of ref.current.querySelectorAll(".report-builder--ordering-result")) {
          if (!node) return;
          const resultId = node.id.substr(7);
          const result = allResults.find((x) => x.id === resultId);
          if (!result) return;
          ReactDOM.render(
            <ResultOrdering
              result={result}
              allResults={allResults}
              block={block}
              isDisabled={isDisabled}
              onOrder={handleResultOrder}
            />,
            node
          );
        }
      }
    }
  }, [
    appContext,
    confirmModal,
    mutationFnReport,
    ref,
    type,
    block,
    allBlocks,
    isDisabled,
    onOpen,
    isUpdating,
    setIsUpdating,
    refetchReport,
    projectData,
    project,
    chartRefs,
    apollo,
  ]);

  // get template name from block type
  const blockName = `block${titleCase(type.toLowerCase())}`;
  // get pre-compiled template
  const template = handlebarsTemplates[blockName];
  // render
  let rendered = template(data);
  // replace mj-table with table
  // cannot use mj-table directly as inner html (table cells) gets stripped
  // note this means that css-class in templates won't work in builder
  rendered = rendered.replace(/<mj-table/g, `<table class="mj-table"`);
  rendered = rendered.replace(/<\/mj-table>/g, "</table>");
  // replace mj-image with img
  rendered = rendered.replace(/<mj-image/g, "<img");
  // replace mj-button with a (so clickable)
  rendered = rendered.replace(/<mj-button/g, "<a");
  rendered = rendered.replace(/<\/mj-button>/g, "</a>");
  // replace css-class with class
  rendered = rendered.replace(/css-class/g, "class");
  // wrap header in div (cannot be done conditionally in template due to invalid HTML nesting and needing mj-raw)
  rendered = rendered.replace("<!-- [__OUTCIDER_START_BLOCK_HEADER__] -->", `<div class="report-builder--hover">`);
  rendered = rendered.replace("<!-- [__OUTCIDER_END_BLOCK_HEADER__] -->", "</div>");

  return (
    <div
      className={classNames({
        "report-builder--block": true,
        [`report-builder--block-type-${type.toLowerCase()}`]: true,
      })}
    >
      {/* {block && (
        <div style={{ border: "1px solid red" }}>
          ID: {block.id}, ORDER: {block.order}
        </div>
      )} */}
      <div ref={(el) => (ref.current = el)} dangerouslySetInnerHTML={{ __html: rendered }} />
    </div>
  );
};

const BlockCreate = ({ index, isDisabled, onOpen }) => {
  return (
    <div className="report-builder--create-block">
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="secondary" size="sm" disabled={isDisabled}>
          <i className="uil-plus mr-1" />
          Create Block
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Results</DropdownItem>
          {resultBlockOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => onOpen({ op: "BLOCK_CREATE", data: { type: option.value, index } })}
            >
              {option.label}
            </DropdownItem>
          ))}
          <DropdownItem header>Non-Results</DropdownItem>
          {nonResultBlockOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => onOpen({ op: "BLOCK_CREATE", data: { type: option.value, index } })}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

const ReportBuilder = ({ report, toggleModal, user, client, project, projectData }) => {
  useEffect(bodyFixedEffect);

  const { clientId, projectId } = useParams();
  const [sendModal, toggleSendModal] = useState(null);
  const alertModal = createConfirmation(AlertModal);

  // set when panel is open
  const [panel, setPanel] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // set when report is re-rendering
  const [isUpdating, setIsUpdating] = useState(false);

  // set when one of the buttons has been clicked
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isViewPreview, setIsViewPreview] = useState(false);
  const [isSendPreview, setIsSendPreview] = useState(false);
  const [isSendReport, setIsSendReport] = useState(false);

  // for getting at charts to download them
  const chartRefs = useRef({});

  // get full report with all blocks and all results
  const {
    loading,
    data,
    refetch: refetchReport,
  } = useQuery(gql(getReportFull), {
    variables: { id: report.id },
  });

  // refetch reports on unmounting
  // (report may have been sent but there is a delay due to it being an async job)
  // this just increases the changes of us catching and displaying the sent date
  // TODO UPGRADE disabled as continuously refreshes, do this better e.g. optimistic update cache with sent date
  // useEffect(() => () => {
  //   projectData.refetchReports();
  // });

  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  if (isMediaBreakpointDownLg) return <MobileNoAccess title="Reports" />;

  if (loading) {
    return <Spinner className="loading-full" />;
  }

  const reportFull = data.getReport;
  const reportData = getReportData({ report: reportFull, op: "builder" });

  // data used in header/footer
  const headerData = pick(reportData, [
    "appUrl",
    "clientName",
    "clientColour",
    "clientLogo",
    "reportTitle",
    "reportDate",
    "reportShowHeader",
    "switches",
  ]);
  const footerData = pick(reportData, ["appUrl", "clientColour", "reportFooter", "switches"]);

  // data used in blocks
  const blockData = pick(reportData, ["appUrl", "clientColour", "contentsBlocks", "switches"]);

  // editable blocks
  const allBlocks = reportData.blocks.filter((block) => !nonEditableBlocks.includes(block.type));

  // open side panel
  const handleOpen = (panel) => {
    setIsDisabled(true);
    setPanel(panel);
  };

  // close side panel
  const handleClose = async (refetch) => {
    // refetch report, and wait for this before enabling
    if (refetch) {
      await refetchReport();
    }
    setIsDisabled(false);
    setIsUpdating(false);
    setPanel(null);
  };

  // save charts as images using Highcharts export server
  const prepareCharts = async (reportSendId, scale) => {
    const charts = chartRefs.current;

    // there are no charts, nothing to do, and check all refs are working
    if (!Object.keys(charts).length) return true;
    for (const ref of Object.values(charts)) {
      if (!ref.current) {
        return false;
      }
    }

    // generate images
    try {
      await pool({
        collection: Object.keys(charts),
        maxConcurrency: 4, // export server has 8 workers, assume 2 concurrent exports
        task: async (chartId) => {
          try {
            const chartRef = charts[chartId];
            const options = JSON.parse(chartRef.current.innerText);
            options.exporting.sourceWidth = 600;
            options.exporting.sourceHeight = 375;
            // get image
            const response = await axios.post(
              "https://charts.outcider.net",
              {
                type: "image/png",
                options: JSON.stringify(options),
                scale,
              },
              {
                responseType: "blob",
              }
            );
            const image = response.data;
            // upload to S3
            const filename = `reports/charts/${reportSendId}/${chartId}.png`;
            await Storage.put(filename, image, { level: "public", contentType: "image/png" });
          } catch (e) {
            Sentry.captureException(e);
            throw e;
          }
        },
      });
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
    return true;
  };

  const handleButton = async (setButtonState, prepareChartsScale, onSuccess) => {
    if (isSubmitting) return;
    const reportSendId = uuid();
    setIsSubmitting(true);
    setButtonState(true);
    const response = await prepareCharts(reportSendId, prepareChartsScale);
    if (!response) {
      await alertModal({
        message: "Report generation is busy at the moment, please try again in a minute.",
      });
      setIsSubmitting(false);
      setButtonState(false);
    } else {
      await onSuccess(reportSendId);
      setIsSubmitting(false);
      setButtonState(false);
    }
  };

  let panelType, panelOp, panelData, panelKey;
  if (panel) {
    panelType = panel.op.split("_")[0]; // e.g. BLOCK
    panelOp = panel.op.split("_")[1]; // e.g. UPDATE
    panelData = panel.data; // e.g. ReportBlock object
    panelKey = `${panelOp}/${panelOp === "CREATE" ? `${panelData.type}/${panelData.index}` : panelData.id}`;
  }
  const panelProps = {
    key: panelKey,
    op: panelOp,
    data: panelData,
    setIsUpdating,
    onClose: handleClose,
    projectData,
  };

  return (
    <>
      <ReportTitle report={report} toggleModal={toggleModal}>
        <Button
          color="secondary"
          onClick={() =>
            handleButton(setIsDownload, 4, async (reportSendId) => {
              const filename = `${report.title} - ${getExportDate()}.pdf`;
              const blob = await pdf(<ReportPDF data={reportData} reportSendId={reportSendId} />).toBlob();
              saveAs(blob, filename);
            })
          }
          disabled={isSubmitting}
        >
          {isDownload ? (
            <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
          ) : (
            <i className="uil-download-alt mr-1" />
          )}
          Download PDF
        </Button>
        <Button
          color="secondary"
          className="ml-2"
          onClick={() =>
            handleButton(setIsViewPreview, 2, (reportSendId) =>
              window.open(
                `${getPublicApiUrlPrefix(client.subdomain)}/report/view/${clientId}/${projectId}/${
                  report.id
                }/${reportSendId}`
              )
            )
          }
          disabled={isSubmitting}
        >
          {isViewPreview ? (
            <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
          ) : (
            <i className="uil-eye mr-1" />
          )}
          View Preview
        </Button>
        <Button
          color="secondary"
          className="ml-2"
          onClick={() =>
            handleButton(setIsSendPreview, 2, (reportSendId) =>
              toggleSendModal({ id: report.id, reportSendId, op: "PREVIEW" })
            )
          }
          disabled={isSubmitting}
        >
          {isSendPreview ? (
            <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
          ) : (
            <i className="uil-envelope-search mr-1" />
          )}
          Send Preview
        </Button>
        <Button
          color="primary"
          className="ml-2"
          onClick={() =>
            handleButton(setIsSendReport, 2, (reportSendId) =>
              toggleSendModal({ id: report.id, reportSendId, op: "SEND" })
            )
          }
          disabled={isSubmitting}
        >
          {isSendReport ? (
            <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />
          ) : (
            <i className="uil-envelopes mr-1" />
          )}
          Send Report
        </Button>
      </ReportTitle>
      <Row>
        <Col md={panel ? 8 : 12}>
          <div
            className={classNames({
              "report-builder": true,
              "report-builder--editable": !isDisabled,
              "report-builder--disabled": isDisabled,
              "report-builder--updating": isUpdating,
              "vh-with-title": true,
              scroll: true,
            })}
          >
            <div className="report-body">
              <Block type="HEADER" data={headerData} />
              {reportData.blocks.map((block, index) => (
                <React.Fragment key={index}>
                  <BlockCreate index={index} isDisabled={isDisabled} onOpen={handleOpen} />
                  <Block
                    type={block.type}
                    block={block}
                    data={{ ...blockData, ...block }}
                    allBlocks={allBlocks}
                    isDisabled={isDisabled}
                    onOpen={handleOpen}
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                    refetchReport={refetchReport}
                    project={project}
                    projectData={projectData}
                    chartRefs={chartRefs}
                  />
                </React.Fragment>
              ))}
              <Block type="FOOTER" data={footerData} />
            </div>
          </div>
        </Col>
        {panel && (
          <Col md={4}>
            <>
              {panelType === "BLOCK" && <BlockPanel {...panelProps} />}
              {panelType === "RESULT" && <ResultPanel {...panelProps} />}
              {panelType === "TABLEROW" && <TableRowPanel {...panelProps} />}
            </>
          </Col>
        )}
      </Row>

      {sendModal && <SendModal data={sendModal} toggle={toggleSendModal} user={user} projectData={projectData} />}
    </>
  );
};

export default ReportBuilder;

import { Button, Form, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { fnUpdateEntity, updateOrganisation, updatePerson } from "../../graphql/mutations";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { inputProcessTermsIncludeQuery, populateForm, processForm } from "../lib/forms";
import {
  queryOrganisations,
  queryPeople,
  searchResultsAllOrganisations,
  searchResultsSuggestedOrganisations,
} from "../../graphql/queries";
import { queryOrganisationsVariables, queryPeopleVariables } from "../../lib/variables";
import { sort, sortArray } from "../../lib/utils";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { AppContext } from "../../App";
import FormCreatable from "../Forms/FormCreatable";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import ModalLoading from "./ModalLoading";
import Spinner from "../../components/Spinner";
import debounce from "debounce-promise";
import find from "lodash/find";
import pick from "lodash/pick";
import { suggestOrganisationsFromPersonFragments } from "../lib/entities";
import { titleCase } from "title-case";
import { updateCacheCreate } from "../lib/cache";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useForm } from "react-hook-form";

const NameModal = ({ data, toggle }) => {
  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(data, {
      fields: ["name"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => toggle(values.name.trim());

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Edit Name</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput
            name="name"
            label="Name"
            required
            helpText={`Edit the ${data.type.toLowerCase()} name above. On saving this change, the current name of "${
              data.name
            }" will be added as an alternative name. We recommend you do not delete this alternative name to maximise search coverage for this ${data.type.toLowerCase()}.`}
            formProps={formProps}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const AllModal = ({ toggle }) => {
  const { clientId, projectId } = useParams();
  const apollo = useApolloClient();

  // get top unconfirmed organisations on project
  const { loading, data } = useQuery(gql(searchResultsAllOrganisations), {
    variables: {
      filter: {
        clientId,
        projectId,
      },
    },
  });

  // cannot edit once set so no default values needed (need to reselect all)
  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: { organisations: null },
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => toggle(values.organisations.map((x) => x.value));

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>All Organisations</ModalHeader>
      {loading ? (
        <ModalLoading />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormSelect
              name="organisations"
              label="Organisations"
              placeholder="Select top organisation or search all..."
              isMulti
              required
              formProps={formProps}
              // async behaviour
              isAsync
              // show top options before searching
              // (cannot edit once set, so no default values needed)
              defaultOptions={data.searchResultsAllOrganisations.organisations.map((x) => ({
                value: x.key,
                label: `${x.key} (${x.doc_count})`,
              }))}
              // search rest on demand
              // use debounce-promise rather than lodash/debounce
              // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
              loadOptions={debounce(async (value, callback) => {
                const results = await apollo.query({
                  query: gql(searchResultsAllOrganisations),
                  variables: {
                    filter: {
                      clientId,
                      projectId,
                      query: inputProcessTermsIncludeQuery(value),
                    },
                  },
                });
                callback(
                  sort(results.data.searchResultsAllOrganisations.organisations, "key").map((x) => ({
                    value: x.key,
                    label: `${x.key} (${x.doc_count})`,
                  }))
                );
              }, 250)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Save
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const EntityModal = ({ data: { name, entity, type }, toggle, isInStakeholdersSection = false, projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [mutationUpdatePerson] = useMutation(gql(updatePerson));
  const [mutationUpdateOrganisation] = useMutation(gql(updateOrganisation));
  const [mutationFnUpdateEntity] = useMutation(gql(fnUpdateEntity));
  const [isDeleting, setIsDeleting] = useState(false);
  const [nameModal, toggleNameModal] = useState(null);
  const [allModal, toggleAllModal] = useState(false);
  const [organisationsAreSuggestions, setOrganisationsAreSuggestions] = useState(false);

  const op = !entity ? "CREATE" : "UPDATE";

  // default values come from existing entity, or just name for unconfirmed
  const defaultValue = entity ? entity : { name };

  // only populate fields that don't require organisation/tag options to have been calculated first
  const populateValues = populateForm(defaultValue, {
    fields: ["name"],
    creatables: ["alternativeNames"],
  });

  const { register, handleSubmit, errors, formState, control, getValues, setValue, watch } = useForm({
    defaultValues: populateValues,
    // https://github.com/react-hook-form/react-hook-form/issues/2578
    shouldUnregister: false,
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      const submit = { clientId, projectId, type, ...values };
      if (op === "UPDATE") submit["id"] = entity.id;
      if (organisationOverride) submit["organisations"] = organisationOverride.map((x) => ({ value: x, label: x }));
      const input = await processForm(
        { ...submit },
        {
          selectMultis: ["organisations"],
          creatables: ["alternativeNames", "tags"],
        }
      );

      if (op === "CREATE") {
        const updateCacheCreateQuery = type === "PERSON" ? queryPeople : queryOrganisations;
        const updateCacheCreateQueryVariables =
          type === "PERSON"
            ? queryPeopleVariables({ clientId, projectId })
            : queryOrganisationsVariables({ clientId, projectId });
        await mutationFnUpdateEntity({
          variables: { input },
          // add new person/organisation
          update: updateCacheCreate(gql(updateCacheCreateQuery), updateCacheCreateQueryVariables),
          // refetch other queries that may have items added to them
          refetchQueries: type === "PERSON" ? ["QueryOrganisations", "QueryTags"] : ["QueryTags"],
          awaitRefetchQueries: true,
        });
      } else {
        await mutationFnUpdateEntity({
          variables: { input },
          // refetch other queries that may have items added to them
          refetchQueries: type === "PERSON" ? ["QueryOrganisations", "QueryTags"] : ["QueryTags"],
          awaitRefetchQueries: true,
        });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const handleDelete = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    try {
      // update url (ONLY IF IN STAKEHOLDERS SETION), then mutate, to avoid hitting redirectAlert in StakeholdersPanel
      if (isInStakeholdersSection) {
        // bit hacky
        const urlWithPanelClosed = url.split("/").slice(0, -1).join("/");
        history.push(urlWithPanelClosed);
      }
      toggle();
      const mutation = type === "PERSON" ? mutationUpdatePerson : mutationUpdateOrganisation;
      const input = pick(entity, ["clientId", "projectId", "id", "uniqueName"]);
      await mutation({ variables: { input: { ...input, isDeleted: true } } });
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  // watch for changes on name/alternativeNames to refetch/recalculate organisation suggestions
  let allNames = [];
  let allNamesSearchQuery = "";
  if (type === "PERSON") {
    const watchNames = watch(["name", "alternativeNames"]);
    if (watchNames) {
      allNames = [watchNames.name, ...(watchNames.alternativeNames || []).map((x) => x.value)];
      allNamesSearchQuery = allNames.map((x) => `\\"${x}\\"`).join(" ");
    }
  }

  // watch for changes on tags to change title/button
  const watchTags = watch("tags");

  // get suggested organisations/fragments for person
  const { loading, data } = useQuery(gql(searchResultsSuggestedOrganisations), {
    variables: {
      filter: {
        clientId,
        projectId,
        query: allNamesSearchQuery,
      },
    },
    skip: type !== "PERSON" && allNamesSearchQuery,
  });

  // when primary name is changed from the initial value, add existing to alternatives if it isn't there already
  const [primaryName, setPrimaryName] = useState(defaultValue.name);
  useEffect(() => {
    const existingPrimaryName = getValues("name");
    if (existingPrimaryName && existingPrimaryName !== primaryName) {
      // change primary name to new value
      setValue("name", primaryName);
      // get existing values in alternativeNames
      const existingAlternativeNames = getValues("alternativeNames") || [];
      if (!existingAlternativeNames.map((x) => x.value.toLowerCase()).includes(existingPrimaryName.toLowerCase())) {
        setValue("alternativeNames", [
          ...existingAlternativeNames,
          { value: existingPrimaryName, label: existingPrimaryName },
        ]);
      }
    }
  }, [getValues, setValue, primaryName]);

  // when allNames populated, and loading finished, build suggested/all options
  const [organisationOverride, setOrganisationOverride] = useState(null);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [organisationPopulated, setOrganisationPopulated] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  useDeepCompareEffect(() => {
    // wait until allNames is populated and loading finished
    if (loading) return;

    if (type === "PERSON") {
      let organisationOpts = [];
      let currentOptions = [];

      // if updating, add current options - they may no longer be suggestions (so wouldn't be selectable), or may have been unfollowed and therefore deleted
      // at this point they are confirmed stakeholders so we have entity IDs
      if (op === "UPDATE" && entity.organisations) {
        for (const orgId of entity.organisations) {
          const orgInProject = find(projectData.organisations, (x) => x.id === orgId);
          if (orgInProject) currentOptions.push({ value: orgInProject.id, label: orgInProject.name });
        }
        if (currentOptions.length) {
          organisationOpts.push({
            label: "Current organisations",
            options: currentOptions,
          });
        }
      }

      // calculate suggested organisations
      const suggestedOrganisations = suggestOrganisationsFromPersonFragments(
        allNames,
        data.searchResultsSuggestedOrganisations.fragments,
        data.searchResultsSuggestedOrganisations.organisations
      );

      // build suggested options
      const suggestedOptions = suggestedOrganisations.map((x) => ({
        value: x.organisation,
        label: x.organisation,
      }));
      if (suggestedOptions.length) {
        organisationOpts.push({
          label: "Suggested organisations",
          options: suggestedOptions,
        });
      }

      // if creating, and we have suggested options, default to the first
      if (op === "CREATE" && suggestedOptions.length) {
        setValue("organisations", [suggestedOptions[0]]);
        setOrganisationsAreSuggestions(true);
      }

      // add recent options
      organisationOpts.push({
        label: "Recently mentioned in connection with",
        options: sort(
          data.searchResultsSuggestedOrganisations.organisations.map((x) => ({ value: x, label: x })),
          "label"
        ),
      });

      // update options state, populate field if updating (but not if names/alternativeNames have been changed and we want to show new suggestions)
      // (the current values have already been added to the valid options above)
      setOrganisationOptions(organisationOpts);
      if (op === "UPDATE" && !organisationPopulated) {
        setValue("organisations", currentOptions);
        setOrganisationPopulated(true);
      }
    }

    // update options state, populate field if updating
    // (same as selectMulti populate behaviour - if value is no longer a valid option, don't keep existing value)
    const tagOpts = sort(
      projectData.tags.filter((x) => x.type === "ENTITY").map((x) => ({ value: x.id, label: x.name })),
      "label"
    );
    setTagOptions(tagOpts);
    if (op === "UPDATE" && entity.tags)
      setValue(
        "tags",
        tagOpts.filter((x) => entity.tags.includes(x.value))
      );
  }, [type, allNames, loading, data]);

  // const tagOptions = sort(
  //   projectTags.filter((x) => x.type === "ENTITY").map((x) => ({ value: x.id, label: x.name })),
  //   "label"
  // );

  const title = !entity
    ? watchTags
      ? `Follow & Create ${titleCase(type.toLowerCase())} Stakeholder`
      : `Follow ${titleCase(type.toLowerCase())}`
    : `Edit ${titleCase(type.toLowerCase())}`;
  const action = !entity ? (watchTags ? "Follow & Create Stakeholder" : "Follow") : "Save";

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={true}>
        <ModalHeader toggle={() => toggle()}>{title}</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormInput
              name="name"
              label="Name"
              readOnly
              addon={
                <InputGroupAddon addonType="append">
                  <Button onClick={() => toggleNameModal({ name: primaryName, type })}>Edit</Button>
                </InputGroupAddon>
              }
              required
              helpText={`Enter how to identify this ${type.toLowerCase()} in the Stakeholder directory.`}
              formProps={formProps}
            />
            <FormCreatable
              name="alternativeNames"
              label="Alternative names"
              options={[]}
              noOptionsMessage={() => null}
              classNamePrefix="react-select--no-options"
              type="alternative name"
              helpText={`Enter any alternative names or common abbreviations that this ${type.toLowerCase()} is known by.`}
              formProps={formProps}
            />
            {type === "PERSON" && (
              <>
                {organisationOverride ? (
                  <div className="form-group">
                    <label>Organisations:</label>
                    {/* TODO style these like the select/creatable items, but also the select/creatable items are styled slightly differently which needs fixing */}
                    <p className="mb-0">{sortArray(organisationOverride).join(", ")}</p>
                    <p>
                      <span className="invalid-feedback text-muted d-block">
                        You have manually associated this person with these organisations.{" "}
                        <span className="click textlink" onClick={() => setOrganisationOverride(null)}>
                          Remove manual override?
                        </span>
                      </span>
                    </p>
                  </div>
                ) : (
                  <FormSelect
                    name="organisations"
                    label={organisationsAreSuggestions ? "Suggested organisation" : "Organisations"}
                    isLoading={loading}
                    options={organisationOptions}
                    isMulti
                    isClearable={true}
                    helpText={
                      organisationsAreSuggestions ? (
                        <>
                          We have identified an organisation that this person is likely to be associated with. You can
                          change this organisation or select any other organisation(s) this person is associated with.{" "}
                          <span className="click textlink" onClick={() => toggleAllModal(true)}>
                            Organisation not listed?
                          </span>
                        </>
                      ) : (
                        <>
                          Select the organisation(s) this person is associated with.{" "}
                          <span className="click textlink" onClick={() => toggleAllModal(true)}>
                            Organisation not listed?
                          </span>
                        </>
                      )
                    }
                    formProps={formProps}
                  />
                )}
              </>
            )}
            <hr />
            <FormCreatable
              name="tags"
              label="Stakeholder groups"
              options={tagOptions}
              type="stakeholder group"
              helpText={`Create/select stakeholder group(s) to confirm this ${type.toLowerCase()} as a stakeholder.`}
              formProps={formProps}
            />
          </ModalBody>
          <ModalFooter>
            {op === "UPDATE" && (
              <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
                {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
                Unfollow
              </Button>
            )}
            <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              {action}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {nameModal && (
        <NameModal
          data={nameModal}
          toggle={(name) => {
            if (name) setPrimaryName(name);
            toggleNameModal();
          }}
        />
      )}
      {allModal && (
        <AllModal
          toggle={(organisations) => {
            if (organisations) setOrganisationOverride(organisations);
            toggleAllModal();
          }}
        />
      )}
    </>
  );
};

export default EntityModal;

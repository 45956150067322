import { Card } from "reactstrap";
import PageTitle from "../../components/PageTitle";
import React from "react";

const MobileNoAccess = ({ title }) => (
  <>
    <PageTitle title={title} />
    <Card body className="mb-0 card-fullscreen vh-with-title">
      <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
        <p className="mb-0 text-center">
          <i className="uil-exclamation-triangle font-24 d-block" />
          This page cannot be accessed from a mobile device.
        </p>
      </div>
    </Card>
  </>
);

export default MobileNoAccess;

import { getProjectHelpers, sort } from "./utils";
import {
  queryAgents,
  queryFilters,
  queryOrganisations,
  queryPeople,
  queryProjects,
  queryTags,
} from "../graphql/queries";
import {
  queryAgentsVariables,
  queryFiltersVariables,
  queryOrganisationsVariables,
  queryPeopleVariables,
  queryTagsVariables,
} from "./variables";

import { devProjectOverrides } from "./devProjectOverrides";

// get projectData (used in functions)
export const getProjectData = async ({ gateway, clientId, projectId }) => {
  // project
  const projectPromise = gateway.runQuery({
    operationName: "QueryProjects",
    query: queryProjects,
    variables: {
      clientId,
      id: { eq: projectId },
    },
  });

  // agents
  const agentsPromise = gateway.runAllQuery({
    operationName: "QueryAgents",
    query: queryAgents,
    variables: queryAgentsVariables({ clientId, projectId }),
  });

  // filters
  const filtersPromise = gateway.runAllQuery({
    operationName: "QueryFilters",
    query: queryFilters,
    variables: queryFiltersVariables({ clientId, projectId }),
  });

  // people
  const peoplePromise = gateway.runAllQuery({
    operationName: "QueryPeople",
    query: queryPeople,
    variables: queryPeopleVariables({ clientId, projectId }),
  });

  // organisations
  const organisationsPromise = gateway.runAllQuery({
    operationName: "QueryOrganisations",
    query: queryOrganisations,
    variables: queryOrganisationsVariables({ clientId, projectId }),
  });

  // tags
  const tagsPromise = gateway.runAllQuery({
    operationName: "QueryTags",
    query: queryTags,
    variables: queryTagsVariables({ clientId, projectId }),
  });

  // get all
  const result = await Promise.all([
    projectPromise,
    agentsPromise,
    filtersPromise,
    peoplePromise,
    organisationsPromise,
    tagsPromise,
  ]);

  // get data
  const dataProject = result[0];
  const dataAgents = result[1];
  const dataFilters = result[2];
  const dataPeople = result[3];
  const dataOrganisations = result[4];
  const dataTags = result[5];

  // get project
  const projectRes = dataProject.queryProjects.items.length ? dataProject.queryProjects.items[0] : null;
  if (!projectRes) throw new Error("Project does not exist.");

  // apply dev overrides
  const project = { ...projectRes, ...devProjectOverrides() };

  // filter
  const agents = sort(
    dataAgents.queryAgents.items.filter(projectDataFilterFunctions["agents"]),
    projectDataSortKey["agents"]
  );
  const filters = sort(
    dataFilters.queryFilters.items.filter(projectDataFilterFunctions["filters"]),
    projectDataSortKey["filters"]
  );
  const people = sort(
    dataPeople.queryPeople.items.filter(projectDataFilterFunctions["people"]),
    projectDataSortKey["people"]
  );
  const organisations = sort(
    dataOrganisations.queryOrganisations.items.filter(projectDataFilterFunctions["organisations"]),
    projectDataSortKey["organisations"]
  );
  const tags = sort(dataTags.queryTags.items.filter(projectDataFilterFunctions["tags"]), projectDataSortKey["tags"]);

  return {
    agents,
    filters,
    people,
    organisations,
    tags,
    ...getProjectHelpers(project),
  };
};

// functions to apply to each type of projectData
export const projectDataFilterFunctions = {
  agents: (x) => !x.isDeleted,
  filters: (x) => !x.isDeleted,
  people: (x) => !x.isDeleted,
  organisations: (x) => !x.isDeleted,
  tags: (x) => !x.isDeleted,
  charts: (x) => !x.isDeleted,
  // charts: (x) => x,
  mailingLists: (x) => !x.isDeleted,
  mailingListMembers: (x) => !x.isDeleted,
  reports: (x) => !x.isDeleted,
  // socialAuthorGroups: (x) => x,
  users: (x) => !x.isDeleted,
};

// sorting to apply to each type of projectData
export const projectDataSortKey = {
  agents: "name",
  filters: "name",
  people: "name",
  organisations: "name",
  tags: "name",
  charts: "name",
  mailingLists: "name",
  mailingListMembers: "email",
  reports: "name",
  // socialAuthorGroups: "name",
  users: "name",
};

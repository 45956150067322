module.exports=`{{#if (or switches.showUnsubscribe reportFooter)}}
  <mj-wrapper mj-class="bg-client">
    <mj-section mj-class="p-0">
      <mj-column>
        <mj-text mj-class="footer">
          {{#if switches.showUnsubscribe}}
            <a href="[__OUTCIDER_UNSUBSCRIBE__]" class="report-footer-link">Unsubscribe</a>
          {{/if}}
          {{#if (and switches.showUnsubscribe reportFooter)}}<br /><br />{{/if}}
          {{#if reportFooter}}
            {{reportFooter}}
          {{/if}}
        </mj-text>
      </mj-column>
    </mj-section>
  </mj-wrapper>
{{/if}}

{{#if switches.showTrack}}
  <mj-raw><img src="[__OUTCIDER_TRACK__]" alt="" height="1px" width="1px" /></mj-raw>
{{/if}}`
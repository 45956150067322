import { Button, ButtonGroup, Col, Row } from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { getLinkedItemNames, sortArray } from "../../lib/utils";
import { headerFormatter, selectFilter, textFilter } from "../lib/tables";

import { AppContext } from "../../App";
import BootstrapTable from "react-bootstrap-table-next";
import ResultsCounter from "../ResultsCounter";
import ResultsError from "../Results/ResultsError";
import Spinner from "../../components/Spinner";
import StakeholderPanel from "./StakeholderPanel";
import StakeholdersMenu from "./StakeholdersMenu";
import StakeholdersPanel from "./StakeholdersPanel";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import classNames from "classnames";
import filterFactory from "react-bootstrap-table2-filter";
import find from "lodash/find";
import { getStakeholdersInResults } from "../lib/entities";
import uniq from "lodash/uniq";
import { useApolloClient } from "@apollo/client";
import { useMediaBreakpointDownLg } from "../lib/effects";

const StakeholdersListing = ({ filter, menuOpen, setMenuOpen, user, project, projectData }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();
  const history = useHistory();
  const { clientId, projectId, entityType, stakeholderType } = useParams();
  const { path, url } = useRouteMatch();
  const totalsRef = useRef({});

  const [loading, setLoading] = useState(true);
  const [stakeholders, setStakeholders] = useState(null);
  const [refetch, setRefetch] = useState(null);
  const [error, setError] = useState(false);

  // mobile
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  const [mobileViewClosedPanel, setMobileViewClosedPanel] = useState(false);

  // for nested routes
  const stakeholderId = history.location.pathname.split("/")[7];

  // get stakeholders in results
  useEffect(() => {
    async function getStakeholders() {
      try {
        const stakeholders = await getStakeholdersInResults({
          entityType,
          stakeholderType,
          filter,
          clientId,
          projectId,
          projectData,
          maxDate: appContext.maxDate,
          apollo,
        });
        setStakeholders(stakeholders);
      } catch (e) {
        setStakeholders([]); // render empty table
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getStakeholders();
  }, [
    entityType,
    stakeholderType,
    filter,
    clientId,
    projectId,
    projectData,
    appContext,
    apollo,
    refetch, // refresh when refetch value is changed
  ]);

  // get stakeholder group options
  const entityGroups = projectData.tags.filter((x) => x.type === "ENTITY");

  if (loading) return <Spinner className="loading-full" />;

  // build records, add to list of organisations/groups (found in the current entityType) for filtering
  const records = [];
  const organisations = [];
  const groups = [];
  stakeholders.forEach((stakeholder) => {
    let stakeholderOrganisations;
    if (entityType === "people") {
      stakeholderOrganisations = getLinkedItemNames(projectData.organisations, stakeholder.organisations, []);
      organisations.push(...stakeholderOrganisations);
    }
    const stakeholderGroups = getLinkedItemNames(entityGroups, stakeholder.tags, []);
    groups.push(...stakeholderGroups);
    const record = {
      id: stakeholder.id,
      name: stakeholder.name,
      groups: stakeholderGroups.length ? stakeholderGroups : [],
      results: (
        <ResultsCounter
          // ref is forwarded to the raw total
          ref={(el) => (totalsRef.current[stakeholder.id] = el)}
          icon="uil-list-ul"
          source="METABASE"
          filter={filter}
          query={{ [entityType === "people" ? "person" : "organisation"]: [stakeholder.id] }}
          projectData={projectData}
        />
      ),
    };
    if (entityType === "people") record.organisations = stakeholderOrganisations.length ? stakeholderOrganisations : [];
    records.push(record);
  });

  // remove duplicates and sort
  let organisationsOptions;
  if (entityType === "people")
    organisationsOptions = sortArray(uniq(organisations)).map((x) => ({ value: x, label: x }));
  const groupsOptions = sortArray(uniq(groups)).map((x) => ({ value: x, label: x }));

  // apply filter from location state
  let groupsDefaultValue = null;
  if (history.location.state && history.location.state.group) {
    const groupName = history.location.state.group;
    const validGroupNames = groupsOptions.map((x) => x.value);
    if (!validGroupNames.includes(groupName)) {
      return (
        <Redirect
          to={{
            pathname: `/${clientId}/${projectId}/stakeholders/${entityType}/all/all`,
            state: {
              redirectAlert: `There are no ${entityType} in the stakeholder group ${groupName}, therefore the column filter has been removed.`,
            },
          }}
        />
      );
    } else {
      groupsDefaultValue = groupName;
    }
  }

  // build columns
  const columns = [];
  columns.push({
    dataField: "name",
    text: "Name",
    sort: true,
    filter: textFilter,
    formatter: (cell, row) => (
      <mark
        className={classNames({
          entity: true,
          stakeholder: true,
          person: entityType === "people",
          organisation: entityType === "organisations",
          "font-bold": true,
        })}
      >
        {cell}
        {row.groups.length > 0 && <i className="uil-check-circle" />}
      </mark>
    ),
    headerFormatter: headerFormatter,
  });
  if (entityType === "people") {
    columns.push({
      dataField: "organisations",
      text: "Organisations",
      sort: false,
      filter: selectFilter({ options: organisationsOptions }),
      formatter: (cell) => {
        if (!cell.length) return "-";
        return (
          <>
            {cell.map((org) => {
              const entity = find(projectData.organisations, (x) => x.name === org);
              return (
                <mark
                  key={org}
                  className={classNames({
                    entity: true,
                    organisation: true,
                    stakeholder: entity,
                  })}
                >
                  {org}
                  {entity.tags && <i className="uil-check-circle" />}
                </mark>
              );
            })}
          </>
        );
      },
      headerFormatter: headerFormatter,
    });
  }
  if (stakeholderType !== "followed") {
    columns.push({
      dataField: "groups",
      text: "Tags",
      sort: false,
      filter: selectFilter({
        options: groupsOptions,
        defaultValue: groupsDefaultValue,
      }),
      formatter: (cell) => (cell.length ? cell.join(", ") : "-"),
      headerFormatter: headerFormatter,
    });
  }
  columns.push({
    dataField: "results",
    text: "Results",
    sort: true,
    sortValue: (_, row) => {
      const totalRef = totalsRef.current[row.id];
      return totalRef ? parseInt(totalRef.innerHTML, 10) : 0;
    },
    headerFormatter: headerFormatter,
  });

  return (
    <>
      {isMediaBreakpointDownLg && (
        <ButtonGroup className="responsive-closed-panel-buttons">
          <Button
            outline={mobileViewClosedPanel}
            disabled={stakeholderId}
            onClick={() => setMobileViewClosedPanel(false)}
          >
            <i className="uil-table" />
          </Button>
          <Button
            outline={!mobileViewClosedPanel}
            disabled={stakeholderId}
            onClick={() => setMobileViewClosedPanel(true)}
          >
            <i className="uil-chart-line" />
          </Button>
        </ButtonGroup>
      )}
      <Row
        className={classNames({
          "responsive-panel--container": true,
          "mt-2": isMediaBreakpointDownLg,
        })}
      >
        {menuOpen && (
          <Col lg={2}>
            <StakeholdersMenu projectData={projectData} />
          </Col>
        )}

        <Col lg={menuOpen ? 6 : 8}>
          <ToolkitProvider
            // needed to clear sort state if entity/stakeholder type changes
            key={`${entityType}/${stakeholderType}`}
            bootstrap4
            keyField="id"
            data={records}
            columns={columns}
          >
            {(props) => (
              <BootstrapTable
                {...props.baseProps}
                hover
                rowClasses="click"
                defaultSorted={[
                  {
                    dataField: "name",
                    order: "asc",
                  },
                ]}
                // pagination disabled for now
                // pagination={paginationFactory({
                //   sizePerPage: 50,
                //   hideSizePerPage: true,
                //   showTotal: true,
                //   paginationTotalRenderer: (from, to, size) =>
                //     size > 0 ? (
                //       <span className="react-bootstrap-table-pagination-total">
                //         Showing {from} to {to} of {size} {entityType}
                //       </span>
                //     ) : null,
                // })}
                filter={filterFactory()}
                classes="bootstrap-table mb-0"
                wrapperClasses={classNames({
                  "vh-with-title": !isMediaBreakpointDownLg,
                  "vh-with-title-wrapped-buttons": isMediaBreakpointDownLg,
                  "table-responsive": true,
                  card: true,
                  "mb-0": true,
                })}
                headerWrapperClasses="card-header"
                selectRow={{
                  selected: stakeholderId ? [stakeholderId] : [],
                  mode: "radio",
                  hideSelectColumn: true,
                  clickToSelect: true,
                  classes: "bootstrap-table-row-active",
                  onSelect: (row, isSelected) => {
                    isSelected ? history.push(`${url}/${row.id}`) : history.push(`${url}`);
                    setMenuOpen(false);
                    return false;
                  },
                }}
                noDataIndication={
                  error ? (
                    <ResultsError message="stakeholders" refetch={() => setRefetch(Date.now())} />
                  ) : (
                    <div className="table-empty">
                      <p className="mb-0 text-center">
                        <i className="uil-users-alt font-24 d-block" />
                        {projectData.comprehendUsed
                          ? `There are no ${entityType} to display.`
                          : `Stakeholder analysis is not enabled for this project.`}
                      </p>
                    </div>
                  )
                }
              />
            )}
          </ToolkitProvider>
        </Col>

        <Col lg={4} className="responsive-panel--panel">
          <Switch>
            {((isMediaBreakpointDownLg && mobileViewClosedPanel) || !isMediaBreakpointDownLg) && (
              <Route exact path={path}>
                <StakeholdersPanel
                  entityType={entityType}
                  stakeholderType={stakeholderType}
                  filter={filter}
                  user={user}
                  project={project}
                  projectData={projectData}
                />
              </Route>
            )}
            <Route path={`${path}/:stakeholderId`}>
              <StakeholderPanel
                key={stakeholderId}
                stakeholderId={stakeholderId}
                filter={filter}
                onClose={() => history.push(`${url}`)}
                user={user}
                project={project}
                projectData={projectData}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
    </>
  );
};

export default StakeholdersListing;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fnDashboard = /* GraphQL */ `
  query FnDashboard($input: FnDashboardInput!) {
    fnDashboard(input: $input)
  }
`;
export const fnTranslateResult = /* GraphQL */ `
  query FnTranslateResult($input: IdsInput!) {
    fnTranslateResult(input: $input) {
      clientId
      projectId
      id
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnIntercomToggleTooltips = /* GraphQL */ `
  query FnIntercomToggleTooltips {
    fnIntercomToggleTooltips
  }
`;
export const searchResultsRaw = /* GraphQL */ `
  query SearchResultsRaw(
    $searchParams: AWSJSON!
    $excerptQuery: String
    $sort: [SearchResultsRawSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchResultsRaw(
      searchParams: $searchParams
      excerptQuery: $excerptQuery
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchResultsRawWithoutContent = /* GraphQL */ `
  query SearchResultsRawWithoutContent(
    $searchParams: AWSJSON!
    $excerptQuery: String
    $sort: [SearchResultsRawSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchResultsRawWithoutContent(
      searchParams: $searchParams
      excerptQuery: $excerptQuery
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchResultsAggregations = /* GraphQL */ `
  query SearchResultsAggregations($searchParams: AWSJSON!) {
    searchResultsAggregations(searchParams: $searchParams) {
      aggregations
      __typename
    }
  }
`;
export const searchResultsTotal = /* GraphQL */ `
  query SearchResultsTotal($searchParams: AWSJSON!) {
    searchResultsTotal(searchParams: $searchParams) {
      total
      __typename
    }
  }
`;
export const searchResultsAllOrganisations = /* GraphQL */ `
  query SearchResultsAllOrganisations(
    $filter: SearchResultsAllOrganisationsInput!
  ) {
    searchResultsAllOrganisations(filter: $filter) {
      organisations {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const searchResultsSuggestedOrganisations = /* GraphQL */ `
  query SearchResultsSuggestedOrganisations(
    $filter: SearchResultsSuggestedOrganisationsInput!
  ) {
    searchResultsSuggestedOrganisations(filter: $filter) {
      organisations
      fragments
      __typename
    }
  }
`;
export const searchResultsAllSourceNames = /* GraphQL */ `
  query SearchResultsAllSourceNames(
    $filter: SearchResultsAllSourceNamesInput!
  ) {
    searchResultsAllSourceNames(filter: $filter) {
      sourceName {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const searchResultsAllSourceUrls = /* GraphQL */ `
  query SearchResultsAllSourceUrls($filter: SearchResultsAllSourceUrlsInput!) {
    searchResultsAllSourceUrls(filter: $filter) {
      sourceUrl {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const searchResultsAllAuthorNames = /* GraphQL */ `
  query SearchResultsAllAuthorNames(
    $filter: SearchResultsAllAuthorNamesInput!
  ) {
    searchResultsAllAuthorNames(filter: $filter) {
      authorName {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const searchResultsAllAuthorEmails = /* GraphQL */ `
  query SearchResultsAllAuthorEmails(
    $filter: SearchResultsAllAuthorEmailsInput!
  ) {
    searchResultsAllAuthorEmails(filter: $filter) {
      authorEmail {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const searchResultsAllAuthorGroups = /* GraphQL */ `
  query SearchResultsAllAuthorGroups(
    $filter: SearchResultsAllAuthorGroupsInput!
  ) {
    searchResultsAllAuthorGroups(filter: $filter) {
      authorGroups {
        key
        doc_count
        __typename
      }
      __typename
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      subdomain
      name
      colour
      logo
      image
      reportLogo
      reportFooter
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryClients = /* GraphQL */ `
  query QueryClients(
    $subdomain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClients(
      subdomain: $subdomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      email
      clients
      role
      perms
      projects
      enabled
      name
      firstName
      lastName
      timezone
      language
      tempPassword
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        clients
        role
        perms
        projects
        enabled
        name
        firstName
        lastName
        timezone
        language
        tempPassword
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientDeleteQueue = /* GraphQL */ `
  query GetClientDeleteQueue($id: ID!) {
    getClientDeleteQueue(id: $id) {
      id
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientDeleteQueues = /* GraphQL */ `
  query ListClientDeleteQueues(
    $filter: ModelClientDeleteQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientDeleteQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryClientDeleteQueue = /* GraphQL */ `
  query QueryClientDeleteQueue(
    $queue: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientDeleteQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryClientDeleteQueue(
      queue: $queue
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryProjects = /* GraphQL */ `
  query QueryProjects(
    $clientId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProjects(
      clientId: $clientId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProjectDeleteQueue = /* GraphQL */ `
  query GetProjectDeleteQueue($id: ID!) {
    getProjectDeleteQueue(id: $id) {
      id
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjectDeleteQueues = /* GraphQL */ `
  query ListProjectDeleteQueues(
    $filter: ModelProjectDeleteQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectDeleteQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryProjectDeleteQueue = /* GraphQL */ `
  query QueryProjectDeleteQueue(
    $queue: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDeleteQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryProjectDeleteQueue(
      queue: $queue
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryAgents = /* GraphQL */ `
  query QueryAgents(
    $clientId: ID!
    $projectIdId: ModelAgentByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAgents(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgentBackfillQueue = /* GraphQL */ `
  query GetAgentBackfillQueue($id: ID!) {
    getAgentBackfillQueue(id: $id) {
      id
      agent {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgentBackfillQueues = /* GraphQL */ `
  query ListAgentBackfillQueues(
    $filter: ModelAgentBackfillQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentBackfillQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agent {
          clientId
          projectId
          id
          social360Id
          name
          any
          inConnectionWithAny
          inConnectionWithAll
          none
          mediaTypes
          metabaseFilterId
          isMetafilter
          metabaseSearchBackfillCompleted
          metabaseSearchBackfillEndDate
          metabaseSearchBackfillSequenceId
          metabaseSearchBackfillDate
          newsSourceRanks
          socialSourceRanks
          sourceNames
          sourceNamesInclude
          sourceDomains
          sourceDomainsInclude
          sourceCategories
          sourceCategoriesInclude
          countries
          articleTopics
          articleTopicsInclude
          languages
          socialLastIndexedDate
          socialLastUpdatedDate
          socialBackfillDate
          socialLanguages
          isDraft
          isEnabled
          isDeleted
          hasWebSources
          hasSocialSources
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryAgentBackfillQueue = /* GraphQL */ `
  query QueryAgentBackfillQueue(
    $queue: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentBackfillQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAgentBackfillQueue(
      queue: $queue
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agent {
          clientId
          projectId
          id
          social360Id
          name
          any
          inConnectionWithAny
          inConnectionWithAll
          none
          mediaTypes
          metabaseFilterId
          isMetafilter
          metabaseSearchBackfillCompleted
          metabaseSearchBackfillEndDate
          metabaseSearchBackfillSequenceId
          metabaseSearchBackfillDate
          newsSourceRanks
          socialSourceRanks
          sourceNames
          sourceNamesInclude
          sourceDomains
          sourceDomainsInclude
          sourceCategories
          sourceCategoriesInclude
          countries
          articleTopics
          articleTopicsInclude
          languages
          socialLastIndexedDate
          socialLastUpdatedDate
          socialBackfillDate
          socialLanguages
          isDraft
          isEnabled
          isDeleted
          hasWebSources
          hasSocialSources
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResult = /* GraphQL */ `
  query GetResult($id: ID!) {
    getResult(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      source
      id
      createdDate
      publishedDate
      title
      content
      content100
      url
      image
      language
      sourceType
      sourceName
      sourceUrl
      authorName
      authorEmail
      outboundUrls
      wordCount
      sourceRank
      sourceCountry
      sourceRegion
      sourceCategory
      sourceFeedAutoRank
      sourceFeedAutoRankOrder
      sourceFeedInboundLinkCount
      sourceFeedAutoTopics
      sourceFeedEditorialTopics
      sourceFeedGenre
      copyright
      loginStatus
      mediaValue
      mediaValueCurrency
      mozrank
      reach
      reachFrequency
      articleTopics
      authorUrl
      authorImage
      authorGroups
      authorPosts
      authorFollowers
      authorFollowing
      socialInternalId
      socialEngagement
      socialReactions
      socialComments
      socialShares
      socialViews
      duplicateGroup
      duplicateGroupSource
      excerpt
      agents
      people
      organisations
      topics
      concerns
      sentiment
      trafficLight
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
      commentFilterId
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      pendingJobs
      includePublicFeed
      isTop
      isNotTop
      isDeleted
      searchParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryResults = /* GraphQL */ `
  query QueryResults(
    $clientId: ID!
    $projectIdId: ModelResultByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryResults(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryResultsDuplicateGroupSource = /* GraphQL */ `
  query QueryResultsDuplicateGroupSource(
    $clientId: ID!
    $projectIdDuplicateGroupSource: ModelResultByClientProjectDuplicateGroupSourceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryResultsDuplicateGroupSource(
      clientId: $clientId
      projectIdDuplicateGroupSource: $projectIdDuplicateGroupSource
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchResults = /* GraphQL */ `
  query SearchResults(
    $filter: SearchableResultFilterInput
    $sort: [SearchableResultSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResultAggregationInput]
  ) {
    searchResults(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getResultComprehendQueue = /* GraphQL */ `
  query GetResultComprehendQueue($id: ID!) {
    getResultComprehendQueue(id: $id) {
      id
      result {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      lock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listResultComprehendQueues = /* GraphQL */ `
  query ListResultComprehendQueues(
    $filter: ModelResultComprehendQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultComprehendQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        result {
          clientId
          projectId
          source
          id
          createdDate
          publishedDate
          title
          content
          content100
          url
          image
          language
          sourceType
          sourceName
          sourceUrl
          authorName
          authorEmail
          outboundUrls
          wordCount
          sourceRank
          sourceCountry
          sourceRegion
          sourceCategory
          sourceFeedAutoRank
          sourceFeedAutoRankOrder
          sourceFeedInboundLinkCount
          sourceFeedAutoTopics
          sourceFeedEditorialTopics
          sourceFeedGenre
          copyright
          loginStatus
          mediaValue
          mediaValueCurrency
          mozrank
          reach
          reachFrequency
          articleTopics
          authorUrl
          authorImage
          authorGroups
          authorPosts
          authorFollowers
          authorFollowing
          socialInternalId
          socialEngagement
          socialReactions
          socialComments
          socialShares
          socialViews
          duplicateGroup
          duplicateGroupSource
          excerpt
          agents
          people
          organisations
          topics
          concerns
          sentiment
          trafficLight
          commentId
          commentContent
          commentUserId
          commentAiAnalyst
          commentFilterId
          aiSummary
          aiExcerpt
          aiAnalysis
          aiRecommendations
          pendingJobs
          includePublicFeed
          isTop
          isNotTop
          isDeleted
          searchParams
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        lock
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryResultComprehendQueue = /* GraphQL */ `
  query QueryResultComprehendQueue(
    $queue: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultComprehendQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryResultComprehendQueue(
      queue: $queue
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        result {
          clientId
          projectId
          source
          id
          createdDate
          publishedDate
          title
          content
          content100
          url
          image
          language
          sourceType
          sourceName
          sourceUrl
          authorName
          authorEmail
          outboundUrls
          wordCount
          sourceRank
          sourceCountry
          sourceRegion
          sourceCategory
          sourceFeedAutoRank
          sourceFeedAutoRankOrder
          sourceFeedInboundLinkCount
          sourceFeedAutoTopics
          sourceFeedEditorialTopics
          sourceFeedGenre
          copyright
          loginStatus
          mediaValue
          mediaValueCurrency
          mozrank
          reach
          reachFrequency
          articleTopics
          authorUrl
          authorImage
          authorGroups
          authorPosts
          authorFollowers
          authorFollowing
          socialInternalId
          socialEngagement
          socialReactions
          socialComments
          socialShares
          socialViews
          duplicateGroup
          duplicateGroupSource
          excerpt
          agents
          people
          organisations
          topics
          concerns
          sentiment
          trafficLight
          commentId
          commentContent
          commentUserId
          commentAiAnalyst
          commentFilterId
          aiSummary
          aiExcerpt
          aiAnalysis
          aiRecommendations
          pendingJobs
          includePublicFeed
          isTop
          isNotTop
          isDeleted
          searchParams
          createdAt
          updatedAt
          __typename
        }
        queue
        timestamp
        lock
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($id: ID!) {
    getTranslation(id: $id) {
      clientId
      projectId
      id
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTranslations = /* GraphQL */ `
  query ListTranslations(
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        id
        title
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryTranslations = /* GraphQL */ `
  query QueryTranslations(
    $clientId: ID!
    $projectIdId: ModelTranslationByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTranslations(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        id
        title
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTranslations = /* GraphQL */ `
  query SearchTranslations(
    $filter: SearchableTranslationFilterInput
    $sort: [SearchableTranslationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTranslationAggregationInput]
  ) {
    searchTranslations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        id
        title
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      resultId
      id
      content
      userId
      aiAnalyst
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        resultId
        id
        content
        userId
        aiAnalyst
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryComments = /* GraphQL */ `
  query QueryComments(
    $clientId: ID!
    $projectIdResultId: ModelCommentByClientProjectResultIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryComments(
      clientId: $clientId
      projectIdResultId: $projectIdResultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        resultId
        id
        content
        userId
        aiAnalyst
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchComments = /* GraphQL */ `
  query SearchComments(
    $filter: SearchableCommentFilterInput
    $sort: [SearchableCommentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCommentAggregationInput]
  ) {
    searchComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        resultId
        id
        content
        userId
        aiAnalyst
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      organisations
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        organisations
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryPeople = /* GraphQL */ `
  query QueryPeople(
    $clientId: ID!
    $projectIdUniqueName: ModelPersonByClientProjectUniqueNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryPeople(
      clientId: $clientId
      projectIdUniqueName: $projectIdUniqueName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        organisations
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPeople = /* GraphQL */ `
  query SearchPeople(
    $filter: SearchablePersonFilterInput
    $sort: [SearchablePersonSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePersonAggregationInput]
  ) {
    searchPeople(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        organisations
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryOrganisations = /* GraphQL */ `
  query QueryOrganisations(
    $clientId: ID!
    $projectIdUniqueName: ModelOrganisationByClientProjectUniqueNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryOrganisations(
      clientId: $clientId
      projectIdUniqueName: $projectIdUniqueName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrganisations = /* GraphQL */ `
  query SearchOrganisations(
    $filter: SearchableOrganisationFilterInput
    $sort: [SearchableOrganisationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganisationAggregationInput]
  ) {
    searchOrganisations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        tags
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      name
      uniqueName
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        type
        name
        uniqueName
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryTags = /* GraphQL */ `
  query QueryTags(
    $clientId: ID!
    $projectIdTypeUniqueName: ModelTagByClientProjectTypeUniqueNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTags(
      clientId: $clientId
      projectIdTypeUniqueName: $projectIdTypeUniqueName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        type
        name
        uniqueName
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        type
        name
        uniqueName
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getFilter = /* GraphQL */ `
  query GetFilter($id: ID!) {
    getFilter(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      query
      isTop
      isVisible
      order
      issueAlertLastSent
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFilters = /* GraphQL */ `
  query ListFilters(
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        query
        isTop
        isVisible
        order
        issueAlertLastSent
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryFilters = /* GraphQL */ `
  query QueryFilters(
    $clientId: ID!
    $projectIdId: ModelFilterByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFilters(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        query
        isTop
        isVisible
        order
        issueAlertLastSent
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearch = /* GraphQL */ `
  query GetSearch($id: ID!) {
    getSearch(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      query
      chartQuery
      resultsType
      filterName
      filterId
      skipFilterHasChangesCheck
      expire
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSearches = /* GraphQL */ `
  query ListSearches(
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        query
        chartQuery
        resultsType
        filterName
        filterId
        skipFilterHasChangesCheck
        expire
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const querySearch = /* GraphQL */ `
  query QuerySearch(
    $clientId: ID!
    $projectIdId: ModelSearchByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySearch(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        query
        chartQuery
        resultsType
        filterName
        filterId
        skipFilterHasChangesCheck
        expire
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChart = /* GraphQL */ `
  query GetChart($id: ID!) {
    getChart(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      metricType
      chartType
      resultsType
      dateRange
      startDate
      startTime
      endDate
      endTime
      comparisonStartDate
      comparisonStartTime
      comparisonEndDate
      comparisonEndTime
      aggSize
      showValues
      sortByValue
      hidden
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCharts = /* GraphQL */ `
  query ListCharts(
    $filter: ModelChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        metricType
        chartType
        resultsType
        dateRange
        startDate
        startTime
        endDate
        endTime
        comparisonStartDate
        comparisonStartTime
        comparisonEndDate
        comparisonEndTime
        aggSize
        showValues
        sortByValue
        hidden
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryCharts = /* GraphQL */ `
  query QueryCharts(
    $clientId: ID!
    $projectIdId: ModelChartByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryCharts(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        metricType
        chartType
        resultsType
        dateRange
        startDate
        startTime
        endDate
        endTime
        comparisonStartDate
        comparisonStartTime
        comparisonEndDate
        comparisonEndTime
        aggSize
        showValues
        sortByValue
        hidden
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailingList = /* GraphQL */ `
  query GetMailingList($id: ID!) {
    getMailingList(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      isExternal
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMailingLists = /* GraphQL */ `
  query ListMailingLists(
    $filter: ModelMailingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMailingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        isExternal
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryMailingLists = /* GraphQL */ `
  query QueryMailingLists(
    $clientId: ID!
    $projectIdId: ModelMailingListByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMailingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMailingLists(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        isExternal
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailingListMember = /* GraphQL */ `
  query GetMailingListMember($id: ID!) {
    getMailingListMember(id: $id) {
      clientId
      projectId
      listId
      list {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        isExternal
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      email
      firstName
      lastName
      company
      country
      isUnsubscribed
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMailingListMembers = /* GraphQL */ `
  query ListMailingListMembers(
    $filter: ModelMailingListMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMailingListMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        listId
        list {
          clientId
          projectId
          id
          name
          isExternal
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        email
        firstName
        lastName
        company
        country
        isUnsubscribed
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryMailingListMembers = /* GraphQL */ `
  query QueryMailingListMembers(
    $clientId: ID!
    $projectIdId: ModelMailingListMemberByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMailingListMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMailingListMembers(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        listId
        list {
          clientId
          projectId
          id
          name
          isExternal
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        email
        firstName
        lastName
        company
        country
        isUnsubscribed
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryMailingListMembersListId = /* GraphQL */ `
  query QueryMailingListMembersListId(
    $clientId: ID!
    $projectIdListId: ModelMailingListMemberByClientProjectListIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMailingListMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMailingListMembersListId(
      clientId: $clientId
      projectIdListId: $projectIdListId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        listId
        list {
          clientId
          projectId
          id
          name
          isExternal
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        email
        firstName
        lastName
        company
        country
        isUnsubscribed
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      title
      subject
      showHeader
      sentAt
      isDeleted
      blocks {
        items {
          clientId
          projectId
          reportId
          id
          type
          auto
          autoFilter
          title
          subtitle
          text
          image
          imageCaption
          imageLink
          chartId
          bannerShowTitle
          contentsInclude
          pdfPageBreakBefore
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sends {
        items {
          clientId
          projectId
          reportId
          id
          isPreview
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        title
        subject
        showHeader
        sentAt
        isDeleted
        blocks {
          nextToken
          __typename
        }
        sends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReports = /* GraphQL */ `
  query QueryReports(
    $clientId: ID!
    $projectIdId: ModelReportByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReports(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        title
        subject
        showHeader
        sentAt
        isDeleted
        blocks {
          nextToken
          __typename
        }
        sends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportBlock = /* GraphQL */ `
  query GetReportBlock($id: ID!) {
    getReportBlock(id: $id) {
      clientId
      projectId
      reportId
      id
      type
      auto
      autoFilter
      title
      subtitle
      text
      image
      imageCaption
      imageLink
      chartId
      bannerShowTitle
      contentsInclude
      pdfPageBreakBefore
      order
      results {
        items {
          clientId
          projectId
          reportId
          reportBlockId
          id
          type
          resultId
          source
          publishedDate
          title
          excerpt
          url
          image
          sourceName
          sourceType
          authorImage
          tableColumn1
          tableColumn2
          showImage
          imageOverride
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportBlocks = /* GraphQL */ `
  query ListReportBlocks(
    $filter: ModelReportBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        reportId
        id
        type
        auto
        autoFilter
        title
        subtitle
        text
        image
        imageCaption
        imageLink
        chartId
        bannerShowTitle
        contentsInclude
        pdfPageBreakBefore
        order
        results {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportBlocks = /* GraphQL */ `
  query QueryReportBlocks(
    $clientId: ID!
    $projectIdId: ModelReportBlockByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportBlocks(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        id
        type
        auto
        autoFilter
        title
        subtitle
        text
        image
        imageCaption
        imageLink
        chartId
        bannerShowTitle
        contentsInclude
        pdfPageBreakBefore
        order
        results {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportBlocksReportId = /* GraphQL */ `
  query QueryReportBlocksReportId(
    $clientId: ID!
    $projectIdReportId: ModelReportBlockByClientProjectReportIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportBlocksReportId(
      clientId: $clientId
      projectIdReportId: $projectIdReportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        id
        type
        auto
        autoFilter
        title
        subtitle
        text
        image
        imageCaption
        imageLink
        chartId
        bannerShowTitle
        contentsInclude
        pdfPageBreakBefore
        order
        results {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportResult = /* GraphQL */ `
  query GetReportResult($id: ID!) {
    getReportResult(id: $id) {
      clientId
      projectId
      reportId
      reportBlockId
      id
      type
      resultId
      source
      publishedDate
      title
      excerpt
      url
      image
      sourceName
      sourceType
      authorImage
      tableColumn1
      tableColumn2
      showImage
      imageOverride
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportResults = /* GraphQL */ `
  query ListReportResults(
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        reportId
        reportBlockId
        id
        type
        resultId
        source
        publishedDate
        title
        excerpt
        url
        image
        sourceName
        sourceType
        authorImage
        tableColumn1
        tableColumn2
        showImage
        imageOverride
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportResults = /* GraphQL */ `
  query QueryReportResults(
    $clientId: ID!
    $projectIdId: ModelReportResultByClientProjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportResults(
      clientId: $clientId
      projectIdId: $projectIdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        reportBlockId
        id
        type
        resultId
        source
        publishedDate
        title
        excerpt
        url
        image
        sourceName
        sourceType
        authorImage
        tableColumn1
        tableColumn2
        showImage
        imageOverride
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportResultsReportId = /* GraphQL */ `
  query QueryReportResultsReportId(
    $clientId: ID!
    $projectIdReportId: ModelReportResultByClientProjectReportIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportResultsReportId(
      clientId: $clientId
      projectIdReportId: $projectIdReportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        reportBlockId
        id
        type
        resultId
        source
        publishedDate
        title
        excerpt
        url
        image
        sourceName
        sourceType
        authorImage
        tableColumn1
        tableColumn2
        showImage
        imageOverride
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportResultsReportBlockId = /* GraphQL */ `
  query QueryReportResultsReportBlockId(
    $clientId: ID!
    $projectIdReportBlockId: ModelReportResultByClientProjectReportBlockIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportResultsReportBlockId(
      clientId: $clientId
      projectIdReportBlockId: $projectIdReportBlockId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        reportBlockId
        id
        type
        resultId
        source
        publishedDate
        title
        excerpt
        url
        image
        sourceName
        sourceType
        authorImage
        tableColumn1
        tableColumn2
        showImage
        imageOverride
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportSend = /* GraphQL */ `
  query GetReportSend($id: ID!) {
    getReportSend(id: $id) {
      clientId
      projectId
      reportId
      id
      isPreview
      recipients {
        items {
          clientId
          projectId
          reportSendId
          id
          email
          mailingListMemberId
          firstName
          lastName
          company
          country
          sentAt
          openedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportSends = /* GraphQL */ `
  query ListReportSends(
    $filter: ModelReportSendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportSends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        reportId
        id
        isPreview
        recipients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportSends = /* GraphQL */ `
  query QueryReportSends(
    $clientId: ID!
    $projectIdReportId: ModelReportSendByClientProjectReportIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportSendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportSends(
      clientId: $clientId
      projectIdReportId: $projectIdReportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportId
        id
        isPreview
        recipients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportSendRecipient = /* GraphQL */ `
  query GetReportSendRecipient($id: ID!) {
    getReportSendRecipient(id: $id) {
      clientId
      projectId
      reportSendId
      reportSend {
        clientId
        projectId
        reportId
        id
        isPreview
        recipients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      email
      mailingListMemberId
      firstName
      lastName
      company
      country
      sentAt
      openedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportSendRecipients = /* GraphQL */ `
  query ListReportSendRecipients(
    $filter: ModelReportSendRecipientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportSendRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportSendId
        reportSend {
          clientId
          projectId
          reportId
          id
          isPreview
          createdAt
          updatedAt
          __typename
        }
        id
        email
        mailingListMemberId
        firstName
        lastName
        company
        country
        sentAt
        openedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryReportSendRecipients = /* GraphQL */ `
  query QueryReportSendRecipients(
    $clientId: ID!
    $projectIdReportSendId: ModelReportSendRecipientByClientProjectReportSendIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportSendRecipientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryReportSendRecipients(
      clientId: $clientId
      projectIdReportSendId: $projectIdReportSendId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        reportSendId
        reportSend {
          clientId
          projectId
          reportId
          id
          isPreview
          createdAt
          updatedAt
          __typename
        }
        id
        email
        mailingListMemberId
        firstName
        lastName
        company
        country
        sentAt
        openedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailQueue = /* GraphQL */ `
  query GetEmailQueue($id: ID!) {
    getEmailQueue(id: $id) {
      id
      queue
      timestamp
      lock
      template
      email
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailQueues = /* GraphQL */ `
  query ListEmailQueues(
    $filter: ModelEmailQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        queue
        timestamp
        lock
        template
        email
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryEmailQueue = /* GraphQL */ `
  query QueryEmailQueue(
    $queue: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryEmailQueue(
      queue: $queue
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue
        timestamp
        lock
        template
        email
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      status
      data
      result
      expire
      resultId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        status
        data
        result
        expire
        resultId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlertSubscription = /* GraphQL */ `
  query GetAlertSubscription($id: ID!) {
    getAlertSubscription(id: $id) {
      clientId
      projectId
      userId
      user {
        id
        email
        clients
        role
        perms
        projects
        enabled
        name
        firstName
        lastName
        timezone
        language
        tempPassword
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlertSubscriptions = /* GraphQL */ `
  query ListAlertSubscriptions(
    $filter: ModelAlertSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        userId
        user {
          id
          email
          clients
          role
          perms
          projects
          enabled
          name
          firstName
          lastName
          timezone
          language
          tempPassword
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryAlertSubscriptions = /* GraphQL */ `
  query QueryAlertSubscriptions(
    $clientId: ID!
    $projectIdType: ModelAlertSubscriptionByClientProjectTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAlertSubscriptions(
      clientId: $clientId
      projectIdType: $projectIdType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientId
        projectId
        userId
        user {
          id
          email
          clients
          role
          perms
          projects
          enabled
          name
          firstName
          lastName
          timezone
          language
          tempPassword
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { OutciderRoute, allFlattenRoutes as routes } from "./index";
import React, { useEffect, useState } from "react";

import VerticalLayout from "../layouts/Vertical";
import { getUser } from "../outcider/lib/auth";
import { useApolloClient } from "@apollo/client";
import { useIntercom } from "react-use-intercom";

// TODO handle dirty page confirmation globally here?
// const getUserConfirmation = (message, callback) => {
//   const modal = document.createElement("div");
//   document.body.appendChild(modal);

//   const withCleanup = (answer) => {
//     ReactDOM.unmountComponentAtNode(modal);
//     document.body.removeChild(modal);
//     callback(answer);
//   };

//   ReactDOM.render(
//     <div message={message} onCancel={() => withCleanup(false)} onConfirm={() => withCleanup(true)} />,
//     modal
//   );
// };

const Routes = (props) => {
  const apollo = useApolloClient();
  const { boot, show } = useIntercom();
  const [user, setUser] = useState(null);

  // boot intercom once logged in
  useEffect(() => {
    async function doUser() {
      const user = await getUser(apollo);
      if (user) {
        setUser(user);
        boot({
          name: user.name,
          email: user.email,
          hideDefaultLauncher: true, // launch button in LeftSidebar
        });
      }
    }
    doUser();
  }, [apollo, boot]);

  if (!user) return null;

  return (
    <BrowserRouter
    // getUserConfirmation={getUserConfirmation}
    >
      <VerticalLayout {...props} user={user} showIntercom={show}>
        <Switch>
          {routes.map((route, index) => {
            return <OutciderRoute key={index} {...route} />;
          })}
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </VerticalLayout>
    </BrowserRouter>
  );
};

export default Routes;

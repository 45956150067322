// only return `order` as that is all that needs to be merged into existing objects
export const fnOrder = /* GraphQL */ `
  mutation FnOrder($input: FnOrderInput!) {
    fnOrder(input: $input) {
      ... on Filter {
        clientId
        projectId
        id
        order
      }
    }
  }
`;

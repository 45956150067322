import { Button, ButtonGroup, FormGroup, Input, Label } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";

import FormError from "./FormError";
import classNames from "classnames";
import { getS3Public } from "../../lib/files";

const FormFile = ({
  name,
  label,
  accept,
  current,
  uploadPath,
  uploadLevel,
  required,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  formProps,
  ...rest
}) => {
  const ref = useRef(); // ref to file input

  const [file, setFile] = useState(null);
  const [action, setAction] = useState(null);
  const [deletedCurrent, setDeletedCurrent] = useState(false);

  const actionField = `${name}_FILE_ACTION`;
  const uploadField = `${name}_FILE_UPLOAD`;

  // set initial action state based on whether we have a current file
  useEffect(() => {
    setAction(current ? "KEEP" : "UPLOAD");
  }, [setAction, current]);

  // register file field
  useEffect(() => {
    formProps.register(name, {
      // file field cannot be required as they are not repopulated with existing data
      required: false,
      // custom validate functions handles required/not-required
      validate: (value) => {
        if (!required) return true;
        if ((action === "DELETE" && current) || (action === "UPLOAD" && !(current || value)))
          return "This field is required.";
      },
    });
  }, [formProps, name, required, action, current]);

  // we only handle one file
  const onChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    formProps.setValue(name, file);
    setAction("UPLOAD");
    formProps.setValue(actionField, "UPLOAD");
  };

  // trigger click on file input
  const selectFile = () => {
    ref.current.click();
  };

  // delete the current file
  const deleteCurrent = () => {
    setAction("DELETE");
    setDeletedCurrent(true);
    formProps.setValue(actionField, "DELETE");
  };

  // delete the new file
  const deleteUpload = () => {
    setFile(null);
    formProps.setValue(name, null);
    // where to go back to depending on if we had a current file at the start and if we deleted it
    const action = deletedCurrent ? "DELETE" : current ? "KEEP" : "UPLOAD";
    setAction(action);
    formProps.setValue(actionField, action);
  };

  // view the current file from S3
  const viewCurrent = () => {
    const url = getS3Public(current);
    window.open(url);
  };

  return (
    <FormGroup
      className={classNames({
        "form-error": formProps.errors[name],
        [`${className}`]: className,
      })}
    >
      <Label for={name}>
        {label}: {required && "*"}
      </Label>
      <div>
        {action === "DELETE" && <Button onClick={selectFile}>Select file...</Button>}
        {action === "KEEP" && (
          <ButtonGroup style={{ maxWidth: "100%" }}>
            <Button
              className="text-left"
              onClick={selectFile}
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {current.split("/").slice(-1)}
            </Button>
            {accept === "image/*" && (
              <Button onClick={viewCurrent}>
                <i className="uil-eye" />
              </Button>
            )}
            <Button color="danger" onClick={deleteCurrent}>
              <i className="uil-times" />
            </Button>
          </ButtonGroup>
        )}
        {action === "UPLOAD" && (
          <ButtonGroup>
            <Button className="text-left" onClick={selectFile}>
              {file ? file.name : "Select file..."}
            </Button>
            {file && (
              <Button color="danger" onClick={deleteUpload}>
                <i className="uil-times" />
              </Button>
            )}
          </ButtonGroup>
        )}

        <div style={{ position: "absolute", left: -10000, opacity: 1 }}>
          <Input
            type="file"
            name={name}
            accept={accept}
            {...rest}
            innerRef={(r) => {
              ref.current = r;
            }}
            onChange={onChange}
          />
          <input type="text" name={actionField} defaultValue={action} ref={formProps.register({ required: false })} />
          <input
            type="text"
            name={uploadField}
            defaultValue={JSON.stringify({
              path: uploadPath,
              level: uploadLevel,
            })}
            ref={formProps.register({ required: false })}
          />
        </div>

        {!hideErrors && (
          <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
        )}
      </div>
    </FormGroup>
  );
};

export default FormFile;

import { Button, ButtonGroup, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { useContext, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { fnTranslateResult, searchResultsRaw } from "../../graphql/queries";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useMediaBreakpointDownLg, useMediaBreakpointDownMd } from "../lib/effects";

import { AppContext } from "../../App";
import ResultPanelAnalysis from "./ResultPanelAnalysis";
import ResultPanelComments from "./ResultPanelComments";
import ResultPanelStory from "./ResultPanelStory";
import ResultPanelTags from "./ResultPanelTags";
import ResultsError from "./ResultsError";
import Spinner from "../../components/Spinner";
import { TRANSLATE_LANGUAGES } from "../../lib/constants";
import { buildSearchResultsParams } from "../../lib/queries";
import classNames from "classnames";
import findKey from "lodash/findKey";
import { getResultPrefixForSource } from "../../lib/utils";

// for keeping translation if tab changes within result
const ResultTranslation = ({ result, children }) => {
  const appContext = useContext(AppContext);
  const apollo = useApolloClient();

  const { clientId, projectId } = useParams();

  const [translation, setTranslation] = useState(result.language);
  const [translationLoading, setTranslationLoading] = useState(false);
  const [translatedResult, setTranslatedResult] = useState(null);

  const translate = async (language) => {
    if (translationLoading) return;

    // do nothing if clicked current language
    if (language === translation) return;

    // revert back to original if clicked result language
    if (language === result.language) {
      setTranslation(result.language);
      setTranslatedResult(null);
      return;
    }

    // get language code
    const languageCode = findKey(TRANSLATE_LANGUAGES, (x) => x === language);

    // set loading
    setTranslationLoading(true);

    try {
      // get translation
      let translatedResult = await apollo.query({
        query: gql(fnTranslateResult),
        variables: { input: { clientId, projectId, id: `${result.id}-${languageCode}` } },
      });
      translatedResult = translatedResult.data.fnTranslateResult;

      // update language
      setTranslation(language);

      // update result
      setTranslatedResult({ title: translatedResult.title, content: translatedResult.content });
    } catch (e) {
      appContext.handleError(e);
    } finally {
      setTranslationLoading(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        translation,
        translationLoading,
        translatedResult,
        translate,
      })}
    </>
  );
};

const ResultPanel = ({
  source,
  resultId,
  resultsType,
  filter,
  duplicates,
  highlight,
  excerptBaseIsDynamic,
  listingDuplicates,
  showNav,
  onPrev,
  onNext,
  onClose,
  user,
  projectData,
}) => {
  const appContext = useContext(AppContext);

  const { clientId, projectId } = useParams();
  const [activeTab, setActiveTab] = useState("story");

  const isMediaBreakpointDownMd = useMediaBreakpointDownMd();
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  // for keeping draft comment if tab changes within result
  const [draftComment, setDraftComment] = useState(null);

  // get result with highlights applied to excerpt and content
  const searchParams = buildSearchResultsParams({
    source,
    filterParams: {
      clientId,
      projectId,
      id: resultId,
      isDeleted: resultsType === "deleted",
      maxDate: appContext.maxDate,
    },
    highlight: highlight.query,
    resultsType,
    projectData,
  });
  const { loading, data, refetch, error } = useQuery(gql(searchResultsRaw), {
    variables: {
      searchParams: JSON.stringify(searchParams),
      excerptQuery: highlight.query, // used in cache
      limit: 1,
    },
  });

  // show tabs while loading, show spinner in each tab
  let result = null;
  if (!loading && !error) {
    result = data.searchResultsRaw.items.length ? data.searchResultsRaw.items[0] : null;
    // if no longer a valid result, hard/soft deleted, go up a level (closing the result)
    // not doing for now:
    // - check top story status since it isn't just based on isTop but on issue matching
    // - work out if active result is now invalid, and need to close the result without a redirectAlert (but do a redirectAlert otherwise)
    if (
      !result ||
      (result && resultsType === "deleted" && !result.isDeleted) ||
      (result && resultsType !== "deleted" && result.isDeleted)
    )
      return (
        <Redirect
          to={{
            pathname: `/${clientId}/${projectId}/${getResultPrefixForSource(source)}/${resultsType}`,
          }}
        />
      );
  }

  const tabs = [
    {
      id: "story",
      title: "Story",
      icon: "uil-file-alt",
      component: ResultPanelStory,
    },
    {
      id: "tags",
      title: "Tags",
      icon: "uil-tag-alt",
      component: ResultPanelTags,
    },
    {
      id: "analysis",
      title: "Analysis",
      icon: "uil-chart",
      component: ResultPanelAnalysis,
    },
    {
      id: "comments",
      title: "Comments",
      icon: "uil-comments",
      component: ResultPanelComments,
    },
  ];

  return (
    <Card
      className={classNames({
        "side-panel": true,
        "side-panel--white": true,
        "vh-with-title": !listingDuplicates && !isMediaBreakpointDownLg,
        "vh-with-title-wrapped-buttons": !listingDuplicates && isMediaBreakpointDownLg,
        "vh-duplicates-modal": listingDuplicates,
      })}
    >
      <Nav tabs className="nav-bordered">
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              href="#"
              className={classNames({ active: activeTab === tab.id })}
              onClick={() => setActiveTab(tab.id)}
            >
              <i className={`${tab.icon} d-md-none`} />
              <span
                className={classNames({
                  "d-none": activeTab !== tab.id,
                  "d-md-inline": true,
                  "ml-1": isMediaBreakpointDownMd,
                })}
              >
                {tab.title}
              </span>
            </NavLink>
          </NavItem>
        ))}
        <NavItem className="ml-auto side-panel--buttons">
          {showNav && (
            <ButtonGroup>
              <Button color="secondary" size="md" className="side-panel--nav-button-md" onClick={() => onPrev()}>
                <i className="uil-angle-left" />
              </Button>

              <Button color="secondary" size="md" className="side-panel--nav-button-md" onClick={() => onNext()}>
                <i className="uil-angle-right" />
              </Button>
            </ButtonGroup>
          )}
          <Button color="secondary" size="md" className="side-panel--close-button-md" onClick={onClose}>
            <i className="uil-times" />
          </Button>
        </NavItem>
      </Nav>
      <CardBody className="scroll">
        <TabContent className={classNames({ "h-100": error })} activeTab={activeTab}>
          {tabs
            .filter((tab) => tab.id === activeTab) // only render tab if active
            .map((tab, index) => (
              <TabPane className={classNames({ "h-100": error })} tabId={tab.id} key={index}>
                {error ? (
                  <ResultsError message="this result" refetch={refetch} />
                ) : (
                  <>
                    {loading ? (
                      <Spinner className="loading-relative" />
                    ) : (
                      <ResultTranslation result={result}>
                        <tab.component
                          source={source}
                          result={result}
                          resultsType={resultsType}
                          filter={filter}
                          duplicates={duplicates}
                          highlight={highlight}
                          excerptBaseIsDynamic={excerptBaseIsDynamic}
                          draftComment={draftComment}
                          setDraftComment={setDraftComment}
                          user={user}
                          projectData={projectData}
                        />
                      </ResultTranslation>
                    )}
                  </>
                )}
              </TabPane>
            ))}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default ResultPanel;

import { Col, Row } from "reactstrap";

import React from "react";
import classNames from "classnames";
import { useMediaBreakpointDownLg } from "../outcider/lib/effects";

const PageTitle = (props) => {
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();
  const wrapButtonsMediaBreakpointDownLg = props.wrapButtonsMediaBreakpointDownLg || false;

  let renderButtonsFirst = true;
  if (isMediaBreakpointDownLg && wrapButtonsMediaBreakpointDownLg) renderButtonsFirst = false;

  return (
    <Row>
      <Col>
        <div className="page-title-box">
          {renderButtonsFirst && <div className="page-title-right pl-2">{props.children}</div>}
          <h2
            className={classNames({
              "text-primary": !props.small,
              "text-title": props.small,
              "page-title": true,
            })}
          >
            {props.title}
            {props.subtitle && (
              <>
                {" "}
                <span className="text-title font-14 font-normal">{props.subtitle}</span>
              </>
            )}
          </h2>
          {!renderButtonsFirst && <div className="page-title-right page-title-right--xs-sm-md">{props.children}</div>}
        </div>
      </Col>
    </Row>
  );
};

export default PageTitle;

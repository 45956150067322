import { Button, ButtonGroup, FormGroup, Label } from "reactstrap";

import { Controller } from "react-hook-form";
import FormError from "./FormError";
import React from "react";
import classNames from "classnames";
import { getSentimentTrafficLightColour } from "../../lib/utils";
import remove from "lodash/remove";

const FormToggle = ({
  name,
  label,
  options,
  isMulti,
  required,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  onChange,
  isDisabled,
  formProps,
  ...rest
}) => {
  const isSelected = (props, option) =>
    isMulti
      ? props.value && props.value.map((x) => x.value).includes(option.value)
      : props.value && props.value.value === option.value;

  const handleChange = (props, option) => {
    if (isMulti) {
      // add/remove the selected options
      let newValue;
      if (props.value && props.value.includes(option)) {
        newValue = remove(props.value, (x) => x !== option);
      } else {
        newValue = (props.value || []).concat(option);
      }
      // return null if nothing selected
      if (newValue.length === 0) {
        props.onChange(null);
      } else {
        props.onChange(newValue);
      }
    } else {
      // change the selected option
      if (isSelected(props, option)) {
        props.onChange(null);
      } else {
        props.onChange(option);
      }
    }
  };

  const getIcon = (value) =>
    ({
      NEGATIVE: "uil-sad",
      NEUTRAL: "uil-meh",
      POSITIVE: "uil-smile",
      THREAT: "uil-exclamation-triangle",
      ALERT: "uil-bell",
      OPPORTUNITY: "uil-lightbulb-alt",
    }[value] || null);

  return (
    <FormGroup
      className={classNames({
        "form-error": formProps.errors[name],
        [`${className}`]: className,
      })}
    >
      {label && (
        <Label for={name}>
          {label}: {required && "*"}
        </Label>
      )}
      <Controller
        name={name}
        control={formProps.control}
        rules={{ required }}
        defaultValue={null}
        render={(props) => (
          // TODO probably required behaviour needs doing
          <ButtonGroup className="d-flex">
            {options.map((option) => {
              return (
                <Button
                  key={option.value}
                  className={classNames({
                    "form-toggle": true,
                    "form-toggle--notselected": !isSelected(props, option),
                    "form-toggle--selected": isSelected(props, option),
                    "flex-grow-1": true,
                  })}
                  style={{
                    backgroundColor: getSentimentTrafficLightColour(option.value),
                  }}
                  color="link" // removes shadows
                  disabled={isDisabled}
                  onClick={(e) => {
                    handleChange(props, option); // react-hook-form onChange
                    onChange && onChange(); // custom onChange if provided
                  }}
                >
                  {getIcon(option.value) && (
                    <i
                      className={classNames({
                        "d-block": true,
                        [getIcon(option.value)]: true,
                      })}
                      style={{
                        color: getSentimentTrafficLightColour(option.value),
                      }}
                    />
                  )}
                  {option.label}
                </Button>
              );
            })}
          </ButtonGroup>
        )}
      />
      {!hideErrors && (
        <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
      )}
    </FormGroup>
  );
};

export default FormToggle;

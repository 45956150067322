/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fnAdminClient = /* GraphQL */ `
  mutation FnAdminClient($input: FnAdminClientInput!) {
    fnAdminClient(input: $input) {
      id
      subdomain
      name
      colour
      logo
      image
      reportLogo
      reportFooter
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnAdminUser = /* GraphQL */ `
  mutation FnAdminUser($input: FnAdminUserInput!) {
    fnAdminUser(input: $input) {
      id
      email
      clients
      role
      perms
      projects
      enabled
      name
      firstName
      lastName
      timezone
      language
      tempPassword
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnAdminUserToggle = /* GraphQL */ `
  mutation FnAdminUserToggle($input: OpIdInput!) {
    fnAdminUserToggle(input: $input) {
      id
      email
      clients
      role
      perms
      projects
      enabled
      name
      firstName
      lastName
      timezone
      language
      tempPassword
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnDeleteAgent = /* GraphQL */ `
  mutation FnDeleteAgent($input: IdsInput!) {
    fnDeleteAgent(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnDisableAgent = /* GraphQL */ `
  mutation FnDisableAgent($input: IdsInput!) {
    fnDisableAgent(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnDuplicateAgent = /* GraphQL */ `
  mutation FnDuplicateAgent($input: IdsInput!) {
    fnDuplicateAgent(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnEnableAgent = /* GraphQL */ `
  mutation FnEnableAgent($input: IdsInput!) {
    fnEnableAgent(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnPreviewAgent = /* GraphQL */ `
  mutation FnPreviewAgent($input: FnPreviewAgentInput!) {
    fnPreviewAgent(input: $input) {
      source
      results {
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        __typename
      }
      total
      __typename
    }
  }
`;
export const fnUpdateAgent = /* GraphQL */ `
  mutation FnUpdateAgent($input: FnUpdateAgentInput!) {
    fnUpdateAgent(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnUpdateEntity = /* GraphQL */ `
  mutation FnUpdateEntity($input: FnUpdateEntityInput!) {
    fnUpdateEntity(input: $input) {
      ... on Person {
        clientId
        projectId
        project {
          clientId
          client {
            id
            subdomain
            name
            colour
            logo
            image
            reportLogo
            reportFooter
            isDeleted
            createdAt
            updatedAt
            __typename
          }
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        organisations
        tags
        isDeleted
        createdAt
        updatedAt
      }
      ... on Organisation {
        clientId
        projectId
        project {
          clientId
          client {
            id
            subdomain
            name
            colour
            logo
            image
            reportLogo
            reportFooter
            isDeleted
            createdAt
            updatedAt
            __typename
          }
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        uniqueName
        alternativeNames
        tags
        isDeleted
        createdAt
        updatedAt
      }
    }
  }
`;
export const fnTagResult = /* GraphQL */ `
  mutation FnTagResult($input: FnTagResultInput!) {
    fnTagResult(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      source
      id
      createdDate
      publishedDate
      title
      content
      content100
      url
      image
      language
      sourceType
      sourceName
      sourceUrl
      authorName
      authorEmail
      outboundUrls
      wordCount
      sourceRank
      sourceCountry
      sourceRegion
      sourceCategory
      sourceFeedAutoRank
      sourceFeedAutoRankOrder
      sourceFeedInboundLinkCount
      sourceFeedAutoTopics
      sourceFeedEditorialTopics
      sourceFeedGenre
      copyright
      loginStatus
      mediaValue
      mediaValueCurrency
      mozrank
      reach
      reachFrequency
      articleTopics
      authorUrl
      authorImage
      authorGroups
      authorPosts
      authorFollowers
      authorFollowing
      socialInternalId
      socialEngagement
      socialReactions
      socialComments
      socialShares
      socialViews
      duplicateGroup
      duplicateGroupSource
      excerpt
      agents
      people
      organisations
      topics
      concerns
      sentiment
      trafficLight
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
      commentFilterId
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      pendingJobs
      includePublicFeed
      isTop
      isNotTop
      isDeleted
      searchParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnBulkResult = /* GraphQL */ `
  mutation FnBulkResult($input: FnBulkResultInput!) {
    fnBulkResult(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      source
      id
      createdDate
      publishedDate
      title
      content
      content100
      url
      image
      language
      sourceType
      sourceName
      sourceUrl
      authorName
      authorEmail
      outboundUrls
      wordCount
      sourceRank
      sourceCountry
      sourceRegion
      sourceCategory
      sourceFeedAutoRank
      sourceFeedAutoRankOrder
      sourceFeedInboundLinkCount
      sourceFeedAutoTopics
      sourceFeedEditorialTopics
      sourceFeedGenre
      copyright
      loginStatus
      mediaValue
      mediaValueCurrency
      mozrank
      reach
      reachFrequency
      articleTopics
      authorUrl
      authorImage
      authorGroups
      authorPosts
      authorFollowers
      authorFollowing
      socialInternalId
      socialEngagement
      socialReactions
      socialComments
      socialShares
      socialViews
      duplicateGroup
      duplicateGroupSource
      excerpt
      agents
      people
      organisations
      topics
      concerns
      sentiment
      trafficLight
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
      commentFilterId
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      pendingJobs
      includePublicFeed
      isTop
      isNotTop
      isDeleted
      searchParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnShareResult = /* GraphQL */ `
  mutation FnShareResult($input: FnShareResultInput!) {
    fnShareResult(input: $input)
  }
`;
export const fnAlert = /* GraphQL */ `
  mutation FnAlert($input: FnAlertInput!) {
    fnAlert(input: $input)
  }
`;
export const fnOrder = /* GraphQL */ `
  mutation FnOrder($input: FnOrderInput!) {
    fnOrder(input: $input) {
      ... on Filter {
        clientId
        projectId
        project {
          clientId
          client {
            id
            subdomain
            name
            colour
            logo
            image
            reportLogo
            reportFooter
            isDeleted
            createdAt
            updatedAt
            __typename
          }
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        source
        name
        query
        isTop
        isVisible
        order
        issueAlertLastSent
        isDeleted
        createdAt
        updatedAt
      }
    }
  }
`;
export const fnReport = /* GraphQL */ `
  mutation FnReport($input: FnReportInput!) {
    fnReport(input: $input)
  }
`;
export const fnArchiveProject = /* GraphQL */ `
  mutation FnArchiveProject($input: ClientProjectIdInput!) {
    fnArchiveProject(input: $input) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnDeleteProject = /* GraphQL */ `
  mutation FnDeleteProject($input: ClientProjectIdInput!) {
    fnDeleteProject(input: $input) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnUnarchiveProject = /* GraphQL */ `
  mutation FnUnarchiveProject($input: ClientProjectIdInput!) {
    fnUnarchiveProject(input: $input) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fnComprehendResult = /* GraphQL */ `
  mutation FnComprehendResult($input: OpIdInput!) {
    fnComprehendResult(input: $input) {
      people
      organisations
      removedPeople
      removedOrganisations
      __typename
    }
  }
`;
export const fnEmail = /* GraphQL */ `
  mutation FnEmail($input: OpIdInput!) {
    fnEmail(input: $input)
  }
`;
export const fnSocialAgent = /* GraphQL */ `
  mutation FnSocialAgent($input: IdInput!) {
    fnSocialAgent(input: $input) {
      new {
        found
        duplicate
        updated
        created
        error
        __typename
      }
      updated {
        found
        updated
        skipped
        error
        __typename
      }
      __typename
    }
  }
`;
export const fnTriggerJob = /* GraphQL */ `
  mutation FnTriggerJob($input: FnTriggerJobInput!) {
    fnTriggerJob(input: $input) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      status
      data
      result
      expire
      resultId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const jobAlert = /* GraphQL */ `
  mutation JobAlert($input: IdInput!) {
    jobAlert(input: $input)
  }
`;
export const jobExport = /* GraphQL */ `
  mutation JobExport($input: IdInput!) {
    jobExport(input: $input)
  }
`;
export const jobReport = /* GraphQL */ `
  mutation JobReport($input: IdInput!) {
    jobReport(input: $input)
  }
`;
export const jobResultAiAnalyst = /* GraphQL */ `
  mutation JobResultAiAnalyst($input: IdInput!) {
    jobResultAiAnalyst(input: $input)
  }
`;
export const jobSendReport = /* GraphQL */ `
  mutation JobSendReport($input: IdInput!) {
    jobSendReport(input: $input)
  }
`;
export const taskAlert = /* GraphQL */ `
  mutation TaskAlert {
    taskAlert
  }
`;
export const taskComprehendResults = /* GraphQL */ `
  mutation TaskComprehendResults {
    taskComprehendResults
  }
`;
export const taskDeleteQueue = /* GraphQL */ `
  mutation TaskDeleteQueue {
    taskDeleteQueue
  }
`;
export const taskEmail = /* GraphQL */ `
  mutation TaskEmail {
    taskEmail
  }
`;
export const taskGetMetabaseFilterResults = /* GraphQL */ `
  mutation TaskGetMetabaseFilterResults {
    taskGetMetabaseFilterResults {
      found
      unmatched
      matched
      duplicate
      updated
      created
      error
      __typename
    }
  }
`;
export const taskGetMetabaseSearchResults = /* GraphQL */ `
  mutation TaskGetMetabaseSearchResults {
    taskGetMetabaseSearchResults {
      found
      duplicate
      updated
      created
      error
      __typename
    }
  }
`;
export const taskGetSocialResults = /* GraphQL */ `
  mutation TaskGetSocialResults {
    taskGetSocialResults
  }
`;
export const taskUnlock = /* GraphQL */ `
  mutation TaskUnlock {
    taskUnlock
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      subdomain
      name
      colour
      logo
      image
      reportLogo
      reportFooter
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      subdomain
      name
      colour
      logo
      image
      reportLogo
      reportFooter
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      email
      clients
      role
      perms
      projects
      enabled
      name
      firstName
      lastName
      timezone
      language
      tempPassword
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      email
      clients
      role
      perms
      projects
      enabled
      name
      firstName
      lastName
      timezone
      language
      tempPassword
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClientDeleteQueue = /* GraphQL */ `
  mutation CreateClientDeleteQueue(
    $input: CreateClientDeleteQueueInput!
    $condition: ModelClientDeleteQueueConditionInput
  ) {
    createClientDeleteQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientDeleteQueue = /* GraphQL */ `
  mutation UpdateClientDeleteQueue(
    $input: UpdateClientDeleteQueueInput!
    $condition: ModelClientDeleteQueueConditionInput
  ) {
    updateClientDeleteQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientDeleteQueue = /* GraphQL */ `
  mutation DeleteClientDeleteQueue(
    $input: DeleteClientDeleteQueueInput!
    $condition: ModelClientDeleteQueueConditionInput
  ) {
    deleteClientDeleteQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      clientId
      client {
        id
        subdomain
        name
        colour
        logo
        image
        reportLogo
        reportFooter
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      description
      image
      timezone
      topStoriesViewAll
      dashboardChart1
      dashboardChart2
      dailyAlertAuto
      dailyAlertHour
      dailyAlertTopAll
      dailyAlertLastSent
      comprehendEnabled
      comprehendUsed
      socialEnabled
      socialUsed
      aiAnalystEnabled
      aiAnalystPromptWho
      aiAnalystPromptTopic
      aiAnalystPromptFocus
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProjectDeleteQueue = /* GraphQL */ `
  mutation CreateProjectDeleteQueue(
    $input: CreateProjectDeleteQueueInput!
    $condition: ModelProjectDeleteQueueConditionInput
  ) {
    createProjectDeleteQueue(input: $input, condition: $condition) {
      id
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProjectDeleteQueue = /* GraphQL */ `
  mutation UpdateProjectDeleteQueue(
    $input: UpdateProjectDeleteQueueInput!
    $condition: ModelProjectDeleteQueueConditionInput
  ) {
    updateProjectDeleteQueue(input: $input, condition: $condition) {
      id
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProjectDeleteQueue = /* GraphQL */ `
  mutation DeleteProjectDeleteQueue(
    $input: DeleteProjectDeleteQueueInput!
    $condition: ModelProjectDeleteQueueConditionInput
  ) {
    deleteProjectDeleteQueue(input: $input, condition: $condition) {
      id
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      social360Id
      name
      any
      inConnectionWithAny
      inConnectionWithAll
      none
      mediaTypes
      metabaseFilterId
      isMetafilter
      metabaseSearchBackfillCompleted
      metabaseSearchBackfillEndDate
      metabaseSearchBackfillSequenceId
      metabaseSearchBackfillDate
      newsSourceRanks
      socialSourceRanks
      sourceNames
      sourceNamesInclude
      sourceDomains
      sourceDomainsInclude
      sourceCategories
      sourceCategoriesInclude
      countries
      articleTopics
      articleTopicsInclude
      languages
      socialLastIndexedDate
      socialLastUpdatedDate
      socialBackfillDate
      socialLanguages
      isDraft
      isEnabled
      isDeleted
      hasWebSources
      hasSocialSources
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgentBackfillQueue = /* GraphQL */ `
  mutation CreateAgentBackfillQueue(
    $input: CreateAgentBackfillQueueInput!
    $condition: ModelAgentBackfillQueueConditionInput
  ) {
    createAgentBackfillQueue(input: $input, condition: $condition) {
      id
      agent {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgentBackfillQueue = /* GraphQL */ `
  mutation UpdateAgentBackfillQueue(
    $input: UpdateAgentBackfillQueueInput!
    $condition: ModelAgentBackfillQueueConditionInput
  ) {
    updateAgentBackfillQueue(input: $input, condition: $condition) {
      id
      agent {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgentBackfillQueue = /* GraphQL */ `
  mutation DeleteAgentBackfillQueue(
    $input: DeleteAgentBackfillQueueInput!
    $condition: ModelAgentBackfillQueueConditionInput
  ) {
    deleteAgentBackfillQueue(input: $input, condition: $condition) {
      id
      agent {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        any
        inConnectionWithAny
        inConnectionWithAll
        none
        mediaTypes
        metabaseFilterId
        isMetafilter
        metabaseSearchBackfillCompleted
        metabaseSearchBackfillEndDate
        metabaseSearchBackfillSequenceId
        metabaseSearchBackfillDate
        newsSourceRanks
        socialSourceRanks
        sourceNames
        sourceNamesInclude
        sourceDomains
        sourceDomainsInclude
        sourceCategories
        sourceCategoriesInclude
        countries
        articleTopics
        articleTopicsInclude
        languages
        socialLastIndexedDate
        socialLastUpdatedDate
        socialBackfillDate
        socialLanguages
        isDraft
        isEnabled
        isDeleted
        hasWebSources
        hasSocialSources
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $input: CreateResultInput!
    $condition: ModelResultConditionInput
  ) {
    createResult(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      source
      id
      createdDate
      publishedDate
      title
      content
      content100
      url
      image
      language
      sourceType
      sourceName
      sourceUrl
      authorName
      authorEmail
      outboundUrls
      wordCount
      sourceRank
      sourceCountry
      sourceRegion
      sourceCategory
      sourceFeedAutoRank
      sourceFeedAutoRankOrder
      sourceFeedInboundLinkCount
      sourceFeedAutoTopics
      sourceFeedEditorialTopics
      sourceFeedGenre
      copyright
      loginStatus
      mediaValue
      mediaValueCurrency
      mozrank
      reach
      reachFrequency
      articleTopics
      authorUrl
      authorImage
      authorGroups
      authorPosts
      authorFollowers
      authorFollowing
      socialInternalId
      socialEngagement
      socialReactions
      socialComments
      socialShares
      socialViews
      duplicateGroup
      duplicateGroupSource
      excerpt
      agents
      people
      organisations
      topics
      concerns
      sentiment
      trafficLight
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
      commentFilterId
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      pendingJobs
      includePublicFeed
      isTop
      isNotTop
      isDeleted
      searchParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $input: UpdateResultInput!
    $condition: ModelResultConditionInput
  ) {
    updateResult(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      source
      id
      createdDate
      publishedDate
      title
      content
      content100
      url
      image
      language
      sourceType
      sourceName
      sourceUrl
      authorName
      authorEmail
      outboundUrls
      wordCount
      sourceRank
      sourceCountry
      sourceRegion
      sourceCategory
      sourceFeedAutoRank
      sourceFeedAutoRankOrder
      sourceFeedInboundLinkCount
      sourceFeedAutoTopics
      sourceFeedEditorialTopics
      sourceFeedGenre
      copyright
      loginStatus
      mediaValue
      mediaValueCurrency
      mozrank
      reach
      reachFrequency
      articleTopics
      authorUrl
      authorImage
      authorGroups
      authorPosts
      authorFollowers
      authorFollowing
      socialInternalId
      socialEngagement
      socialReactions
      socialComments
      socialShares
      socialViews
      duplicateGroup
      duplicateGroupSource
      excerpt
      agents
      people
      organisations
      topics
      concerns
      sentiment
      trafficLight
      commentId
      commentContent
      commentUserId
      commentAiAnalyst
      commentFilterId
      aiSummary
      aiExcerpt
      aiAnalysis
      aiRecommendations
      pendingJobs
      includePublicFeed
      isTop
      isNotTop
      isDeleted
      searchParams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createResultComprehendQueue = /* GraphQL */ `
  mutation CreateResultComprehendQueue(
    $input: CreateResultComprehendQueueInput!
    $condition: ModelResultComprehendQueueConditionInput
  ) {
    createResultComprehendQueue(input: $input, condition: $condition) {
      id
      result {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      lock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateResultComprehendQueue = /* GraphQL */ `
  mutation UpdateResultComprehendQueue(
    $input: UpdateResultComprehendQueueInput!
    $condition: ModelResultComprehendQueueConditionInput
  ) {
    updateResultComprehendQueue(input: $input, condition: $condition) {
      id
      result {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      lock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteResultComprehendQueue = /* GraphQL */ `
  mutation DeleteResultComprehendQueue(
    $input: DeleteResultComprehendQueueInput!
    $condition: ModelResultComprehendQueueConditionInput
  ) {
    deleteResultComprehendQueue(input: $input, condition: $condition) {
      id
      result {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        source
        id
        createdDate
        publishedDate
        title
        content
        content100
        url
        image
        language
        sourceType
        sourceName
        sourceUrl
        authorName
        authorEmail
        outboundUrls
        wordCount
        sourceRank
        sourceCountry
        sourceRegion
        sourceCategory
        sourceFeedAutoRank
        sourceFeedAutoRankOrder
        sourceFeedInboundLinkCount
        sourceFeedAutoTopics
        sourceFeedEditorialTopics
        sourceFeedGenre
        copyright
        loginStatus
        mediaValue
        mediaValueCurrency
        mozrank
        reach
        reachFrequency
        articleTopics
        authorUrl
        authorImage
        authorGroups
        authorPosts
        authorFollowers
        authorFollowing
        socialInternalId
        socialEngagement
        socialReactions
        socialComments
        socialShares
        socialViews
        duplicateGroup
        duplicateGroupSource
        excerpt
        agents
        people
        organisations
        topics
        concerns
        sentiment
        trafficLight
        commentId
        commentContent
        commentUserId
        commentAiAnalyst
        commentFilterId
        aiSummary
        aiExcerpt
        aiAnalysis
        aiRecommendations
        pendingJobs
        includePublicFeed
        isTop
        isNotTop
        isDeleted
        searchParams
        createdAt
        updatedAt
        __typename
      }
      queue
      timestamp
      lock
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTranslation = /* GraphQL */ `
  mutation CreateTranslation(
    $input: CreateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    createTranslation(input: $input, condition: $condition) {
      clientId
      projectId
      id
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTranslation = /* GraphQL */ `
  mutation UpdateTranslation(
    $input: UpdateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    updateTranslation(input: $input, condition: $condition) {
      clientId
      projectId
      id
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      resultId
      id
      content
      userId
      aiAnalyst
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      resultId
      id
      content
      userId
      aiAnalyst
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      organisations
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      organisations
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      uniqueName
      alternativeNames
      tags
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      name
      uniqueName
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      name
      uniqueName
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFilter = /* GraphQL */ `
  mutation CreateFilter(
    $input: CreateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    createFilter(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      query
      isTop
      isVisible
      order
      issueAlertLastSent
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFilter = /* GraphQL */ `
  mutation UpdateFilter(
    $input: UpdateFilterInput!
    $condition: ModelFilterConditionInput
  ) {
    updateFilter(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      query
      isTop
      isVisible
      order
      issueAlertLastSent
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSearch = /* GraphQL */ `
  mutation CreateSearch(
    $input: CreateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    createSearch(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      query
      chartQuery
      resultsType
      filterName
      filterId
      skipFilterHasChangesCheck
      expire
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSearch = /* GraphQL */ `
  mutation UpdateSearch(
    $input: UpdateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    updateSearch(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      query
      chartQuery
      resultsType
      filterName
      filterId
      skipFilterHasChangesCheck
      expire
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChart = /* GraphQL */ `
  mutation CreateChart(
    $input: CreateChartInput!
    $condition: ModelChartConditionInput
  ) {
    createChart(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      metricType
      chartType
      resultsType
      dateRange
      startDate
      startTime
      endDate
      endTime
      comparisonStartDate
      comparisonStartTime
      comparisonEndDate
      comparisonEndTime
      aggSize
      showValues
      sortByValue
      hidden
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChart = /* GraphQL */ `
  mutation UpdateChart(
    $input: UpdateChartInput!
    $condition: ModelChartConditionInput
  ) {
    updateChart(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      metricType
      chartType
      resultsType
      dateRange
      startDate
      startTime
      endDate
      endTime
      comparisonStartDate
      comparisonStartTime
      comparisonEndDate
      comparisonEndTime
      aggSize
      showValues
      sortByValue
      hidden
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMailingList = /* GraphQL */ `
  mutation CreateMailingList(
    $input: CreateMailingListInput!
    $condition: ModelMailingListConditionInput
  ) {
    createMailingList(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      isExternal
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMailingList = /* GraphQL */ `
  mutation UpdateMailingList(
    $input: UpdateMailingListInput!
    $condition: ModelMailingListConditionInput
  ) {
    updateMailingList(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      name
      isExternal
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMailingListMember = /* GraphQL */ `
  mutation CreateMailingListMember(
    $input: CreateMailingListMemberInput!
    $condition: ModelMailingListMemberConditionInput
  ) {
    createMailingListMember(input: $input, condition: $condition) {
      clientId
      projectId
      listId
      list {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        isExternal
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      email
      firstName
      lastName
      company
      country
      isUnsubscribed
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMailingListMember = /* GraphQL */ `
  mutation UpdateMailingListMember(
    $input: UpdateMailingListMemberInput!
    $condition: ModelMailingListMemberConditionInput
  ) {
    updateMailingListMember(input: $input, condition: $condition) {
      clientId
      projectId
      listId
      list {
        clientId
        projectId
        project {
          clientId
          id
          social360Id
          name
          description
          image
          timezone
          topStoriesViewAll
          dashboardChart1
          dashboardChart2
          dailyAlertAuto
          dailyAlertHour
          dailyAlertTopAll
          dailyAlertLastSent
          comprehendEnabled
          comprehendUsed
          socialEnabled
          socialUsed
          aiAnalystEnabled
          aiAnalystPromptWho
          aiAnalystPromptTopic
          aiAnalystPromptFocus
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        name
        isExternal
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      email
      firstName
      lastName
      company
      country
      isUnsubscribed
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      title
      subject
      showHeader
      sentAt
      isDeleted
      blocks {
        items {
          clientId
          projectId
          reportId
          id
          type
          auto
          autoFilter
          title
          subtitle
          text
          image
          imageCaption
          imageLink
          chartId
          bannerShowTitle
          contentsInclude
          pdfPageBreakBefore
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sends {
        items {
          clientId
          projectId
          reportId
          id
          isPreview
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      source
      name
      title
      subject
      showHeader
      sentAt
      isDeleted
      blocks {
        items {
          clientId
          projectId
          reportId
          id
          type
          auto
          autoFilter
          title
          subtitle
          text
          image
          imageCaption
          imageLink
          chartId
          bannerShowTitle
          contentsInclude
          pdfPageBreakBefore
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sends {
        items {
          clientId
          projectId
          reportId
          id
          isPreview
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportBlock = /* GraphQL */ `
  mutation CreateReportBlock(
    $input: CreateReportBlockInput!
    $condition: ModelReportBlockConditionInput
  ) {
    createReportBlock(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      id
      type
      auto
      autoFilter
      title
      subtitle
      text
      image
      imageCaption
      imageLink
      chartId
      bannerShowTitle
      contentsInclude
      pdfPageBreakBefore
      order
      results {
        items {
          clientId
          projectId
          reportId
          reportBlockId
          id
          type
          resultId
          source
          publishedDate
          title
          excerpt
          url
          image
          sourceName
          sourceType
          authorImage
          tableColumn1
          tableColumn2
          showImage
          imageOverride
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportBlock = /* GraphQL */ `
  mutation UpdateReportBlock(
    $input: UpdateReportBlockInput!
    $condition: ModelReportBlockConditionInput
  ) {
    updateReportBlock(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      id
      type
      auto
      autoFilter
      title
      subtitle
      text
      image
      imageCaption
      imageLink
      chartId
      bannerShowTitle
      contentsInclude
      pdfPageBreakBefore
      order
      results {
        items {
          clientId
          projectId
          reportId
          reportBlockId
          id
          type
          resultId
          source
          publishedDate
          title
          excerpt
          url
          image
          sourceName
          sourceType
          authorImage
          tableColumn1
          tableColumn2
          showImage
          imageOverride
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReportBlock = /* GraphQL */ `
  mutation DeleteReportBlock(
    $input: DeleteReportBlockInput!
    $condition: ModelReportBlockConditionInput
  ) {
    deleteReportBlock(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      id
      type
      auto
      autoFilter
      title
      subtitle
      text
      image
      imageCaption
      imageLink
      chartId
      bannerShowTitle
      contentsInclude
      pdfPageBreakBefore
      order
      results {
        items {
          clientId
          projectId
          reportId
          reportBlockId
          id
          type
          resultId
          source
          publishedDate
          title
          excerpt
          url
          image
          sourceName
          sourceType
          authorImage
          tableColumn1
          tableColumn2
          showImage
          imageOverride
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportResult = /* GraphQL */ `
  mutation CreateReportResult(
    $input: CreateReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    createReportResult(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      reportBlockId
      id
      type
      resultId
      source
      publishedDate
      title
      excerpt
      url
      image
      sourceName
      sourceType
      authorImage
      tableColumn1
      tableColumn2
      showImage
      imageOverride
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportResult = /* GraphQL */ `
  mutation UpdateReportResult(
    $input: UpdateReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    updateReportResult(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      reportBlockId
      id
      type
      resultId
      source
      publishedDate
      title
      excerpt
      url
      image
      sourceName
      sourceType
      authorImage
      tableColumn1
      tableColumn2
      showImage
      imageOverride
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReportResult = /* GraphQL */ `
  mutation DeleteReportResult(
    $input: DeleteReportResultInput!
    $condition: ModelReportResultConditionInput
  ) {
    deleteReportResult(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      reportBlockId
      id
      type
      resultId
      source
      publishedDate
      title
      excerpt
      url
      image
      sourceName
      sourceType
      authorImage
      tableColumn1
      tableColumn2
      showImage
      imageOverride
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportSend = /* GraphQL */ `
  mutation CreateReportSend(
    $input: CreateReportSendInput!
    $condition: ModelReportSendConditionInput
  ) {
    createReportSend(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      id
      isPreview
      recipients {
        items {
          clientId
          projectId
          reportSendId
          id
          email
          mailingListMemberId
          firstName
          lastName
          company
          country
          sentAt
          openedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportSend = /* GraphQL */ `
  mutation UpdateReportSend(
    $input: UpdateReportSendInput!
    $condition: ModelReportSendConditionInput
  ) {
    updateReportSend(input: $input, condition: $condition) {
      clientId
      projectId
      reportId
      id
      isPreview
      recipients {
        items {
          clientId
          projectId
          reportSendId
          id
          email
          mailingListMemberId
          firstName
          lastName
          company
          country
          sentAt
          openedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportSendRecipient = /* GraphQL */ `
  mutation CreateReportSendRecipient(
    $input: CreateReportSendRecipientInput!
    $condition: ModelReportSendRecipientConditionInput
  ) {
    createReportSendRecipient(input: $input, condition: $condition) {
      clientId
      projectId
      reportSendId
      reportSend {
        clientId
        projectId
        reportId
        id
        isPreview
        recipients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      email
      mailingListMemberId
      firstName
      lastName
      company
      country
      sentAt
      openedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportSendRecipient = /* GraphQL */ `
  mutation UpdateReportSendRecipient(
    $input: UpdateReportSendRecipientInput!
    $condition: ModelReportSendRecipientConditionInput
  ) {
    updateReportSendRecipient(input: $input, condition: $condition) {
      clientId
      projectId
      reportSendId
      reportSend {
        clientId
        projectId
        reportId
        id
        isPreview
        recipients {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      email
      mailingListMemberId
      firstName
      lastName
      company
      country
      sentAt
      openedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailQueue = /* GraphQL */ `
  mutation CreateEmailQueue(
    $input: CreateEmailQueueInput!
    $condition: ModelEmailQueueConditionInput
  ) {
    createEmailQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      lock
      template
      email
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailQueue = /* GraphQL */ `
  mutation UpdateEmailQueue(
    $input: UpdateEmailQueueInput!
    $condition: ModelEmailQueueConditionInput
  ) {
    updateEmailQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      lock
      template
      email
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailQueue = /* GraphQL */ `
  mutation DeleteEmailQueue(
    $input: DeleteEmailQueueInput!
    $condition: ModelEmailQueueConditionInput
  ) {
    deleteEmailQueue(input: $input, condition: $condition) {
      id
      queue
      timestamp
      lock
      template
      email
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      status
      data
      result
      expire
      resultId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      clientId
      projectId
      project {
        clientId
        client {
          id
          subdomain
          name
          colour
          logo
          image
          reportLogo
          reportFooter
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        id
        social360Id
        name
        description
        image
        timezone
        topStoriesViewAll
        dashboardChart1
        dashboardChart2
        dailyAlertAuto
        dailyAlertHour
        dailyAlertTopAll
        dailyAlertLastSent
        comprehendEnabled
        comprehendUsed
        socialEnabled
        socialUsed
        aiAnalystEnabled
        aiAnalystPromptWho
        aiAnalystPromptTopic
        aiAnalystPromptFocus
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      status
      data
      result
      expire
      resultId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlertSubscription = /* GraphQL */ `
  mutation CreateAlertSubscription(
    $input: CreateAlertSubscriptionInput!
    $condition: ModelAlertSubscriptionConditionInput
  ) {
    createAlertSubscription(input: $input, condition: $condition) {
      clientId
      projectId
      userId
      user {
        id
        email
        clients
        role
        perms
        projects
        enabled
        name
        firstName
        lastName
        timezone
        language
        tempPassword
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlertSubscription = /* GraphQL */ `
  mutation UpdateAlertSubscription(
    $input: UpdateAlertSubscriptionInput!
    $condition: ModelAlertSubscriptionConditionInput
  ) {
    updateAlertSubscription(input: $input, condition: $condition) {
      clientId
      projectId
      userId
      user {
        id
        email
        clients
        role
        perms
        projects
        enabled
        name
        firstName
        lastName
        timezone
        language
        tempPassword
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlertSubscription = /* GraphQL */ `
  mutation DeleteAlertSubscription(
    $input: DeleteAlertSubscriptionInput!
    $condition: ModelAlertSubscriptionConditionInput
  ) {
    deleteAlertSubscription(input: $input, condition: $condition) {
      clientId
      projectId
      userId
      user {
        id
        email
        clients
        role
        perms
        projects
        enabled
        name
        firstName
        lastName
        timezone
        language
        tempPassword
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      id
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;

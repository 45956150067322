import find from "lodash/find";
import { getPublicApiUrlPrefix } from "./constants";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import orderBy from "lodash/orderBy";

// used in downloads/exports
export const getExportDate = () =>
  new Date().toISOString().replace("T", "-").replace("Z", "").replace(/:/g, "-").substr(0, 19);

export const getTimestamp = () => Math.floor(new Date() / 1000);
export const getTimestamp7Days = () => Math.floor(new Date() / 1000) + 86400 * 7;

// get paginated results from AWS SDK
// https://advancedweb.hu/how-to-paginate-the-aws-js-sdk-using-async-generators/
export const getPaginatedResults = async (fn) => {
  const EMPTY = Symbol("empty");
  const res = [];
  for await (const lf of (async function* () {
    let NextMarker = EMPTY;
    while (NextMarker || NextMarker === EMPTY) {
      const { marker, results } = await fn(NextMarker !== EMPTY ? NextMarker : undefined);
      yield* results;
      NextMarker = marker;
    }
  })()) {
    res.push(lf);
  }
  return res;
};

export const DATE_FORMAT = "D MMMM YYYY";
export const DATE_FORMAT_SHORT = "DD/MM/YYYY";

// format utc date for display in local timezone or utc
export const formatDate = (timestamp, tz = "UTC", short = false) => {
  const format = short ? DATE_FORMAT_SHORT : DATE_FORMAT;
  return moment.utc(timestamp).tz(tz).format(format);
};

export const DATETIME_FORMAT = "D MMMM YYYY HH:mm";
export const DATETIME_FORMAT_SHORT = "DD/MM/YYYY HH:mm";

// format utc datetime for display in local timezone or utc
export const formatDateTime = (timestamp, tz = "UTC", short = false) => {
  const format = short ? DATETIME_FORMAT_SHORT : DATETIME_FORMAT;
  return moment.utc(timestamp).tz(tz).format(format);
};

// sort `values` array of objects by single `key` asc, case insensitive, handle non-strings
export const sort = (values, key, direction = "asc") => {
  return orderBy(values, [(x) => (typeof x[key] === "string" ? x[key].toLowerCase() : x[key])], [direction]);
};

// sort `values` array by value, case insensitive
export const sortArray = (values) => values.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

// sort orderable, numbers ascending, then nulls last, fallback to name for unordered
export const sortOrderable = (values) => {
  return orderBy(values, ["order", "name"], ["asc", "asc"]);
};

// get name of a linked item by id, null if not found (e.g. has been deleted so not in projectData)
export const getLinkedItemName = (items, id, defaultName = null) => {
  if (!id) return defaultName;
  const obj = find(items || [], (x) => x.id === id);
  return obj ? obj.name : defaultName;
};

// get names of linked items by ids, null if not found (e.g. has been deleted so not in projectData)
export const getLinkedItemNames = (items, ids, defaultNames = null) => {
  if (!(ids && ids.length)) return defaultNames;
  const result = [];
  ids.forEach((id) => {
    const obj = find(items || [], (x) => x.id === id);
    if (obj) result.push(obj.name);
  });
  if (!result.length) return defaultNames;
  return sortArray(result);
};

export const getResultSourceName = (source) => {
  if (source === "ALL") return "all";
  if (source === "METABASE") return "news";
  if (source === "SOCIAL360") return "social";
  throw new Error("Invalid source in getResultSourceName");
};

// social charts can be on influencer or overall coverage, but we only have influencer results stored
export const getResultSourceNameForCharts = (source) => {
  if (source === "METABASE") return "news";
  if (source === "SOCIAL360") return "social influencer";
  if (source === "SOCIAL360_COVERAGE") return "social coverage";
  throw new Error("Invalid source in getResultSourceNameForCharts");
};

// get prefix based on source e.g. `results` or `social`
export const getResultPrefixForSource = (source) => {
  if (source === "METABASE") return "results";
  if (source === "SOCIAL360") return "social";
  throw new Error("Invalid source in getResultPrefixForSource");
};

// get source based on prefix
export const getResultSourceFromPrefix = (prefix) => {
  if (prefix === "results") return "METABASE";
  if (prefix === "social") return "SOCIAL360";
  throw new Error("Invalid source in getResultSourceFromPrefix");
};

// get source based on public feed param
export const getResultSourceFromPublicFeed = (param) => {
  if (param === "news") return "METABASE";
  if (param === "social") return "SOCIAL360";
  if (param === "all") return "ALL";
  throw new Error("Invalid source in getResultSourceFromPublicFeed");
};

// link to app for non-url results or NLA results
export const getResultExternalUrl = (result, resultsType, clientSubdomain) => {
  if (!result.url || (result.url && result.url.includes("nla-eclipsweb"))) {
    return `${getPublicApiUrlPrefix(clientSubdomain)}/${result.clientId}/${result.projectId}/${getResultPrefixForSource(
      result.source
    )}/${resultsType}/${result.id}`;
  }
  return result.url;
};

export const getProjectHelpers = (project) => {
  // sources of results enabled on project
  const resultsSources = ["METABASE"];
  const resultsSourcesForCharts = ["METABASE"];
  if (project.socialUsed) {
    resultsSources.push("SOCIAL360");
    resultsSourcesForCharts.push("SOCIAL360");
    resultsSourcesForCharts.push("SOCIAL360_COVERAGE");
  }

  return {
    comprehendEnabled: project.comprehendEnabled,
    comprehendUsed: project.comprehendUsed,
    socialEnabled: project.socialEnabled,
    socialUsed: project.socialUsed,
    resultsSources,
    resultsSourcesForCharts,
  };
};

export const getSentimentTrafficLightColour = (value) =>
  ({
    NEGATIVE: "#eb5757",
    NEUTRAL: "#2d9cdb",
    POSITIVE: "#219653",
    THREAT: "#eb5757",
    ALERT: "#f2994a",
    OPPORTUNITY: "#219653",
  }[value.toUpperCase()] || null);

// list of chart types and display names for chart builder, in the order required
// valid chart types for each metric are worked out from CONFIG (excludes the "quick" types)
// NOTE: the `value`s must match the values used in the `options` in FILTER_TYPES for clickable charts to work (except for frequency charts)
export const BUILDER_METRIC_TYPE_OPTIONS_METABASE = [
  {
    label: "Frequency",
    options: [
      { value: "keywordFrequency", label: "Keyword Frequency" },
      { value: "issueFrequency", label: "Issue Frequency" },
      { value: "resultFrequency", label: "Result Frequency" },
      { value: "reachFrequency", label: "Reach Frequency" },
    ],
  },
  {
    label: "Article",
    options: [
      { value: "mediaType", label: "Media Type" },
      { value: "articleTopic", label: "Topic" },
      { value: "language", label: "Language" },
      { value: "mediaValue", label: "Total Media Value" },
      { value: "reach", label: "Total Reach" },
    ],
  },
  {
    label: "Source",
    options: [
      { value: "sourceName", label: "Source Name" },
      { value: "authorName", label: "Author Name" },
      { value: "category", label: "Source Category" },
      { value: "country", label: "Source Country" },
      { value: "sourceRank", label: "Source Rank" },
    ],
  },
  {
    label: "Tags",
    options: [
      { value: "sentiment", label: "Sentiment" },
      { value: "trafficLight", label: "Traffic Light" },
      { value: "topic", label: "Topic Tag" },
      { value: "concern", label: "Key Concern Tag" },
    ],
  },
  {
    label: "Stakeholders", // update ChartModal if this label is changed
    options: [
      { value: "person", label: "People" },
      { value: "organisation", label: "Organisations" },
      { value: "entityGroup", label: "Stakeholder Groups" },
    ],
  },
];

// social360 influencers
export const BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360 = [
  {
    label: "Frequency",
    options: [
      { value: "keywordFrequency", label: "Keyword Frequency" },
      { value: "issueFrequency", label: "Issue Frequency" },
      { value: "resultFrequency", label: "Result Frequency" },
      { value: "engagementFrequency", label: "Engagement Frequency" },
    ],
  },
  {
    label: "Article",
    options: [
      { value: "mediaType", label: "Media Type" },
      { value: "articleTopic", label: "Topic" },
      { value: "language", label: "Language" },
      { value: "socialEngagement", label: "Total Engagement" },
    ],
  },
  {
    label: "Source",
    options: [
      { value: "sourceName", label: "Source Name" },
      { value: "authorGroups", label: "Author Groups" },
      { value: "authorName", label: "Author Name" },
    ],
  },
  {
    label: "Tags",
    options: [
      { value: "sentiment", label: "Sentiment" },
      { value: "trafficLight", label: "Traffic Light" },
      { value: "topic", label: "Topic Tag" },
      { value: "concern", label: "Key Concern Tag" },
    ],
  },
];

// social360 overall coverage
export const BUILDER_METRIC_TYPE_OPTIONS_SOCIAL360_COVERAGE = [
  {
    label: "Frequency",
    options: [
      { value: "keywordFrequency", label: "Keyword Frequency" },
      { value: "agentFrequency", label: "Agent Frequency" },
      { value: "resultFrequency", label: "Result Frequency" },
      { value: "engagementFrequency", label: "Engagement Frequency" },
    ],
  },
  {
    label: "Article",
    options: [{ value: "mediaType", label: "Media Type" }],
  },
  {
    label: "Tags",
    options: [{ value: "sentiment", label: "Sentiment" }],
  },
];

// all valid chart types, filtered down later
export const BUILDER_CHART_TYPE_OPTIONS = [
  { value: "pie", label: "Pie" },
  { value: "line", label: "Line" },
  { value: "bar", label: "Bar" },
  { value: "stackedBar", label: "Stacked Bar" },
  { value: "multiBar", label: "Multi Bar" },
  { value: "column", label: "Column" },
  { value: "stackedColumn", label: "Stacked Column" },
  { value: "multiColumn", label: "Multi Column" },
  { value: "wordcloud", label: "Word Cloud" },
];

// chart builder metric types that do not support top/manualtop
export const BUILDER_METRIC_TYPE_NOT_SUPPORTS_TOP = ["person", "organisation"];

// chart builder chart types that support comparison series
export const BUILDER_CHART_TYPE_SUPPORTS_COMPARISON = ["bar", "column"];

// height of charts on results pages
export const HEIGHT = 400;

// config for news/social results
// chart types starting "quick" are only used in the results pages
// OUT-89 aggSize default value which can be overridden on user-created charts if aggSizeEditable is true
export const CONFIG_RESULTS = {
  sentiment: {
    config: {
      aggField: "sentiment.keyword",
      aggSize: 4,
      aggSizeEditable: false,
      nameFormat: "titleCase",
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    line: {
      aggType: "termThenTime",
      processType: "termThenTime",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  trafficLight: {
    config: {
      aggField: "trafficLight.keyword",
      aggSize: 3,
      aggSizeEditable: false,
      nameFormat: "titleCase",
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    line: {
      aggType: "termThenTime",
      processType: "termThenTime",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  keywordFrequency: {
    config: {},
    pie: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    line: {
      aggType: "time",
      processType: "time",
      seriesTypes: ["keywords"],
    },
    bar: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    column: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    wordcloud: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
  },

  issueFrequency: {
    config: {},
    line: {
      aggType: "time",
      processType: "time",
      seriesTypes: ["issues"],
    },
    bar: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["issues"],
    },
    quickDashboardLineIssues: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["issues"],
    },
    // all topped results in each issue
    // (but if issue is not a top story issue, only keep manually topped)
    quickTopStoriesDashboardLineAll: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["issuesTopAll"],
    },
    // manually topped results in each issue
    quickTopStoriesDashboardLineManual: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["issuesTopManual"],
    },
  },

  resultFrequency: {
    config: {},
    // frequency will be based on current results set
    line: {
      aggType: "time",
      processType: "time",
    },
    // force series to all/top/issues
    quickResultsPanelLineAll: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["all", "top", "issues"],
    },
    // force series to top
    quickResultsPanelLineTop: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["top"],
    },
    // force series to manual top
    quickResultsPanelLineManualTop: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["manualtop"],
    },
    // force series to issues, which is filtered to current issue
    quickResultsPanelLineIssue: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["issues"],
    },
  },

  reachFrequency: {
    config: {
      aggField: "reach",
    },
    line: {
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
    quickDashboardLineIssues: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
    // force series to all/top/issues
    quickResultsPanelLineAll: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["all", "top", "issues"],
    },
    // force series to top
    quickResultsPanelLineTop: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["top"],
    },
    // force series to manual top
    quickResultsPanelLineManualTop: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["manualtop"],
    },
    // force series to issues, which is filtered to current issue
    quickResultsPanelLineIssue: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
  },

  // exactly as reachFrequency but different aggField
  engagementFrequency: {
    config: {
      aggField: "socialEngagement",
    },
    line: {
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
    quickDashboardLineIssues: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
    // force series to all/top/issues
    quickResultsPanelLineAll: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["all", "top", "issues"],
    },
    // force series to top
    quickResultsPanelLineTop: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["top"],
    },
    // force series to manual top
    quickResultsPanelLineManualTop: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["manualtop"],
    },
    // force series to issues, which is filtered to current issue
    quickResultsPanelLineIssue: {
      highchartsType: "line",
      aggType: "timeThenSum",
      processType: "timeThenSum",
      seriesTypes: ["issues"],
    },
  },

  mediaType: {
    config: {
      aggField: "sourceType.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  sourceName: {
    config: {
      aggField: "sourceName.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  authorName: {
    config: {
      aggField: "authorName.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  authorGroups: {
    config: {
      aggField: "authorGroups.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  category: {
    config: {
      aggField: "sourceCategory.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "term",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      aggType: "term",
      processType: "termStacked",
      seriesTypes: ["issues"],
    },
  },

  country: {
    config: {
      aggField: "sourceCountry.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  sourceRank: {
    config: {
      aggField: "sourceRank",
      aggSize: 10,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    line: {
      aggType: "termThenTime",
      processType: "termThenTime",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  articleTopic: {
    config: {
      aggField: "articleTopics.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  language: {
    config: {
      aggField: "language.keyword",
      aggSize: 20,
      aggSizeEditable: true,
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  topic: {
    config: {
      aggField: "topics.keyword",
      aggSize: 20,
      aggSizeEditable: true,
      projectDataLookup: "tags",
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  concern: {
    config: {
      aggField: "concerns.keyword",
      aggSize: 20,
      aggSizeEditable: true,
      projectDataLookup: "tags",
    },
    pie: {
      aggType: "term",
      processType: "term",
    },
    bar: {
      aggType: "term",
      processType: "term",
    },
    column: {
      aggType: "term",
      processType: "term",
    },
  },

  person: {
    config: {
      aggSize: 10,
      aggSizeEditable: true,
      projectDataLookup: "people",
    },
    pie: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["people"],
    },
    line: {
      aggType: "time",
      processType: "time",
      seriesTypes: ["people"],
    },
    quickStakeholdersPanelPieAll: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["people"],
      stakeholderType: "all",
    },
    quickStakeholdersPanelLineAll: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["people"],
      stakeholderType: "all",
    },
    quickStakeholdersPanelPieFollowed: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["people"],
      stakeholderType: "followed",
    },
    quickStakeholdersPanelLineFollowed: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["people"],
      stakeholderType: "followed",
    },
    quickStakeholdersPanelPieTagged: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["people"],
      stakeholderType: "tagged",
    },
    quickStakeholdersPanelLineTagged: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["people"],
      stakeholderType: "tagged",
    },
  },

  organisation: {
    config: {
      aggSize: 10,
      aggSizeEditable: true,
      projectDataLookup: "organisations",
    },
    pie: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["organisations"],
    },
    line: {
      aggType: "time",
      processType: "time",
      seriesTypes: ["organisations"],
    },
    quickStakeholdersPanelPieAll: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["organisations"],
      stakeholderType: "all",
    },
    quickStakeholdersPanelLineAll: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["organisations"],
      stakeholderType: "all",
    },
    quickStakeholdersPanelPieFollowed: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["organisations"],
      stakeholderType: "followed",
    },
    quickStakeholdersPanelLineFollowed: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["organisations"],
      stakeholderType: "followed",
    },
    quickStakeholdersPanelPieTagged: {
      highchartsType: "pie",
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["organisations"],
      stakeholderType: "tagged",
    },
    quickStakeholdersPanelLineTagged: {
      highchartsType: "line",
      aggType: "time",
      processType: "time",
      seriesTypes: ["organisations"],
      stakeholderType: "tagged",
    },
  },

  entityGroup: {
    config: {
      projectDataLookup: "tags",
    },
    pie: {
      aggType: "count",
      processType: "countOrSum",
      seriesTypes: ["entityGroups"],
    },
    line: {
      aggType: "time",
      processType: "time",
      seriesTypes: ["entityGroups"],
    },
  },

  mediaValue: {
    config: {
      aggField: "mediaValue",
    },
    pie: {
      aggType: "sum",
      processType: "countOrSum",
    },
    bar: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiBar: {
      highchartsType: "bar",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiColumn: {
      highchartsType: "column",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
  },

  reach: {
    config: {
      aggField: "reach",
    },
    pie: {
      aggType: "sum",
      processType: "countOrSum",
    },
    bar: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiBar: {
      highchartsType: "bar",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiColumn: {
      highchartsType: "column",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
  },

  // exactly as reach but different aggField
  socialEngagement: {
    config: {
      aggField: "socialEngagement",
    },
    pie: {
      aggType: "sum",
      processType: "countOrSum",
    },
    bar: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiBar: {
      highchartsType: "bar",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
    column: {
      aggType: "sum",
      processType: "countOrSum",
    },
    multiColumn: {
      highchartsType: "column",
      aggType: "sum",
      processType: "countOrSumMulti",
      seriesTypes: ["issues"],
    },
  },
};

// config for social coverage
// `line/bar/pie` are the only chart types supported by API so use `socialAPIChartType` to override
export const CONFIG_SOCIAL360_COVERAGE = {
  sentiment: {
    config: {
      socialAPI: {
        type: "split",
        field: "sentiment",
      },
      nameFormat: "titleCase",
    },
    pie: {
      processType: "term",
    },
    line: {
      processType: "termThenTime",
    },
    bar: {
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      socialAPIChartType: "bar",
      processType: "termStacked",
      seriesTypes: ["agents"],
    },
    column: {
      socialAPIChartType: "bar",
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      socialAPIChartType: "bar",
      processType: "termStacked",
      seriesTypes: ["agents"],
    },
  },

  keywordFrequency: {
    config: {
      socialAPI: {
        type: "count",
      },
    },
    pie: {
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    line: {
      processType: "time",
      seriesTypes: ["keywords"],
    },
    bar: {
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    column: {
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
    wordcloud: {
      processType: "countOrSum",
      seriesTypes: ["keywords"],
    },
  },

  agentFrequency: {
    config: {
      socialAPI: {
        type: "count",
      },
    },
    line: {
      processType: "time",
      seriesTypes: ["agents"],
    },
    bar: {
      processType: "countOrSum",
      seriesTypes: ["agents"],
    },
    column: {
      socialAPIChartType: "bar",
      processType: "countOrSum",
      seriesTypes: ["agents"],
    },
  },

  resultFrequency: {
    config: {
      socialAPI: {
        type: "count",
      },
    },
    line: {
      processType: "time",
    },
  },

  engagementFrequency: {
    config: {
      socialAPI: {
        type: "sum",
        field: "engagement_count",
      },
    },
    line: {
      processType: "time",
      seriesTypes: ["agents"],
    },
  },

  mediaType: {
    config: {
      socialAPI: {
        type: "split",
        field: "feed_type",
      },
    },
    pie: {
      processType: "term",
    },
    bar: {
      processType: "term",
    },
    stackedBar: {
      highchartsType: "bar",
      socialAPIChartType: "bar",
      processType: "termStacked",
      seriesTypes: ["agents"],
    },
    column: {
      processType: "term",
    },
    stackedColumn: {
      highchartsType: "column",
      socialAPIChartType: "bar",
      processType: "termStacked",
      seriesTypes: ["agents"],
    },
  },
};

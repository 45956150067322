import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import React from "react";
import { confirmable } from "react-confirm";

const ConfirmModal = ({ show, proceed, message }) => (
  <Modal className="modal-dialog-centered modal-confirmable" isOpen={show}>
    <ModalHeader toggle={() => proceed(false)}>Confirm</ModalHeader>
    <ModalBody>{message ? message : "Are you sure?"}</ModalBody>
    <ModalFooter>
      <Button className="mr-auto" onClick={() => proceed(false)}>
        Cancel
      </Button>
      <Button color="danger" onClick={() => proceed(true)}>
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
);

export default confirmable(ConfirmModal);

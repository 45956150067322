import { Button, Card, CardBody, Form, Nav, NavItem, Spinner } from "reactstrap";
import React, { useContext } from "react";
import { fnReport, updateReportResult } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import FormEditor from "../Forms/FormEditor";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";

const TableRowPanel = ({ op, data, setIsUpdating, onClose }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId, reportId } = useParams();
  const [mutationFnReport] = useMutation(gql(fnReport));
  const [mutationUpdateReportResult] = useMutation(gql(updateReportResult));

  const initial = op === "CREATE" ? {} : data;

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      editors: ["tableColumn1", "tableColumn2"],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      // mutations going through fnReport require a refresh
      let refetch = false;
      setIsUpdating(true);
      const formData = await processForm(values, {
        editors: ["tableColumn1", "tableColumn2"],
      });
      if (op === "CREATE") {
        const input = { reportId, reportBlockId: data.id, id: uuid(), ...formData };
        await mutationFnReport({
          variables: { input: { clientId, projectId, op: "TABLEROW_CREATE", data: JSON.stringify(input) } },
        });
        refetch = true;
      } else {
        const input = { clientId, projectId, reportId, reportBlockId: data.reportBlockId, id: data.id, ...formData };
        await mutationUpdateReportResult({ variables: { input } });
      }
      onClose(refetch);
    } catch (e) {
      setIsUpdating(false);
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="side-panel side-panel--white vh-with-title">
        <Nav tabs className="nav-bordered">
          <NavItem className="flex-grow-1">
            <h4 className="side-panel-title text-title mt-0 mb-0">{title} Table Row</h4>
          </NavItem>
          <NavItem className="ml-auto side-panel--buttons">
            <Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Save
            </Button>
            <Button color="secondary" size="sm" className="side-panel--close-button-sm" onClick={() => onClose(false)}>
              <i className="uil-times" />
            </Button>
          </NavItem>
        </Nav>

        <CardBody className="pb-2 scroll">
          <FormEditor name="tableColumn1" label="Left column" formProps={formProps} />
          <FormEditor name="tableColumn2" label="Right column" formProps={formProps} />
        </CardBody>
      </Card>
    </Form>
  );
};

export default TableRowPanel;

import React from "react";
import { Redirect } from "react-router";

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

// force a route re-render, used in two situations
// - state has been passed to the url (e.g. redirectAlert) and we need to clear it
// - props provided into the route (e.g. project, user) have changed, BUT we don't have a subscription, and the route needs to update
export const refreshRoute = (history) => history.replace(history.location.pathname);

// reordering for dnd
export const reorderDraggables = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// if `obj` doesn't exist, or is soft deleted, redirect with alert
export const handleDeleted = (obj, type, url, messageOverride) => {
  if (!obj || (obj && obj.isDeleted)) {
    return (
      <Redirect
        to={{
          pathname: url,
          state: {
            redirectAlert: messageOverride
              ? messageOverride
              : `The ${type} you are trying to access does not exist or has been deleted.`,
          },
        }}
      />
    );
  }
};

// if search/filter source is not as expected (on different type of results page), redirect with alert
export const handleSource = (actual, expected, type, url, messageOverride) => {
  if (actual !== expected) {
    return (
      <Redirect
        to={{
          pathname: url,
          state: {
            redirectAlert: messageOverride
              ? messageOverride
              : `The ${type} you are trying to access does not exist or has been deleted.`,
          },
        }}
      />
    );
  }
};

// get name of breakpoint from effects
export const getBreakpoint = (downSm, downMd, downLg, downLgXl, downXl) =>
  downSm ? "xs" : downMd ? "sm" : downLg ? "md" : downLgXl ? "lg" : downXl ? "lgXl" : "xl";

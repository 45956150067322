import { Card, CardBody, Table } from "reactstrap";
import { formatDateTime, sort } from "../../lib/utils";
import { gql, useQuery } from "@apollo/client";

import React from "react";
import ReportTitle from "./ReportTitle";
import Spinner from "../../components/Spinner";
import { boolFormatter } from "../lib/tables";
import classNames from "classnames";
import { getPublicApiUrlPrefix } from "../../lib/constants";
import { getReportStats } from "../../lib/reports";
import pull from "lodash/pull";

const SendTable = ({ report, send, isLast, user, client }) => {
  const recipients = sort(send.recipients.items, "email", "asc");

  const renderRecipientDetails = (recipient) => {
    const str = pull(
      [
        recipient.firstName && recipient.lastName ? `${recipient.firstName} ${recipient.lastName}` : null,
        recipient.company,
        recipient.country,
      ],
      null
    ).join(", ");
    return str ? <span className="d-block text-muted">{str}</span> : null;
  };

  const renderTick = (check) => boolFormatter(check);

  return (
    <Card className={classNames({ "card-table": true, "mb-0": isLast })}>
      <CardBody className="p-0">
        <Table className="mb-0">
          <thead>
            <tr>
              <td className="pl-3 pr-3 font-bold">
                {formatDateTime(send.createdAt, user.timezone)}
                <i
                  className="uil-eye click ml-1"
                  style={{ opacity: 0.5 }}
                  onClick={() =>
                    window.open(
                      `${getPublicApiUrlPrefix(client.subdomain)}/report/web/${report.clientId}/${report.projectId}/${
                        report.id
                      }/${send.id}`
                    )
                  }
                />
              </td>
              <td className="pl-3 pr-3 text-center text-muted">Sent</td>
              <td className="pl-3 pr-3 text-center text-muted">Opened</td>
            </tr>
          </thead>
          <tbody>
            {recipients.length === 0 && (
              <tr className="empty">
                <td className="pl-3 pr-3 text-muted">There are no recipients to display.</td>
              </tr>
            )}
            {recipients.map((recipient) => (
              <tr key={recipient.id}>
                <td className="pl-3 pr-3 w-100 align-middle">
                  <span className="font-bold">{recipient.email}</span>
                  {renderRecipientDetails(recipient)}
                </td>
                <td className="pl-3 pr-3 text-center align-middle">{renderTick(recipient.sentAt)}</td>
                <td className="pl-3 pr-3 text-center align-middle">{renderTick(recipient.openedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const ReportStatistics = ({ report, user, client, projectData }) => {
  // get report stats
  const { loading, data } = useQuery(gql(getReportStats), {
    variables: { id: report.id },
    // load every time this page is visited and keep refreshing
    fetchPolicy: "network-only",
    pollInterval: 2_000,
  });

  if (loading) {
    return <Spinner className="loading-full" />;
  }

  const reportStats = data.getReport;

  // don't show preview sends
  const reportSends = sort(
    reportStats.sends.items.filter((x) => !x.isPreview),
    "createdAt",
    "desc"
  );

  const numReportSends = reportSends.length;

  return (
    <>
      <ReportTitle report={report} />
      {numReportSends === 0 ? (
        <Card body className="mb-0 card-prompt vh-with-title">
          <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
            <p className="mb-0 text-center">
              <i className="uil-newspaper font-24 d-block" />
              There are no reports statistics to display.
            </p>
          </div>
        </Card>
      ) : (
        <>
          {reportSends.map((send, index) => (
            <SendTable
              key={send.id}
              report={report}
              send={send}
              isLast={index === numReportSends - 1}
              user={user}
              client={client}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ReportStatistics;

import * as Sentry from "@sentry/react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import React from "react";
import { confirmable } from "react-confirm";

const ErrorModal = ({ show, proceed, message, eventId }) => (
  <Modal className="modal-dialog-centered" isOpen={show}>
    <ModalHeader toggle={() => proceed(false)}>Error</ModalHeader>
    <ModalBody>{message}</ModalBody>
    <ModalFooter>
      {eventId && (
        <Button
          color="danger"
          onClick={() => {
            const sentryUser = localStorage.getItem("SentryUser")
              ? JSON.parse(localStorage.getItem("SentryUser"))
              : null;
            const user = sentryUser
              ? {
                  name: sentryUser.username,
                  email: sentryUser.email,
                }
              : null;
            proceed(false); // close modal first
            Sentry.showReportDialog({
              eventId,
              user,
            });
          }}
        >
          <i className="uil-bug mr-1" />
          Submit Crash Report
        </Button>
      )}
      <Button onClick={() => proceed(false)}>OK</Button>
    </ModalFooter>
  </Modal>
);

export default confirmable(ErrorModal);

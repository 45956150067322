import { FormGroup, Input, InputGroup, Label } from "reactstrap";

import FormError from "./FormError";
import React from "react";
import classNames from "classnames";

const FormInput = ({
  name,
  label,
  type,
  addon,
  required,
  validate,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  formProps,
  ...rest
}) => (
  <FormGroup
    className={classNames({
      "form-error": formProps.errors[name],
      [`${className}`]: className,
    })}
  >
    {label && (
      <Label for={name}>
        {label}: {required && "*"}
      </Label>
    )}
    <InputGroup>
      <Input type={type || "text"} name={name} {...rest} innerRef={formProps.register({ required, validate })} />
      {addon}
    </InputGroup>
    {!hideErrors && (
      <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
    )}
  </FormGroup>
);

export default FormInput;

import { getExcerptHash } from "./excerpts";

// merge incoming dynamodb data with existing
const dynamoMerge = (existing, incoming) => {
  const incomingRefs = (incoming.items || []).map((x) => x.__ref);
  const data = {
    ...existing,
    items: [...(existing.items || []).filter((x) => !incomingRefs.includes(x.__ref)), ...(incoming.items || [])],
    nextToken: incoming.nextToken || null,
    // required to ensure cache updates correctly
    __typename: existing.__typename || incoming.__typename,
  };
  return data;
};

// merge incoming searchable data with existing
const searchableMerge = (existing, incoming) => {
  const incomingRefs = (incoming.items || []).map((x) => x.__ref);
  const data = {
    ...existing,
    items: [...(existing.items || []).filter((x) => !incomingRefs.includes(x.__ref)), ...(incoming.items || [])],
    // don't remove total on last page
    total: incoming.total ? incoming.total : existing.total || 0,
    nextToken: incoming.nextToken || null,
    // required to ensure cache updates correctly
    __typename: existing.__typename || incoming.__typename,
  };
  return data;
};

// define merge behaviour for queries
// required for pagination, fetchAllQuery and updateCacheCreate
export const cacheQueryFields = {
  listClients: {
    keyArgs: ["filter"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  listUserProfiles: {
    keyArgs: ["filter"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  listProjects: {
    keyArgs: ["filter"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryAgents: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  querySearch: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryFilters: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryPeople: {
    keyArgs: ["clientId", "projectIdUniqueName"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryOrganisations: {
    keyArgs: ["clientId", "projectIdUniqueName"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryTags: {
    keyArgs: ["clientId", "projectIdTypeUniqueName"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryCharts: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryMailingLists: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryMailingListMembers: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  queryReports: {
    keyArgs: ["clientId", "projectIdId"],
    merge(existing = {}, incoming) {
      return dynamoMerge(existing, incoming);
    },
  },
  // getReport: {
  //   merge(existing = {}, incoming) {
  //     console.log("EX", existing);
  //     console.log("IN", incoming);
  //     return dynamoMerge(existing, incoming);
  //   },
  // },
  searchResultsRaw: {
    keyArgs: ["searchParams", "excerptQuery", "sort"],
    merge(existing = {}, incoming) {
      return searchableMerge(existing, incoming);
    },
  },
  // searchPersons: {
  //   keyArgs: ["filter"],
  //   merge(existing = {}, incoming) {
  //     return searchableMerge(existing, incoming);
  //   },
  // },
  // searchOrganisations: {
  //   keyArgs: ["filter"],
  //   merge(existing = {}, incoming) {
  //     return searchableMerge(existing, incoming);
  //   },
  // },
};

// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
export const cacheTypeFields = {
  // for supporting connections on Report and ReportBlock
  Report: {
    fields: {
      blocks: {
        merge: (existing = {}, incoming) => {
          return { ...existing, ...incoming };
        },
      },
      sends: {
        merge: (existing = {}, incoming) => {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  ReportBlock: {
    fields: {
      results: {
        merge: (existing = {}, incoming) => {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  // results can have different excerpts based on the keywords used to generate the excerpt
  // amend the cache to store each one that is received, keyed by the md5 hash of the keywords used
  Result: {
    fields: {
      excerpt: {
        merge: (existing = {}, incoming, options) => {
          if (incoming) {
            const excerptHash = options.variables.excerptQuery ? getExcerptHash(options.variables.excerptQuery) : null;
            return {
              ...existing,
              [excerptHash]: incoming,
            };
          }
          return existing;
        },
      },
    },
  },
};

// update cache after create mutation
export const updateCacheCreate = (query, variables) => (cache, { data }) => {
  // console.log("*** CREATE ***");
  // console.log("Query", query);
  // console.log("Variables", variables);
  // console.log("Data", data);
  // get existing query name
  const existing = cache.readQuery({ query, variables });
  // console.log("Existing cached value", existing);
  const existingQueryName = Object.keys(existing)[0];
  // console.log("Existing query name", existingQueryName);
  // get new item
  const newQueryName = Object.keys(data)[0];
  // console.log("New query name", newQueryName);
  const newItem = data[newQueryName];
  // console.log("New item", newItem);
  // write the new item (merge function handles the concatenation)
  const mergeOperation = {
    query,
    variables,
    data: {
      [`${existingQueryName}`]: {
        items: [newItem],
        // apollo 3.8 started warning about these missing, even though it worked without
        nextToken: null,
        __typename: newItem.__typename,
      },
    },
  };
  // console.log("Merge operation", mergeOperation);
  cache.writeQuery(mergeOperation);
  // console.log("*** END CREATE ***");
};

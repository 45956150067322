import { Button, Card, CardBody, Form, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem } from "reactstrap";
import React, { useContext, useState } from "react";
import { boolFilter, boolFormatter, headerFormatterMultiLine, textFilter, textFormatter } from "../lib/tables";
import { createMailingListMember, updateMailingListMember } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import BootstrapTable from "react-bootstrap-table-next";
import { COUNTRIES } from "../lib/options";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSelect from "../Forms/FormSelect";
import FormSwitch from "../Forms/FormSwitch";
import Spinner from "../../components/Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { createConfirmation } from "react-confirm";
import filterFactory from "react-bootstrap-table2-filter";
import pick from "lodash/pick";
import { queryMailingListMembers } from "../../graphql/queries";
import { queryMailingListMembersVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

const MemberModal = ({ data: { data, op }, toggle, listId }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);

  const [mutationCreateMailingListMember] = useMutation(gql(createMailingListMember));
  const [mutationUpdateMailingListMember] = useMutation(gql(updateMailingListMember));

  const initial = op === "CREATE" ? {} : data;

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["email", "firstName", "lastName", "company"],
      selects: [["country", COUNTRIES, false]],
      switches: [["isUnsubscribed", false]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const confirmModal = createConfirmation(ConfirmModal);

  const handleDelete = async () => {
    if (isDeleting) return;
    const ok = await confirmModal();
    if (!ok) return;
    setIsDeleting(true);
    try {
      const input = pick(data, ["clientId", "projectId", "listId", "id"]);
      await mutationUpdateMailingListMember({ variables: { input: { ...input, isDeleted: true } } });
      toggle();
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (op === "CREATE") {
        const input = await processForm(
          { id: uuid(), clientId, projectId, listId, ...values },
          { selects: ["country"] }
        );
        await mutationCreateMailingListMember({
          variables: { input },
          update: updateCacheCreate(
            gql(queryMailingListMembers),
            queryMailingListMembersVariables({ clientId, projectId })
          ),
        });
      } else {
        const input = await processForm(
          { id: data.id, clientId, projectId, listId, ...values },
          { selects: ["country"] }
        );
        await mutationUpdateMailingListMember({ variables: { input } });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{title} Member</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="email" label="Email" type="email" required formProps={formProps} />
          <FormInput name="firstName" label="First name" formProps={formProps} />
          <FormInput name="lastName" label="Last name" formProps={formProps} />
          <FormInput name="company" label="Company" formProps={formProps} />
          <FormSelect name="country" label="Country" options={COUNTRIES} isClearable={true} formProps={formProps} />
          <FormSwitch name="isUnsubscribed" label="Unsubscribed" oneLine formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          {op === "UPDATE" && (
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Delete
            </Button>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ListPanel = ({ list, toggleListModal, onClose, user, projectData }) => {
  const [modal, toggleModal] = useState(null);

  // get members in mailing list
  const records = projectData.mailingListMembers.filter((x) => x.listId === list.id);
  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter,
      headerFormatter: headerFormatterMultiLine,
    },
    {
      dataField: "firstName",
      text: "First name",
      sort: true,
      filter: textFilter,
      formatter: textFormatter,
      headerFormatter: headerFormatterMultiLine,
    },
    {
      dataField: "lastName",
      text: "Last name",
      sort: true,
      filter: textFilter,
      formatter: textFormatter,
      headerFormatter: headerFormatterMultiLine,
    },
    {
      dataField: "company",
      text: "Company",
      sort: true,
      filter: textFilter,
      formatter: textFormatter,
      headerFormatter: headerFormatterMultiLine,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      filter: textFilter,
      formatter: textFormatter,
      headerFormatter: headerFormatterMultiLine,
    },
    {
      dataField: "isUnsubscribed",
      text: "Unsubscribed",
      sort: true,
      filter: boolFilter,
      formatter: boolFormatter,
      headerFormatter: headerFormatterMultiLine,
    },
  ];

  return (
    <>
      <Card className="side-panel side-panel--white vh-with-title">
        <Nav tabs className="nav-bordered">
          <NavItem>
            <h4 className="side-panel-title text-title mt-0 mb-0">
              {list.name}
              <i
                className="mdi mdi-pencil-outline click ml-1"
                style={{ opacity: 0.5 }}
                onClick={() => {
                  toggleListModal({
                    data: list,
                    op: "UPDATE",
                  });
                }}
              />
            </h4>
          </NavItem>
          <NavItem className="ml-auto side-panel--buttons">
            <Button color="primary" size="sm" onClick={() => toggleModal({ data: null, op: "CREATE" })}>
              <i className="uil-plus mr-1" />
              Create Member
            </Button>
            <Button color="secondary" size="sm" className="side-panel--close-button-sm" onClick={onClose}>
              <i className="uil-times" />
            </Button>
          </NavItem>
        </Nav>

        <CardBody className="p-0">
          <ToolkitProvider bootstrap4 keyField="id" data={records} columns={columns}>
            {(props) => (
              <BootstrapTable
                {...props.baseProps}
                hover
                rowClasses="click"
                defaultSorted={[
                  {
                    dataField: "email",
                    order: "asc",
                  },
                ]}
                filter={filterFactory()}
                classes="bootstrap-table mb-0"
                wrapperClasses="vh-with-title table-responsive card mb-0"
                headerWrapperClasses="card-header"
                selectRow={{
                  mode: "radio",
                  hideSelectColumn: true,
                  clickToSelect: true,
                  onSelect: (row, isSelected) => {
                    toggleModal({ data: row, op: "UPDATE" });
                    return false;
                  },
                }}
                noDataIndication={
                  <div className="table-empty">
                    <p className="mb-0 text-center">
                      <i className="uil-envelopes font-24 d-block" />
                      There are no members to display in this mailing list.
                    </p>
                  </div>
                }
              />
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>

      {modal && <MemberModal data={modal} toggle={toggleModal} listId={list.id} />}
    </>
  );
};

export default ListPanel;

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { boolFilter, boolFormatter, headerFormatter, textFilter } from "../lib/tables";
import { createMailingList, updateMailingList } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";

import { AppContext } from "../../App";
import BootstrapTable from "react-bootstrap-table-next";
import ConfirmModal from "../Modals/ConfirmModal";
import FormInput from "../Forms/FormInput";
import FormSwitch from "../Forms/FormSwitch";
import ListPanel from "./ListPanel";
import PageTitle from "../../components/PageTitle";
import Spinner from "../../components/Spinner";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { bodyFixedEffect } from "../lib/effects";
import { createConfirmation } from "react-confirm";
import filterFactory from "react-bootstrap-table2-filter";
import find from "lodash/find";
import { handleDeleted } from "../lib/utils";
import pick from "lodash/pick";
import { queryMailingLists } from "../../graphql/queries";
import { queryMailingListsVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const ListsModal = ({ data: { data, op }, toggle }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [mutationCreateMailingList] = useMutation(gql(createMailingList));
  const [mutationUpdateMailingList] = useMutation(gql(updateMailingList));

  const initial = op === "CREATE" ? {} : data;

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      fields: ["name"],
      switches: [["isExternal", false]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const confirmModal = createConfirmation(ConfirmModal);

  const handleDelete = async () => {
    if (isDeleting) return;
    const ok = await confirmModal();
    if (!ok) return;
    setIsDeleting(true);
    try {
      history.push(url);
      toggle();
      // update url, then mutate, to avoid hitting redirectAlert in Lists
      const input = pick(data, ["clientId", "projectId", "id"]);
      await mutationUpdateMailingList({ variables: { input: { ...input, isDeleted: true } } });
    } catch (e) {
      setIsDeleting(false);
      appContext.handleError(e);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (op === "CREATE") {
        const input = await processForm({ id: uuid(), clientId, projectId, ...values });
        await mutationCreateMailingList({
          variables: { input },
          update: updateCacheCreate(gql(queryMailingLists), queryMailingListsVariables({ clientId, projectId })),
        });
      } else {
        const input = await processForm({ id: data.id, clientId, projectId, ...values });
        await mutationUpdateMailingList({ variables: { input } });
      }
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  const title = op === "CREATE" ? "Create" : "Edit";
  const action = op === "CREATE" ? "Create" : "Save";

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>{title} Mailing List</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />
          <FormSwitch name="isExternal" label="External" oneLine formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          {op === "UPDATE" && (
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
              Delete
            </Button>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            {action}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Lists = ({ user, projectData }) => {
  useEffect(bodyFixedEffect);
  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [modal, toggleModal] = useState(null);

  // for nested routes
  const listId = history.location.pathname.split("/")[4];
  if (listId) {
    const deleted = handleDeleted(
      find(projectData.mailingLists, (x) => x.id === listId),
      "mailing list",
      `/${clientId}/${projectId}/lists`
    );
    if (deleted) return deleted;
  }

  const records = projectData.mailingLists;
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell) => <span className="font-bold">{cell}</span>,
      filter: textFilter,
      headerFormatter: headerFormatter,
      headerClasses: "bootstrap-table-stretch",
      classes: "bootstrap-table-stretch",
    },
    {
      dataField: "isExternal",
      text: "External",
      sort: true,
      filter: boolFilter,
      formatter: boolFormatter,
      headerFormatter: headerFormatter,
    },
  ];

  return (
    <>
      <PageTitle title="Mailing Lists">
        <Button color="success" onClick={() => toggleModal({ data: null, op: "CREATE" })}>
          <i className="uil-plus mr-1" />
          Create Mailing List
        </Button>
      </PageTitle>

      <Row className="responsive-panel--container">
        <Col md={listId ? 4 : 12}>
          <ToolkitProvider bootstrap4 keyField="id" data={records} columns={columns}>
            {(props) => (
              <BootstrapTable
                {...props.baseProps}
                hover
                rowClasses="click"
                defaultSorted={[
                  {
                    dataField: "name",
                    order: "asc",
                  },
                ]}
                filter={filterFactory()}
                classes="bootstrap-table mb-0"
                wrapperClasses="vh-with-title table-responsive card mb-0"
                headerWrapperClasses="card-header"
                selectRow={{
                  selected: listId ? [listId] : [],
                  mode: "radio",
                  hideSelectColumn: true,
                  clickToSelect: true,
                  classes: "bootstrap-table-row-active",
                  onSelect: (row, isSelected) => {
                    isSelected ? history.push(`${url}/${row.id}`) : history.push(`${url}`);
                    return false;
                  },
                }}
                noDataIndication={
                  <div className="table-empty">
                    <p className="mb-0 text-center">
                      <i className="uil-envelopes font-24 d-block" />
                      There are no mailing lists to display.
                    </p>
                  </div>
                }
              />
            )}
          </ToolkitProvider>
        </Col>

        <Col md={8} className="responsive-panel--panel">
          <Switch>
            <Route path={`${path}/:listId`}>
              <ListPanel
                key={listId}
                list={find(projectData.mailingLists, (x) => x.id === listId)}
                toggleListModal={toggleModal}
                onClose={() => history.push(`${url}`)}
                user={user}
                projectData={projectData}
              />
            </Route>
          </Switch>
        </Col>
      </Row>

      {modal && <ListsModal data={modal} toggle={toggleModal} />}
    </>
  );
};

export default Lists;

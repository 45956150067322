import { FormGroup, Label } from "reactstrap";

import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";
import FormError from "./FormError";
import React from "react";
import Select from "react-select";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { inputValidateSelectMulti } from "../lib/forms";

const FormSelect = ({
  name,
  label,
  isLoading,
  options,
  switchAddon,
  required,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  onChange,
  formProps,
  isAsync,
  ...rest
}) => {
  const ReactSelectComponent = isAsync ? AsyncSelect : Select;

  return (
    <FormGroup
      data-intercom-target="select-form-group"
      className={classNames({
        "form-error": formProps.errors[name],
        [`${className}`]: className,
      })}
    >
      {label && (
        <Label data-intercom-target="select-label" for={name}>
          {label}: {required && "*"}
        </Label>
      )}

      {isLoading && <Spinner className="spinner-border-sm float-right" />}
      {switchAddon && <div className="float-right">{switchAddon}</div>}

      <div data-intercom-target="select-component">
        <Controller
          name={name}
          control={formProps.control}
          rules={{
            required,
            validate: (value) => {
              if (required) return inputValidateSelectMulti(value);
              return true;
            },
          }}
          defaultValue={null}
          render={(props) => (
            <ReactSelectComponent
              name={name}
              value={props.value}
              options={options}
              isClearable={false}
              classNamePrefix="form-select"
              onChange={(e) => {
                props.onChange(e); // react-hook-form onChange
                onChange && onChange(); // custom onChange if provided
              }}
              {...rest}
            />
          )}
        />
      </div>
      {!hideErrors && (
        <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
      )}
    </FormGroup>
  );
};

export default FormSelect;

import { THUMBOR_KEY, THUMBOR_SERVER } from "./constants";

import Thumbor from "thumbor";
import { getEnv } from "./env";

const FILE_BUCKETS = {
  sandbox: "outcider79245b9c081d4ce79afb65b1a2e212fb94835-sandbox",
  staging: "outcider79245b9c081d4ce79afb65b1a2e212fb120928-staging",
  production: "outcider79245b9c081d4ce79afb65b1a2e212fb171627-production",
};

const FILE_BUCKET = FILE_BUCKETS[getEnv()];

// get a URL from an S3 key in the `public` folder
// CustomS3BucketPolicyPublic has made everything with `public/*` prefix publicly accessible (to non-app users e.g. emails)
export const getS3Public = (key) => `https://${FILE_BUCKET}.s3.eu-west-2.amazonaws.com/public/${key}`;

// resize an image using Thumbor, from a URL or an S3 key
export const getThumborImage = (key, width = 0, height = 0, fitIn = false, forceJpeg = false) => {
  if (!key) return null;

  const thumbor = new Thumbor(THUMBOR_KEY, THUMBOR_SERVER);
  const imageUrl = key.startsWith("http://") || key.startsWith("https://") ? key : getS3Public(key);

  let img = thumbor.setImagePath(encodeURIComponent(imageUrl));

  // force to jpg (result images may be webp etc, not supported by react-pdf and maybe other things)
  // everything else kept in input format (logos may be pngs with transparent background)
  // needs to be done before resize
  if (forceJpeg) img = img.filter("format(jpeg)").filter("quality(90)");

  // resize/fit
  if (fitIn) {
    img = img.fitIn(width, height);
  } else {
    img = img.resize(width, height);
  }

  return img.buildUrl();
};

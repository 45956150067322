import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";

import classNames from "classnames";
import { generatePath } from "react-router";
import { getMenuItems } from "../outcider/lib/menu";

const DEFAULT_ROUTE_PROPS = {
  resultsType: "all",
  entityType: "people",
  stakeholderType: "all",
};

// const MenuItemWithChildren = ({ item, linkClassNames, subMenuClassNames, clientId, projectId, user }) => {
//   return (
//     <li className="side-nav-item" data-route-section={item.section}>
//       <Link
//         to={generatePath(item.path, { clientId, projectId })}
//         className={classNames("has-arrow", "side-sub-nav-link", linkClassNames)}
//       >
//         {item.icon && <i className={item.icon}></i>}
//         {item.badge && <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
//         <span> {item.name} </span>
//       </Link>

//       <ul className={classNames(subMenuClassNames, "mm-collapse")}>
//         {item.children
//           .filter((child) => !child.can || (child.can && user.can[child.can]))
//           .map((child, i) => {
//             return (
//               <React.Fragment key={i}>
//                 {child.children ? (
//                   <MenuItemWithChildren
//                     item={child}
//                     subMenuClassNames="side-nav-third-level"
//                     linkClassNames=""
//                     clientId={clientId}
//                     projectId={projectId}
//                   />
//                 ) : (
//                   <MenuItem item={child} className="" linkClassName="" clientId={clientId} projectId={projectId} />
//                 )}
//               </React.Fragment>
//             );
//           })}
//       </ul>
//     </li>
//   );
// };

const MenuItem = ({ item, className, linkClassName, project, clientId, projectId }) => {
  // if (item.hidden) return;

  // // inject list of filters
  // if (item.dynamic === "FILTERS") {
  //   const filters = [
  //     {
  //       id: "1",
  //       name: "One",
  //     },
  //     {
  //       id: "2",
  //       name: "Two",
  //     },
  //     {
  //       id: "3",
  //       name: "Three",
  //     },
  //   ];
  //   return (
  //     <React.Fragment>
  //       {filters.map((filter) => (
  //         <li key={filter.id} className={classNames("side-nav-item", className)}>
  //           <MenuItemLink
  //             item={{ ...filter, path: item.path }}
  //             className={linkClassName}
  //             clientId={clientId}
  //             projectId={projectId}
  //             filterId={filter.id}
  //           />
  //         </li>
  //       ))}
  //     </React.Fragment>
  //   );
  // }
  return (
    <li className={classNames("side-nav-item", className)} data-route-section={item.section}>
      <MenuItemLink item={item} className={linkClassName} project={project} clientId={clientId} projectId={projectId} />
    </li>
  );
};

const MenuItemLink = ({ item, className, project, clientId, projectId }) => {
  return (
    <Link
      to={generatePath(item.path, {
        clientId,
        projectId,
        ...DEFAULT_ROUTE_PROPS,
      })}
      className={classNames("side-nav-link-ref", "side-sub-nav-link", className)}
    >
      {item.icon && <i className={item.icon}></i>}
      {item.badge && <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
      <span> {item.name(project)} </span>
    </Link>
  );
};

class AppMenu extends Component {
  componentDidMount = () => {
    // hide menus in mobile on page change
    this.props.history.listen(() => {
      if (document.body) {
        document.body.classList.remove("sidebar-enable");
      }
    });
  };

  // only re-render when client/project is changed
  shouldComponentUpdate(nextProps) {
    if (this.props.clientId !== nextProps.clientId || this.props.projectId !== nextProps.projectId) return true;
    return false;
  }

  render() {
    const { project, clientId, projectId, user } = this.props;
    const menuItems = getMenuItems();

    return (
      <React.Fragment>
        <ul className="metismenu side-nav" id="menu-bar">
          {menuItems
            // visibility based on user permissions
            .filter((item) => !item.can || (item.can && user.can[item.can]))
            // visibility based on project settings
            .filter((item) => !item.visible || (item.visible && item.visible(project)))
            .map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  item={item}
                  className=""
                  linkClassName="side-nav-link"
                  project={project}
                  clientId={clientId}
                  projectId={projectId}
                />
              );
              // return (
              //   <React.Fragment key={item.id}>
              //     <React.Fragment>
              //       {item.children ? (
              //         <MenuItemWithChildren
              //           item={item}
              //           subMenuClassNames="side-nav-second-level"
              //           linkClassNames="side-nav-link"
              //           clientId={clientId}
              //           projectId={projectId}
              //           user={user}
              //         />
              //       ) : (
              //         <MenuItem
              //           item={item}
              //           className=""
              //           linkClassName="side-nav-link"
              //           clientId={clientId}
              //           projectId={projectId}
              //         />
              //       )}
              //     </React.Fragment>
              //   </React.Fragment>
              // );
            })}
        </ul>
      </React.Fragment>
    );
  }
}

export default withRouter(AppMenu);

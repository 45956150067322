import { Document, Link, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getAuthorContactLink, getResultMetadata } from "../../lib/metadata";

import React from "react";

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  title: {
    fontSize: 14,
    lineHeight: 1.3,
    paddingBottom: 5,
  },
  metadata: {
    fontSize: 10,
    lineHeight: 1.3,
    color: "#666666",
  },
  agents: {
    fontSize: 10,
    lineHeight: 1.3,
    color: "#f2994a",
  },
  content: {
    borderTop: "1px solid #000000",
    borderBottom: "1px solid #000000",
    fontSize: 10,
    lineHeight: 1.3,
    paddingTop: 15,
    marginTop: 10,
    paddingBottom: 10,
    marginBottom: 15,
  },
  link: {
    color: "#000000",
  },
  // https://github.com/diegomura/react-pdf/issues/487#issuecomment-497530466
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    paddingBottom: 2,
  },
  tableColHeader: {
    width: "100%",
  },
  tableColTitle: {
    width: "32%",
  },
  tableColContent: {
    width: "68%",
    // stop long outbound links from overflowing
    overflow: "hidden",
  },
  tableCellHeader: {
    fontSize: 8.5,
    textTransform: "uppercase",
    textDecoration: "underline",
    lineHeight: 1.3,
    color: "#333333",
    marginTop: 3,
  },
  tableCellTitle: {
    fontSize: 8.5,
    textTransform: "uppercase",
    lineHeight: 1.3,
    color: "#666666",
  },
  tableCellContent: {
    fontSize: 8.5,
    lineHeight: 1.3,
  },
});

const ResultPDF = ({ title, content, url, details, agents, result, user }) => {
  const metadata = getResultMetadata(result, user, "RESULT_PDF");

  const renderRowValue = (row) => {
    const key = row[1];
    const value = row[2];

    if (key === "articleOutboundLinks") {
      return value.map((item, index) => (
        <Text key={index} style={styles.tableCellContent}>
          <Link src={item} style={styles.link}>
            {item}
          </Link>
        </Text>
      ));
    }

    if (key === "authorContact") {
      return (
        <Text style={styles.tableCellContent}>
          <Link src={getAuthorContactLink(result)}>{value}</Link>
        </Text>
      );
    }

    if (["articleTopics", "sourceAutoTopics", "authorGroups", "autoAnalystRecommendations"].includes(key)) {
      return value.map((item, index) => (
        <Text key={index} style={styles.tableCellContent}>
          {item}
        </Text>
      ));
    }

    return <Text style={styles.tableCellContent}>{row[2]}</Text>;
  };

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>{title ? title : "No Title"}</Text>
        {url && (
          <Link src={url} style={styles.metadata}>
            {url}
          </Link>
        )}
        <Text style={styles.metadata}>{details}</Text>
        <Text style={styles.agents}>{agents.join(", ")}</Text>

        <Text style={styles.content}>{content ? content : "No article text available."}</Text>

        <View style={styles.table}>
          {metadata.map((group, index) => (
            <React.Fragment key={index}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>{group[0]}</Text>
                </View>
              </View>
              {group[1].map((row, rowIndex) => (
                <View style={styles.tableRow} key={rowIndex}>
                  <View style={styles.tableColTitle}>
                    <Text style={styles.tableCellTitle}>{row[0]}:</Text>
                  </View>
                  <View style={styles.tableColContent}>{renderRowValue(row)}</View>
                </View>
              ))}
            </React.Fragment>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ResultPDF;

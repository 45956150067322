import React, { useEffect, useState } from "react";
import { bodyFixedEffect, useMediaBreakpointDownMd } from "../lib/effects";
import { gql, useQuery } from "@apollo/client";
import { handleDeleted, handleSource, refreshRoute } from "../lib/utils";
import { queryFilters, querySearch } from "../../graphql/queries";
import { queryFiltersGetVariables, querySearchGetVariables } from "../../lib/variables";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "reactstrap";
import PageTitle from "../../components/PageTitle";
import ResultsListing from "./ResultsListing";
import ResultsSettings from "./ResultsSettings";
import Spinner from "../../components/Spinner";
import UpdateModal from "../Filters/UpdateModal";
import { getResultPrefixForSource } from "../../lib/utils";
import useCookie from "react-use-cookie";

const Results = ({ source, user, client, project, projectData }) => {
  useEffect(bodyFixedEffect);

  const { clientId, projectId, resultsType } = useParams();
  const history = useHistory();

  // for nested routes
  let searchId, filterId;
  if (resultsType.startsWith("search-")) searchId = resultsType.substr(7);
  if (resultsType.startsWith("issue-")) filterId = resultsType.substr(6);

  const [showDuplicates, setShowDuplicates] = useCookie("ResultsShowDuplicates", "false");
  const [dateRange, setDateRange] = useCookie("ResultsDateRange", "1");
  const [startDate, setStartDate] = useCookie("ResultsStartDate", "");
  const [startTime, setStartTime] = useCookie("ResultsStartTime", "00:00");
  const [endDate, setEndDate] = useCookie("ResultsEndDate", "");
  const [endTime, setEndTime] = useCookie("ResultsEndTime", "00:00");

  const [menuOpen, setMenuOpen] = useState(false);
  const [updateFilterModal, toggleUpdateFilterModal] = useState(null);

  // search
  const { loading: loadingSearch, data: dataSearch } = useQuery(gql(querySearch), {
    variables: querySearchGetVariables({ clientId, projectId, id: searchId }),
    skip: !searchId,
  });

  // filter
  const { loading: loadingFilter, data: dataFilter } = useQuery(gql(queryFilters), {
    variables: queryFiltersGetVariables({ clientId, projectId, id: filterId }),
    skip: !filterId,
  });

  // mobile
  const isMediaBreakpointDownMd = useMediaBreakpointDownMd();
  const resultId = history.location.pathname.split("/")[5]; // doesn't consider listingDuplicates but maybe ok?
  const [mobileViewSettings, setMobileViewSettings] = useState(false);

  // if there is chartTimestamp/chartDateRange in the route state, apply and clear from state
  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.chartTimestamp &&
      Object.keys(history.location.state.chartTimestamp).length > 0
    ) {
      const { startDate, startTime, endDate, endTime } = history.location.state.chartTimestamp;
      setDateRange("0");
      setStartDate(startDate);
      setStartTime(startTime);
      setEndDate(endDate);
      setEndTime(endTime);
      refreshRoute(history);
    }
    if (history.location.state && history.location.state.chartDateRange) {
      setDateRange(history.location.state.chartDateRange);
      refreshRoute(history);
    }
  });

  if (loadingSearch || loadingFilter) {
    return <Spinner className="loading-full" />;
  }

  // if searching check it exists (no delete check as searches have ttl expiry)
  let search = null;
  if (searchId) {
    search = dataSearch.querySearch.items.length ? dataSearch.querySearch.items[0] : null;
    const deleted = handleDeleted(
      search,
      "search",
      `/${clientId}/${projectId}/${getResultPrefixForSource(source)}/all`
    );
    if (deleted) return deleted;
    // check being accessed through the correct results source for the search
    const invalidSource = handleSource(
      search.source,
      source,
      "search",
      `/${clientId}/${projectId}/${getResultPrefixForSource(source)}/all`
    );
    if (invalidSource) return invalidSource;
  }

  // if filtering check it exists
  let filter = null;
  if (filterId) {
    filter = dataFilter.queryFilters.items.length ? dataFilter.queryFilters.items[0] : null;
    const deleted = handleDeleted(filter, "issue", `/${clientId}/${projectId}/${getResultPrefixForSource(source)}/all`);
    if (deleted) return deleted;
    // check being accessed through the correct results source for the filter
    const invalidSource = handleSource(
      filter.source,
      source,
      "issue",
      `/${clientId}/${projectId}/${getResultPrefixForSource(source)}/all`
    );
    if (invalidSource) return invalidSource;
  }

  const getTitle = () => {
    if (resultsType === "all") return "All Results";
    if (resultsType === "top") return "Top Stories";
    if (resultsType === "manualtop") return "Top Stories (Manual)";
    if (resultsType === "deleted") return "Deleted Results";
    // temporary search
    if (resultsType.startsWith("search-") && search && !search.filterId) return "Search Results";
    // filter with unsaved changes (name is not editable)
    if (resultsType.startsWith("search-") && search && search.filterId) return search.filterName;
    // filter with no changes (name is editable)
    if (resultsType.startsWith("issue-")) return filter.name;
    return "Results";
  };

  // friendly timeframe description
  const timeframe = {
    1: "from the last 24 hours",
    7: "from the last 7 days",
    30: "from the last 30 days",
    90: "from the last 90 days",
    0: "from custom timeframe",
  }[dateRange];

  let showRss = true;
  if (resultsType === "deleted" || resultsType.startsWith("search-")) showRss = false;

  const settingsProps = {
    client,
    source,
    showDuplicates,
    setShowDuplicates,
    showRss,
    dateRange,
    setDateRange,
    startDate,
    setStartDate,
    startTime,
    setStartTime,
    endDate,
    setEndDate,
    endTime,
    setEndTime,
  };

  // disabled as route is now hidden
  // // upsell when social never used
  // if (source === "SOCIAL360" && !projectData.resultsSources.includes("SOCIAL360"))
  //   return (
  //     <>
  //       <PageTitle title="Social Results" />
  //       <Card body className="mb-0 card-prompt vh-with-title">
  //         <div className="mb-0 h-100 d-flex justify-content-center align-items-center">
  //           <p className="mb-0 text-center">
  //             <i className="uil-file-bookmark-alt font-24 d-block" />
  //             Social Influencer results are not enabled for this project.
  //           </p>
  //         </div>
  //       </Card>
  //     </>
  //   );

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="uil-bars click mr-2" onClick={() => setMenuOpen(!menuOpen)} />
            {getTitle()}
            {user.can.updateIssues && resultsType.startsWith("issue-") && (
              <i
                className="mdi mdi-pencil-outline click ml-1"
                style={{ opacity: 0.5 }}
                onClick={() => toggleUpdateFilterModal(true)}
              />
            )}
          </>
        }
        subtitle={timeframe}
        wrapButtonsMediaBreakpointDownLg
      >
        {isMediaBreakpointDownMd ? (
          <div className="settings-popup-container">
            <Button color="success" disabled={resultId} onClick={() => setMobileViewSettings(!mobileViewSettings)}>
              <i className="uil-cog" />
            </Button>
            {mobileViewSettings && !resultId && (
              <div className="settings-popup">
                <ResultsSettings {...settingsProps} isPopup />
              </div>
            )}
          </div>
        ) : (
          <ResultsSettings {...settingsProps} />
        )}
      </PageTitle>

      <ResultsListing
        source={source}
        resultsType={resultsType}
        showDuplicates={showDuplicates}
        dateRange={dateRange}
        startDate={startDate}
        startTime={startTime}
        endDate={endDate}
        endTime={endTime}
        timeframe={timeframe}
        search={search}
        filter={filter}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        user={user}
        project={project}
        projectData={projectData}
      />

      {filter && updateFilterModal && <UpdateModal data={filter} toggle={toggleUpdateFilterModal} />}
    </>
  );
};

export default Results;

export const resultBlockOptions = [
  { value: "BANNER", label: "Banner" },
  { value: "HEADLINE", label: "Headline" },
  { value: "LIST", label: "List" },
  { value: "FOCUS", label: "Focus" },
  { value: "GRID", label: "Grid" },
  { value: "QUOTE", label: "Quote" },
];

export const tableBlockOption = { value: "TABLE", label: "Table" };

export const nonResultBlockOptions = [
  { value: "HEADING", label: "Heading" },
  { value: "TEXT", label: "Text" },
  { value: "IMAGE", label: "Image" },
  { value: "CHART", label: "Chart" },
  tableBlockOption,
  { value: "CONTENTS", label: "Contents" },
];

export const nonEditableBlocks = ["HEADER", "FOOTER", "DELETED"];
export const addableBlocks = [...resultBlockOptions.map((x) => x.value), tableBlockOption.value];

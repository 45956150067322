import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { useState } from "react";

import Filters from "../Filters";
import ResultsPanelCharts from "./ResultsPanelCharts";
import ResultsPanelEntities from "./ResultsPanelEntities";
import ResultsPanelOverview from "./ResultsPanelOverview";
import classNames from "classnames";
import { useMediaBreakpointDownLg } from "../lib/effects";

const ResultsPanel = ({
  source,
  resultsType,
  filterParams,
  listingDuplicates,
  search,
  filter,
  filtersKey,
  user,
  project,
  projectData,
}) => {
  const isMediaBreakpointDownLg = useMediaBreakpointDownLg();

  const [activeTab, setActiveTab] = useState("overview");

  const tabs = [
    {
      id: "overview",
      title: "Overview",
      icon: "uil-dashboard",
      component: ResultsPanelOverview,
    },
  ];
  if (source === "METABASE") {
    tabs.push(
      {
        id: "charts",
        title: "Quick Charts",
        icon: "uil-chart-line",
        component: ResultsPanelCharts,
      },
      {
        id: "entities",
        title: "People & Organisations",
        icon: "uil-users-alt",
        component: ResultsPanelEntities,
      }
    );
  }

  return (
    <Card
      className={classNames({
        "side-panel": true,
        "side-panel--gray": true,
        "vh-with-title": !listingDuplicates && !isMediaBreakpointDownLg,
        "vh-with-title-wrapped-buttons": !listingDuplicates && isMediaBreakpointDownLg,
        "vh-duplicates-modal": listingDuplicates,
      })}
    >
      {!isMediaBreakpointDownLg && (
        <div className="results-filters">
          <Filters
            key={filtersKey}
            source={source}
            resultsType={resultsType}
            search={search}
            filter={filter}
            user={user}
            projectData={projectData}
          />
        </div>
      )}
      {/* social only has one tab (the first, selected, one) so hide the nav */}
      {source === "METABASE" && (
        <Nav tabs className="nav-bordered">
          {tabs.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                href="#"
                className={classNames({ active: activeTab === tab.id })}
                onClick={() => setActiveTab(tab.id)}
              >
                <i className={`${tab.icon} d-md-none mr-1`} />
                <span
                  className={classNames({
                    "d-none": activeTab !== tab.id,
                    "d-md-inline": true,
                  })}
                >
                  {tab.title}
                </span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      )}
      <CardBody className="scroll pb-0">
        <TabContent activeTab={activeTab}>
          {tabs
            .filter((tab) => tab.id === activeTab) // only render tab if active
            .map((tab, index) => (
              <TabPane tabId={tab.id} key={index}>
                <tab.component
                  source={source}
                  resultsType={resultsType}
                  filterParams={filterParams}
                  search={search}
                  filter={filter}
                  user={user}
                  project={project}
                  projectData={projectData}
                />
              </TabPane>
            ))}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default ResultsPanel;

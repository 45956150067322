import { Comparator, selectFilter as bsSelectFilter, textFilter as bsTextFilter } from "react-bootstrap-table2-filter";

import React from "react";
import classNames from "classnames";

export const textFormatter = (cell, row) => (cell ? cell : "-");

// not just used in tables
export const boolFormatter = (cell, row) => (
  <>
    <i
      className={classNames({
        mdi: true,
        "font-20": true,
        [`mdi-${cell ? "check" : "close"}-circle`]: true,
        "check-yes": cell,
        "check-no": !cell,
      })}
    />
  </>
);

export const headerFormatter = (column, colIndex, components) => (
  <div className="bootstrap-table-header">
    <div className="bootstrap-table-header--title">
      {column.text}
      {components.sortElement}
    </div>
    {components.filterElement && <div className="bootstrap-table-header--filter ml-3">{components.filterElement}</div>}
  </div>
);

export const headerFormatterMultiLine = (column, colIndex, components) => (
  <div className="bootstrap-table-header-multiline">
    <div className="bootstrap-table-header-multiline--title">
      {column.text}
      {components.sortElement}
    </div>
    {components.filterElement && (
      <div className="bootstrap-table-header-multiline--filter mt-1">{components.filterElement}</div>
    )}
  </div>
);

export const textFilter = bsTextFilter({ placeholder: "Filter...", className: "form-control-sm" });

export const selectFilter = (props) =>
  bsSelectFilter({
    comparator: Comparator.LIKE,
    placeholder: "Filter...",
    className: "form-control-sm",
    ...props,
  });

export const boolFilter = bsSelectFilter({
  placeholder: "Filter...",
  className: "form-control-sm",
  options: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
});

import { Button, FormGroup, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import { inputValidateSelectMulti, populateForm } from "../lib/forms";

import { AppContext } from "../../App";
import AsyncCreatable from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import FormError from "./FormError";
import FormInput from "./FormInput";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import pull from "lodash/pull";
import { sortArray } from "../../lib/utils";

// doesn't use the usual submit logic due to avoiding a Form (on this field) being nested within another Form (e.g. on FilterModal)
const EditModal = ({ data, toggle, type }) => {
  const appContext = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, errors, control, setValue, getValues } = useForm({
    defaultValues: populateForm(
      { values: data.join("\n") },
      {
        fields: ["values"],
      }
    ),
  });
  const formProps = { errors, register, control, setValue };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    try {
      // split and trim values, remove resulting empty values
      const tidiedValues = pull(
        values.values.split("\n").map((x) => x.trim()),
        ""
      );
      // remove duplicates, keep first occurance
      // https://stackoverflow.com/a/48731445
      let dedupedValues = [
        ...tidiedValues
          .map((s) => s.toLowerCase())
          .reduce((map, s, i) => (map.get(s) ? map : map.set(s, tidiedValues[i])), new Map())
          .values(),
      ];
      toggle(dedupedValues);
    } catch (e) {
      appContext.handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Edit</ModalHeader>
      <ModalBody>
        <FormInput
          name="values"
          type="textarea"
          className="mb-0 edit-modal-textarea"
          helpTextClassName="mb-0"
          helpText={`Enter one ${type || "option"} per line.`}
          rows={15}
          formProps={formProps}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
          {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FormCreatable = ({
  name,
  label,
  isLoading,
  options,
  type,
  addon,
  switchAddon,
  required,
  validate,
  className,
  helpTextClassName,
  helpText,
  hideErrors,
  showEditModal,
  onChange,
  formProps,
  isAsync,
  ...rest
}) => {
  if (showEditModal && !formProps.getValues)
    throw new Error("formProps.getValues is required when showEditModal is set.");

  const [modal, toggleModal] = useState(null);

  const ReactSelectComponent = isAsync ? AsyncCreatable : Creatable;

  return (
    <FormGroup
      className={classNames({
        "form-error": formProps.errors[name],
        [`${className}`]: className,
      })}
    >
      {label && (
        <Label for={name}>
          {label}: {required && "*"}
        </Label>
      )}

      {showEditModal && (
        <i
          className="mdi mdi-pencil-outline click float-right ml-1"
          onClick={() => {
            // get values from field, blank list if field is blank
            const values = (formProps.getValues()[name] ?? []).map((x) => x.value);
            toggleModal(values);
          }}
        />
      )}
      {isLoading && <Spinner className="spinner-border-sm float-right" />}
      {switchAddon && <div className="float-right">{switchAddon}</div>}

      <Controller
        name={name}
        control={formProps.control}
        rules={{
          required,
          validate: (value) => {
            if (required) return inputValidateSelectMulti(value);
            if (validate) return validate(value);
            return true;
          },
        }}
        defaultValue={null}
        render={(props) => (
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <ReactSelectComponent
              name={name}
              value={props.value}
              options={options}
              isMulti={true}
              isClearable={false}
              placeholder={`Enter ${type || "option"}s...`}
              formatCreateLabel={(label) => `Add ${type || "option"} "${label}"`}
              openMenuOnClick={false}
              className="react-select w-100"
              classNamePrefix="react-select"
              onChange={(e) => {
                props.onChange(e); // react-hook-form onChange
                onChange && onChange(); // custom onChange if provided
              }}
              {...rest}
            />
            {addon}
          </InputGroup>
        )}
      />

      {!hideErrors && (
        <FormError name={name} errors={formProps.errors} helpText={helpText} className={helpTextClassName} />
      )}

      {modal && (
        <EditModal
          data={modal}
          toggle={(newValues) => {
            toggleModal();
            if (newValues) {
              formProps.setValue(
                name,
                newValues.length ? sortArray(newValues).map((x) => ({ label: x, value: x })) : null
              );
            }
          }}
          type={type}
        />
      )}
    </FormGroup>
  );
};

export default FormCreatable;

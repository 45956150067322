import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { populateForm, processForm } from "../lib/forms";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { AppContext } from "../../App";
import FormInput from "../Forms/FormInput";
import Spinner from "../../components/Spinner";
import { createAgent } from "../../graphql/mutations";
import { queryAgents } from "../../graphql/queries";
import { queryAgentsVariables } from "../../lib/variables";
import { updateCacheCreate } from "../lib/cache";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

const CreateAgentModal = ({ toggle }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [mutationCreateAgent] = useMutation(gql(createAgent));

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(null),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    // create draft agent without any criteria, return id
    try {
      const id = uuid();
      const input = await processForm({ id, clientId, projectId, ...values });
      await mutationCreateAgent({
        variables: { input },
        update: updateCacheCreate(gql(queryAgents), queryAgentsVariables({ clientId, projectId })),
      });
      history.push(`${url}/${id}`);
      toggle();
    } catch (e) {
      appContext.handleError(e);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true}>
      <ModalHeader toggle={() => toggle()}>Create Agent</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormInput name="name" label="Name" required formProps={formProps} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
            Create
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateAgentModal;

import { Button, Form, ModalBody, ModalFooter } from "reactstrap";
import React, { useContext } from "react";
import { fnShareResult, fnTriggerJob } from "../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";
import { inputValidateRecipients, populateForm, processForm } from "../lib/forms";

import AlertModal from "./AlertModal";
import { AppContext } from "../../App";
import { ConditionalWrapper } from "../lib/utils";
import FormCreatable from "../Forms/FormCreatable";
import Spinner from "../../components/Spinner";
import { createConfirmation } from "react-confirm";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const RecipientsForm = ({ type, isModal, onComplete, shareResultIds, sendReportData, user, projectData }) => {
  const appContext = useContext(AppContext);
  const { clientId, projectId } = useParams();
  const alertModal = createConfirmation(AlertModal);
  const [mutationFnShareResult] = useMutation(gql(fnShareResult));
  const [mutationFnTriggerJob] = useMutation(gql(fnTriggerJob));

  // define available recipient options for each type
  const availableRecipientTypes = {
    DAILY: ["subscribers", "users", "mailingListsInternal", "mailingListMembersInternal"],
    SHARE: ["users", "mailingListsInternal", "mailingListMembersInternal"],
    REPORT_PREVIEW: [],
    REPORT_SEND: ["mailingListsAll"],
  };

  const subscribersOption = `__OUTCIDER_SUBSCRIBERS__daily-${projectId}`;

  // build options
  const options = [];
  availableRecipientTypes[type].forEach((recipientType) => {
    // SUBSCRIBERS
    if (recipientType === "subscribers") {
      options.push({
        label: "Subscribers",
        options: [{ value: subscribersOption, label: "All Subscribers" }],
      });
    }

    // USERS
    // users on the client of this project, who have access to this project, excluding admins and disabled, include self
    if (recipientType === "users") {
      const clientUsers = projectData.users.filter(
        (x) => ((x.projects || []).length ? x.projects.includes(projectId) : true) && x.role !== "ADMIN" && x.enabled
      );
      if (clientUsers.length) {
        options.push({
          label: "Users",
          options: clientUsers.map((x) => ({ value: `__OUTCIDER_USER__${x.id}`, label: x.name })),
        });
      }
    }

    // MAILING LISTS
    // mailing lists on this project (not deleted)
    if (["mailingListsInternal", "mailingListsAll"].includes(recipientType)) {
      // internal only, or all
      const mailingLists =
        recipientType === "mailingListsInternal"
          ? projectData.mailingLists.filter((x) => !x.isExternal)
          : projectData.mailingLists;
      if (mailingLists.length) {
        options.push({
          label: `Mailing Lists${recipientType === "mailingListsInternal" ? " (Internal)" : ""}`,
          options: mailingLists.map((x) => {
            const label =
              recipientType === "mailingListsInternal"
                ? x.name
                : `${x.name} (${x.isExternal ? "External" : "Internal"})`;
            return { value: `__OUTCIDER_LIST__${x.id}`, label };
          }),
        });
      }
    }

    // MAILING LIST MEMBERS
    // mailing list members on this project (not deleted, not unsubscribed, and their list not deleted)
    // remove duplicates by email address (user may be in multiple lists, use first)
    if (["mailingListMembersInternal", "mailingListMembersAll"].includes(recipientType)) {
      // internal only, or all
      const mailingLists =
        recipientType === "mailingListsInternal"
          ? projectData.mailingLists.filter((x) => !x.isExternal)
          : projectData.mailingLists;
      const validMailingListIds = mailingLists.map((x) => x.id);
      const validMailingListMembers = projectData.mailingListMembers.filter(
        (x) => validMailingListIds.includes(x.listId) && !x.isUnsubscribed
      );
      const mailingListMembers = [];
      const seenEmails = [];
      validMailingListMembers.forEach((member) => {
        if (!seenEmails.includes(member.email)) {
          mailingListMembers.push(member);
          seenEmails.push(member.email);
        }
      });
      if (mailingListMembers.length) {
        options.push({
          label: `Mailing List Members${recipientType === "mailingListsInternal" ? " (Internal)" : ""}`,
          options: mailingListMembers.map((x) => ({
            value: `__OUTCIDER_MEMBER__${x.id}`,
            label: x.firstName && x.lastName ? `${x.firstName} ${x.lastName}` : x.email,
          })),
        });
      }
    }
  });

  // build initial data
  let initial = {};
  if (type === "DAILY") initial.recipients = subscribersOption;
  if (type === "REPORT_PREVIEW") {
    options.push({ value: user.email, label: user.email });
    initial.recipients = [user.email];
  }

  const recipientOptionsAreGrouped = type !== "REPORT_PREVIEW";

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(initial, {
      selectMultis: [["recipients", options, recipientOptionsAreGrouped]],
    }),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };

  const onSubmit = async (values) => {
    try {
      let onCompleteReturnValue = null;

      // DAILY alert
      if (type === "DAILY") {
        const formData = await processForm(values, { creatables: ["recipients"] });
        const jobData = { op: "SEND", type: "DAILY", ...formData };

        // trigger job, return job ID to onComplete
        // JobStatus will show "pending" message, subscription will update dailyAlertLastSent
        const res = await mutationFnTriggerJob({
          variables: { input: { clientId, projectId, job: "ALERT", data: JSON.stringify(jobData) } },
        });
        onCompleteReturnValue = res.data.fnTriggerJob.id;

        await alertModal({
          message: "The Daily Summary Alert email has been sent to the selected recipients.",
        });
      }

      // SHARE results
      if (type === "SHARE") {
        const input = await processForm(
          { clientId, projectId, ids: shareResultIds, ...values },
          { creatables: ["recipients"] }
        );
        const res = await mutationFnShareResult({ variables: { input } });

        const numResults = shareResultIds.length;
        const numRecipients = res.data.fnShareResult.length;
        await alertModal({
          message: (
            <>
              Shared{" "}
              <b>
                {numResults} result{numResults === 1 ? "" : "s"}
              </b>{" "}
              with{" "}
              <b>
                {numRecipients} recipient{numRecipients === 1 ? "" : "s"}.
              </b>
            </>
          ),
        });
      }

      if (["REPORT_PREVIEW", "REPORT_SEND"].includes(type)) {
        const formData = await processForm(values, { creatables: ["recipients"] });
        const jobData = { ...sendReportData, ...formData };

        // trigger job, return job ID to onComplete
        const res = await mutationFnTriggerJob({
          variables: { input: { clientId, projectId, job: "SENDREPORT", data: JSON.stringify(jobData) } },
        });
        onCompleteReturnValue = res.data.fnTriggerJob.id;
      }

      // fire onComplete if provided
      onComplete && onComplete(onCompleteReturnValue);
    } catch (e) {
      appContext.handleError(e);
    }
  };

  let label = "Recipients",
    placeholder = "Select recipients...",
    action = "OK";
  if (type === "DAILY") {
    label = "Send daily summary to";
    placeholder = "Select recipients...";
    action = "Send";
  }
  if (type === "SHARE") {
    placeholder = "Select users, mailing lists/members, or enter email addresses...";
    action = "Share";
  }
  if (type === "REPORT_PREVIEW") {
    label = "Send preview report to";
    placeholder = "Enter email addresses...";
    action = "Send";
  }
  if (type === "REPORT_SEND") {
    label = "Send report to";
    placeholder = "Select mailing lists...";
    action = "Send";
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
      <ConditionalWrapper condition={isModal} wrapper={(children) => <ModalBody>{children}</ModalBody>}>
        <FormCreatable
          name="recipients"
          label={label}
          type="email"
          placeholder={placeholder}
          options={options}
          required
          validate={inputValidateRecipients}
          formProps={formProps}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={isModal} wrapper={(children) => <ModalFooter>{children}</ModalFooter>}>
        <Button color="primary" type="submit" disabled={isSubmitting} className="ml-auto">
          {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
          {action}
        </Button>
      </ConditionalWrapper>
    </Form>
  );
};

export default RecipientsForm;

import {
  Button,
  ButtonGroup,
  Col,
  CustomInput,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { COOKIE_EXPIRY, getPublicApiUrlPrefix } from "../../lib/constants";
import { populateForm, validateDateRange } from "../lib/forms";

import AlertModal from "../Modals/AlertModal";
import FormInput from "../Forms/FormInput";
import React from "react";
import Spinner from "../../components/Spinner";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import { getResultPrefixForSource } from "../../lib/utils";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const CustomTimeframeForm = ({
  setDateRange,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endDate,
  setEndDate,
  endTime,
  setEndTime,
}) => {
  const alertModal = createConfirmation(AlertModal);

  const { register, handleSubmit, errors, formState, control, setValue } = useForm({
    defaultValues: populateForm(
      { startDate, startTime, endDate, endTime },
      {
        fields: ["startDate", "startTime", "endDate", "endTime"],
      }
    ),
  });
  const { isSubmitting } = formState;
  const formProps = { errors, register, control, setValue };
  const onSubmit = async (values) => {
    let startDate, startTime, endDate, endTime;
    try {
      const validatedDates = validateDateRange(values.startDate, values.startTime, values.endDate, values.endTime);
      startDate = validatedDates.startDate;
      startTime = validatedDates.startTime;
      endDate = validatedDates.endDate;
      endTime = validatedDates.endTime;
    } catch (e) {
      return await alertModal({
        message: e.message,
      });
    }
    setDateRange("0");
    setStartDate(startDate);
    setStartTime(startTime);
    setEndDate(endDate);
    setEndTime(endTime);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="mb-0">
        <Label for="startDate">Start date:</Label>
        <Row noGutters>
          <Col xs={7}>
            <FormInput name="startDate" type="date" formProps={formProps} />
          </Col>
          <Col xs={5}>
            <FormInput name="startTime" type="time" formProps={formProps} />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="mb-0">
        <Label for="endDate">End date:</Label>
        <Row noGutters>
          <Col xs={7}>
            <FormInput name="endDate" type="date" formProps={formProps} />
          </Col>
          <Col xs={5}>
            <FormInput name="endTime" type="time" formProps={formProps} />
          </Col>
        </Row>
      </FormGroup>
      <div className="d-flex">
        <Button color="primary" className="ml-auto" type="submit" disabled={isSubmitting}>
          {isSubmitting && <Spinner className="spinner-border-sm mr-1" tag="span" color="white" />}
          Save
        </Button>
      </div>
    </Form>
  );
};

const ResultsSettings = ({
  client,
  source,
  showDuplicates,
  setShowDuplicates,
  showRss,
  dateRange,
  setDateRange,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endDate,
  setEndDate,
  endTime,
  setEndTime,
  isPopup,
}) => {
  const { clientId, projectId, resultsType } = useParams();

  return (
    <div className={classNames({ "results-settings": true, "results-settings--popup": isPopup })}>
      <div className="results-settings-duplicates">
        {source === "METABASE" && (
          <CustomInput
            type="switch"
            id="showDuplicates"
            label="Duplicates"
            // this is a string as its stored in a cookie
            onChange={(e) => setShowDuplicates(e.target.checked.toString(), { days: COOKIE_EXPIRY })}
            defaultChecked={showDuplicates === "true" ? true : false}
            className={classNames({
              "mr-auto": isPopup,
              "mr-2": !isPopup,
            })}
          />
        )}
        {showRss && (
          <i
            className={classNames({
              "uil-rss": true,
              click: true,
              "mr-2": !isPopup,
            })}
            onClick={() =>
              window.open(
                `${getPublicApiUrlPrefix(client.subdomain)}/rss/${clientId}/${projectId}/${getResultPrefixForSource(
                  source
                )}/${resultsType}`
              )
            }
          />
        )}
      </div>
      <ButtonGroup>
        <Button outline={dateRange !== "1"} color="primary" onClick={() => setDateRange("1", { days: COOKIE_EXPIRY })}>
          24{dateRange === "1" && ` hours`}
        </Button>
        <Button outline={dateRange !== "7"} color="primary" onClick={() => setDateRange("7", { days: COOKIE_EXPIRY })}>
          7{dateRange === "7" && ` days`}
        </Button>
        <Button
          outline={dateRange !== "30"}
          color="primary"
          onClick={() => setDateRange("30", { days: COOKIE_EXPIRY })}
        >
          30{dateRange === "30" && ` days`}
        </Button>
        <Button
          outline={dateRange !== "90"}
          color="primary"
          onClick={() => setDateRange("90", { days: COOKIE_EXPIRY })}
        >
          90{dateRange === "90" && ` days`}
        </Button>
        <UncontrolledButtonDropdown>
          <DropdownToggle caret outline={dateRange !== "0"} color="primary">
            Custom
          </DropdownToggle>
          <DropdownMenu right className="results-date-dropdown">
            <CustomTimeframeForm
              setDateRange={setDateRange}
              startDate={startDate}
              setStartDate={setStartDate}
              startTime={startTime}
              setStartTime={setStartTime}
              endDate={endDate}
              setEndDate={setEndDate}
              endTime={endTime}
              setEndTime={setEndTime}
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </ButtonGroup>
    </div>
  );
};

export default ResultsSettings;

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RTL_LANGUAGES, TRANSLATE_LANGUAGES, languageWordBoundary } from "../../lib/constants";
import React, { useContext, useEffect, useState } from "react";
import { formatDateTime, getExportDate, getLinkedItemName, getLinkedItemNames } from "../../lib/utils";
import { gql, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router";

import AlertModal from "../Modals/AlertModal";
import { AppContext } from "../../App";
import EntityModal from "../Modals/EntityModal";
import Highlighter from "react-highlight-words";
import ResultControls from "./ResultControls";
import ResultPDF from "./ResultPDF";
import ShareModal from "../Modals/ShareModal";
import Spinner from "../../components/Spinner";
import { TwitterTweetEmbed } from "react-twitter-embed";
import classNames from "classnames";
import { createConfirmation } from "react-confirm";
import escapeStringRegexp from "escape-string-regexp";
import { numberFormat } from "humanize";
import { pdf } from "@react-pdf/renderer";
import pick from "lodash/pick";
import { saveAs } from "file-saver";
import { updateResult } from "../../graphql/mutations";
import { useMediaBreakpointDownSm } from "../lib/effects";

const ResultPanelStory = ({
  result,
  resultsType,
  filter,
  duplicates,
  highlight,
  translation,
  translationLoading,
  translatedResult,
  translate,
  isPreview,
  user,
  projectData,
}) => {
  const history = useHistory();
  const { clientId, projectId } = useParams();
  const appContext = useContext(AppContext);

  const alertModal = createConfirmation(AlertModal);

  const [modal, toggleModal] = useState(null);
  const [shareModal, toggleShareModal] = useState(null);

  const [isChangingPriorityIssue, setIsChangingPriorityIssue] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [mutationUpdateResult] = useMutation(gql(updateResult));

  const isMediaBreakpointDownSm = useMediaBreakpointDownSm();

  // facebook embed
  useEffect(() => {
    if (result.source === "SOCIAL360" && result.sourceType === "Facebook") window.FB.XFBML.parse();
  }, [result]);

  // mapping of name (or alternative) to confirmed entity, giving precedence to confirmed
  const people = {};
  for (const person of result.people || []) {
    people[person.toLowerCase()] = null;
  }
  for (const person of projectData.people) {
    for (const name of [person.name, ...(person.alternativeNames || [])]) {
      people[name.toLowerCase()] = person;
    }
  }
  const peopleKeys = Object.keys(people);

  // mapping of name (or alternative) to confirmed entity, giving precedence to confirmed
  const organisations = {};
  for (const organisation of result.organisations || []) {
    organisations[organisation.toLowerCase()] = null;
  }
  for (const organisation of projectData.organisations) {
    for (const name of [organisation.name, ...(organisation.alternativeNames || [])]) {
      organisations[name.toLowerCase()] = organisation;
    }
  }
  const organisationsKeys = Object.keys(organisations);

  // highlight keywords and entities
  const highlightTerms = highlight.terms;
  const highlightTermsForComparison = highlight.terms.map((x) => x.toLowerCase());
  const highlightAll = [...highlightTerms, ...peopleKeys, ...organisationsKeys];
  const wordBoundary = languageWordBoundary(result.language);

  // estimate 200 words per minute
  const wordsToMinutes = (wordCount) => Math.ceil(wordCount / 200);

  const handleChangePriorityIssue = async (priorityIssue) => {
    if (isChangingPriorityIssue) return;
    setIsChangingPriorityIssue(true);

    // top status already set given change priority issue controls are shown
    // just update the priority issue
    const mutation = {
      commentFilterId: priorityIssue.id,
    };

    try {
      await mutationUpdateResult({
        variables: {
          input: {
            ...pick(result, ["clientId", "projectId", "id", "duplicateGroupSource"]),
            ...mutation,
          },
        },
      });
    } catch (e) {
      appContext.handleError(e);
    }

    setIsChangingPriorityIssue(false);
  };

  const renderLanguage = () => {
    return (
      <UncontrolledDropdown>
        <i
          className={classNames({
            "uil-globe": true,
            click: true,
            "mr-1": true,
            "color-orange": translatedResult,
          })}
          onClick={() => translate(translation !== user.language ? user.language : result.language)}
        />
        <DropdownToggle tag="div" caret>
          {translationLoading && <Spinner className="spinner-border-xs spinner-border-xs--translate mr-1" tag="span" />}
          {translation}
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(TRANSLATE_LANGUAGES).map((language) => (
            <DropdownItem key={language} onClick={() => translate(language)} active={language === translation}>
              {language}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const renderAuthor = () => {
    const name = result.authorName;
    if (!name) return;
    const comparison = name.toLowerCase();
    // author is an unfollowed person or organisation (they can be either), once followed we know they which type they are
    const isPerson = peopleKeys.includes(comparison);
    const isOrganisation = organisationsKeys.includes(comparison);
    const isAuthor = !isPerson && !isOrganisation;
    // see if there are entities
    let entity = null,
      type = null;
    if (isPerson) {
      entity = people[comparison];
      type = "PERSON";
    }
    if (isOrganisation) {
      entity = organisations[comparison];
      type = "ORGANISATION";
    }
    return (
      <div className="result-author text-primary">
        <span className="font-semibold">Author:</span>
        {!projectData.comprehendEnabled && !entity ? (
          ` ${result.authorName}`
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle tag="div">
              <mark
                className={classNames({
                  entity: true,
                  author: isAuthor,
                  person: isPerson,
                  organisation: isOrganisation,
                  stakeholder: entity,
                  "entity--clickable":
                    !isPreview &&
                    projectData.comprehendEnabled &&
                    (user.can.updateStakeholders || (!user.can.updateStakeholders && entity)),
                })}
                onClick={(e) => {
                  if (isPreview || !projectData.comprehendEnabled) return false;
                  // edit entity, or show dropdown to create entity
                  if (entity) {
                    e.stopPropagation();
                    if (user.can.updateStakeholders) {
                      toggleModal({ name, entity, type });
                    } else {
                      history.push(
                        `/${clientId}/${projectId}/stakeholders/${
                          type === "PERSON" ? "people" : "organisations"
                        }/all/all/${entity.id}`
                      );
                    }
                  }
                }}
              >
                {result.authorName}
                {entity && entity.tags && <i className="uil-check-circle" />}
              </mark>
            </DropdownToggle>
            {user.can.updateStakeholders && !isPreview && projectData.comprehendEnabled && (
              <DropdownMenu>
                <DropdownItem header className="mt-0">
                  Author is a...
                </DropdownItem>
                <DropdownItem onClick={() => toggleModal({ name, entity, type: "PERSON" })}>Person</DropdownItem>
                <DropdownItem onClick={() => toggleModal({ name, entity, type: "ORGANISATION" })}>
                  Organisation
                </DropdownItem>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        )}
      </div>
    );
  };

  const downloadPDF = async () => {
    const filename = `${result.title || "No Title"} - ${getExportDate()}.pdf`;
    const blob = await pdf(
      <ResultPDF
        title={title}
        content={content}
        url={url}
        details={pdfDetails}
        agents={agents}
        result={result}
        user={user}
      />
    ).toBlob();
    saveAs(blob, filename);
  };

  let title = result.title;
  let content = result.content;
  if (translatedResult) {
    title = translatedResult.title;
    content = translatedResult.content;
  }

  // only show link if it exists and is not nla-eclipsweb
  const url = result.url && !result.url.includes("nla-eclipsweb") ? result.url : null;

  // if sharing use UTC
  const details = (
    <>
      {formatDateTime(result.publishedDate, user.timezone)} | {result.sourceName} | {result.sourceType}
    </>
  );
  const pdfDetails = (
    <>
      {formatDateTime(result.publishedDate)} (UTC) | {result.sourceName} | {result.sourceType}
    </>
  );

  // get linked items
  const agents = getLinkedItemNames(projectData.agents, result.agents);
  const priorityIssue = getLinkedItemName(projectData.filters, result.commentFilterId);

  // dropdown to change priority issue
  const priorityIssues = projectData.filters.filter((x) => x.source === result.source);

  return (
    <div
      className={classNames({
        "result-story": true,
        "result-story--preview": isPreview,
        "result-content": true,
        "result-content--rtl": RTL_LANGUAGES.includes(translation),
      })}
    >
      {user.can.updateResult && !isPreview && (
        <ResultControls
          result={result}
          resultsType={resultsType}
          filter={filter}
          duplicates={duplicates}
          source={result.source}
          projectData={projectData}
        />
      )}

      <UncontrolledDropdown
        className={classNames({
          "card-widgets": true,
          // normally in card, except in preview modal
          "mt-1": !isPreview,
          "font-16": isPreview,
          "float-right": isPreview,
        })}
      >
        <DropdownToggle tag="a" onClick={(e) => e.stopPropagation()}>
          <i className="uil-share-alt" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              toggleShareModal([result.id]);
            }}
          >
            <i className="uil-envelope mr-1" />
            Email
          </DropdownItem>
          <DropdownItem
            onClick={async () => {
              if (isDownloading) return;
              setIsDownloading(true);
              try {
                await downloadPDF();
              } catch (e) {
                return await alertModal({
                  message:
                    "We're sorry but an error occurred when preparing your PDF file. Our technical team have been informed of the error.",
                });
              }
              setIsDownloading(false);
            }}
          >
            <i className="uil-file-alt mr-1" />
            PDF
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <p
        className="result-title text-primary font-20 font-semibold mb-2"
        dir={RTL_LANGUAGES.includes(translation) ? "rtl" : "ltr"}
        title={user.can.debug && result.source === "SOCIAL360" ? result.socialInternalId : ""}
      >
        {title ? (
          <Highlighter
            searchWords={highlight.terms.map((word) => `${wordBoundary}${escapeStringRegexp(word)}${wordBoundary}`)} // match full words
            autoEscape={false}
            textToHighlight={title}
            highlightTag="u"
          />
        ) : (
          "No Title"
        )}
      </p>

      {result.source === "METABASE" && (result.authorName || url) && (
        <div className="text-primary">
          {renderAuthor()}
          {url && (
            <p className="mb-0">
              <a href={url} rel="noopener noreferrer" target="_blank">
                View original article{result.sourceUrl && ` at ${result.sourceUrl}`}
              </a>{" "}
              <i className="uil-external-link-alt" />
            </p>
          )}
        </div>
      )}

      {result.source === "SOCIAL360" && (
        <div className="text-primary">
          <p className="mb-0">
            <span className="font-semibold">{result.authorEmail}</span> | Posts: {numberFormat(result.authorPosts, 0)} |
            Followers: {numberFormat(result.authorFollowers, 0)} | Following: {numberFormat(result.authorFollowing, 0)}
          </p>
          <p className="mb-0">
            <a href={result.url} rel="noopener noreferrer" target="_blank">
              View original post
            </a>{" "}
            <i className="uil-external-link-alt" />
          </p>
        </div>
      )}

      <div className="d-flex mt-2" style={{ flexDirection: isMediaBreakpointDownSm ? "column" : "row" }}>
        <p className="text-muted mb-0 mr-auto">
          {details}
          {priorityIssue && (
            <>
              <br />
              <UncontrolledDropdown>
                <DropdownToggle tag="span" className="color-purple" caret={priorityIssues.length > 0 && !isPreview}>
                  <i className="mdi mdi-star result-linked-item result-linked-item--filter" />
                  {priorityIssue}
                </DropdownToggle>
                {priorityIssues.length > 0 && !isPreview && (
                  <DropdownMenu right>
                    <DropdownItem header>Change Priority Issue</DropdownItem>
                    {priorityIssues
                      .filter((x) => x.id !== result.commentFilterId)
                      .map((filter) => (
                        <DropdownItem
                          key={filter.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChangePriorityIssue(filter);
                          }}
                        >
                          {filter.name}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                )}
              </UncontrolledDropdown>
            </>
          )}
          {agents && (
            <>
              {!priorityIssue && <br />}
              <span className="color-orange">
                <i className="uil-search-alt result-linked-item result-linked-item--agent" />
                {agents.join(", ")}
              </span>
            </>
          )}
        </p>
        {!isMediaBreakpointDownSm ? (
          <div className="text-right pl-2" style={{ whiteSpace: "nowrap" }}>
            {result.wordCount && <p className="mb-0">{wordsToMinutes(result.wordCount)} minute read</p>}
            <div className="result-language">{renderLanguage()}</div>
          </div>
        ) : (
          <div className="d-flex pt-2" style={{ whiteSpace: "nowrap" }}>
            {result.wordCount && <p className="mb-0 mr-auto">{wordsToMinutes(result.wordCount)} minute read</p>}
            <div className="result-language pl-2">{renderLanguage()}</div>
          </div>
        )}
      </div>

      <hr />

      {result.source === "METABASE" && result.image && (
        <img className="result-image" src={result.image} alt={result.title} style={{ maxWidth: 300, maxHeight: 300 }} />
      )}

      <div className="result-text text-primary font-16" dir={RTL_LANGUAGES.includes(translation) ? "rtl" : "ltr"}>
        {content
          ? content.split("\n").map((paragraph, paragraphIndex) => {
              return (
                paragraph.trim() && (
                  <p key={paragraphIndex}>
                    <Highlighter
                      searchWords={highlightAll.map(
                        (word) => `${wordBoundary}${escapeStringRegexp(word)}${wordBoundary}`
                      )} // match full words
                      autoEscape={false}
                      textToHighlight={paragraph}
                      highlightTag={({ children, highlightIndex }) => {
                        // compare using lowercase
                        const comparison = children.toLowerCase();

                        // if word is an entity as well as a term, entity takes priority
                        if (peopleKeys.includes(comparison)) {
                          const entity = people[comparison];
                          return (
                            <>
                              <mark
                                className={classNames({
                                  entity: true,
                                  person: true,
                                  stakeholder: entity,
                                  "entity--clickable":
                                    !isPreview &&
                                    projectData.comprehendEnabled &&
                                    (user.can.updateStakeholders || (!user.can.updateStakeholders && entity)),
                                })}
                                onClick={() => {
                                  if (isPreview || !projectData.comprehendEnabled) return false;
                                  if (user.can.updateStakeholders) {
                                    toggleModal({ name: children, entity, type: "PERSON" });
                                  } else if (entity) {
                                    history.push(`/${clientId}/${projectId}/stakeholders/people/all/all/${entity.id}`);
                                  }
                                }}
                              >
                                {highlightTerms.includes(comparison) ? <u>{children}</u> : <>{children}</>}
                                {entity && entity.tags && <i className="uil-check-circle" />}
                              </mark>
                            </>
                          );
                        }
                        if (organisationsKeys.includes(comparison)) {
                          const entity = organisations[comparison];
                          return (
                            <>
                              <mark
                                className={classNames({
                                  entity: true,
                                  organisation: true,
                                  stakeholder: entity,
                                  "entity--clickable":
                                    !isPreview &&
                                    projectData.comprehendEnabled &&
                                    (user.can.updateStakeholders || (!user.can.updateStakeholders && entity)),
                                })}
                                onClick={() => {
                                  if (isPreview || !projectData.comprehendEnabled) return false;
                                  if (user.can.updateStakeholders) {
                                    toggleModal({ name: children, entity, type: "ORGANISATION" });
                                  } else if (entity) {
                                    history.push(
                                      `/${clientId}/${projectId}/stakeholders/organisations/all/all/${entity.id}`
                                    );
                                  }
                                }}
                              >
                                {highlightTerms.includes(comparison) ? <u>{children}</u> : <>{children}</>}
                                {entity && entity.tags && <i className="uil-check-circle" />}
                              </mark>
                            </>
                          );
                        }
                        // highlight terms
                        if (highlightTermsForComparison.includes(comparison)) {
                          return <u>{children}</u>;
                        }
                        // otherwise return word as normal
                        return children;
                      }}
                    />
                  </p>
                )
              );
            })
          : "No article text available."}
      </div>

      {result.source === "SOCIAL360" && (
        <>
          <hr />
          {result.source === "SOCIAL360" && result.sourceType === "Twitter" && (
            <div className="result-embed result-embed-twitter">
              <TwitterTweetEmbed tweetId={result.url.split("/").pop()} />
            </div>
          )}

          {result.source === "SOCIAL360" && result.sourceType === "Facebook" && (
            <div className="result-embed result-embed-facebook">
              <div className="fb-post" data-href={result.url}></div>
            </div>
          )}
        </>
      )}

      {modal && <EntityModal data={modal} toggle={toggleModal} projectData={projectData} />}

      {shareModal && <ShareModal data={shareModal} toggle={toggleShareModal} user={user} projectData={projectData} />}
    </div>
  );
};

export default ResultPanelStory;
